import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '../../constants/businessConstants'
import {
  Expense,
  fetchExpensesBreakdown,
  fetchOtherBreakdown,
  fetchProfitsBreakdown,
  fetchUncategorizedBreakdown,
} from '../../actions/reportActions'
import { fetchTransactionCategoriesIfNeeded } from './reports.slice'
import { fetchFilteredUserTransactions } from '../Transactions/transactions.slice'
import './Reports.scss'
import ProfitAndLossReports from './ProfitAndLossReports'
import { transactionFilterToQueryUrlService } from '../../services/transactionFilterToQueryUrlService'
import { MAX_TRANSACTION_LIMIT } from '../../constants/transactionConstants'
import {
  Button,
  DatePicker,
  GridRowColumn,
  Link,
} from '../../components/BaseComponents'
import { getCurrentUser } from '../../selectors/user.selectors'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { useAppDispatch } from '../../utils/typeHelpers'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import PageHeader from '../../components/shared/PageHeader'
import PdfWrapper, {
  PdfWrapperHandle,
} from '../../components/shared/PdfWrapper'
import ProfitAndLossPDF from './ProfitAndLossPDF'
import FreeTrialUnreviewedAlert from '../../components/shared/FreeTrialUnreviewedAlert'

const ProfitAndLossPanel = () => {
  const dispatch = useAppDispatch()
  const pdfRef = useRef<PdfWrapperHandle>(null)

  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const [searchParams] = useSearchParams()

  const [profitsObj, setProfitsObj] = useState<{
    profits: Expense[]
    sum: number | string
  } | null>(null)
  const [expensesObj, setExpensesObj] = useState<{
    expenses: Expense[]
    sum: number | string
  } | null>(null)
  const [otherObj, setOtherObj] = useState<{
    otherExpenses: Expense[]
    sum: number | string
  } | null>(null)
  const [uncategorizedObj, setUncategorizedObj] = useState<{
    sum: number
    transactionCategoryId: number | null
  } | null>(null)
  const [fetchingData, setFetchingData] = useState(true)
  const [startDate, setStartDate] = useState(
    DEFAULT_START_DATE.format(DATE_FORMATS.INPUT)
  )
  const [endDate, setEndDate] = useState(
    DEFAULT_END_DATE.format(DATE_FORMATS.INPUT)
  )

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  const handleDateRangeChange = useCallback(
    ([startDate, endDate]: [string, string]) => {
      setStartDate(startDate)
      setEndDate(endDate)
    },
    []
  )

  useEffect(() => {
    const startDate = searchParams.get('startDate')
    const endDate = searchParams.get('endDate')
    if (startDate) {
      setStartDate(startDate)
    }
    if (endDate) {
      setEndDate(endDate)
    }
  }, [searchParams])

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      // const { startDate, endDate } = this.state
      // Default query to fetch all if there is no start or end date
      const query = {
        year: !startDate || !endDate ? 'all' : undefined,
        startDate:
          startDate && endDate ? moment(startDate, 'MM-DD-YYYY') : undefined,
        endDate:
          startDate && endDate ? moment(endDate, 'MM-DD-YYYY') : undefined,
      }

      const [profits, expenses, uncategorized, other] = await Promise.all([
        dispatch(fetchProfitsBreakdown(query)),
        dispatch(fetchExpensesBreakdown(query)),
        dispatch(fetchUncategorizedBreakdown(query)),
        dispatch(fetchOtherBreakdown(query)),
        currentUser?.id &&
          fetchFilteredUserTransactions(
            transactionFilterToQueryUrlService({
              startDate: startDate
                ? moment(startDate, 'MM-DD-YYYY').format(DATE_FORMATS.INPUT)
                : '',
              endDate: endDate
                ? moment(endDate, 'MM-DD-YYYY').format(DATE_FORMATS.INPUT)
                : '',
              limit: MAX_TRANSACTION_LIMIT,
              userId: currentUser.id,
            })
          )(dispatch),
      ])
      setProfitsObj(profits)
      setExpensesObj(expenses)
      setUncategorizedObj(uncategorized)
      setOtherObj(other)
      setFetchingData(false)
    }
    fetchData()
  }, [dispatch, endDate, startDate, currentUser?.id])

  const fromInsights = useMemo(() => {
    return searchParams.get('from') === 'insights'
  }, [searchParams])

  return (
    <>
      {fromInsights && (
        <Link to="/insights/overview" style={{ marginBottom: 12 }}>
          <Icon name="arrow left" style={{ marginTop: -10 }} />
          Back to Practice Insights
        </Link>
      )}
      <PageHeader
        header="Profit and Loss Statement"
        extraContent={
          !fetchingData &&
          profitsObj && (
            <Button onClick={pdfRef.current?.generatePDF}>
              <Icon name="download" style={{ marginRight: 8 }} />
              {isMobile ? 'Download' : 'Download Statement'}
            </Button>
          )
        }
      />
      <Grid className="statementTitle" style={{ margin: '0 5px' }}>
        <GridRowColumn computer={5} tablet={7} mobile={10}>
          <DatePicker
            clearable
            value={`${startDate} - ${endDate}`}
            onChange={handleDateRangeChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            fullWidth
          />
        </GridRowColumn>

        <GridRowColumn>
          <FreeTrialUnreviewedAlert />
        </GridRowColumn>

        <GridRowColumn>
          <ProfitAndLossReports
            startDate={startDate}
            endDate={endDate}
            profitsObj={profitsObj}
            expensesObj={expensesObj}
            otherObj={otherObj}
            fetchingData={fetchingData}
            uncategorizedObj={uncategorizedObj}
            userIdForAdmin={null}
          />
        </GridRowColumn>
        <Grid.Row />
      </Grid>

      <PdfWrapper
        filename={`profit_and_loss_${currentUser?.firstName}_${currentUser?.lastName}.pdf`}
        ref={pdfRef}
        hideComponent
        hideMargin
      >
        <ProfitAndLossPDF
          expensesObj={expensesObj}
          profitsObj={profitsObj}
          startDate={startDate}
          endDate={endDate}
        />
      </PdfWrapper>
    </>
  )
}

export default ProfitAndLossPanel
