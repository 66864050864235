import { useCallback } from 'react'
import { useUsersnapCustomDisplayLogicWidget } from '../../components/shared/UsersnapSurvey'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { track } from '@amplitude/analytics-browser'
import { selectMembershipStart } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'
import { DateTime } from 'luxon'

/**
 *  This is a TEMPORARY onboarding survey to collect feedback on the onboarding experience.
 *
 *  It will be removed after the survey is no longer needed.
 *
 * Survey will run: March 12 - April 12, 2025
 *
 * This is:
 * Survey #2: Post complete sign up/ payment flow in Platform
 * https://www.notion.so/heard/Make-Onboarding-Fast-Easy-18f591be52578004a6ffd06df62b8f5c?pvs=4#1ae591be5257805f873ce3010aec2a88
 */
const SignupSuccessSurvey = () => {
  const onSubmit = useCallback(() => {
    track('completed sign up success survey: survey #2')
  }, [])
  const showObSurvey = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.showOnboardingSurveys,
    false
  )
  const membershipStartDate = useReselector(selectMembershipStart)
  // Define the cutoff date
  const cutoffDate = DateTime.fromISO('2025-03-12')
  const shouldShowSurvey =
    showObSurvey &&
    membershipStartDate &&
    DateTime.fromISO(membershipStartDate) >= cutoffDate

  useUsersnapCustomDisplayLogicWidget({
    projectKey: '5832c99e-fa07-4017-a8cf-ed722f88e38b',
    shouldShow: shouldShowSurvey || false,
    maxTimesShown: 3,
    showAgainAfterDays: 3,
    onSubmit,
  })
  return null
}

export default SignupSuccessSurvey
