import { Grid, Loader } from 'semantic-ui-react'
import { Button, Card, Icon, Text } from '../../../components/BaseComponents'
import { useEffect, useMemo, useState } from 'react'
import {
  INSIGHTS_CHANGE_TYPE,
  INSIGHTS_TYPE,
  InsightsRowAPIResponse,
  formatPercentage,
  formatSummary,
  getInsightsMonthYear,
} from '../../../components/Finances/shared/utils'
import {
  useFetchResponse,
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import {
  FETCH_INSIGHTS_SUMMARY_KEY,
  fetchInsightsSummary,
  fetchInsightsTableData,
} from '../../../components/Finances/shared/financeActions'
import { SummaryTextForExpenses } from '../../../components/Finances/shared/CurrentSummaryCard'
import { getIsFetching } from '../../../reducers/fetch'
import { DateTime } from 'luxon'
import { StackedIcon } from '../../../components/BaseComponents/Icon'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { orderBy } from 'lodash'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const ExpensesInsightsCard = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const getEndDate = useGetEndDateForFinancialInsights(DateTime.now())
  const [topExpenseCategory, setTopExpenseCategory] =
    useState<InsightsRowAPIResponse>()

  useEffect(() => {
    pageView('rexpense change card')
  }, [pageView])

  const { month, year } = useMemo(() => {
    return getInsightsMonthYear(getEndDate())
  }, [getEndDate])

  const summaryDataResponse = useFetchResponse(
    fetchInsightsSummary,
    INSIGHTS_TYPE.EXPENSES,
    month,
    year
  )

  const summaryData = summaryDataResponse?.[0]

  const formattedData = useMemo(() => {
    return formatSummary(summaryData, INSIGHTS_TYPE.EXPENSES)
  }, [summaryData])

  const changeType = useMemo(() => {
    if (!summaryData) return INSIGHTS_CHANGE_TYPE.EMPTY
    const {
      percent_change_from_previous_month_decimal,
      previous_month_total_in_cents,
      target_month_total_in_cents,
    } = summaryData
    if (previous_month_total_in_cents === 0) {
      return INSIGHTS_CHANGE_TYPE.LAST_MONTH_EMPTY
    } else if (target_month_total_in_cents === 0) {
      return INSIGHTS_CHANGE_TYPE.EMPTY
    } else if (percent_change_from_previous_month_decimal > 0) {
      return INSIGHTS_CHANGE_TYPE.INCREASE
    } else if (percent_change_from_previous_month_decimal < 0) {
      return INSIGHTS_CHANGE_TYPE.DECREASE
    }
    return INSIGHTS_CHANGE_TYPE.SAME
  }, [summaryData])

  const getIconImg = (changeType: string) => {
    switch (changeType) {
      case INSIGHTS_CHANGE_TYPE.INCREASE:
        return solid('arrow-up')
      case INSIGHTS_CHANGE_TYPE.DECREASE:
        return solid('arrow-down')
      default:
        return solid('arrows-up-down')
    }
  }

  useEffect(() => {
    const fetchExpenseBreakdownData = async () => {
      const endDate = DateTime.now().toISODate()
      const startDate = DateTime.now().startOf('month').toISODate()

      const expenseData = await fetchInsightsTableData(
        INSIGHTS_TYPE.EXPENSES,
        startDate,
        endDate
      )(dispatch)

      if (expenseData && expenseData.length > 0) {
        const sortedExpenses = orderBy(
          expenseData,
          'transactions_sum_in_cents',
          'asc'
        )

        const topExpense = sortedExpenses[0]
        setTopExpenseCategory(topExpense)
      }
    }

    if (changeType === INSIGHTS_CHANGE_TYPE.INCREASE) {
      fetchExpenseBreakdownData()
    }
  }, [changeType, dispatch, getEndDate])

  const queryLoading = useReselector(getIsFetching, FETCH_INSIGHTS_SUMMARY_KEY)
  return (
    <Card type="subsection" backgroundColor="natural">
      {queryLoading && <Loader />}
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Text as="h2">Expenses Overview</Text>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={1}>
            <StackedIcon
              title={'none-status-icon'}
              color="white"
              size="1x"
              backgroundIcon={solid('circle')}
              backgroundSize={'3x'}
              icon={getIconImg(changeType)}
              backgroundColor={
                changeType === INSIGHTS_CHANGE_TYPE.INCREASE
                  ? 'orange'
                  : changeType === INSIGHTS_CHANGE_TYPE.DECREASE ||
                      changeType === INSIGHTS_CHANGE_TYPE.SAME
                    ? 'green'
                    : 'green'
              }
            />
          </Grid.Column>
          <Grid.Column width={13} style={{ marginLeft: '20px' }}>
            <SummaryTextForExpenses
              changeType={changeType}
              currentMonth={formattedData?.month_name}
              prevMonth={formattedData?.previous_month_name}
              amount={formattedData?.month_total}
              formattedPercentChange={formatPercentage(
                formattedData?.percent_change
              )}
              formattedPercentCounterpart={formatPercentage(
                formattedData?.percent_counterpart_change
              )}
              prevMonthTotal={formattedData?.previous_month_total}
              textForDashboardCard
            />
            {changeType === INSIGHTS_CHANGE_TYPE.INCREASE &&
              topExpenseCategory && (
                <Text color="darkGray" style={{ marginTop: '10px' }}>
                  Your highest spend is
                  <CurrencyFormatLabel
                    value={formatCurrencyFromCents(
                      topExpenseCategory.transactions_sum_in_cents
                    )}
                  />
                  in <b>{topExpenseCategory.transaction_category_name}</b>.
                </Text>
              )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row width={14}>
          <Grid.Column>
            <Button
              variant="secondary"
              onClick={() => {
                navigate('/insights/expenses')
                track('clicked view expenses button', {
                  button: 'view expenses',
                })
              }}
            >
              View Expenses{' '}
              <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default ExpensesInsightsCard
