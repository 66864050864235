import {
  Text,
  Button,
  Card,
  GridRowColumn,
  Link,
  Icon,
  Modal,
  Alert,
} from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useCallback, useState } from 'react'
import { fetchBillingPortalSession } from '../../../actions/settings/billingActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useReselector } from '../../../utils/sharedHooks'
import { selectSampleTaxCalculation } from '../../../selectors/userTaxCalculation.selectors'
import EstimateBreakdownV3 from './components/EstimateBreakdown/EstimateBreakdownV3'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { selectIsBasicPlanWithoutQTE } from '../../../reducers/subscription.slice'

export const BasicPlanQTEUpsellBanner = () => {
  const dispatch = useAppDispatch()
  const isBasicPlanWithoutQTE = useReselector(selectIsBasicPlanWithoutQTE)
  const redirectToStripePortal = useCallback(async () => {
    const res = await fetchBillingPortalSession()(dispatch)
    if (res) {
      window.location.href = res.url
    }
  }, [dispatch])

  if (!isBasicPlanWithoutQTE) {
    return null
  }

  return (
    <div
      style={{
        padding: 16,
        display: 'flex',
        gap: 16,
        backgroundColor: Colors.lightYellow,
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Text as="h3">You&apos;re currently on a Basic Subscription Plan.</Text>
        <Text>
          Upgrade your plan and get quarterly tax estimates, annual tax
          preparation and more.
        </Text>
      </div>
      <Button variant="secondary" onClick={redirectToStripePortal}>
        Explore Plans
      </Button>
    </div>
  )
}

const BasicPlanQTEInfoCard = () => {
  return (
    <Card type="section" backgroundColor="stone40">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={4}>
            <Image
              size="medium"
              src="https://heard-images.s3.amazonaws.com/assets/bank-auto.svg"
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid>
              <GridRowColumn>
                <Text as="h2">What are quarterly taxes?</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg">
                  As a therapy practice owner, there&apos;s a very good chance
                  you need to pay quarterly taxes. Rather than paying the IRS
                  all the taxes you owe them in one lump sum at the end of the
                  year, you pay it to them gradually, in quarterly installments.
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg">
                  You&apos;ll need to figure out how to estimate the taxes you
                  owe, and factor quarterly payments into your budget. Check out
                  the following resources to help you calculate an estimate.
                </Text>
              </GridRowColumn>
              <GridRowColumn
                columnStyle={{
                  display: 'flex',
                  gap: 8,
                  flexDirection: 'column',
                }}
              >
                <Link
                  href="https://www.joinheard.com/articles/how-to-pay-quarterly-estimated-taxes-for-your-therapy-practice"
                  newPage
                >
                  Read article{' '}
                  <Icon
                    style={{ marginLeft: 4 }}
                    icon={regular('arrow-up-right-from-square')}
                  />
                </Link>
                <Link
                  href="https://www.joinheard.com/events/navigating-quarterly-taxes-as-a-therapist"
                  newPage
                >
                  Watch video
                  <Icon
                    style={{ marginLeft: 4 }}
                    icon={regular('arrow-up-right-from-square')}
                  />
                </Link>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

const BasicPlanQTEUpsellCard = () => {
  const dispatch = useAppDispatch()
  const [exampleBreakdownModalOpen, setExampleBreakdownModalOpen] =
    useState(false)
  const sampleCalculation = useReselector(selectSampleTaxCalculation)
  const estimateDetails = useReselector(selectActiveQuarterlyTaxEstimateDetails)

  const redirectToStripePortal = useCallback(async () => {
    const res = await fetchBillingPortalSession()(dispatch)
    if (res) {
      window.location.href = res.url
    }
  }, [dispatch])

  return (
    <>
      <Card type="section" backgroundColor="natural">
        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={4}>
              <Image
                size="medium"
                src="https://heard-images.s3.amazonaws.com/assets/calculator2.svg"
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                <GridRowColumn>
                  <Text as="h2">Want quarterly taxes estimated for you?</Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Text as="bodyLg">
                    Upgrade your plan and we&apos;ll help you calculate your
                    quarterly tax estimates. We&apos;ll gather information from
                    you to calculate an estimate, and provide a detailed
                    breakdown of how we got to that number.
                  </Text>
                </GridRowColumn>
                <GridRowColumn>
                  <Button
                    variant="secondaryLink"
                    onClick={() => setExampleBreakdownModalOpen(true)}
                  >
                    View breakdown example
                  </Button>
                </GridRowColumn>
                <GridRowColumn>
                  <Button onClick={redirectToStripePortal}>
                    Explore Plans
                  </Button>
                </GridRowColumn>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Modal
        open={exampleBreakdownModalOpen}
        onClose={() => setExampleBreakdownModalOpen(false)}
        size="fullscreen"
      >
        <Modal.Content>
          <Grid>
            <Grid.Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="secondaryLink"
                onClick={() => setExampleBreakdownModalOpen(false)}
              >
                <Icon icon={regular('xmark')} />
              </Button>
            </Grid.Row>
            <Grid.Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
              }}
            >
              <Alert type="warn" style={{ flexGrow: 1 }}>
                <Text as="h2">Using sample data</Text>
              </Alert>
            </Grid.Row>
            <EstimateBreakdownV3
              taxCalc={sampleCalculation}
              estimateDetails={estimateDetails}
              isSample
            />
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  )
}

export const BasicPlanQTEUpsell = () => {
  return (
    <div style={{ display: 'flex', gap: 42, flexDirection: 'column' }}>
      <BasicPlanQTEInfoCard />
      <BasicPlanQTEUpsellCard />
    </div>
  )
}
