import { useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { useSearchParams } from 'react-router-dom'
import { Alert, GridRowColumn, Link, Text } from '../../BaseComponents'
import { ArticleCard } from '../shared/ArticleCard'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import InsightsBreakdownTable from '../shared/InsightsBreakdownTable'
import { CurrentPanel } from '../shared/CurrentPanel'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { isEmpty } from 'lodash'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { BannerCard } from '../../BaseComponents/BannerCard'
import { fetchInsightsTableDropdownOptions } from '../shared/financeActions'
import {
  InsightsDatePicker,
  useInsightsDatePicker,
} from '../shared/InsightsDatePicker'
import { InsightBreadcrumb } from '../shared/InsightBreadcrumb'

const InsightsIncomePanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)
  const [years, setYears] = useState<number[]>([])

  const [searchParams] = useSearchParams()

  const { monthParam, yearParam } = useMemo(() => {
    return {
      monthParam: searchParams.get('month'),
      yearParam: searchParams.get('year'),
    }
  }, [searchParams])

  const { yearFilter, monthFilter, inputDate, setYearFilter, setMonthFilter } =
    useInsightsDatePicker(monthParam, yearParam)

  const trackInsights = useTrackFinancialInsightsButtonClick()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const fetchYears = async () => {
      const years = await fetchInsightsTableDropdownOptions(
        INSIGHTS_TYPE.INCOME
      )(dispatch)

      if (years?.length) {
        setYears(years)
      } else {
        setYears([])
      }
    }
    fetchYears()
  }, [dispatch])

  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.INCOME,
    })
  }

  return (
    <Grid padded={isMobile}>
      <GridRowColumn>
        <InsightBreadcrumb month={monthFilter} year={yearFilter} />
      </GridRowColumn>
      <GridRowColumn
        short
        columnStyle={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: 24,
        }}
      >
        <Text as="h1"> Income </Text>
        <InsightsDatePicker
          monthFilter={monthFilter}
          yearFilter={yearFilter}
          handleMonthFilterChange={setMonthFilter}
          handleYearFilterChange={setYearFilter}
          insightsType={INSIGHTS_TYPE.INCOME}
        />
      </GridRowColumn>
      <GridRowColumn short />
      {displayBanner && (
        <GridRowColumn>
          <BannerCard
            headerText="What is Income?"
            imageUrl="https://heard-images.s3.amazonaws.com/assets/pavilion.svg"
            onClose={closeBanner}
            bodyContent="Income is the money you receive in exchange for your services.
                If you have other streams of income deposited into the linked
                accounts, you can see it all here in one place."
          />
        </GridRowColumn>
      )}
      {!hasLinkedPlaidAccounts && (
        <GridRowColumn>
          <Alert>
            <Text>
              You do not have a connected bank account.{' '}
              <Link to={'/accounts#connected-institutions'}>
                Link Business Account
              </Link>
            </Text>
          </Alert>
        </GridRowColumn>
      )}
      <UncategorizedTransactionBanner inputDate={inputDate} />
      <CurrentPanel insightsType={INSIGHTS_TYPE.INCOME} inputDate={inputDate} />
      <TrackInsightsSection type={INSIGHTS_TYPE.INCOME} inputDate={inputDate} />
      <GridRowColumn>
        <Text as="h2">Income Breakdown</Text>
      </GridRowColumn>
      <InsightsBreakdownTable
        insightsType={INSIGHTS_TYPE.INCOME}
        inputDate={inputDate}
        years={years}
      />
      <GridRowColumn>
        <Text as="h2">Learn More</Text>
      </GridRowColumn>
      <Grid.Row className="short" columns={isMobile ? 1 : 3}>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="The Complete List of Income Streams for Therapists"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/the-complete-list-of-income-streams-for-therapists"
            insightsType={INSIGHTS_TYPE.INCOME}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="How to Set Your Fees in Private Practice (Plus 3 Mistakes to Avoid)"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/how-to-set-your-fees-in-private-practice-plus-3-mistakes-to-avoid"
            insightsType={INSIGHTS_TYPE.INCOME}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="Should Therapists Have a Separate Business for Other Income Streams?"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/should-therapists-have-a-separate-business-for-other-income-streams"
            insightsType={INSIGHTS_TYPE.INCOME}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default InsightsIncomePanel
