import { Divider, Grid, Image, Loader } from 'semantic-ui-react'
import {
  Accordion,
  GridRowColumn,
  IconButton,
  Text,
  Button,
} from '../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UserActionItem } from '../../Dashboard/UserActionItems/userActionItems.slice'
import { useAnalyticsTrack } from '../../Amplitude'
import { useEffect } from 'react'
import { BaaUrl } from '../../../actions/helloSignActions'

export const SignBaaCard = ({
  signBaaActionItem,
  baa,
  openSignatureModal,
  fetchingBaa,
  baaSigned,
}: {
  signBaaActionItem: UserActionItem | undefined
  baa: BaaUrl | undefined
  openSignatureModal: () => void
  fetchingBaa: boolean
  baaSigned: boolean
}) => {
  const track = useAnalyticsTrack()
  const hasSignedBAA =
    baaSigned ||
    signBaaActionItem?.status === 'signed' ||
    signBaaActionItem?.completedAt

  useEffect(() => {
    track('viewed sign BAA agreement')
  }, [track])

  const handleButtonClick = async () => {
    await openSignatureModal()
  }

  const handleDownloadBaa = () => {
    if (baa) {
      window.open(baa.fileUrl || '', '_blank')
    }
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <Image
            src={'https://heard-images.s3.amazonaws.com/assets/unsigned.png'}
            style={{
              backgroundColor: '#E4F2E6',
            }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid>
            {!hasSignedBAA ? (
              <>
                <GridRowColumn>
                  <Text
                    as="h3"
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    Let&apos;s make it official!
                  </Text>
                  <Text
                    style={{
                      marginBottom: 6,
                    }}
                  >
                    Heard cares about security and your personal data. To
                    continue, we&apos;ll need you to sign a Business Associate
                    Agreement (BAA).
                  </Text>
                  <Text>
                    This is a contract that protects your business. It allows us
                    to securely handle data that could include sensitive
                    details, such as your clients&apos; identifying information.
                  </Text>
                </GridRowColumn>
                <GridRowColumn
                  style={{
                    marginBottom: 20,
                  }}
                >
                  {fetchingBaa ? (
                    <Loader active inline />
                  ) : (
                    <Button onClick={handleButtonClick}>Sign BAA</Button>
                  )}
                </GridRowColumn>
              </>
            ) : (
              <>
                <GridRowColumn>
                  <Text
                    as="h3"
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    Thank you for signing the Business Associate Agreement
                  </Text>
                  <Text>
                    You can now download a copy of the BAA for your records.
                    Click Continue to proceed to the next step.
                  </Text>
                </GridRowColumn>
                <GridRowColumn
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Text>
                    Heard Business Associate Agreement{' '}
                    {typeof baa !== 'string' && baa?.fileUrl ? (
                      <IconButton
                        style={{ marginLeft: 16 }}
                        icon={regular('download')}
                        onClick={handleDownloadBaa}
                      />
                    ) : (
                      <Loader active inline style={{ marginLeft: 16 }} />
                    )}
                  </Text>
                </GridRowColumn>
              </>
            )}
            <Divider />
            <GridRowColumn>
              <Accordion
                title="Why does Heard need this document?"
                content={
                  <Text>
                    Your clients&apos; personal information may appear in your
                    transactions. We ask that you sign this agreement to ensure
                    that all of this Protected Health Information (PHI) is
                    secure.
                  </Text>
                }
                variant="text"
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
              />
              <Accordion
                title="What is a Business Associate Agreement?"
                content={
                  <Text>
                    A business associate agreement establishes a legally-binding
                    relationship between HIPAA-covered entities and business
                    associates to ensure complete protection of Protected Health
                    Information (PHI).
                  </Text>
                }
                variant="text"
              />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
