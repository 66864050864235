import { useMemo } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { Link, Icon } from '../../BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'

export const InsightBreadcrumb = ({
  month,
  year,
}: {
  month?: string | number | null
  year?: string | number | null
}) => {
  const shouldDisplayPracticeInsights = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.consolidatedReportsFinancialOverview,
    false
  )

  const insightsLink = useMemo(() => {
    let baseLink = '/insights/overview?'
    if (month) {
      baseLink += `month=${month}&`
    }
    if (year) {
      baseLink += `year=${year}`
    }
    return baseLink
  }, [month, year])

  if (!shouldDisplayPracticeInsights) {
    return null
  }

  return (
    <Link to={insightsLink}>
      <Icon icon={regular('arrow-left')} style={{ marginRight: 8 }} />
      Back to Practice Insights
    </Link>
  )
}
