import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import {
  ALWAYS_FETCH_USER_TAX_ESTIMATES_KEY,
  fetchUserTaxEstimates,
} from './userTaxEstimates.slice'
import QuarterlyTaxPaymentHistory from './components/QuarterlyTaxPaymentHistory'
import {
  FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
} from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import {
  selectActiveQTEDetailsAsDates,
  selectActiveQuarterlyTaxEstimateDetails,
} from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { Tab, Loader } from '../../../components/BaseComponents'
import {
  FETCH_ALL_USER_TAX_CALCULATIONS_KEY,
  fetchUserTaxCalculationsIfNeeded,
} from '../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import { useAnalyticsView } from '../../Amplitude'
import { useReselector } from '../../../utils/sharedHooks'
import PageHeader from '../../../components/shared/PageHeader'
import ActiveQuarterTab from './components/ActiveQuarterTab'
import { getIsFetchingOrNotStarted } from '../../../reducers/fetch'
import { fetchFilteredUserTransactions } from '../../Transactions/transactions.slice'
import { fetchBookkeepingReports } from '../../../actions/bookkeepingReportActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { selectIsBasicPlanWithoutQTE } from '../../../reducers/subscription.slice'

const TAB_INDEXES: { [key: number]: string } = {
  0: 'current',
  1: 'payment_history',
}

const QuarterlyTaxEstimatesPanel = () => {
  const dispatch = useAppDispatch()
  const fetchingEstimates = useReselector(
    getIsFetchingOrNotStarted,
    ALWAYS_FETCH_USER_TAX_ESTIMATES_KEY
  )
  const fetchingQTEDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY
  )
  const fetchingUserTaxCalculations = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_USER_TAX_CALCULATIONS_KEY
  )
  const fetching =
    fetchingEstimates || fetchingQTEDetails || fetchingUserTaxCalculations
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTabIndex, setActiveTabIndex] = useState(
    searchParams.get('tab') === 'payment_history' ? 1 : 0
  )
  const activeQuarterlyTaxEstimateDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const activeQTEDetails = useReselector(selectActiveQTEDetailsAsDates)

  const pageView = useAnalyticsView()

  // Dates need to stay as moment objects until the fetchFilteredUserTransactions
  // function is refactored to use Luxon
  const booksStart =
    activeQTEDetails?.utcNoOffset.bookkeepingPeriodStartDate.toISODate()
  const booksEnd =
    activeQTEDetails?.utcNoOffset.bookkeepingPeriodEndDate.toISODate()
  const { startDate, endDate } = useMemo(
    () => ({
      startDate: moment(booksStart),
      endDate: moment(booksEnd),
    }),
    [booksEnd, booksStart]
  )

  useEffect(() => {
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
    dispatch(fetchUserTaxCalculationsIfNeeded(true))
    dispatch(fetchFilteredUserTransactions({ startDate, endDate }))
    dispatch(fetchBookkeepingReports())
  }, [dispatch, endDate, startDate])

  useEffect(() => {
    pageView('quarterly taxes')
  }, [pageView])

  const tabs = useMemo(() => {
    const panes: {
      menuItem: string
      render: () => JSX.Element | null
    }[] = [
      {
        menuItem: `Q${activeQuarterlyTaxEstimateDetails?.taxQuarter} ${activeQuarterlyTaxEstimateDetails?.taxYear}`,
        render: () => <ActiveQuarterTab />,
      },
    ]

    if (activeQuarterlyTaxEstimateDetails) {
      panes.push({
        menuItem: 'Payment History',
        render: () => <QuarterlyTaxPaymentHistory />,
      })
    }

    return panes
  }, [activeQuarterlyTaxEstimateDetails])

  const isBasicPlanWithoutQTE = useReselector(selectIsBasicPlanWithoutQTE)

  const innerContent = useMemo(() => {
    if (isBasicPlanWithoutQTE) {
      return <ActiveQuarterTab />
    }
    const updateTab = (newIndex: number) => {
      setSearchParams({ tab: TAB_INDEXES[newIndex] }, { replace: true })
      setActiveTabIndex(newIndex)
    }
    return (
      <Tab
        panes={tabs}
        defaultActiveIndex={activeTabIndex}
        activeIndex={activeTabIndex}
        onTabClick={updateTab}
      />
    )
  }, [isBasicPlanWithoutQTE, tabs, activeTabIndex, setSearchParams])

  return (
    <>
      <PageHeader header="Quarterly Taxes" />
      {fetching ? <Loader loading={fetching} /> : innerContent}
    </>
  )
}

export default QuarterlyTaxEstimatesPanel
