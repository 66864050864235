import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

const PastDeadlineSignup = () => {
  const navigate = useNavigate()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const nextTaxYear = Number(currentTaxYear) + 1

  const gridConfig = { ...makeGridConfig([10, 14], true) }

  return (
    <Grid>
      <GridRowColumn centerContent {...gridConfig}>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
          style={{
            width: 300,
            height: 300,
          }}
        />
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Text as="h1" textAlign="center">
          Looking for annual tax guidance?
        </Text>
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Text as="h3" textAlign="center">
          Check back in December for the kickoff to our {nextTaxYear} tax season
        </Text>
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Text textAlign="center">
          We’ll be here to help guide the way. Later this year, we&apos;ll ask
          you a few questions that will help us personalize your {nextTaxYear}{' '}
          tax season experience.
        </Text>
      </GridRowColumn>
      <GridRowColumn centerContent {...gridConfig}>
        <Button variant="secondary" onClick={() => navigate('/dashboard')}>
          Go to Home
        </Button>
      </GridRowColumn>
    </Grid>
  )
}

export default PastDeadlineSignup
