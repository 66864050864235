import { useEffect, useMemo, useState } from 'react'
import { Fonts, Colors } from '../../../styles/theme'
import { Alert, Loader } from '../../BaseComponents'
import { selectAllVisibleAccounts } from '../../../selectors/financeSelectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchRolledOutInstitutionIds } from '../../../actions/userActions'
import { getCurrentUser } from '../../../selectors/user.selectors'
import {
  hasAutoStatementUploadAccount,
  hasHealthyManualStatementUploadAccount,
} from './helpers'

export const NoMoreStatementsAlert = () => {
  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack()
  const [fetching, setFetching] = useState(true)
  const user = useReselector(getCurrentUser)
  const financialAccounts = useReselector(selectAllVisibleAccounts)

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      if (user?.id) await dispatch(fetchRolledOutInstitutionIds(user.id, false))
      setFetching(false)
    }

    fetch()
  }, [dispatch, user?.id])

  const shouldShowAlert = useMemo(
    () =>
      hasAutoStatementUploadAccount(financialAccounts) ||
      hasHealthyManualStatementUploadAccount(financialAccounts),
    [financialAccounts]
  )

  if (!shouldShowAlert) {
    return null
  }

  if (fetching) {
    return <Loader />
  }

  return (
    <Alert
      fullWidth
      closeable
      stackOnMobile
      type="info"
      permanentDismissalKey="noMoreStatementsAlertDismissed"
      title="NEW! No more statement uploads for automated accounts"
      style={{ backgroundColor: Colors.stone40 }}
      innerTextStyle={{ ...Fonts.bodySm }}
      onClose={() => track('close no more statements alert')}
      customIcon={
        <img
          src="https://heard-images.s3.amazonaws.com/assets/bank-auto.svg"
          alt="banner"
          height={140}
          width={140}
        />
      }
    >
      <div style={{ margin: '8px 0' }}>
        We can now automatically reconcile all accounts labeled as{' '}
        <strong>Automated</strong>. You won&apos;t need to manually upload
        statements for these accounts.
      </div>
      <ul style={{ paddingLeft: 25 }}>
        <li>
          You&apos;ll still need to upload statements for accounts labeled as{' '}
          <strong>Manual Upload</strong>.
        </li>
        <li>
          Automated accounts include those using automatic statement uploads and
          limited bank access. If you&apos;ve already set these up, you
          don&apos;t need to do anything else.
        </li>
      </ul>
    </Alert>
  )
}
