import { Grid, Image } from 'semantic-ui-react'
import {
  Alert,
  Button,
  Checkbox,
  GridRowColumn,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useCallback, useMemo, useState } from 'react'
import {
  select1040FormForYear,
  select1120sFormForYear,
} from '../annualTaxFilingForms.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import {
  UPDATE_ANNUAL_TAX_FILING_KEY,
  updateAnnualTaxFilings,
} from '../annualTaxFilings.slice'
import { getFetchError } from '../../../../reducers/fetch'
import { fetchUserYearEndModuleStatuses } from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'

const TaxOptOutModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const dispatch = useAppDispatch()
  const [acknowledged, setAcknowledged] = useState(false)
  const [success, setSuccess] = useState(false)
  const filing = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const form1120s = useReselector(select1120sFormForYear, taxYear)
  const isTwoFormFiler = useMemo(
    () => form1040?.id && form1120s?.id,
    [form1040?.id, form1120s?.id]
  )
  const updateError = useReselector(getFetchError, UPDATE_ANNUAL_TAX_FILING_KEY)
  const formType = useMemo(() => {
    if (isTwoFormFiler) {
      return 'business or personal'
    } else if (form1120s?.id) {
      return 'business'
    }
    return 'personal'
  }, [isTwoFormFiler, form1120s?.id])

  const closeModal = useCallback(() => {
    setAcknowledged(false)
    close()
    if (success) {
      window.location.reload()
    }
  }, [close, success])

  const handleConfirm = useCallback(async () => {
    if (acknowledged && filing?.id) {
      const res = await updateAnnualTaxFilings(filing.id, {
        optedOutAt: new Date().toISOString(),
        annualTaxFormNeeds: null,
        isLateJoiner: false,
      })(dispatch)
      if (res) {
        fetchUserYearEndModuleStatuses()(dispatch)
        setSuccess(true)
      }
    }
  }, [acknowledged, dispatch, filing?.id])

  return (
    <Modal open={open} onClose={closeModal} size="tiny" closeIcon={!success}>
      {!success && (
        <Modal.Header>
          <Text as="h2">Opt out of taxes</Text>
        </Modal.Header>
      )}
      <Modal.Content>
        {success ? (
          <Grid>
            <Grid.Row />
            <GridRowColumn centerContent>
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/circle_check_2.svg"
                style={{ height: 80, width: 80 }}
              />
            </GridRowColumn>
            <GridRowColumn centerContent>
              <Text>You have been opted out of {taxYear} taxes</Text>
            </GridRowColumn>
            <GridRowColumn centerContent>
              <Button onClick={closeModal}>Close</Button>
            </GridRowColumn>
            <Grid.Row />
          </Grid>
        ) : (
          <Grid>
            <GridRowColumn>
              <Text>
                I understand that by opting out, Heard will not do my {formType}{' '}
                taxes for {taxYear}. I also understand that I will not receive a
                refund for tax preparation services.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Checkbox
                label="I acknowledge the above"
                value={acknowledged}
                onChange={() => setAcknowledged(!acknowledged)}
              />
            </GridRowColumn>
            {updateError?.message && (
              <GridRowColumn>
                <Alert as="error">{updateError?.message}</Alert>
              </GridRowColumn>
            )}
          </Grid>
        )}
      </Modal.Content>
      {!success && (
        <Modal.Actions>
          <Button
            variant="link"
            onClick={closeModal}
            style={{ marginRight: 24 }}
          >
            Cancel
          </Button>
          <Button disabled={!acknowledged} onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default TaxOptOutModal
