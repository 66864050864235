import { List } from 'semantic-ui-react'
import { Card, Icon, Text } from '../../components/BaseComponents'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import TestimonialListItem from './Testimonial/TestimonialListItem'
import { useSearchParams } from 'react-router-dom'

const Checkmark = () => (
  <Icon
    style={{ display: 'inline' }}
    color="green"
    size="2x"
    icon={light('check')}
  />
)

const HeardListItem = ({ contentText }: { contentText: string }) => (
  <List.Item style={{ padding: '12px 0' }}>
    <List.Icon verticalAlign="top">
      <Checkmark />
    </List.Icon>
    <List.Content>
      <Text>{contentText}</Text>
    </List.Content>
  </List.Item>
)

const WhatYouGetCard = () => {
  const [searchParams] = useSearchParams()
  const basicPlanSearchParamValue = searchParams.get('subscription')
  const useBasicNoQtePlan = basicPlanSearchParamValue === 'basic'
  const useBasicPlan = basicPlanSearchParamValue === 'new'
  const useAnyBasicPlan = useBasicPlan || useBasicNoQtePlan

  return (
    <Card style={{ padding: 48 }} backgroundColor="stone40">
      <Text as="h2">What you get with Heard</Text>
      <List style={{ marginTop: 30 }}>
        <HeardListItem contentText="Tax pros who specialize in therapy practices" />
        {!useAnyBasicPlan && (
          <HeardListItem contentText="Annual personal and professional income tax filing, state and federal" />
        )}
        {!useBasicNoQtePlan && (
          <HeardListItem contentText="Quarterly tax estimates" />
        )}
        <HeardListItem contentText="Monthly bookkeeping and financial reports" />
        <HeardListItem contentText="Anytime messaging support" />
        <TestimonialListItem
          title="Michelle Vo"
          subtitle="LCSW"
          imageSrc="https://heard-images.s3.amazonaws.com/assets/signup/therapist-profile-pic-2.jpg"
          comment="Heard has supported my business in more ways than I can express! Since partnering with them in 2021, they've provided invaluable assistance with my personal tax returns, quarterly tax filings, and establishing payroll for my S Corporation."
          style={{ margin: '40px 0 0px 15px' }}
        />
      </List>
    </Card>
  )
}

export default WhatYouGetCard
