import { Fragment } from 'react'
import { List } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Accordion,
  Card,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import Tooltip from '../../../../Taxes/QuarterlyTaxEstimates/components/Tooltip'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import type { selectProductDescription } from '../../../../../selectors/user.selectors'

const DetailsAccordion = ({
  productDescription,
  hideTitle,
  forOneTimePayment = false,
}: {
  productDescription: ReturnType<typeof selectProductDescription>
  hideTitle?: boolean
  forOneTimePayment?: boolean
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Accordion
      initialOpen={false}
      variant={'text'}
      style={{
        width: '100%',
        marginBottom: isMobile ? 32 : 16,
      }}
      title={
        <Text as={isMobile ? 'bodySm' : 'bodyMd'}>
          {forOneTimePayment ? 'See details' : 'Plan details'}
        </Text>
      }
      content={
        <Card
          fullWidth
          backgroundColor={'stone40'}
          style={{
            ...(isMobile ? { paddingLeft: 14, paddingRight: 14 } : {}),
          }}
        >
          {productDescription
            ?.filter(({ isVisible }) => isVisible)
            .map(({ title, details }) => (
              <Fragment key={title}>
                {!hideTitle && (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Text
                      as={isMobile ? 'h2' : 'h1'}
                      style={{ fontWeight: 400, marginBottom: 8 }}
                    >
                      {title}
                    </Text>
                  </div>
                )}
                <List style={{ paddingRight: 12 }}>
                  {details.map((item) => (
                    <List.Item
                      key={item.text}
                      style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        icon={regular('check')}
                        color={'green'}
                        style={{
                          marginRight: 16,
                          display: 'inline-block',
                        }}
                      />
                      <Text
                        style={{
                          display: 'inline-block',
                          paddingRight: 10,
                        }}
                      >
                        {item.text}
                      </Text>
                      {item.tooltip && (
                        <Tooltip
                          popup={{
                            body: item.tooltip,
                          }}
                          position="right center"
                          labelComponent={
                            <Icon
                              icon={regular('question-circle')}
                              color={'mediumGray'}
                            />
                          }
                          bordered
                          style={{
                            marginLeft: 5,
                            ...(isMobile ? { marginLeft: 'auto' } : {}),
                          }}
                        />
                      )}
                    </List.Item>
                  ))}
                </List>
              </Fragment>
            ))}
        </Card>
      }
    />
  )
}

export default DetailsAccordion
