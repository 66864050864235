import axios from 'axios'
import { DateTime } from 'luxon'
import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'
import {
  OnboardingStep,
  UserOnboardingStep,
  receiveAllOnboardingSteps,
  receiveUpdatedUserOnboardingStep,
  receiveUserOnboardingSteps,
  UserOnboardingStepBase,
  UserCompleteDate,
} from './onboarding.reducer'

export type UpdatableFields = Partial<
  Pick<UserOnboardingStep, 'startedAt' | 'completedAt' | 'skippedAt'>
>

export interface UserEventFields {
  kind: string
}

export interface userEventSubmitted {
  eventSubmitted: string
}

export const FETCH_USER_ONBOARDING_STEPS_KEY = 'FETCH_ONBOARDING_STEPS_KEY'
export const fetchUserOnboardingStepsIfNeeded = ({
  alwaysFetch,
  expanded,
}: {
  alwaysFetch?: boolean
  expanded?: boolean
} = {}) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching your onboarding steps.',
    alwaysFetch,
    fetchKey: FETCH_USER_ONBOARDING_STEPS_KEY,
    fetchFunction: (dispatch) => {
      return axios
        .get<
          UserOnboardingStep[]
        >(`/finances/api/v1/onboarding/user-onboarding-steps${expanded ? '?expandSteps=true' : ''}`)
        .then((response) => {
          dispatch(receiveUserOnboardingSteps(response.data))
          return response.data
        })
    },
  })

export const FETCH_USER_COMPLETE_ONBOARDING_DATE =
  'FETCH_USER_COMPLETE_ONBOARDING_DATE'
export const fetchUserCompleteOnboardingDate = () =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching your onboarding steps.',
    fetchKey: FETCH_USER_COMPLETE_ONBOARDING_DATE,
    alwaysFetch: true,
    fetchFunction: async () => {
      return await axios
        .get<UserCompleteDate>(
          '/finances/api/v1/onboarding/user-onboarding-completed-date'
        )
        .then((response) => {
          return response.data
        })
    },
  })

export const FETCH_USER_EVENT_KIND = 'FETCH_USER_EVENT_KIND'
export const fetchUserEventKind = ({
  kind,
}: {
  kind?: string
} = {}) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching your onboarding steps.',
    fetchKey: FETCH_USER_EVENT_KIND,
    alwaysFetch: true,
    fetchFunction: async () => {
      return await axios
        .get<userEventSubmitted>(
          `/finances/api/v1/onboarding/user-onboarding-event?kind=${kind}`
        )
        .then((response) => {
          return response.data
        })
    },
  })

export const createUserOnboardingEvent = (updatableFields: UserEventFields) => {
  return fetchWrapper({
    defaultErrorMessage: 'There was an error updating the onboarding step.',
    fetchFunction: (dispatch) =>
      axios
        .post<UserOnboardingStepBase>(
          '/finances/api/v1/onboarding/user-onboarding-event',
          updatableFields
        )
        .then((response) => {
          dispatch(receiveUpdatedUserOnboardingStep(response.data))
          return response.data
        }),
  })
}

export const updateUserOnboardingStep = ({
  id,
  updatableFields,
}: {
  id: number
  updatableFields: UpdatableFields
}) => {
  return fetchWrapper({
    defaultErrorMessage: 'There was an error updating the onboarding step.',
    fetchFunction: (dispatch) =>
      axios
        .patch<UserOnboardingStepBase>(
          `/finances/api/v1/onboarding/user-onboarding-steps/${id}`,
          updatableFields
        )
        .then((response) => {
          dispatch(receiveUpdatedUserOnboardingStep(response.data))
          return response.data
        }),
  })
}

export const updateUserOnboardingSteps = (
  updates: Array<{
    onboardingStepId: number
    updatableFields: UpdatableFields
  }>
) => {
  const formattedUpdates = updates.map((update) => ({
    id: update.onboardingStepId,
    ...update.updatableFields,
  }))

  return fetchWrapper({
    defaultErrorMessage: 'There was an error updating onboarding steps.',
    fetchFunction: (dispatch) =>
      axios
        .patch<
          UserOnboardingStep[]
        >('/finances/api/v1/onboarding/user-onboarding-steps', formattedUpdates)
        .then((response) => {
          dispatch(receiveUserOnboardingSteps(response.data))
          return response.data
        }),
  })
}

export const markCompletedAtAndStartedAtForSteps = ({
  currentStepId,
  nextStepId,
}: {
  currentStepId: number
  nextStepId?: number
}) => {
  const timestamp = DateTime.now().toISO()

  const updates: {
    onboardingStepId: number
    updatableFields: UpdatableFields
  }[] = [
    {
      onboardingStepId: currentStepId,
      updatableFields: {
        completedAt: timestamp,
      },
    },
  ]

  if (nextStepId) {
    updates.push({
      onboardingStepId: nextStepId,
      updatableFields: {
        startedAt: timestamp,
      },
    })
  }
  return updateUserOnboardingSteps(updates)
}

export const fetchAllOnboardingSteps = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching onboarding steps.',
    fetchFunction: (dispatch) =>
      axios
        .get<OnboardingStep[]>('/finances/api/v1/onboarding/onboarding-steps')
        .then((response) => {
          dispatch(receiveAllOnboardingSteps(response.data))
          return response.data
        }),
  })
