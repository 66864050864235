import { Table, Label, Button } from 'semantic-ui-react'
import moment from 'moment'

import { updateAdminUserFinancialInsights } from '../../../actions/adminFinancialInsightActions'
import { FinancialInsight } from '../../../reducers/finances/financialInsightsReducer'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const UpdateActiveButton = ({ insight }: { insight: FinancialInsight }) => {
  const dispatch = useAppDispatch()

  const updateActive = async (active: boolean) => {
    const data = {
      active,
    }
    await updateAdminUserFinancialInsights(
      insight.userId,
      insight.id,
      data
    )(dispatch)
  }

  return (
    <span>
      <Button
        className="link"
        style={{ color: '#B3B3B3 !important' }}
        onClick={() => updateActive(!insight.active)}
      >
        Set {insight.active ? 'Active' : 'Inactive'}
      </Button>
    </span>
  )
}

const ActiveLabel = ({ active }: { active: boolean }) => (
  <Label color={active ? 'green' : 'grey'}>
    {active ? 'Active' : 'Inactive'}{' '}
  </Label>
)

const FinancialInsightsTable = ({
  insights,
}: {
  insights: FinancialInsight[] | undefined
}) => (
  <Table
    striped
    compact="very"
    className="userTransactionsTable backgroundWhite"
  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Month</Table.HeaderCell>
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Active</Table.HeaderCell>
        <Table.HeaderCell>Date Created</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {insights?.map((insight) => (
        <Table.Row key={insight.id}>
          <Table.Cell>{moment(insight.createdAt).format('MMMM')}</Table.Cell>
          <Table.Cell>{insight.title}</Table.Cell>
          <Table.Cell>{insight.description}</Table.Cell>
          <Table.Cell>
            <ActiveLabel active={insight.active} />
          </Table.Cell>
          <Table.Cell>
            {moment(insight.createdAt).format(DATE_FORMATS.DISPLAY_SHORT)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            <UpdateActiveButton insight={insight} />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default FinancialInsightsTable
