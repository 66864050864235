import { Fragment, useEffect, useMemo, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Icon,
  makeNumberSchema,
  makeReqBoolSchema,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import UploadedDocument from '../TaxChecklist/Documents/UploadedDocument'
import FileUploadModal from '../../../../components/FileUpload/FileUploadModal'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { useReselector } from '../../../../utils/sharedHooks'
import { stateAbbToState } from '../../../../constants/locationConstants'
import { getFetchError } from '../../../../reducers/fetch'
import {
  AnnualTaxOutcome,
  UPDATE_ANNUAL_TAX_OUTCOME_KEY,
  updateAnnualTaxOutcome,
} from '../annualTaxOutcome.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { fetchTaxUserDocumentsForYearIfNeeded } from '../taxUserDocuments.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const AnnualTax1040WrapUpModal = ({
  onClose,
  modalOpen,
  year,
  outcomes,
}: {
  onClose: () => void
  modalOpen: boolean
  year?: string
  outcomes: AnnualTaxOutcome[]
}) => {
  const dispatch = useAppDispatch()
  const [modalOpenForIndex, setModalOpenForIndex] = useState<number>()
  const error = useReselector(getFetchError, UPDATE_ANNUAL_TAX_OUTCOME_KEY)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const yearToUse = year || currentTaxYear

  useEffect(() => {
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsForYearIfNeeded(yearToUse))
  }, [dispatch, yearToUse])

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      outcomes: outcomes.map((outcome) => ({
        ...outcome,
        amount: outcome.amountInCents
          ? centsToDollars(outcome.amountInCents)
          : '',
      })),
    },
    onSubmit: async (data) => {
      const results = await Promise.all(
        data.outcomes.map(
          (outcome) =>
            outcome.isRefund !== null &&
            updateAnnualTaxOutcome(outcome.id, {
              isRefund: outcome.isRefund,
              receiptDocumentId: outcome.receiptDocumentId,
              amountInCents: dollarsToCents(outcome.amount),
            })(dispatch)
        )
      )

      if (results.every((res) => res)) {
        onClose()
      }
    },
  })

  const {
    values,
    submitForm,
    setFieldValue,
    validateForm,
    isValid,
    isSubmitting,
  } = formik

  const content = useMemo(() => {
    return (
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text>
              For each entity, please let us know if you received a refund or if
              you made a tax payment.
            </Text>
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Alert type="error">{error.message}</Alert>
            </GridRowColumn>
          )}
          {values.outcomes.map((result, i) => {
            const { id, isRefund, isFederal, receiptDocumentId, state } = result
            const title = isFederal
              ? 'federal tax'
              : `${state ? stateAbbToState(state) : ''} state tax`

            return (
              <Fragment key={id}>
                {i !== 0 && (
                  <GridRowColumn>
                    <Divider />
                  </GridRowColumn>
                )}
                <Grid.Row key={id}>
                  <Grid.Column width={1}>
                    <Icon
                      icon={isFederal ? light('landmark') : light('home')}
                      color="green"
                      size="2x"
                    />
                  </Grid.Column>

                  <Grid.Column width={15}>
                    <Grid>
                      <GridRowColumn>
                        <Text as="eyebrow" color="darkGray">
                          {title}
                        </Text>
                      </GridRowColumn>
                      <GridRowColumn short>
                        <FormikLabelError
                          name={`outcomes[${i}].isRefund`}
                          label={`For your personal ${title} return, you:`}
                          schema={makeReqBoolSchema()}
                        />
                      </GridRowColumn>
                      <Grid.Row className="short">
                        <Grid.Column width={8}>
                          <FormikRadioToggleButton
                            name={`outcomes[${i}].isRefund`}
                            value
                            fullWidth
                            onClick={validateForm}
                          >
                            Received a Refund
                          </FormikRadioToggleButton>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FormikRadioToggleButton
                            name={`outcomes[${i}].isRefund`}
                            value={false}
                            fullWidth
                            onClick={validateForm}
                          >
                            Paid Taxes
                          </FormikRadioToggleButton>
                        </Grid.Column>
                      </Grid.Row>
                      {isRefund !== null && (
                        <GridRowColumn>
                          <FormikInput
                            label={
                              isRefund
                                ? 'How much was your refund?'
                                : `How much did you pay in ${title}es?`
                            }
                            componentType="currency"
                            name={`outcomes[${i}].amount`}
                            fullWidth
                            schema={makeNumberSchema({
                              allowedDecimals: 2,
                            })}
                          />
                        </GridRowColumn>
                      )}
                      {isRefund === false && (
                        <>
                          <GridRowColumn verticalAlign="middle">
                            <Button
                              variant="secondary"
                              onClick={() => setModalOpenForIndex(i)}
                            >
                              <Icon
                                icon={regular('upload')}
                                size="1x"
                                style={{ marginRight: 10 }}
                              />
                              Upload payment receipt
                            </Button>
                          </GridRowColumn>
                          {receiptDocumentId && (
                            <UploadedDocument id={receiptDocumentId} />
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Fragment>
            )
          })}
        </Grid>
      </Modal.Content>
    )
  }, [error, validateForm, values.outcomes])

  return (
    <FormikProvider value={formik}>
      <Modal open={modalOpen} closeIcon onClose={onClose}>
        <Modal.Header>{yearToUse} Annual Tax Season Wrap-Up</Modal.Header>
        {content}
        <Modal.Actions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={submitForm}
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>

      <FileUploadModal
        open={modalOpenForIndex !== undefined}
        close={() => setModalOpenForIndex(undefined)}
        userFacing
        documentType={UploadDocumentType.TAX}
        categoryInternalName={
          UserDocumentCategoryIdentifier.annualTaxPaymentReceipt
        }
        setUploadedFile={async ({ id }) => {
          await setFieldValue(
            `outcomes[${modalOpenForIndex}].receiptDocumentId`,
            id
          )
        }}
        year={yearToUse}
      />
    </FormikProvider>
  )
}
