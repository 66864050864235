import { Grid } from 'semantic-ui-react'

import {
  useFetchResponseWithUtils,
  useReselector,
} from '../../../utils/sharedHooks'
import WelcomeToPayroll from './WelcomeToPayroll'
import { PayrollInterestOutroContent } from './InterestFlow/PayrollInterestOutro'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { getMostRecentPayrollInterestSurvey } from './payrollInterestSurvey.actions'
import { Loader } from '../../../components/BaseComponents'

const PayrollUpsell = () => {
  // todo this data will be removed of user
  const user = useReselector(getCurrentUser)

  const latestPayrollSurvey = useFetchResponseWithUtils(
    getMostRecentPayrollInterestSurvey
  )

  const submittedGEPForm = Boolean(
    user?.gepRequestFormSubmittedAt ||
      latestPayrollSurvey.result?.gepRequestFormSubmittedAt
  )

  if (latestPayrollSurvey.loading) {
    return <Loader />
  }

  if (submittedGEPForm) {
    return (
      <Grid>
        <PayrollInterestOutroContent
          latestPayrollSurvey={latestPayrollSurvey.result}
        />
      </Grid>
    )
  }

  return <WelcomeToPayroll />
}

export default PayrollUpsell
