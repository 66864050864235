import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

import { useFetchResponse, useReselector } from '../../../../utils/sharedHooks'
import { Alert, Button, Text } from '../../../../components/BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { userGetBookkeepingSubmission } from '../../../Bookkeeping/end-of-year-review/actions'
import { EndOfYearSubmissionStatus } from '../../../Bookkeeping/end-of-year-review/types'
import { selectDeadlineBannerCopy } from '../annualTaxFilings.selector'

const DeadlineBanner = () => {
  const navigate = useNavigate()
  const deadlineBannerCopy = useReselector(selectDeadlineBannerCopy)

  const shouldShowDeadlineBanner = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableYeDeadlineBanner,
    false
  )

  const submission = useFetchResponse(userGetBookkeepingSubmission)

  if (
    !shouldShowDeadlineBanner ||
    submission?.status === EndOfYearSubmissionStatus.waitingOnHeard ||
    !deadlineBannerCopy
  ) {
    return null
  }

  return (
    <Alert type="warn">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text>{deadlineBannerCopy.bannerCopy}</Text>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              variant="secondaryLink"
              onClick={() => navigate('/taxes/annual')}
            >
              {deadlineBannerCopy.buttonCopy}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Alert>
  )
}

export default DeadlineBanner
