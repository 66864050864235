import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Card,
  CircularWrapper,
  GridRowColumn,
  Icon,
  Text,
  Link,
  Button,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import {
  useFetchResponseWithUtils,
  useReselector,
} from '../../../../utils/sharedHooks'
import {
  getCurrentUser,
  selectIsCurrentUserScorp,
} from '../../../../selectors/user.selectors'
import { PayrollSetup } from '../../../../reducers/auth/userReducer'
import { GEP_PER_PERSON_PRICE, GEP_SUB_PRICE } from '../../helpers'
import { Colors } from '../../../../styles/theme'
import { selectSubscriptionIncludesFreePayroll } from '../../../../reducers/subscription.slice'
import {
  getMostRecentPayrollInterestSurvey,
  PayrollInterestSurvey,
} from '../payrollInterestSurvey.actions'

export const PayrollInterestOutroContent = ({
  latestPayrollSurvey,
}: {
  latestPayrollSurvey?: PayrollInterestSurvey | null
}) => {
  const user = useReselector(getCurrentUser)
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const gridConfig = { ...makeGridConfig([10, 14], true) }
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )

  // todo remove user portion when this is removed from user
  const interestedInGepAt =
    latestPayrollSurvey?.interestedInGepAt || user?.interestedInGepAt
  const payrollSetup = latestPayrollSurvey?.payrollSetup || user?.payrollSetup

  const cardContent = useMemo(() => {
    if (interestedInGepAt) {
      return (
        <Text>
          Our team will review your information to ensure you meet the
          requirements for Heard payroll. You should expect to receive a message
          in about 1 business day.
          <br />
          <br />
          After you’re approved, follow the instructions below to enroll. We’ll
          also send you this information in a message.
        </Text>
      )
    }

    if (
      payrollSetup === PayrollSetup.gusto ||
      payrollSetup === PayrollSetup.other
    ) {
      return (
        <Text>
          Since you’re using a separate payroll provider, your payroll data
          won’t be available in Heard. Please connect with your provider’s
          support team for any further assistance.
        </Text>
      )
    } else if (payrollSetup === PayrollSetup.none) {
      if (isScorp) {
        return (
          <Text>
            Please note that as an S corporation, you’re <b>required</b> by the
            IRS to pay yourself through a payroll.
            <br />
            We’ll remind you to update this information later. If you wish to
            sign up for Heard Payroll in the future, you can find it in your
            sidebar.
          </Text>
        )
      }
      return (
        <Text>
          If your needs change and you wish to sign up for Heard Payroll in the
          future, reach out to us via Messages.
        </Text>
      )
    }
    return null
  }, [interestedInGepAt, isScorp, payrollSetup])

  if (latestPayrollSurvey?.gepEligible === false) {
    return (
      <>
        <GridRowColumn {...gridConfig}>
          <Text as="h1">Heard Payroll</Text>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <Card type="subsection" backgroundColor="lightYellow">
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/plant-snake-short.svg"
              style={{
                width: 72,
                height: 80,
                backgroundColor: Colors.transparent,
              }}
            />
            <br />
            <Text as="h2">We’re still growing</Text>
            <br />
            <Text>
              Our team has determined that we&apos;re unable to currently
              support you via Heard Payroll. Please review Messages for more
              details.
              <br />
              <br />
              We&apos;ll be in touch if we&apos;re able to support your needs in
              the future.
              <br />
              <br />
              If your circumstances change, and you&apos;d like to be
              re-evaluated, reach out to Support via Messages
            </Text>
          </Card>
        </GridRowColumn>
      </>
    )
  }

  return (
    <>
      <GridRowColumn {...gridConfig}>
        <Text as="h1">Tell us about your payroll needs</Text>
      </GridRowColumn>
      <GridRowColumn {...gridConfig}>
        <Card type="subsection" backgroundColor="lightGreen">
          <br />
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/check.svg"
            style={{
              width: 80,
              height: 80,
              backgroundColor: Colors.transparent,
            }}
          />
          <br />
          <Text as="h2">
            {interestedInGepAt
              ? 'Thanks! We’re reviewing your request'
              : 'Thanks for letting us know about your payroll needs!'}
          </Text>
          <br />
          {cardContent}
        </Card>
      </GridRowColumn>
      {interestedInGepAt && (
        <>
          <GridRowColumn {...gridConfig}>
            <Text as="h2">What to expect next</Text>
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">1</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={4}>
              <Text>Enroll in Payroll</Text>
              <Text as="bodySm" style={{ marginBottom: 8 }}>
                Once a member of our Support team has verified your eligibility,
                go to <b>Enroll in Payroll</b> in your sidebar.
              </Text>
              {hasFreePayrollWithSubscription ? (
                <Text as="bodyXs" color="darkGray">
                  Payroll for yourself is included in your plan. Additional
                  employees cost ${GEP_PER_PERSON_PRICE}/mo per employee.
                </Text>
              ) : (
                <Text as="bodyXs" color="darkGray">
                  Heard Payroll is a base cost of ${GEP_SUB_PRICE}/month + $
                  {GEP_PER_PERSON_PRICE}/individual per month. Once you enroll,
                  your subscription will be charged by Heard, not Gusto.
                </Text>
              )}
            </Grid.Column>
            <Grid.Column width={5} verticalAlign="middle">
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/payroll_enroll.png"
                style={{ width: 182, height: 70 }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">2</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={9}>
              {payrollSetup === PayrollSetup.gusto ? (
                <>
                  <Text>Connect to Gusto</Text>
                  <Text as="bodySm">
                    Take a few minutes to link your Heard and Gusto account and
                    accept the Terms of Service.
                  </Text>
                </>
              ) : (
                <>
                  <Text>Complete payroll onboarding</Text>
                  <Text as="bodySm">
                    We’ll walk you through the information and documentation
                    needed to ensure compliance with state and federal laws.
                  </Text>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column
              width={1}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <CircularWrapper height={28} wrapperColor="stone">
                <Text as="h3">3</Text>
              </CircularWrapper>
            </Grid.Column>
            <Grid.Column width={7}>
              <Text>Start managing your payroll in Heard</Text>
              <Text as="bodySm">
                Your payroll should now be active in Heard.
                {payrollSetup === PayrollSetup.other
                  ? ' Export your statements from your previous provider and upload them into Gusto.'
                  : ''}
              </Text>

              {payrollSetup === PayrollSetup.gusto && (
                <>
                  <br />
                  <Text as="bodyXs" color="darkGray">
                    <b>Important:</b> Migration fully transfers your payroll to
                    Heard. Your Gusto payroll will no longer be available, nor
                    will they be able to provide support. Please reach out to us
                    via Messages instead.
                  </Text>
                </>
              )}
              <br />
              <Link
                href="https://support.joinheard.com/hc/en-us/sections/7709867454359-Payroll"
                newPage
                style={{ fontSize: 14 }}
              >
                Heard Payroll help articles{' '}
                <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </>
  )
}

const PayrollInterestOutro = () => {
  const navigate = useNavigate()

  const latestPayrollSurvey = useFetchResponseWithUtils(
    getMostRecentPayrollInterestSurvey
  )

  return (
    <Grid>
      <PayrollInterestOutroContent
        latestPayrollSurvey={latestPayrollSurvey.result}
      />
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column computer={13} tablet={8} only="computer tablet" />
        <Grid.Column computer={3} tablet={5} mobile={16}>
          <Button fullWidth onClick={() => navigate('/')}>
            Done
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PayrollInterestOutro
