import moment from 'moment'
import MomentBD from 'moment-business-days'
import { DateTime, DateTimeUnit } from 'luxon'

export enum DATE_FORMATS {
  // For date inputs.  Generally should be used where user is entering values
  INPUT = 'MM-DD-YYYY',
  // Standard short display.  If you're displaying a date value to the user it will probably be this one or the long version
  DISPLAY_SHORT = 'MMM DD, YYYY',
  // Standard long date display
  DISPLAY_LONG = 'MMMM DD, YYYY',
  // Standard full date/time display
  DISPLAY_FULL = 'MMM DD YYYY h:mm:ss a',
  // Numbers only display
  DISPLAY_SIMPLE = 'MM/DD/YYYY',
  // Used for gusto api calls. Gets and posts need to use this
  GUSTO_SUBMIT = 'YYYY-MM-DD',
  // Used when we need to know month and year only ie monthly reports
  MONTH_YEAR = 'MMM YYYY',
  // Used when year isn't needed
  MONTH_DAY = 'MMM D',
  // Used for quarter displays
  QUARTER = 'Q, YYYY',
  // Used for time display.  Can be combined with above
  TIME = 'h:mm A',
  // Used where day of the week should be displayed, ie in Payroll reminders
  DAY_OF_WEEK_DAY_MONTH = 'dddd MMMM DD',
  // Used for some forms
  TIMESTAMP = 'YYYY-MM-DD HH:mm:ss',
  DISPLAY_TIMEZONE = 'MMM DD YYYY h:mm a z',
  // https://datatracker.ietf.org/doc/html/rfc5545 used by some apis
  RFC5545 = 'YYYYMMDDTHHmmss[Z]',
  UTC = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
  YEAR = 'YYYY',
  // used for statement month
  YEAR_MONTH = 'YYYY-MM',
}

export enum DATE_FORMATS_LUXON {
  // For date inputs.  Generally should be used where user is entering values
  INPUT = 'LL-dd-y',
  // Standard short display.  If you're displaying a date value to the user it will probably be this one or the long version
  DISPLAY_SHORT = 'LLL dd, y',
  // Shorter version of DISPLAY_SHORT with a short year
  DISPLAY_SHORT_YEAR = 'LLL dd, ‘yy',
  // Standard long date display
  DISPLAY_LONG = 'LLLL dd, y',
  // Standard long date display with shortened day
  DISPLAY_LONG_SHORT_DAY = 'LLLL d, y',
  // Standard full date/time display
  DISPLAY_FULL = 'LLLL dd yyyy h:mm:ss a',
  // Numbers only display
  DISPLAY_SIMPLE = 'LL/dd/y',
  // Shortest display string for year
  DISPLAY_ABBR = 'L/dd/yy',
  // Used for gusto api calls. Gets and posts need to use this
  GUSTO_SUBMIT = 'yyyy-LL-dd',
  // Used when we need to know month and year only ie monthly reports
  MONTH_YEAR = 'LLL y',
  // Used when you need the full month name
  MONTH_YEAR_LONG = 'LLLL y',
  // Used when year isn't needed
  MONTH_DAY = 'LLL d',
  // Used when year isn't needed, but you want the full month name
  MONTH_DAY_LONG = 'LLLL d',
  // Used for quarter displays
  QUARTER = 'q, y',
  // Used for time display.  Can be combined with above
  TIME = 'h:mm a',
  // Used where day of the week should be displayed, ie in Payroll reminders
  DAY_OF_WEEK_DAY_MONTH = 'cccc LLLL dd',
  // Used for some forms
  TIMESTAMP = 'y-LL-dd HH:mm:ss',
  DISPLAY_TIMEZONE = 'LLL dd y TTT',
  // https://datatracker.ietf.org/doc/html/rfc5545 used by some apis
  RFC5545 = "yLLdd'T'HHmmss'Z'",
  // Used when querying transactions
  YEAR_MONTH_DAY = 'yyyy-MM-dd',
  // Used for bookkeeping report dates
  YEAR_MONTH = 'yyyy-MM',
  MONTH = 'LLLL',
}

export const getMomentBD = () => {
  // Taken from https://www.frbservices.org/about/holiday-schedules.  These are used for payday calculation
  const HOLIDAYS_2025 = [
    '05-26-2025',
    '06-19-2025',
    '07-04-2025',
    '09-01-2025',
    '10-13-2025',
    '11-11-2025',
    '11-27-2025',
    '12-25-2025',
  ]

  const HOLIDAYS_2026 = [
    '01-01-2026',
    '01-19-2026',
    '02-16-2026',
    '05-25-2026',
    '06-19-2026',
    '07-03-2026',
    '09-07-2026',
    '10-12-2026',
    '11-11-2026',
    '11-26-2026',
    '12-25-2026',
  ]

  const HOLIDAYS_2027 = [
    '01-01-2027',
    '01-18-2027',
    '02-15-2027',
    '05-31-2027',
    '06-18-2027',
    '07-05-2027',
    '09-06-2027',
    '10-11-2027',
    '11-11-2027',
    '11-25-2027',
    '12-24-2027',
  ]

  const HOLIDAYS_2028 = [
    '12-31-2027',
    '01-17-2028',
    '02-21-2028',
    '05-29-2028',
    '06-19-2028',
    '07-04-2028',
    '09-04-2028',
    '10-09-2028',
    '11-10-2028',
    '11-23-2028',
    '12-25-2028',
  ]

  const HOLIDAYS_2029 = [
    '01-01-2029',
    '01-15-2029',
    '02-19-2029',
    '05-28-2029',
    '06-19-2029',
    '07-04-2029',
    '09-03-2029',
    '10-08-2029',
    '11-12-2029',
    '11-22-2029',
    '12-25-2029',
  ]

  MomentBD.updateLocale('us', {
    holidays: [
      ...HOLIDAYS_2025,
      ...HOLIDAYS_2026,
      ...HOLIDAYS_2027,
      ...HOLIDAYS_2028,
      ...HOLIDAYS_2029,
    ],
    holidayFormat: DATE_FORMATS.INPUT,
  })

  return MomentBD
}

// The `moment.toDate` method will return a date with the current timezone but there are times where you just want the utc date (like for min/max date in datepicker)
export const utcMomentToUTCDate = (date: string) =>
  moment(moment.utc(date).format('YYYY-MM-DD HH:mm:ss')).toDate()

/** This is returns a Luxon DateTime */
export const isoToUTCDateTime = (isoDate: string) =>
  DateTime.fromISO(isoDate, { zone: 'utc' })

export const formatISOFromUTC = (
  isoDate: string | undefined | null,
  format: DATE_FORMATS_LUXON = DATE_FORMATS_LUXON.INPUT
) => {
  if (!isoDate) {
    return ''
  }
  return isoToUTCDateTime(isoDate).toFormat(format)
}

export const isoToLocalDate = (isoDate?: string | null) => {
  if (!isoDate) {
    return null
  }
  const date = DateTime.fromISO(isoDate, { setZone: true })
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: date.hour,
    minute: date.minute,
    second: date.second,
    millisecond: date.millisecond,
  })
}

export const convertUtcToLocalDate = (isoDate?: string) => {
  if (!isoDate) {
    return null
  }
  const utcDate = isoToUTCDateTime(isoDate)
  return DateTime.fromObject({
    year: utcDate.year,
    month: utcDate.month,
    day: utcDate.day,
  })
}

export const getTimeDifferenceInDaysAndHours = (time: string) => {
  const differenceInHours = Math.round(
    (new Date().getTime() - DateTime.fromISO(time).toJSDate().getTime()) /
      (1000 * 3600)
  )
  if (differenceInHours < 23) {
    return `${differenceInHours} hour(s)`
  } else {
    const days = Math.floor(differenceInHours / 24)
    const hours = differenceInHours % 24
    return `${days} day(s) ${hours} hour(s)`
  }
}

export const getOrdinal = (n: number) => {
  const end = ['th', 'st', 'nd', 'rd']
  const endKey = n % 100
  const endString = end[(endKey - 20) % 10] || end[endKey] || end[0]
  return `${n}${endString}`
}

// Formats the date with ordinal after the day part
// so formatDateWithOrdinal(DateTime.fromObject({ day: 1, month: 1, year: 2020 }), DATE_FORMATS_LUXON.MONTH_DAY) will output Jan 1st
export const formatDateWithOrdinal = (date: DateTime, format: string) =>
  date.toFormat(format.replace(/d+/, `'${getOrdinal(date.get('day'))}'`))

export const convertInputToIso = (
  dateStr?: string,
  options?: { startOf?: DateTimeUnit; endOf?: DateTimeUnit }
) => {
  if (!dateStr) {
    return undefined
  }
  let date = DateTime.fromFormat(dateStr, DATE_FORMATS_LUXON.INPUT)
  if (options?.startOf) {
    date = date.startOf(options.startOf)
  } else if (options?.endOf) {
    date = date.endOf(options.endOf)
  }

  return date.toISO()
}

/**
 * Converts a YYYY-MM string to a DateTime object.
 * '2024-1' and '2024-01' are acceptable.
 */
export const convertYearMonthStringToDateTime = (yearMonth?: string | null) => {
  if (!yearMonth) {
    return null
  }

  const year = yearMonth.split('-')[0]
  const month = yearMonth.split('-')[1]

  if (!year || !month) {
    return null
  }

  const dateObject = DateTime.fromObject({
    year: Number(year),
    month: Number(month),
  })
  if (!dateObject.isValid) {
    return null
  }
  return dateObject
}
