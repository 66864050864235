import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
  Container,
  Header,
  Breadcrumb,
  Divider,
  Label,
  Grid,
} from 'semantic-ui-react'

import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import AllocationGuideAmountsTable from '../AllocationGuides/AllocationGuideAmountsTable'
import UserBreadcrumbs from './UserRecord/UserBreadcrumbs'
import {
  formatCurrency,
  centsToDollars,
  addCurrencyArray,
} from '../../utils/currencyHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { fetchAdminUserAllocationGuides } from '../../actions/allocationGuideActions'
import { getAllocationGuideById } from '../../selectors/allocationGuideSelectors'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useAppDispatch } from '../../utils/typeHelpers'

const AllocationGuidePanel = () => {
  const dispatch = useAppDispatch()
  const { userId, allocationGuideId } = useParams()
  const allocationGuide = useReselector(
    getAllocationGuideById,
    allocationGuideId
  )

  useEffect(() => {
    if (userId) {
      dispatch(fetchAdminUserAllocationGuides(userId))
    }
  }, [dispatch, userId])

  const notification = useMemo(() => {
    if (allocationGuide?.userNotifiedAt) {
      return (
        <Label basic color="green">
          Notified:{' '}
          {moment(allocationGuide?.userNotifiedAt).format(
            DATE_FORMATS.DISPLAY_LONG
          )}
        </Label>
      )
    } else {
      return (
        <Label basic color="orange">
          Not Notified
        </Label>
      )
    }
  }, [allocationGuide?.userNotifiedAt])

  const profits = addCurrencyArray([
    centsToDollars(allocationGuide?.incomeAtCompilationInCents),
    centsToDollars(allocationGuide?.expensesAtCompilationInCents),
  ])

  return (
    <Container id="adminPanel">
      <UserBreadcrumbs userId={userId} pageName={'Allocation Guides'}>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>
          {moment(allocationGuide?.date).format(DATE_FORMATS.MONTH_YEAR)}
        </Breadcrumb.Section>
      </UserBreadcrumbs>
      <Divider />

      <Container className="backdrop padded">
        <div className="flexbox">
          <Header as="h3">
            {moment(allocationGuide?.date).format(DATE_FORMATS.MONTH_YEAR)}{' '}
            Allocation Guide
          </Header>
          <div>
            <p>
              <b>Compiled On:</b>{' '}
              {moment(allocationGuide?.compiledOn).format(
                DATE_FORMATS.DISPLAY_LONG
              )}
            </p>
            <p>
              <b>Notified On:</b> {notification}
            </p>
            <p>
              <b>User ID:</b> {allocationGuide?.userId}
            </p>
          </div>
        </div>
        <Divider />
        <Grid padded doubling stackable>
          <Grid.Row>
            <Grid.Column width={10}>
              {allocationGuide && (
                <AllocationGuideAmountsTable
                  allocationGuide={allocationGuide}
                  profits={profits}
                />
              )}
            </Grid.Column>

            <Grid.Column width={6}>
              <div className="pnlCard">
                <Header as="h3" className="compact">
                  Profit and Loss*
                </Header>
                <i>*at time of allocation guide creation</i>
                <br />
                <br />
                <div className="flexbox">
                  <p style={{ fontSize: '1.2em', fontWeight: '500' }}>Income</p>
                  <p style={{ fontSize: '1.2em', fontWeight: '500' }}>
                    <CurrencyFormatLabel
                      value={formatCurrency(
                        centsToDollars(
                          allocationGuide?.incomeAtCompilationInCents
                        )
                      )}
                    />
                  </p>
                </div>
                <div className="flexbox">
                  <p style={{ fontSize: '1.2em', fontWeight: '500' }}>
                    Expenses
                  </p>
                  <p style={{ fontSize: '1.2em', fontWeight: '500' }}>
                    <CurrencyFormatLabel
                      value={formatCurrency(
                        centsToDollars(
                          allocationGuide?.expensesAtCompilationInCents
                        )
                      )}
                    />
                  </p>
                </div>

                <div className="flexbox">
                  <p style={{ fontSize: '1.2em', fontWeight: '600' }}>Profit</p>
                  <p style={{ fontSize: '1.2em', fontWeight: '600' }}>
                    <CurrencyFormatLabel value={formatCurrency(profits)} />
                  </p>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Container>
  )
}

export default AllocationGuidePanel
