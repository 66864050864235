import { Table, TableHeaderCell } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import CustomerTableCell from './CustomerTableCell'
import CalculationInputsCell from './CalculationInputsCell'
import CalculationOutputsCell from './CalculationOutputsCell'
import CalculationOutputsAdjustedCell from './CalculationOutputsAdjustedCell'
import ReleaseAndNotifyButton from './ReleaseAndNotifyModule'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectIsCalculatorDefaultVersion,
  selectUserTaxCalculationById,
} from '../../../../selectors/userTaxCalculation.selectors'
import { getUserById } from '../../../../selectors/user.selectors'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'
import { Text } from '../../../../components/BaseComponents'

interface Props {
  id: number
  bookkeepingPeriod: string
  shouldShowCalculations: boolean
  calculationEditHidden: boolean
  checklistLaunchDate?: string | null
}

const CalculationTableRow = ({
  id,
  bookkeepingPeriod,
  shouldShowCalculations,
  calculationEditHidden,
  checklistLaunchDate,
}: Props) => {
  const calc = useReselector(selectUserTaxCalculationById, id)
  const user = useReselector(getUserById, calc?.userId)
  const isDefaultBreakDown = useReselector(selectIsCalculatorDefaultVersion, id)

  if (!calc || !user || !checklistLaunchDate) {
    return null
  }

  const reviewedSinceChecklistLaunch =
    calc.taxProfileLastReviewedAt &&
    isoToUTCDateTime(calc.taxProfileLastReviewedAt) >=
      isoToUTCDateTime(checklistLaunchDate).startOf('day')
  const readyToCalculate =
    reviewedSinceChecklistLaunch &&
    calc.booksClosedForPreviousMonths &&
    calc.hasPaidAllTaxEstimates
  const taxProfileReviewRowProps = reviewedSinceChecklistLaunch
    ? { positive: true }
    : { negative: true }
  const booksClosedRowProps = calc.booksClosedForPreviousMonths
    ? { positive: true }
    : { negative: true }
  const hasPaidAllTaxEstimatesRowProps = calc.hasPaidAllTaxEstimates
    ? { positive: true }
    : { negative: true }

  const lastUpdatedText = calc.plInputsUpdatedAt
    ? `As of ${DateTime.fromISO(calc.plInputsUpdatedAt).toFormat(
        DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
      )}`
    : 'Not yet processed by job'

  return (
    <Table.Row>
      {/* Customer Cell */}
      <CustomerTableCell id={id} />

      {/* Calculation Inputs Cell */}
      {shouldShowCalculations && (
        <CalculationInputsCell id={id} bookkeepingPeriod={bookkeepingPeriod} />
      )}

      {/* Calculation Outputs Cell */}
      {shouldShowCalculations && isDefaultBreakDown && (
        <CalculationOutputsCell id={id} />
      )}

      {/* Outputs Adjusted Cell */}
      <CalculationOutputsAdjustedCell
        id={id}
        calculationEditHidden={calculationEditHidden}
      />

      {/* Checklist completion --*/}
      <Table.Cell verticalAlign="top">
        <Table celled color={readyToCalculate ? 'green' : 'red'}>
          <Table.Header>
            <Text as="eyebrow" style={{ padding: 5 }}>
              {lastUpdatedText}
            </Text>
          </Table.Header>
          <Table.Header>
            <TableHeaderCell>Ready to calculate</TableHeaderCell>
            <TableHeaderCell>{readyToCalculate ? 'Yes' : 'No'}</TableHeaderCell>
          </Table.Header>
          <Table.Body>
            <Table.Row {...taxProfileReviewRowProps}>
              <Table.Cell>Tax profile last reviewed</Table.Cell>
              <Table.Cell>
                {calc.taxProfileLastReviewedAt
                  ? DateTime.fromISO(calc.taxProfileLastReviewedAt).toFormat(
                      DATE_FORMATS_LUXON.DISPLAY_TIMEZONE
                    )
                  : '---'}
              </Table.Cell>
            </Table.Row>
            <Table.Row {...booksClosedRowProps}>
              <Table.Cell>
                Books closed for all required months in bookkeeping period
              </Table.Cell>
              <Table.Cell>
                {calc.booksClosedForPreviousMonths ? 'Yes' : 'No'}
              </Table.Cell>
            </Table.Row>
            <Table.Row {...hasPaidAllTaxEstimatesRowProps}>
              <Table.Cell>
                Has logged payments for all required estimates{' '}
              </Table.Cell>
              <Table.Cell>
                {calc.hasPaidAllTaxEstimates ? 'Yes' : 'No'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Table.Cell>

      {/* Actions Cell */}
      <Table.Cell textAlign="center">
        <ReleaseAndNotifyButton calculation={calc} />
      </Table.Cell>
    </Table.Row>
  )
}

export default CalculationTableRow
