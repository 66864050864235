import { useMemo } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  GridRowColumn,
  Icon,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  QTEChecklistStatuses,
  selectIsPastOrSameAsTaxEstimateIRSPaymentDate,
  selectIsPastTaxEstimateCalculationDate,
  selectQTEChecklistStatusAndDates,
} from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { DATE_FORMATS_LUXON } from '../../../../../utils/dateHelpers'

const IRSPaymentDueAt = ({
  irsPaymentDueAt,
  isPastPaymentDate,
}: {
  irsPaymentDueAt?: DateTime
  isPastPaymentDate: boolean
}) =>
  irsPaymentDueAt ? (
    <>
      <br />
      <Text color={isPastPaymentDate ? 'red' : 'black'}>
        <Icon
          color={isPastPaymentDate ? 'red' : 'oak'}
          style={{ paddingRight: 8 }}
          icon={regular('square-dollar')}
        />{' '}
        IRS payment due{' '}
        <b>{irsPaymentDueAt.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}</b>
      </Text>
    </>
  ) : null

const ChecklistDueAt = ({
  checkListDueAt,
  displayText,
  isPastCalculationDate,
}: {
  checkListDueAt: DateTime | string
  displayText: string
  isPastCalculationDate: boolean
}) => {
  if (!displayText || !checkListDueAt) {
    return null
  }
  const dueDate =
    typeof checkListDueAt === 'string'
      ? checkListDueAt
      : checkListDueAt.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)
  return (
    <Text
      color={isPastCalculationDate ? 'red' : 'black'}
      style={{ marginBottom: 8 }}
    >
      {displayText && (
        <>
          <Icon
            color={isPastCalculationDate ? 'red' : 'oak'}
            style={{ paddingRight: 8 }}
            icon={regular('calendar-check')}
          />{' '}
        </>
      )}
      {displayText} <b>{dueDate}</b>
    </Text>
  )
}

export const ChecklistDoneHeader = () => {
  const checklistStatus = useReselector(selectQTEChecklistStatusAndDates)

  return (
    <Grid centered>
      <GridRowColumn short>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/calculator2.svg"
          style={{ height: 100, width: 117 }}
          centered
        />
      </GridRowColumn>
      <GridRowColumn width={12} columnStyle={{ textAlign: 'center' }}>
        <Text as="display2">We’re Calculating Your Estimate...</Text>
        <Text style={{ marginTop: 16, marginBottom: 16 }}>
          Thank you for completing the checklist! You should receive your
          estimate soon.
        </Text>
        <IRSPaymentDueAt
          irsPaymentDueAt={checklistStatus?.rest?.forDisplay.irsPaymentDue}
          // text is black here even if past due date
          isPastPaymentDate={false}
        />
      </GridRowColumn>
      <Grid.Row />
    </Grid>
  )
}

export const ChecklistOpenHeader = () => {
  const checklistStatus = useReselector(selectQTEChecklistStatusAndDates)

  const isPastCalculationDate = useReselector(
    selectIsPastTaxEstimateCalculationDate
  )
  const isPastPaymentDate = useReselector(
    selectIsPastOrSameAsTaxEstimateIRSPaymentDate
  )
  const infoText = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.checklistOpen: {
        return 'Complete the checklist to receive your quarterly tax estimate'
      }
      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
      case QTEChecklistStatuses.afterIRSPaymentDue:
      case QTEChecklistStatuses.beforeChecklistOpens:
        return ''
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus])

  const [displayText, checklistDueAt]: [
    string,
    DateTime<true> | DateTime<false> | string,
  ] = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.beforeChecklistOpens:
        return ['', '']
      case QTEChecklistStatuses.checklistOpen:
        return ['Checklist due', checklistStatus.endDateForDisplay]
      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
        return ['Complete by', 'ASAP']
      case QTEChecklistStatuses.afterIRSPaymentDue:
        return ['Overdue', 'Checklist']
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus])

  const popupText = useMemo(() => {
    switch (checklistStatus.status) {
      case QTEChecklistStatuses.checklistOpen:
      case QTEChecklistStatuses.beforeChecklistOpens:
      case QTEChecklistStatuses.calcsMayBeReceivedAfterIRSPaymentDue:
      case QTEChecklistStatuses.afterIRSPaymentDue:
        return null
      default:
        return checklistStatus.status satisfies never
    }
  }, [checklistStatus.status])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 124 }}>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/green-checklist.svg"
          style={{ height: 120, width: 120 }}
          centered
        />
      </div>
      <div style={{ marginLeft: 16, flex: 1 }}>
        <Text as="h2" style={{ marginBottom: 8 }}>
          Get Your Estimate
        </Text>
        <Text color="darkGray" style={{ marginBottom: 16 }}>
          {infoText}
        </Text>
        {popupText && (
          <Popup
            trigger={
              <Button variant="tooltipLink" style={{ marginBottom: 40 }}>
                Things you should know
              </Button>
            }
            position="bottom left"
            content={
              <>
                <Text as="h3">Things you should know</Text>
                <br />
                <Text>{popupText}</Text>
              </>
            }
          />
        )}
        <ChecklistDueAt
          checkListDueAt={checklistDueAt}
          displayText={displayText}
          isPastCalculationDate={isPastCalculationDate}
        />
        <IRSPaymentDueAt
          irsPaymentDueAt={checklistStatus?.rest?.forDisplay.irsPaymentDue}
          isPastPaymentDate={isPastPaymentDate}
        />
      </div>
    </div>
  )
}
