import { useMemo, useState } from 'react'
import { Grid, Table } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Label,
  Popup,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import {
  AdminOverallExtensionStatus,
  selectAnnualTaxFilingForId,
  selectOverallExtensionStatusForFilingId,
} from './adminAnnualTaxFilings.selector'
import AdminExtensionInfoModal from './ExtensionInfoModal'

const ExtensionCell = ({
  refreshData,
  filingId,
}: {
  filingId: number
  refreshData: () => void
}) => {
  const filing = useReselector(selectAnnualTaxFilingForId, filingId)
  const user = filing?.user || filing?.annualTaxFilings
  const [adminExtensionModalOpen, setAdminExtensionModalOpen] = useState(false)
  const overallExtensionStatus = useReselector(
    selectOverallExtensionStatusForFilingId,
    filingId
  )

  const extensionStatusLabel = useMemo(() => {
    switch (overallExtensionStatus) {
      case AdminOverallExtensionStatus.notApplicable:
        return (
          <Label color="neutral">
            {AdminOverallExtensionStatus.notApplicable}
          </Label>
        )
      case AdminOverallExtensionStatus.canceled:
        return (
          <Label color="darkGray">{AdminOverallExtensionStatus.canceled}</Label>
        )
      case AdminOverallExtensionStatus.accepted:
        return (
          <Label color="green">{AdminOverallExtensionStatus.accepted}</Label>
        )
      case AdminOverallExtensionStatus.rejected:
        return <Label color="red">{AdminOverallExtensionStatus.rejected}</Label>
      case AdminOverallExtensionStatus.inProgress:
        return (
          <Label color="yellow">{AdminOverallExtensionStatus.inProgress}</Label>
        )
      case AdminOverallExtensionStatus.notStarted:
        return (
          <Label color="orange">{AdminOverallExtensionStatus.notStarted}</Label>
        )
      default:
        overallExtensionStatus satisfies never
        return null
    }
  }, [overallExtensionStatus])

  const statusToolTip = useMemo(
    () => (
      <Grid>
        <GridRowColumn>
          <Text>The user&apos;s overall extension status</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.notApplicable}</b>: The user is not
            required to file an extension
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.notStarted}</b>: The user has not
            started the extension request survey
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.inProgress}</b>: The extension is in
            progress. Includes all users who have started the extension request
            survey but do not yet have an accepted extension filing.
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.accepted}</b>: An extension has been
            filed successfully
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.rejected}</b>: The extension was
            rejected
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            <b>{AdminOverallExtensionStatus.canceled}</b>: The extension job has
            been canceled
          </Text>
        </GridRowColumn>
      </Grid>
    ),
    []
  )

  const exemptedLabel = useMemo(() => {
    if (filing?.exemptedFromExtensionAt) {
      return <Label color="blue">Exempted</Label>
    }
    return null
  }, [filing?.exemptedFromExtensionAt])

  if (!filing || !user) {
    return null
  }

  return (
    <Table.Cell>
      <Grid>
        <GridRowColumn short>
          <Text>
            Status:
            <Popup
              header={
                <GridRowColumn>
                  <Text as="h2">Extension Status</Text>
                </GridRowColumn>
              }
              content={statusToolTip}
            />
            {extensionStatusLabel}
          </Text>
        </GridRowColumn>
        <GridRowColumn short>{exemptedLabel}</GridRowColumn>
        <GridRowColumn>
          <Button
            variant="link"
            onClick={() => setAdminExtensionModalOpen(true)}
          >
            View More
          </Button>
        </GridRowColumn>
      </Grid>
      <AdminExtensionInfoModal
        open={adminExtensionModalOpen}
        close={() => setAdminExtensionModalOpen(false)}
        filing={filing}
        refreshData={refreshData}
        extensionTaxfyleJob={filing.extensionTaxfyleJob}
        filingForms={filing?.annualTaxFilingForms}
      />
    </Table.Cell>
  )
}

export default ExtensionCell
