import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Alert,
  Button,
  Dropdown,
  GridRowColumn,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import {
  fetchPaymentConfigs,
  PUT_UPDATE_PAYMENT_CONFIG_KEY,
  putUpdatePaymentConfig,
} from '../payrollActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Gusto_PaymentConfig,
  Gusto_PaymentSpeed,
} from '../generated_gusto_types'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import PayrollError from '../PayrollError'
import { selectPaySchedule } from '../payroll.selectors'

const dropdownValues = [
  { text: 'Next-day', value: '1-day' as const },
  { text: '2-day', value: '2-day' as const },
  { text: '4-day', value: '4-day' as const },
]

const PaymentConfigSettings = () => {
  const dispatch = useAppDispatch()
  const [paymentConfig, setPaymentConfig] = useState<Gusto_PaymentConfig>()
  const [paymentSpeed, setPaymentSpeed] = useState<Gusto_PaymentSpeed>()
  const [modalOpen, setModalOpen] = useState(false)
  const paySchedule = useReselector(selectPaySchedule)
  const autoPilotEnabled = Boolean(paySchedule?.auto_pilot)

  const isPosting = useReselector(getIsFetching, PUT_UPDATE_PAYMENT_CONFIG_KEY)

  useEffect(() => {
    const fetch = async () => {
      const res = await dispatch(fetchPaymentConfigs())

      if (res) {
        setPaymentConfig(res)
        setPaymentSpeed(res.payment_speed)
      }
    }

    fetch()
  }, [dispatch])

  const onSave = useCallback(async () => {
    const res = await dispatch(
      putUpdatePaymentConfig({ payment_speed: paymentSpeed })
    )

    if (res) {
      setPaymentConfig(res)
      setPaymentSpeed(res.payment_speed)
      setModalOpen(false)
    }
  }, [dispatch, paymentSpeed])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Text>Payment Speed:</Text>
        </Grid.Column>
        {paymentSpeed && (
          <Grid.Column width={2}>
            <Button
              onClick={() => setModalOpen(true)}
              variant="link"
              disabled={autoPilotEnabled}
            >
              Edit
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
      <GridRowColumn>
        <Text style={{ paddingLeft: 23 }}>
          {paymentConfig?.payment_speed === '1-day'
            ? 'Next-day'
            : paymentConfig?.payment_speed}
        </Text>
      </GridRowColumn>
      {autoPilotEnabled && (
        <GridRowColumn>
          <Alert type="warn">
            Payment speed cannot be edited when automatic payroll enabled
          </Alert>
        </GridRowColumn>
      )}
      <Modal
        closeIcon
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        size="tiny"
      >
        <Modal.Header>Update Payment Speed</Modal.Header>
        <Modal.Content>
          <Grid>
            <PayrollError fetchKey={PUT_UPDATE_PAYMENT_CONFIG_KEY} />
            <GridRowColumn>
              <Dropdown
                label="Payment speed"
                options={dropdownValues}
                value={paymentSpeed}
                onChange={setPaymentSpeed}
              />
            </GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onSave} loading={isPosting} disabled={isPosting}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </Grid>
  )
}

export default PaymentConfigSettings
