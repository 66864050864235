import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectRequiresPaymentForPersonalFiling } from '../TaxChecklist/taxChecklist.selectors'
import {
  Alert,
  Button,
  Card,
  Container,
  FormikCheckbox,
  FormikLabelError,
  FormikRadioButton,
  getFieldName,
  GridRowColumn,
  makeReqBoolSchema,
  Text,
} from '../../../../components/BaseComponents'
import { Grid, Image } from 'semantic-ui-react'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import {
  selectCurrentAnnualTaxFiling,
  selectUserAcceptedLateJoinerTerms,
} from '../annualTaxFilings.selector'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectIsCurrentUserScorp } from '../../../../selectors/user.selectors'
import { FormikProvider, useFormik } from 'formik'
import { formatCurrency } from '../../../../utils/currencyHelpers'
import { ADDITIONAL_1040_COST } from '../constants'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { Colors } from '../../../../styles/theme'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  ExtensionReason,
  fetchAnnualTaxFilingsIfNeeded,
  requestExtension,
  UPDATE_ANNUAL_TAX_FILING_KEY,
  updateAnnualTaxFilings,
} from '../annualTaxFilings.slice'
import { fetchFinancialProfileIfNeeded } from '../../../../actions/financialProfileActions'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { fetchUserCatchUpBookkeepingStatus } from '../../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { TaxFormType } from '../Questionnaires/constants'
import { DateTime } from 'luxon'
import { getFetchError } from '../../../../reducers/fetch'
import PageHeader from '../../../../components/shared/PageHeader'

const OptInConfirmation = () => {
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const isSCorp = useReselector(selectIsCurrentUserScorp)
  const extensionDeadline = convertUtcToLocalDate(
    isSCorp
      ? taxDetails?.form_1120_s_extension_survey_due_date
      : taxDetails?.form_1040_extension_survey_due_date
  )
  const navigate = useNavigate()

  return (
    <Container variant="noShadow" centerContent>
      <Card
        variant="noShadow"
        backgroundColor="lightGreen"
        style={{ maxWidth: 520 }}
      >
        <Grid>
          <GridRowColumn>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/circle_check_2.svg"
              style={{ height: 80, width: 80 }}
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2">Next Steps</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>Thank you for trusting Heard with your taxes.</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              We’ve updated your tasks to include an extension request. Please
              fill this out as soon as possible. The deadline to file an
              extension request is{' '}
              <b>
                {extensionDeadline?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
              </b>
              .
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Button
              onClick={() => {
                navigate('/taxes/annual')
                window.location.reload()
              }}
            >
              Back to Annual Taxes
            </Button>
          </GridRowColumn>
        </Grid>
      </Card>
    </Container>
  )
}

const TaxOptIn = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isSCorp = useReselector(selectIsCurrentUserScorp)
  const error = useReselector(getFetchError, UPDATE_ANNUAL_TAX_FILING_KEY)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const backLink = useMemo(
    () =>
      location.state?.fromExtensionRequest
        ? '/taxes/annual/extension_request'
        : '/taxes/annual',
    [location.state?.fromExtensionRequest]
  )
  const [showConfirmationPage, setShowConfirmationPage] = useState(false)
  const paymentRequiredForPersonalFiling = useReselector(
    selectRequiresPaymentForPersonalFiling
  )
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const currentTaxFiling = useReselector(selectCurrentAnnualTaxFiling)
  const isLateJoiner = useReselector(selectUserAcceptedLateJoinerTerms)
  const extensionRequiredAfterDate = useMemo(
    () =>
      isLateJoiner
        ? convertUtcToLocalDate(taxDetails?.new_user_cutoff_date)
        : convertUtcToLocalDate(taxDetails?.tax_season_kickoff_due_date),
    [
      isLateJoiner,
      taxDetails?.new_user_cutoff_date,
      taxDetails?.tax_season_kickoff_due_date,
    ]
  )
  const isPastExtensionDeadline = useMemo(() => {
    const deadline = convertUtcToLocalDate(
      isSCorp
        ? taxDetails?.form_1120_s_extension_survey_due_date
        : taxDetails?.form_1040_extension_survey_due_date
    )
    return deadline && DateTime.now() > deadline
  }, [
    isSCorp,
    taxDetails?.form_1120_s_extension_survey_due_date,
    taxDetails?.form_1040_extension_survey_due_date,
  ])

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchFinancialProfileIfNeeded())
    dispatch(fetchUserCatchUpBookkeepingStatus())
  }, [dispatch])

  const confirmOptIn = useCallback(
    async ({
      personalTaxesOptingIn,
      businessTaxesOptingIn,
    }: {
      personalTaxesOptingIn: boolean
      businessTaxesOptingIn: boolean
    }) => {
      if (currentTaxFiling?.id) {
        const formNeeds: TaxFormType[] = []
        if (businessTaxesOptingIn) {
          formNeeds.push(TaxFormType.form1120s)
        }
        if (personalTaxesOptingIn) {
          formNeeds.push(TaxFormType.form1040)
        }
        const updated = await updateAnnualTaxFilings(currentTaxFiling.id, {
          optedOutAt: null,
          annualTaxFormNeeds: formNeeds,
          isLateJoiner,
        })(dispatch)
        if (updated) {
          if (
            !isPastExtensionDeadline &&
            extensionRequiredAfterDate &&
            DateTime.now() > extensionRequiredAfterDate
          ) {
            const extended = await requestExtension(
              isLateJoiner
                ? ExtensionReason.lateJoiner
                : ExtensionReason.tskOverdue
            )(dispatch)
            if (extended) {
              setShowConfirmationPage(true)
            }
          } else {
            setShowConfirmationPage(true)
          }
        }
      }
    },
    [
      currentTaxFiling?.id,
      dispatch,
      extensionRequiredAfterDate,
      isLateJoiner,
      isPastExtensionDeadline,
    ]
  )

  const formik = useFormik({
    initialValues: {
      acknowledged: null as boolean | null,
      includePersonalFiling: null as boolean | null,
    },
    onSubmit: (values) => {
      return confirmOptIn({
        personalTaxesOptingIn: Boolean(
          !isSCorp || values.includePersonalFiling
        ),
        businessTaxesOptingIn: isSCorp,
      })
    },
  })

  const { values } = formik

  return showConfirmationPage ? (
    <OptInConfirmation />
  ) : (
    <>
      <Grid>
        <GridRowColumn>
          <PageHeader
            divider={false}
            header=""
            backControl={{
              link: '/taxes/annual',
              text: 'Back to Annual Taxes',
            }}
          />
        </GridRowColumn>
      </Grid>
      <Container centerContent variant="noShadow">
        <div style={{ maxWidth: 516 }}>
          <FormikProvider value={formik}>
            <Grid>
              <GridRowColumn centerContent>
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
                  style={{ height: 180, width: 180 }}
                />
              </GridRowColumn>
              <GridRowColumn textAlign="center">
                <Text as="h1">Taxes at Heard</Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text>
                  Heard prepares taxes for your therapy business. However, we
                  cannot support additional non-therapy businesses owned by you
                  or your spouse. Please note, we do not support amended
                  returns.
                </Text>
              </GridRowColumn>
              {!isPastExtensionDeadline && (
                <GridRowColumn short>
                  <Text>
                    Opting in for preparation of{isSCorp ? ' business' : ''}{' '}
                    taxes after{' '}
                    {extensionRequiredAfterDate?.toFormat(
                      DATE_FORMATS_LUXON.DISPLAY_LONG
                    )}
                    , requires filing of an extension. Heard can help you file
                    this at no extra cost.
                  </Text>
                </GridRowColumn>
              )}
              {isSCorp && !isPastExtensionDeadline && (
                <GridRowColumn short>
                  <Text>
                    We recommend that you also file an extension for your
                    personal taxes with your tax preparer. If you prefer, Heard
                    can prepare both your personal tax extension request and
                    filing.
                  </Text>
                </GridRowColumn>
              )}
              {isSCorp && (
                <>
                  <GridRowColumn>
                    <FormikLabelError
                      name={getFieldName<typeof values>(
                        'includePersonalFiling'
                      )}
                      label={`Would you like Heard to prepare ${isPastExtensionDeadline ? 'a' : 'an extension request and'} tax filing (Form 1040) for your ${taxYear} personal taxes?`}
                      description={
                        paymentRequiredForPersonalFiling ? (
                          <Text as="bodySm">
                            The cost is{' '}
                            {formatCurrency(ADDITIONAL_1040_COST, {
                              hideDecimalsIfZero: true,
                            })}{' '}
                            for the personal tax filing.
                          </Text>
                        ) : isPastExtensionDeadline ? (
                          <Text as="bodySm">
                            Personal tax filing is included in your plan.
                          </Text>
                        ) : (
                          <Text as="bodySm">
                            Extension requests and personal tax filing are
                            included in your plan.
                          </Text>
                        )
                      }
                      schema={makeReqBoolSchema()}
                    />
                  </GridRowColumn>
                  <GridRowColumn short>
                    <FormikRadioButton
                      name={getFieldName<typeof values>(
                        'includePersonalFiling'
                      )}
                      label="Yes"
                      value
                    />
                  </GridRowColumn>
                  {values.includePersonalFiling &&
                    paymentRequiredForPersonalFiling && (
                      <GridRowColumn short>
                        <Card backgroundColor="natural">
                          <Text>
                            Great! We will collect payment from you after you
                            have completed your tax questionnaire.{' '}
                            {isPastExtensionDeadline
                              ? ''
                              : 'In the meantime, we will help you file an extension.'}
                          </Text>
                        </Card>
                      </GridRowColumn>
                    )}
                  <GridRowColumn short>
                    <FormikRadioButton
                      name={getFieldName<typeof values>(
                        'includePersonalFiling'
                      )}
                      label={`No, I do not want Heard to prepare an extension request or tax filing (Form 1040) for ${taxYear} personal taxes`}
                      value={false}
                    />
                  </GridRowColumn>
                  {values.includePersonalFiling === false && (
                    <GridRowColumn>
                      <Card backgroundColor="natural">
                        <Grid>
                          <GridRowColumn>
                            <Text>
                              Note: Even though you have decided not to file
                              your Personal tax filing (Form 1040) with us, keep
                              in mind that you will still need to work with a
                              different tax preparer to file this with the IRS.
                            </Text>
                          </GridRowColumn>
                          <GridRowColumn short>
                            <Text>
                              If you change your mind, you can opt back in by{' '}
                              {convertUtcToLocalDate(
                                taxDetails?.form_1120_s_tq_due_date
                              )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}{' '}
                              before you complete your Business Tax
                              Questionnaire.
                            </Text>
                          </GridRowColumn>
                        </Grid>
                      </Card>
                    </GridRowColumn>
                  )}
                </>
              )}
              {(!isSCorp || values.includePersonalFiling !== null) && (
                <GridRowColumn>
                  <FormikCheckbox
                    style={{
                      backgroundColor: Colors.stone40,
                      width: '100%',
                      padding: 12,
                    }}
                    name={getFieldName<typeof values>('acknowledged')}
                    label="I acknowledge the above"
                    schema={makeReqBoolSchema({ trueOnly: true })}
                  />
                </GridRowColumn>
              )}
            </Grid>
          </FormikProvider>
        </div>
      </Container>
      <Grid>
        {error?.message && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        <FormFlowFooter
          onBack={() => navigate(backLink)}
          onForward={formik.submitForm}
          continueDisabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
        />
      </Grid>
    </>
  )
}

export default TaxOptIn
