import { useState, useEffect, useCallback } from 'react'
import {
  Table,
  Checkbox,
  Icon,
  Header,
  Grid,
  Label,
  Button,
  Transition,
} from 'semantic-ui-react'
import moment from 'moment'

import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import ConfirmTransactionButton from '../shared/ConfirmTransactionButton'
import NeedsClarificationButton from '../shared/NeedsClarificationButton'
import { updateTransactionEdit } from '../../../actions/transactionEditActions'
import {
  getTransactionSelector,
  selectTransactionEditByTransactionId,
  selectTransactionEdits,
} from '../../../selectors/financeSelectors'
import { TRANSACTION_TYPE_OPTIONS } from '../../../constants/transactionConstants'
import {
  getTransactionCategoryOptions,
  getTransactionCategorySelector,
} from '../../../features/Reports/reports.selectors'
import { getUserIsAdmin } from '../../../selectors/user.selectors'

import '../TransactionTable.scss'
import {
  getSelectedByTransactionId,
  updateSelectedTransaction,
} from '../../../reducers/admin/selectedTransactionsReducer'
import { isTransactionLocked } from '../../../features/Transactions/helpers'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { Dropdown } from '../../BaseComponents'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { TransactionEdit } from '../../../reducers/admin/allTransactionEdits.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { selectDocumentsByUserId } from '../../../features/UserDocuments/userDocuments.selector'
import MarkedDuplicateTransactionButton from '../shared/MarkedDuplicateTransactionButton'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const TransactionEditReview = ({
  transaction,
  initialTransactionEdit,
  handleTransactionEditCompletion,
  ignoreRedux = false,
}: {
  transaction: Transaction
  initialTransactionEdit: TransactionEdit | null
  handleTransactionEditCompletion: () => void
  ignoreRedux?: boolean
}) => {
  const dispatch = useAppDispatch()
  const [editedTransactionCategoryId, setEditedTransactionCategoryId] =
    useState<number>()
  const [editedTransactionType, setEditedTransactionType] = useState<string>()
  const [editedTransactionNote, setEditedTransactionNote] = useState<
    string | null
  >(null)
  const [submitting, setSubmitting] = useState(false)

  const categoryOptions = useReselector(getTransactionCategoryOptions)
  const [transactionEdit, setTransactionEdit] = useState(initialTransactionEdit)
  const transactionEdits = useReselector(selectTransactionEdits)
  const documents = useReselector(selectDocumentsByUserId, transaction.userId)

  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    transaction.transactionCategoryId
  )

  const receipts = transaction.receipts

  useEffect(() => {
    const newTransactionEdit = ignoreRedux
      ? initialTransactionEdit
      : transactionEdit?.id && transactionEdits[transactionEdit?.id]
    if (newTransactionEdit) {
      setEditedTransactionCategoryId(
        newTransactionEdit.transactionCategoryId || undefined
      )
      setEditedTransactionType(newTransactionEdit.transactionType || undefined)
      setEditedTransactionNote(newTransactionEdit.notes)
      setTransactionEdit(newTransactionEdit)
    }
  }, [transactionEdits, transactionEdit, initialTransactionEdit, ignoreRedux])

  const handleApproveTransactionEdit = useCallback(async () => {
    if (!transactionEdit?.id) {
      return
    }

    setSubmitting(true)
    const result = await updateTransactionEdit(transactionEdit.id, {
      approvedOn: Date.now(),
      transactionType: editedTransactionType || null,
      transactionCategoryId: editedTransactionCategoryId || null,
    })(dispatch)
    if (result) {
      setSubmitting(false)
      handleTransactionEditCompletion()
    }
  }, [
    dispatch,
    editedTransactionCategoryId,
    editedTransactionType,
    handleTransactionEditCompletion,
    transactionEdit?.id,
  ])

  const renderAttachedFiles = () => {
    if (!transactionEdit) return null
    if (!receipts || receipts.length === 0) {
      return <i>No attachments</i>
    } else {
      return receipts.map((receipt) => {
        const doc = ignoreRedux ? receipt : documents[receipt.id]

        if (!doc?.signedUrl) {
          return null
        }

        return (
          <p key={receipt.id}>
            <a href={doc.signedUrl} target="_blank" rel="noopener noreferrer">
              {doc.fileDescription}
            </a>
          </p>
        )
      })
    }
  }

  if (!transactionEdit) {
    return <Header as="h6">User has not reviewed transaction yet</Header>
  } else {
    const disabled =
      editedTransactionType === 'business'
        ? !editedTransactionType || !editedTransactionCategoryId
        : !editedTransactionType

    return (
      <Grid stackable doubling className="compact">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4">
              Proposed Changes for Transaction {transaction.id}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h5">Old Categorization</Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <p>
              <b>Type </b>
            </p>
            <Label>{transaction.type || 'None Selected'}</Label>
          </Grid.Column>
          <Grid.Column width={8}>
            <p>
              <b>Transaction Category</b>
            </p>
            <Label>{selectedCategory?.name || 'None Selected'}</Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h5">Suggested Edits</Header>
          </Grid.Column>

          <Grid.Column width={4}>
            <p>
              <b>Type </b>
            </p>
            <Dropdown
              placeholder="Select Transaction Type"
              value={editedTransactionType}
              options={TRANSACTION_TYPE_OPTIONS}
              onChange={setEditedTransactionType}
              search
              clearable
              selectOnBlur={false}
              fluid
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <p>
              <b>Transaction Category</b>
            </p>
            <Dropdown
              placeholder="Select Category"
              value={editedTransactionCategoryId}
              options={categoryOptions}
              onChange={setEditedTransactionCategoryId}
              search
              clearable
              fluid
              selectOnBlur={false}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <p>
              <b>Receipts</b>
            </p>
            {renderAttachedFiles()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <p>
              <b>Note</b>
            </p>
            <p>{editedTransactionNote || 'None'}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} floated="right" textAlign="right">
            <Button
              size="mini"
              primary
              onClick={handleApproveTransactionEdit}
              disabled={disabled}
              loading={submitting}
            >
              Approve and Confirm Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const TransactionTableExpandedRow = ({
  transactionId,
}: {
  transactionId: number | string
}) => {
  const dispatch = useAppDispatch()
  const [editComplete, setEditComplete] = useState(false)
  const transaction = useReselector(getTransactionSelector, transactionId)
  const selected = useReselector(getSelectedByTransactionId, transactionId)
  const transactionEdit = useReselector(
    selectTransactionEditByTransactionId,
    transactionId
  )

  const [checked, setChecked] = useState(selected || false)
  const [expanded, setExpanded] = useState(false)

  const selectedCategory = useReselector(
    getTransactionCategorySelector,
    transaction?.transactionCategoryId
  )

  const category = useReselector(
    getTransactionCategorySelector,
    transactionEdit?.transactionCategoryId
  )

  const editCategory = category?.name || 'Not provided'

  const isAdmin = useReselector(getUserIsAdmin)

  const handleCheckboxSelect = useCallback(() => {
    const newCheckedValue = !checked
    setChecked(newCheckedValue)
    if (transaction?.id) {
      dispatch(
        updateSelectedTransaction({
          transactionId: transaction.id,
          value: newCheckedValue,
        })
      )
    }
  }, [checked, dispatch, transaction?.id])

  const handleTransactionEditCompletion = () => {
    setExpanded(false)
    setEditComplete(true)
  }

  if (!transaction) {
    return null
  }

  if (editComplete) {
    return (
      <Table.Row positive>
        <Table.Cell colSpan={11}>Transaction Edit Approved</Table.Cell>
      </Table.Row>
    )
  }

  const locked = isTransactionLocked({
    date: transaction.date,
    userLockedYear: transaction.user?.booksLockedForUserYear,
    adminLockedYear: transaction.user?.booksLockedForAdminYear,
    isAdmin,
  })

  return (
    <Transition duration={200} visible={!editComplete} animation="scale">
      <>
        <Table.Row
          negative={!transaction.type}
          warning={Boolean(transaction.type) && !transaction.confirmedAt}
          positive={transaction.confirmedAt !== null}
          disabled={locked}
        >
          <Table.Cell>
            <Checkbox
              checked={selected}
              onClick={() => handleCheckboxSelect()}
            />
          </Table.Cell>
          <Table.Cell>{transaction.id}</Table.Cell>
          <Table.Cell>{transaction.userId}</Table.Cell>
          <Table.Cell>
            {moment.utc(transaction.date).format(DATE_FORMATS.DISPLAY_SHORT)}
          </Table.Cell>
          <Table.Cell>
            {transaction.accountTransactions && (
              <span>
                {transaction.accountTransactions?.plaidInstitutionName}:{' '}
                {transaction.accountTransactions?.name}{' '}
                {transaction.accountTransactions?.mask}
              </span>
            )}
            {!transaction.accountTransactions && <span>N/A</span>}
          </Table.Cell>
          <Table.Cell>{transaction.description}</Table.Cell>
          <Table.Cell textAlign="right">
            <CurrencyFormatLabel
              value={centsToDollars(transaction.amountInCents)}
            />
          </Table.Cell>
          <Table.Cell>
            <div>
              <i>
                <s>{transaction?.type}</s>
              </i>
              <p>{transactionEdit?.transactionType || 'Not provided'}</p>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div>
              <i>
                <s>
                  {selectedCategory ? selectedCategory.name : 'Not Provided'}
                </s>
              </i>
              <p>{editCategory}</p>
            </div>
          </Table.Cell>
          <Table.Cell>{transaction.receipts?.length}</Table.Cell>
          <Table.Cell textAlign="center">
            <div className="actionsCellContainer">
              <ConfirmTransactionButton transaction={transaction} />
              <NeedsClarificationButton transaction={transaction} />
            </div>
          </Table.Cell>
          <Table.Cell onClick={() => setExpanded((prevExpand) => !prevExpand)}>
            {expanded ? (
              <Icon name="caret down" />
            ) : (
              <Icon name="caret right" />
            )}
          </Table.Cell>
          {transaction.manuallyDedupedAt && (
            <Table.Cell>
              <MarkedDuplicateTransactionButton transaction={transaction} />
            </Table.Cell>
          )}
        </Table.Row>
        {expanded && (
          <Table.Row className="transactionTableExpandedRow">
            <Table.Cell colSpan={11}>
              <TransactionEditReview
                transaction={transaction}
                initialTransactionEdit={transactionEdit}
                handleTransactionEditCompletion={
                  handleTransactionEditCompletion
                }
              />
            </Table.Cell>
          </Table.Row>
        )}
      </>
    </Transition>
  )
}

export default TransactionTableExpandedRow
