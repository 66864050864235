import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Header, Table } from 'semantic-ui-react'
import moment from 'moment'

import {
  adminGetUserAllocationGuides,
  adminGetUserAllocationRules,
} from '../../../selectors/allocationGuideSelectors'
import { fetchAdminUserAllocationGuides } from '../../../actions/allocationGuideActions'
import { User } from '../../../reducers/auth/userReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'

const AllocationGuidesCard = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const guides = useReselector(adminGetUserAllocationGuides, user.id)
  const rules = useReselector(adminGetUserAllocationRules, user.id)

  useEffect(() => {
    dispatch(fetchAdminUserAllocationGuides(user.id))
  }, [dispatch, user.id])

  const renderRules = () => {
    if (Object.keys(rules).length === 0) {
      return (
        <div>
          <p>User has not set up allocation guides yet</p>
        </div>
      )
    } else {
      return (
        <div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={3}>Current Guide:</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(rules).map(({ id, ruleName, percentage }) => (
                <Table.Row key={`rule-${id}`}>
                  <Table.Cell>{ruleName}</Table.Cell>
                  <Table.Cell>{percentage}%</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )
    }
  }
  const tableBody = () => {
    if (Object.keys(guides).length === 0) {
      return (
        <Table.Row textAlign="center">
          <Table.Cell colSpan={3}>None yet</Table.Cell>
        </Table.Row>
      )
    } else {
      return Object.values(guides).map(({ id, date, compiledOn }) => {
        return (
          <Table.Row key={`guide${id}`}>
            <Table.Cell>
              {moment(date).format(DATE_FORMATS.MONTH_YEAR)}
            </Table.Cell>
            <Table.Cell>
              {moment(compiledOn).format(DATE_FORMATS.DISPLAY_LONG)}
            </Table.Cell>
            <Table.Cell>
              <Link
                to={`/admin/finances/records/${user.id}/allocation-guides/${id}`}
              >
                View Guide
              </Link>
            </Table.Cell>
          </Table.Row>
        )
      })
    }
  }

  return (
    <Card fluid className="allocationGuidesCard">
      <div className="flexbox">
        <Card.Header>
          <Header as="h4">Allocation Guides</Header>
        </Card.Header>
      </div>
      <Card.Content>{renderRules()}</Card.Content>

      <Card.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>Month</Table.HeaderCell>
              <Table.HeaderCell width={6}>Compiled On</Table.HeaderCell>
              <Table.HeaderCell width={4}>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableBody()}</Table.Body>
        </Table>
      </Card.Content>
    </Card>
  )
}

export default AllocationGuidesCard
