import { useCallback, useEffect, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { Card, Link, Text } from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import {
  getInstructionsLink,
  getMatchingMerchants,
  getNeededDocuments,
  IMerchantDocument,
  INeededDocument,
  IMerchantFees,
} from './documents-map'
import { IDocumentsStepContext } from '../types'
import { NotRelevantDocuments } from '../not-relevant-documents'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { useDocuments } from '../hooks'
import './styles.scss'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'

export interface IProcessingFeesDocumentsProps {
  processingFees?: IMerchantFees[]
  documents: UserDocument[]
  taxYear: string
  stepContext: IDocumentsStepContext
  setStepContext: (context: IDocumentsStepContext) => void
}

export const ProcessingFeesDocuments = ({
  processingFees = [],
  documents,
  taxYear,
  stepContext,
  setStepContext,
}: IProcessingFeesDocumentsProps) => {
  const [uniqueDocumentMerchants, setUniqueDocumentMerchants] = useState<
    IMerchantDocument[]
  >([])
  const [neededDocuments, setNeededDocuments] = useState<INeededDocument[]>([])
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const documentCategoryMap = new Map<
    UploadDocumentType,
    UserDocumentCategoryIdentifier[]
  >()
  documentCategoryMap.set(UploadDocumentType.TAX, [
    UserDocumentCategoryIdentifier.form1099k,
    UserDocumentCategoryIdentifier.form1099nec,
  ])
  documentCategoryMap.set(UploadDocumentType.BOOKKEEPING, [])
  const [fileUpload, setFileUpload] = useState<{
    merchantName: string
    documentCategoryName: string
    documentName: string
    documentCategoryNameInternal?: string
  } | null>(null)

  const handleFileUpload = (
    merchantName?: string,
    documentCategoryName?: string,
    documentName?: string,
    documentCategoryNameInternal?: string
  ) => {
    setIsFileUploadModalOpen(true)

    if (merchantName && documentCategoryName && documentName) {
      setFileUpload({
        merchantName,
        documentCategoryName,
        documentName,
        documentCategoryNameInternal,
      })
    } else {
      setFileUpload(null)
    }
  }

  const onFileUploaded = useCallback(
    (uploadedFile: UploadedFile) => {
      // Update step context based on the uploaded file
      if (!fileUpload) {
        const otherDocumentIds = [
          ...(stepContext.otherDocumentIds ?? []),
          uploadedFile.id,
        ]
        setStepContext({ ...stepContext, otherDocumentIds })
      } else {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload.documentCategoryName
        const name = fileUpload.documentName
        const provider = fileUpload.merchantName
        const documents = [
          ...(stepContext.documents?.filter(
            (doc) =>
              !(
                doc.categoryCode === categoryCode &&
                doc.provider === provider &&
                doc.name === name
              )
          ) ?? []),
          {
            documentId,
            categoryCode,
            name,
            provider,
          },
        ]
        setStepContext({ ...stepContext, documents })
      }
    },
    [fileUpload, stepContext, setStepContext]
  )

  useEffect(() => {
    const mergeMerchantNames = ['Alma', 'Headway']
    const uniqueMerchants = getMatchingMerchants(
      taxYear,
      processingFees,
      mergeMerchantNames
    )
    setUniqueDocumentMerchants(uniqueMerchants)

    const neededDocuments = getNeededDocuments(taxYear, processingFees)
    setNeededDocuments(neededDocuments)
  }, [taxYear, processingFees])

  const { filterDocuments, getStatus } = useDocuments({
    stepContextDocuments: stepContext?.documents,
    userDocuments: documents,
  })

  return (
    <div className="eoybk__processing-fees__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={
          fileUpload === null
            ? UploadDocumentType.OTHER
            : UploadDocumentType.BOOKKEEPING
        }
        categoryInternalName={
          fileUpload?.documentCategoryName &&
          fileUpload?.documentCategoryName === 'mix_documents'
            ? undefined
            : fileUpload?.documentCategoryName
        }
        open={isFileUploadModalOpen}
        userFacing
        isUploadOnly={
          fileUpload?.documentCategoryName === 'mix_documents' ? false : true
        }
        setUploadedFile={onFileUploaded}
        isMixedType={
          fileUpload?.documentCategoryName === 'mix_documents' ? true : false
        }
        internalCategoryNameFlag={fileUpload?.documentCategoryNameInternal}
        documentCategoryMap={documentCategoryMap}
      />

      <StepHeaders
        customColor="yellow"
        description="Next, we need reports that show any processing fees deducted from your income. This helps us record those fees as business expenses."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/credit_card.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Processing fees"
      >
        <Link
          to="https://support.joinheard.com/hc/en-us/articles/19809547067543-Obtaining-and-uploading-year-end-documents#merchant_fees"
          className="eoybk__processing-fees__link"
        >
          Learn more
        </Link>
      </StepHeaders>

      <div>
        <Text>
          The name of this report varies from vendor to vendor. Please see the
          instructions below for each.
        </Text>

        {neededDocuments.length > 0 && (
          <Card backgroundColor="stone40">
            <Text as="eyebrow" color="darkGray">
              DOCUMENTS YOU NEED
            </Text>

            <Text>Based on your transactions, you’ll need to upload:</Text>

            <div className="eoybk__processing-fees__provider-list">
              {neededDocuments.map(({ name, documents }) => (
                <ul
                  key={name}
                  className="eoybk__processing-fees__provider-uploads"
                >
                  <li className="eoybk__processing-fees__provider-upload">
                    <strong>{name}</strong>
                    <ul>
                      {documents.map((document) => (
                        <li key={document.description}>
                          {document.description}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              ))}
            </div>

            <Text>
              Please contact your vendor&apos;s support if you need assistance
              locating your documents.
            </Text>
          </Card>
        )}
      </div>

      <Divider className="eoybk__processing-fees__divider" />

      <Text as="h2">Upload income source reports</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__processing-fees__document-cards">
        <NotRelevantDocuments
          stepContext={stepContext}
          setStepContext={setStepContext}
          renderDocumentUploadCards={(isMarkedNotRelevant, handleNotRelevant) =>
            uniqueDocumentMerchants.map(
              ({
                id,
                merchantName,
                documentCategoryName,
                documentName,
                documentCategoryNameInternal,
              }) => (
                <DocumentUploadCard
                  key={`${id}-${documentName}`}
                  name={`${merchantName} - ${documentName}`}
                  description="This captures income, processing fees, and their timing."
                  href={getInstructionsLink(documentCategoryName, merchantName)}
                  notRelevant={isMarkedNotRelevant?.(
                    documentCategoryName,
                    merchantName,
                    documentName
                  )}
                  setChecked={() =>
                    handleNotRelevant?.(
                      documentCategoryName,
                      merchantName,
                      documentName
                    )
                  }
                  status={getStatus(
                    documentCategoryName,
                    merchantName,
                    isMarkedNotRelevant,
                    documentName
                  )}
                  documents={filterDocuments(
                    documentCategoryName,
                    merchantName,
                    documentName
                  )}
                  handleUploadBtn={() =>
                    handleFileUpload(
                      merchantName,
                      documentCategoryName,
                      documentName,
                      documentCategoryNameInternal
                    )
                  }
                />
              )
            )
          }
        />

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you'd like to share with the bookkeeping team here."
          status="disabled"
          documents={documents.filter((doc) =>
            (stepContext?.otherDocumentIds ?? []).includes(doc.id)
          )}
          handleUploadBtn={() => handleFileUpload()}
        />
      </div>
    </div>
  )
}
