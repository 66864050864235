import { Label } from '../../../../components/BaseComponents'
import {
  getUserById,
  selectPrimaryMembershipForUser,
} from '../../../../selectors/user.selectors'
import { useFetchResponse, useReselector } from '../../../../utils/sharedHooks'
import { canAutoGenerateBS } from './helpers'
import { adminGetSubmission } from '../../../Bookkeeping/end-of-year-review/actions'
import { EOYBookkeepingFollowupSubstep } from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { EndOfYearSubmissionStatus } from '../../../Bookkeeping/end-of-year-review/types'

const NeedsManualBalanceSheetLabel = ({
  userId,
  year,
}: {
  userId: number
  year: string
}) => {
  const { balanceSheetGeneration } = EOYBookkeepingFollowupSubstep
  const user = useReselector(getUserById, userId)

  const submission = useFetchResponse(adminGetSubmission, userId, year)

  const needsManualBS =
    submission?.status === EndOfYearSubmissionStatus.waitingOnHeard &&
    submission?.substepIdentifier === balanceSheetGeneration

  const membership = useReselector(selectPrimaryMembershipForUser, userId)
  const canAutoGenerate = canAutoGenerateBS(
    membership?.startDate,
    user?.financialProfile?.entityChangeElectionDate
  )

  if (!canAutoGenerate && needsManualBS) {
    return (
      <Label color="red">Manual Balance Sheet for {year} Taxes Needed</Label>
    )
  }

  return null
}

export default NeedsManualBalanceSheetLabel
