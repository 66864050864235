import {
  EOYBookkeepingFollowupSubstep,
  YearEndModuleStatusOptions,
  YearEndModuleSubstepId,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  getDefaultButtonText,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Icon, Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { UploadedFile } from '../../../components/FileUpload/FileUploadModal'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
  getModuleComplementaryText,
  getSubstepURL,
} from '../yearEndModuleStatus.helpers'
import { useMemo } from 'react'

const getWaitingOnHeardDescription = (
  substepIdentifier?: YearEndModuleSubstepId | null
) => {
  switch (substepIdentifier) {
    case EOYBookkeepingFollowupSubstep.initialBookkeeperReview:
      return 'We’re working on your bookkeeping. We’ll let you know here if we need anything else from you. You’ll have the chance to re-review your books after we’re done.'
    case EOYBookkeepingFollowupSubstep.followupBookkeeperReview:
      return 'We’re reviewing your updates and will let you know if we need anything else.'
    case EOYBookkeepingFollowupSubstep.balanceSheetGeneration:
      return 'We’re working on creating your balance sheet. This typically takes about 5 business days.'
    default:
      return 'We’re working on your end-of-year bookkeeping'
  }
}

const getActionRequiredDescription = (
  substepIdentifier?: YearEndModuleSubstepId | null
) => {
  switch (substepIdentifier) {
    case EOYBookkeepingFollowupSubstep.userFollowup:
      return 'The bookkeeping team identified some items that we need you to follow up on in order to complete your bookkeeping.'
    case EOYBookkeepingFollowupSubstep.finalUserApproval:
      return 'Please review your completed books. If you’re satisfied, finalize them by signing off.'
    default:
      return 'Please review your end of year bookkeeping summary'
  }
}

const getButtonLabel = (
  status: YearEndModuleStatusOptions,
  substepIdentifier?: YearEndModuleSubstepId | null
) => {
  if (status === YearEndModuleStatusOptions.actionRequired) {
    switch (substepIdentifier) {
      case EOYBookkeepingFollowupSubstep.userFollowup:
        return 'View Follow-Ups'
      case EOYBookkeepingFollowupSubstep.finalUserApproval:
        return 'Review and Sign Off'
      default:
        return 'Review Summary'
    }
  }
  return getDefaultButtonText(status)
}

const getDescription = (
  status: YearEndModuleStatusOptions,
  substepIdentifier?: YearEndModuleSubstepId | null,
  profitLossFileDownloadLinks?: UploadedFile[],
  balanceSheetFileDownloadLink?: UploadedFile
) => {
  switch (status) {
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return getWaitingOnHeardDescription(substepIdentifier)
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return getActionRequiredDescription(substepIdentifier)
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          moduleType={YearEndModuleType.eoyBookkeepingFollowup}
        >
          Our bookkeepers will review your books and let you know if we need
          anything else from you to complete your end of year accounting.
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          <Text as="bodySm">
            You will now <b>need to file an extension for taxes</b>. You still
            need to complete this task to prepare your books.
          </Text>
          <br />
          <Text as="bodySm">
            If you need help, please reach out to your accountant or tax
            professional.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      const links = (
        <div
          style={{ paddingTop: 16, display: 'flex', flexDirection: 'column' }}
        >
          {profitLossFileDownloadLinks?.map((link) => (
            <Link key={link.id} href={link.url} newPage size="small">
              <Icon icon={regular('download')} style={{ paddingRight: 8 }} />
              {link.title}
            </Link>
          ))}
          {balanceSheetFileDownloadLink && (
            <Link href={balanceSheetFileDownloadLink.url} newPage size="small">
              <Icon icon={regular('download')} style={{ paddingRight: 8 }} />
              {balanceSheetFileDownloadLink.title}
            </Link>
          )}
        </div>
      )

      return (
        <span>
          We’ve reviewed and closed your books for the year.
          {links}
        </span>
      )
    }
    default:
      return null
  }
}

const EOYBookkeepingFollowupCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  profitLossFileDownloadLinks,
  balanceSheetFileDownloadLink,
  isScorp,
}: YearEndModuleStatusDynamicDataParams & {
  profitLossFileDownloadLinks?: UploadedFile[]
  balanceSheetFileDownloadLink?: UploadedFile
  isScorp?: boolean
}) => {
  const description = getDescription(
    status,
    substepIdentifier,
    profitLossFileDownloadLinks,
    balanceSheetFileDownloadLink
  )
  const substepUrl = useMemo(
    () => getSubstepURL(taxYear, substepIdentifier),
    [taxYear, substepIdentifier]
  )

  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.eoyBookkeepingFollowup,
    status,
    substepUrl
  )
  const daysUntilDue = getDeadlineApproachingDaysUntilDueDate(dueDate) || 0
  const linkHref = isScorp
    ? 'https://support.joinheard.com/hc/en-us/articles/4560662501271-Filing-for-an-Extension-as-an-S-Corporation'
    : 'https://support.joinheard.com/hc/en-us/articles/12962191242007-Filing-an-extension-for-your-1040-individual-income-tax-return'
  const warningText =
    daysUntilDue >= 0
      ? 'After that, you’ll need to file an extension for your taxes.'
      : 'If you do not complete this ASAP, you will need to file an extension for your taxes.'
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.eoyBookkeepingFollowup}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header={getModuleNameCopy(YearEndModuleType.eoyBookkeepingFollowup)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/boooks-complete.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'days',
        value: '3-7',
        complementaryText: getModuleComplementaryText(
          YearEndModuleType.eoyBookkeepingFollowup
        ),
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            {warningText}{' '}
            <Link
              href={linkHref}
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        text: getButtonLabel(status, substepIdentifier),
        onActionClick,
      }}
    />
  )
}

export default EOYBookkeepingFollowupCard
