import { useEffect, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'

import { GridRowColumn, Text } from '../../../../../components/BaseComponents'
import DetailsAccordion from './DetailsAccordion'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { fetchBasicProductsV2, getProductByInterval } from '../../helpers'
import { StripeInterval } from '../../../../../reducers/subscription.slice'
import {
  centsToCurrency,
  formatCurrency,
  formatCurrencyFromCents,
} from '../../../../../utils/currencyHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectProductDescription } from '../../../../../selectors/user.selectors'
import { StripeProduct } from '../../../../../actions/settings/billingActions'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchStripePlans } from '../../../../../constants/pricingConstants'

const PlanDetailsSection = ({
  stripeProducts,
  selectedProduct,
  freeTrialEnabled,
  basicPlanEnabled,
  basicPlanSearchParamValue,
  isLateJoiner,
}: {
  stripeProducts: StripeProduct[]
  selectedProduct?: StripeProduct
  freeTrialEnabled: boolean
  basicPlanEnabled: boolean
  basicPlanSearchParamValue: string | null | undefined
  isLateJoiner: boolean | null | undefined
}) => {
  const dispatch = useAppDispatch()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const annualPlan = getProductByInterval(stripeProducts, StripeInterval.year)
  const isAnnual = selectedProduct === annualPlan
  const monthlyDollarsFormatted = useMemo(
    () =>
      selectedProduct?.default_price && isAnnual
        ? formatCurrency(
            centsToCurrency(selectedProduct.default_price.unit_amount).divide(
              12
            ),
            { hideDecimalsIfZero: true }
          )
        : formatCurrencyFromCents(selectedProduct?.default_price.unit_amount, {
            hideDecimalsIfZero: true,
          }),
    [isAnnual, selectedProduct?.default_price]
  )

  const sections = [
    'taxes' as const,
    'bookkeeping' as const,
    'budget' as const,
    'scorp' as const,
  ]

  const productDescription = useReselector(selectProductDescription, {
    sections,
    basicPlanEnabled,
    basicPlanSearchParamValue,
  })

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  const annualPrice = useMemo(() => {
    if (basicPlanEnabled) {
      const basicPlans = fetchBasicProductsV2()
      const stripePlans = fetchStripePlans()
      if (basicPlanSearchParamValue === 'basic') {
        return formatCurrencyFromCents(
          basicPlans.BASIC_NO_QTE_PRODUCTS.find(
            (p) => p?.default_price?.id === selectedProduct?.default_price?.id
          )?.default_price?.unit_amount,
          {
            hideDecimalsIfZero: true,
          }
        )
      }
      const basicPlanType =
        selectedProduct?.default_price.id ===
        stripePlans.basic_group.annual.priceId
          ? basicPlans.BASIC_GROUP_PRODUCTS
          : basicPlans.BASIC_SOLO_PRODUCTS
      return formatCurrencyFromCents(
        basicPlanType.find(
          (p) => p?.default_price?.id === selectedProduct?.default_price?.id
        )?.default_price?.unit_amount,
        {
          hideDecimalsIfZero: true,
        }
      )
    }
    return (
      selectedProduct?.default_price &&
      formatCurrencyFromCents(selectedProduct.default_price.unit_amount, {
        hideDecimalsIfZero: true,
      })
    )
  }, [
    basicPlanEnabled,
    basicPlanSearchParamValue,
    selectedProduct?.default_price,
  ])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'}>
            {isAnnual ? 'annual plan' : 'monthly plan'}
          </Text>
        </Grid.Column>
        <Grid.Column width={8}>
          <Text color={'darkGray'} as={'eyebrow'} textAlign="right">
            {!isAnnual && !isLateJoiner && 'cancel anytime'}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={9}>
          <Text as={isMobile ? 'h2' : 'h1'}>{selectedProduct?.name}</Text>
        </Grid.Column>
        <Grid.Column textAlign="right" width={7}>
          <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
            {monthlyDollarsFormatted}
          </Text>
          <Text as={isMobile ? 'h3' : 'h2'} style={{ display: 'inline' }}>
            /mo
          </Text>
          {isAnnual && (
            <div>
              <Text
                color="darkGray"
                style={{
                  position: 'absolute',
                  right: 15,
                }}
              >
                {freeTrialEnabled
                  ? 'Free Trial'
                  : `Billed ${annualPrice} annually`}
              </Text>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn className="short">
        <DetailsAccordion productDescription={productDescription} />
      </GridRowColumn>
    </Grid>
  )
}

export default PlanDetailsSection
