import { useCallback, useState } from 'react'
import { Confirm, Container, Grid } from 'semantic-ui-react'

import { getUserById } from '../../../../selectors/user.selectors'
import {
  disablePayroll,
  fetchPayrollForUser,
} from '../../../Payroll/payrollActions'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import {
  useFetchResponseWithUtils,
  useReselector,
} from '../../../../utils/sharedHooks'
import {
  Button,
  Card,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import EnrollPayrollModal from './EnrollPayrollModal'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import AdminPayrollOnboardedDashboard from './AdminPayrollOnboardedDashboard'
import AdminGepInterestSurvey from './AdminGepInterestSurvey'

const PayrollTabPanel = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch()
  const user = useReselector(getUserById, userId)
  const [modalOpen, setModalOpen] = useState(false)

  const { result: payrollProfile, refetch } = useFetchResponseWithUtils(
    fetchPayrollForUser,
    userId
  )
  const [confirmOpen, setConfirmOpen] = useState(false)

  const onModalClose = useCallback(() => {
    setModalOpen(false)
    refetch()
  }, [refetch])

  const unenrollUser = useCallback(async () => {
    const res = await disablePayroll(userId, payrollProfile?.id)(dispatch)
    if (res) {
      refetch()
      setConfirmOpen(false)
    }
  }, [dispatch, refetch, payrollProfile?.id, userId])

  if (!user) {
    return null
  }

  const { financialProfile, payrollEnabledAt } = user

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Heard Payroll</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              <b>Business Name:</b> {financialProfile?.businessName}
            </Text>
            <Text>
              <b>Tax entity:</b> {financialProfile?.taxEntityType}
            </Text>
          </GridRowColumn>
          <Grid.Row />
          {payrollProfile?.onboardingCompleted && (
            <AdminPayrollOnboardedDashboard payrollProfile={payrollProfile} />
          )}
          <Grid.Row />
          <AdminGepInterestSurvey userId={userId} />
          <Grid.Row />
          {payrollEnabledAt ? (
            <>
              <GridRowColumn short>
                <Text>
                  <b>Payroll Enabled On:</b>{' '}
                  {formatISOFromUTC(
                    payrollEnabledAt,
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </Text>
              </GridRowColumn>
              {payrollProfile && (
                <GridRowColumn short>
                  <Text>
                    <b>Payroll Profile Active:</b>{' '}
                    {String(payrollProfile.isActive)}
                  </Text>
                  <Text>
                    <b>Onboarding Completed:</b>{' '}
                    {String(payrollProfile.onboardingCompleted)}
                  </Text>
                  <Text>
                    <b>Needs Migration:</b>{' '}
                    {String(payrollProfile.needsMigration)}
                  </Text>
                </GridRowColumn>
              )}
              <GridRowColumn>
                <Button variant="warning" onClick={() => setConfirmOpen(true)}>
                  Un-Enroll User in Payroll
                </Button>
              </GridRowColumn>
              <Confirm
                size="tiny"
                open={confirmOpen}
                cancelButton="Cancel"
                confirmButton="Disable Payroll for User"
                content="Are you sure you want to disable Payroll for this User?"
                onCancel={() => setConfirmOpen(false)}
                onConfirm={unenrollUser}
              />
            </>
          ) : (
            <>
              <GridRowColumn>
                <Button variant="secondary" onClick={() => setModalOpen(true)}>
                  Enroll User in Payroll
                </Button>
              </GridRowColumn>
              <EnrollPayrollModal
                open={modalOpen}
                close={onModalClose}
                userId={user.id}
              />
            </>
          )}
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollTabPanel
