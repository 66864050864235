import { useState, useMemo, useEffect } from 'react'
import { Grid, Progress } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  GridRowColumn,
  Input,
  Text,
  Icon,
  Pill,
} from '../../../components/BaseComponents'
import {
  useReselector,
  useFetchResponse,
  useToggle,
} from '../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../selectors/user.selectors'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  dollarsToCents,
  centsToDollars,
  formatCurrencyFromCentsAndRound,
} from '../../../utils/currencyHelpers'
import {
  updateFinancialProfile,
  UPDATE_FINANCIAL_PROFILE_KEY,
} from '../../../actions/financialProfileActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { getIsFetching } from '../../../reducers/fetch'
import styled from 'styled-components'
import { Colors } from '../../../styles/theme'
import {
  INSIGHTS_TYPE,
  getInsightsMonthYear,
} from '../../../components/Finances/shared/utils'
import { fetchInsightsSummary } from '../../../components/Finances/shared/financeActions'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'

const StyledProgress = styled(Progress)`
  height: 9px;
  & > .bar {
    height: 9px !important;
    background-color: ${(props) => props.color || 'green'} !important;
  }
`

export const IncomeGoalCard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()
  const financialProfile = useReselector(getFinancialProfile)
  const updateLoading = useReselector(
    getIsFetching,
    UPDATE_FINANCIAL_PROFILE_KEY(financialProfile?.id)
  )

  const [annualIncome, setAnnualIncome] = useState(
    financialProfile?.annualIncomeGoalInCents
      ? centsToDollars(financialProfile.annualIncomeGoalInCents).toString()
      : ''
  )
  const [editing, toggleEditing] = useToggle()

  const { month, year } = getInsightsMonthYear(DateTime.now())

  useEffect(() => {
    pageView('income goal progress card')
  }, [pageView])

  const summaryDataResponse = useFetchResponse(
    fetchInsightsSummary,
    INSIGHTS_TYPE.INCOME,
    month,
    year
  )

  const handleTrack = async () => {
    await updateFinancialProfile(financialProfile?.id, {
      annualIncomeGoalInCents: dollarsToCents(annualIncome),
    })(dispatch)
    if (editing) {
      toggleEditing()
    }
  }

  const displayState = useMemo(() => {
    if (financialProfile?.annualIncomeGoalInCents === null) {
      return 'empty'
    } else if (editing) {
      return 'editing'
    } else {
      return 'progress'
    }
  }, [financialProfile, editing])

  const incomeYearToDate = useMemo(() => {
    if (!summaryDataResponse?.[0]) return 0
    if (summaryDataResponse[0].ytd_total_in_cents === null) return 0
    return summaryDataResponse[0].ytd_total_in_cents
  }, [summaryDataResponse])

  const incomeGoalProgress = useMemo(() => {
    if (!financialProfile?.annualIncomeGoalInCents) return 0
    return Math.round(
      (incomeYearToDate / financialProfile.annualIncomeGoalInCents) * 100
    )
  }, [incomeYearToDate, financialProfile])

  return (
    <Card type="subsection" backgroundColor="natural">
      {displayState === 'empty' && (
        <Grid>
          <GridRowColumn>
            <Pill color="neutral" variant="light" style={{ marginBottom: 12 }}>
              <Icon icon={solid('sparkle')} style={{ marginRight: 8 }} />
              NEW
            </Pill>
            <Text as="h2">Track your annual income goals</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Enter your target annual income for 2025 to track your progress
              here.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Input
              label="2025 Target Annual Income"
              placeholder="$50,000"
              value={annualIncome}
              onChange={(value) => setAnnualIncome(value)}
              componentType="currency"
            />
          </GridRowColumn>
          <GridRowColumn>
            <Button
              variant="secondary"
              onClick={() => {
                handleTrack()
                track('clicked track button', {
                  button: 'track income',
                })
              }}
              loading={updateLoading}
            >
              Track{' '}
              <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </GridRowColumn>
        </Grid>
      )}
      {displayState === 'progress' && (
        <Grid>
          <GridRowColumn>
            <Text as="h2">Annual Income Goal</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="display2" color="green">
              {incomeGoalProgress}%
            </Text>
            <Text as="bodyXs" color="darkGray">
              towards your 2025 target annual income
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodySm">
              {formatCurrencyFromCentsAndRound(incomeYearToDate, {
                hideDecimalsIfZero: true,
              })}{' '}
              /{' '}
              {formatCurrencyFromCentsAndRound(
                financialProfile?.annualIncomeGoalInCents,
                { hideDecimalsIfZero: true }
              )}{' '}
              <Button
                onClick={() => {
                  toggleEditing()
                  track('clicked edit button', {
                    button: 'edit income',
                  })
                }}
                variant="link"
                size="small"
              >
                (edit)
              </Button>
            </Text>
            <StyledProgress
              percent={incomeGoalProgress}
              size="small"
              color={Colors.green}
            />
            <Button
              variant="secondary"
              onClick={() => {
                navigate('/insights/income')
                track('clicked view income button', {
                  button: 'view income',
                })
              }}
            >
              View Income{' '}
              <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </GridRowColumn>
        </Grid>
      )}
      {displayState === 'editing' && (
        <Grid>
          <GridRowColumn>
            <Text as="h2">Annual Income Goal</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">2025 Target Annual Income</Text>
            <Text as="bodyXs" style={{ marginTop: 4, marginBottom: 4 }}>
              Enter a new annual income goal. We&apos;ll update your progress to
              reflect the new target.
            </Text>
            <Input
              placeholder="$50,000"
              value={annualIncome}
              onChange={(value) => setAnnualIncome(value)}
              componentType="currency"
            />
          </GridRowColumn>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={8}>
              <Button
                onClick={() => {
                  handleTrack()
                  track('clicked update goal button', {
                    button: 'update goal',
                  })
                }}
                loading={updateLoading}
                size="small"
              >
                Update Goal
              </Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                onClick={toggleEditing}
                loading={updateLoading}
                size="small"
                variant="link"
              >
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Card>
  )
}
