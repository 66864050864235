import { useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { Container, Dimmer, Divider, Header, Loader } from 'semantic-ui-react'

import { fetchAllRolesIfNeeded } from '../../../actions/admin/roleActions'
import {
  getTableData,
  listAllAdmins,
  listBookkeepersUsers,
  listUserRoles,
} from './actions'
import { Dispatch, ReduxState } from '../../../utils/typeHelpers'
import CRMTable from './CRMTable'
import { fetchCurrentUserDetailsIfNeeded } from '../../../actions/userActions'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import SummationCards from './SummationCards'
import { RoleName } from '../../../reducers/admin/allRolesReducer'
import {
  Dropdown,
  DropdownOption,
  Tab,
} from '../../../components/BaseComponents'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { BookkeepingReport } from '../../../reducers/finances/bookkeepingReportsReducer'
import { selectBookkeepingReports } from '../../../selectors/bookkeepingReportsSelectors'
import {
  getAllUsersById,
  getCurrentUser,
} from '../../../selectors/user.selectors'
import './style.scss'

export type DateColumn =
  | 'jan'
  | 'feb'
  | 'mar'
  | 'apr'
  | 'may'
  | 'jun'
  | 'jul'
  | 'aug'
  | 'sep'
  | 'oct'
  | 'nov'
  | 'dec'

export type SortAction =
  | {
      type: 'SET_DATA'
      user: UserWithAdminInfo[]
      books: {
        [key: string]: BookkeepingReport
      }
      year: string
    }
  | { type: 'CHANGE_SORT'; column: DateColumn }

const sortReducer = (
  state: {
    column: DateColumn | null
    data: ReturnType<typeof getTableData>
    direction: string | null
  },
  action: SortAction
) => {
  const statuses = [
    'done',
    'reconciled',
    'categorized',
    'plaid_error',
    'missing_info',
    'in_progress',
    'not_started',
  ]

  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: getTableData(action.user, action.year),
        direction: null,
      }
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending'
              ? ('descending' as const)
              : ('ascending' as const),
        }
      }

      return {
        column: action.column,
        data: [...state.data].sort((a, b) => {
          const aStatus = a?.reports?.[action.column]?.status || null
          const bStatus = b?.reports?.[action.column]?.status || null

          if (!bStatus) {
            return 1
          }

          if (!aStatus) {
            return -1
          }
          return statuses.indexOf(bStatus) - statuses.indexOf(aStatus)
        }),
        direction: 'ascending' as const,
      }
    default:
      throw new Error()
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const BookkeepingCRM = ({
  fetchCurrentUserDetailsIfNeeded,
  fetchAllRolesIfNeeded,
  fetchAllUsersIfNeeded,
  currentUser,
  books,
  allUsers,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(20)
  const [year, setYear] = useState(DateTime.now().year.toString())
  const [bookkeepersUsers, setBookkeepersUsers] = useState<UserWithAdminInfo[]>(
    []
  )
  const isUserBookkeeper = Boolean(
    currentUser?.roles?.find((role) => role.name === RoleName.Bookkeeper)
  )
  const [bookkeeper, setBookkeeper] = useState<string>(
    (isUserBookkeeper && currentUser?.id?.toString()) || 'All Books'
  )

  const [bookkeeperOptions, setBookkeeperOptions] = useState([
    { text: 'All Books', value: 'All Books' },
  ])
  const [searchedUserId, setSearchedUserId] = useState<number>()

  useEffect(() => {
    const fetchData = async () => {
      const [allRoles, userRoles, admins] = await Promise.all([
        fetchAllRolesIfNeeded(),
        listUserRoles(),
        listAllAdmins(),
        fetchCurrentUserDetailsIfNeeded(),
        fetchAllUsersIfNeeded(),
      ])

      const bookkeeperRole = Object.values(allRoles).find(
        (role) => role.name === RoleName.Bookkeeper
      )
      if (userRoles && bookkeeperRole) {
        const bookkeepers = userRoles.filter(
          (userRole) => userRole.roleId === bookkeeperRole.id
        )

        setBookkeeperOptions(
          bookkeepers.map((bkUser) => {
            const user = admins.find((admin) => admin.id === bkUser.userId)
            return {
              text: `${user?.firstName ?? ''}'s Books`,
              value: bkUser.userId.toString(),
            }
          })
        )
      }
      setLoading(false)
    }

    fetchData()
  }, [
    fetchCurrentUserDetailsIfNeeded,
    fetchAllUsersIfNeeded,
    fetchAllRolesIfNeeded,
  ])

  const [state, dispatch] = useReducer(sortReducer, {
    column: null,
    data: getTableData(bookkeepersUsers, year),
    direction: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      const users = await listBookkeepersUsers(page, bookkeeper, searchedUserId)
      if (!users) {
        return
      }
      setBookkeepersUsers(users.rows)
      setCount(users.count)
      dispatch({
        type: 'SET_DATA',
        user: users.rows,
        books,
        year,
      })
    }

    fetchData()
  }, [year, bookkeeper, page, books, searchedUserId])

  const { column, data, direction } = state

  const userOptions = () => {
    return Object.values(allUsers)
      .filter((user) => user.bookkeeperId)
      .map((user) => ({
        key: user.id,
        text: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }))
  }

  const yearOptions: DropdownOption[] = []
  for (let yr = DateTime.now().year; yr >= 2021; yr--) {
    const yearString = String(yr)
    yearOptions.push({
      text: yearString,
      value: yearString,
    })
  }

  return (
    <>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading all reports</Loader>
        </Dimmer>
      )}
      {!loading && (
        <Container id="bookkeepingCRM" fluid>
          <div className="flexbox">
            <Header as="h3">Bookkeeping CRM</Header>
            <Dropdown
              placeholder="select a bookkeeper"
              options={bookkeeperOptions}
              value={bookkeeper}
              onChange={(value) => {
                setPage(1)
                setBookkeeper(value)
              }}
            />
          </div>
          <Divider />
          <div className="flexbox">
            <p>{count} users</p>
            <Dropdown
              placeholder="select year"
              options={yearOptions}
              value={year}
              onChange={setYear}
            />
          </div>
          <Divider />
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: 'CRM Table',
                render: () => (
                  <div>
                    <Dropdown
                      search
                      clearable
                      placeholder="select a user"
                      options={userOptions()}
                      value={searchedUserId}
                      onChange={setSearchedUserId}
                      style={{ marginBottom: 10 }}
                    />
                    <CRMTable
                      data={data}
                      column={column}
                      direction={direction}
                      dispatch={dispatch}
                      page={page}
                      setPage={setPage}
                      count={count}
                      year={year}
                    />
                  </div>
                ),
              },
              {
                menuItem: 'Statistics',
                render: () => (
                  <SummationCards year={year} bookkeeperId={bookkeeper} />
                ),
              },
            ]}
          />
        </Container>
      )}
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: getCurrentUser(state),
  books: selectBookkeepingReports(state),
  allUsers: getAllUsersById(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentUserDetailsIfNeeded: () =>
    dispatch(fetchCurrentUserDetailsIfNeeded()),
  fetchAllUsersIfNeeded: () => dispatch(fetchAllUsersIfNeeded()),
  fetchAllRolesIfNeeded: () => dispatch(fetchAllRolesIfNeeded()),
})

export default connect<
  StateProps,
  DispatchProps,
  Record<string, unknown>,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(BookkeepingCRM)
