import { Alert, Link } from '../../components/BaseComponents'
import { useTSKReminderBanner } from '../Taxes/AnnualTaxes/TaxSeasonKickoff/TSKReminderBanner'
import { useReselector } from '../../utils/sharedHooks'
import { selectDeadlineBannerCopy } from '../Taxes/AnnualTaxes/annualTaxFilings.selector'

const HeardCommunityBanner = () => {
  const isShowingTskBanner = useTSKReminderBanner()
  const deadlineBannerCopy = useReselector(selectDeadlineBannerCopy)

  if (isShowingTskBanner || deadlineBannerCopy) {
    return null
  }

  return (
    <Alert type="announcement">
      Join the new{' '}
      <Link href="https://support.joinheard.com/hc/en-us/community/topics">
        Heard Community
      </Link>{' '}
      — connect with other practice owners to share tips, insights, and get
      support.
    </Alert>
  )
}

export default HeardCommunityBanner
