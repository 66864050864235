import { FormikProvider, useFormik } from 'formik'
import { Image, Grid, Divider, Loader } from 'semantic-ui-react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import {
  Alert,
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  makeReqPhoneNumberSchema,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { updateFinancialProfile } from '../../../../actions/financialProfileActions'
import { updateUser } from '../../../../actions/userActions'
import {
  getCurrentUser,
  getFinancialProfile,
  selectParsedAddress,
} from '../../../../selectors/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { STATES } from '../../../../constants/locationConstants'
import { getCurrentAboundPayer } from '../aboundAnnualTaxFilings.selector'
import {
  ABOUND_PAYER_CREATE_KEY,
  ABOUND_PAYER_UPDATE_KEY,
  createAboundPayerRequest,
  fetchAboundPayer,
  FETCH_ABOUND_PAYER_KEY,
  updateAboundPayerRequest,
} from '../aboundAnnualTaxFilings.slice'
import { useEffect, useState } from 'react'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import File1099sPage from './File1099sPage'
import Ten99FilingErrors from './Ten99FilingErrors'
import { identificationNumberProps } from '../../../../components/BaseComponents/Input'
import { useAnalyticsView } from '../../../Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import { TIN_VERIFICATION_STATUS } from './constants'

const validationSchema = yup.object({
  businessName: makeReqStringSchema({ field: 'business name' }),
  businessAddress: makeReqStringSchema({ field: 'business address' }),
  businessCity: makeReqStringSchema({ field: 'business city' }),
  businessState: makeReqStringSchema({ field: 'business state' }),
  businessZipCode: makeNumberSchema({
    field: 'business zipcode',
    numDigits: 5,
    allowLeadingZero: true,
  }),
  businessPhoneNumber: makeReqPhoneNumberSchema(),
  taxIdNumber: makeNumberSchema({
    field: 'Tax Id Number',
    allowLeadingZero: true,
    numDigits: 9,
  }),
})

const VerifyPayerInfoForm = ({
  goToNextStep,
}: {
  goToNextStep: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ssnType, setSsnType] = useState('password')
  const financialProfile = useReselector(getFinancialProfile)
  const user = useReselector(getCurrentUser)
  const payerProfile = useReselector(getCurrentAboundPayer)
  const [processingPayer, setProcessingPayer] = useState(false)
  const { singleLineAddressString, parsedAddress } =
    useReselector(selectParsedAddress)

  const formik = useFormik({
    initialValues: {
      businessName: financialProfile?.businessName || '',
      businessAddress: singleLineAddressString,
      businessCity: parsedAddress.placeName || '',
      businessState: parsedAddress.stateAbbreviation || '',
      businessZipCode: parsedAddress.zipCode || '',
      taxIdNumber: financialProfile?.einNumber?.replace(/\D/g, '') || '',
      businessPhoneNumber: user?.phoneNumber || '',
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      businessAddress,
      businessCity,
      businessState,
      businessZipCode,
      businessName,
      taxIdNumber,
      businessPhoneNumber,
    }) => {
      const requests = []
      const payerProfileData = {
        name: businessName,
        address: businessAddress,
        city: businessCity,
        state: businessState,
        zipcode: businessZipCode,
        country: 'US',
        phoneNumber: businessPhoneNumber.split('+1')[1],
        taxIdNumber: taxIdNumber.replace(/\D/g, ''),
      }
      setProcessingPayer(true)
      if (financialProfile?.id) {
        const addressString = `${businessAddress}, ${businessCity}, ${businessState} ${businessZipCode}`
        requests.push(
          updateFinancialProfile(financialProfile.id, {
            businessAddress: addressString,
            businessName,
            einNumber: taxIdNumber,
          })(dispatch)
        )
      }

      if (user?.id) {
        requests.push(
          updateUser(user.id, {
            phoneNumber: businessPhoneNumber,
          })(dispatch)
        )
      }
      let createdOrUpdatedAboundPayer = null
      if (payerProfile?.id) {
        createdOrUpdatedAboundPayer = await updateAboundPayerRequest(
          payerProfile.id,
          payerProfileData
        )(dispatch)
      } else {
        createdOrUpdatedAboundPayer =
          await createAboundPayerRequest(payerProfileData)(dispatch)
      }
      if (createdOrUpdatedAboundPayer) {
        await Promise.all(requests)
        goToNextStep()
      }
      setProcessingPayer(false)
    },
  })

  const { submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <>
      <GridRowColumn width={10}>
        <Ten99FilingErrors
          fetchKeys={[ABOUND_PAYER_CREATE_KEY, ABOUND_PAYER_UPDATE_KEY]}
        />
        <Card type="subsection" backgroundColor="stone40">
          <FormikProvider value={formik}>
            <Grid>
              <GridRowColumn width={16}>
                <FormikInput
                  label="Business Name"
                  name={fieldNames.businessName}
                  fullWidth
                  required
                />
              </GridRowColumn>
              <GridRowColumn width={16}>
                <FormikInput
                  label="Business Address"
                  fullWidth
                  name={fieldNames.businessAddress}
                  required
                />
              </GridRowColumn>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikInput
                    label="City"
                    fullWidth
                    name={fieldNames.businessCity}
                    required
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <FormikDropdown
                    label="State"
                    name={fieldNames.businessState}
                    options={STATES}
                    fullWidth
                    search
                    required
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <FormikInput
                    label="Zipcode"
                    componentType="number"
                    name={fieldNames.businessZipCode}
                    fullWidth
                    required
                    {...identificationNumberProps}
                  />
                </Grid.Column>
              </Grid.Row>
              <GridRowColumn width={6}>
                <FormikInput
                  label="Business Phone Number"
                  name={fieldNames.businessPhoneNumber}
                  fullWidth
                  required
                  componentType="phone"
                />
              </GridRowColumn>
              <GridRowColumn width={16}>
                <FormikInput
                  label="Tax ID Number (TIN)"
                  name={fieldNames.taxIdNumber}
                  fullWidth
                  required
                  description="This is a nine digit number.
                    Please enter the number without any hyphens."
                  type={ssnType}
                  placeholder="xxxxxxxxx"
                  onRightIconClick={() =>
                    setSsnType((type) =>
                      type === 'password' ? 'text' : 'password'
                    )
                  }
                  rightIcon={ssnType === 'password' ? 'eye' : 'eye slash'}
                  {...identificationNumberProps}
                />
                <Text as="bodySm">
                  If the IRS has assigned your business an Employer
                  Identification Number (EIN), then it is very important that
                  you use your EIN, complete business name, and address as in
                  the EIN letter provided by the IRS. Any variation may cause a
                  mismatch with the IRS and a delay in processing. If the IRS
                  has not assigned you an EIN, you should enter your SSN.
                </Text>
              </GridRowColumn>
            </Grid>
          </FormikProvider>
        </Card>
      </GridRowColumn>
      <Divider />

      <Grid.Row>
        <Grid.Column width={3}>
          <Button
            variant="secondary"
            fullWidth
            onClick={() => {
              navigate('/taxes/annual')
              location.reload()
            }}
          >
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={10} />
        <Grid.Column width={3}>
          <Button disabled={!formik.isValid} fullWidth onClick={submitForm}>
            {processingPayer ? <Loader active inline="centered" /> : 'Continue'}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}
const VerifyPayerInfoPanel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fetchingPayer = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_PAYER_KEY
  )
  const payerProfile = useReselector(getCurrentAboundPayer)
  const payerTinMismatch =
    payerProfile?.tinVerificationStatus === TIN_VERIFICATION_STATUS.mismatch

  useEffect(() => {
    dispatch(fetchAboundPayer())
  }, [dispatch])

  const pageView = useAnalyticsView()
  const screenName = '1099-NEC payer info panel'
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  const contentHeader = <Text as="h3">File 1099-NECs</Text>
  const content = (
    <Grid centered>
      <GridRowColumn width={8}>
        <Image
          src="https://heard-images.s3.us-west-1.amazonaws.com/assets/looking_at_tablet.svg"
          centered
          style={{ height: 100, marginBottom: 32 }}
        />
        <Text textAlign="center" as="h1">
          Update your business information
        </Text>
        <Text as="bodyMd" textAlign="center">
          First, let&apos;s make sure that your business information is up to
          date prior to submitting your 1099-NEC Filings. Please review below.
        </Text>
      </GridRowColumn>
      {payerTinMismatch && (
        <GridRowColumn width={10}>
          <Alert type="error">
            We are unable to verify your Tax Identification Number (TIN). If the
            IRS has assigned your business an Employer Identification Number
            (EIN), then it is very important that you use your EIN, complete
            business name, and address as in the EIN letter provided by the IRS.
            Any variation may cause a mismatch with the IRS and a delay in
            processing. If the IRS has not assigned you an EIN, you should enter
            your SSN. We will need this number in order to file your
            1099-NEC(s).
          </Alert>
        </GridRowColumn>
      )}

      <VerifyPayerInfoForm
        goToNextStep={() => navigate('/taxes/annual/file_1099_nec/contractors')}
      />
    </Grid>
  )

  const enable1099nec = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable1099nec,
    false
  )
  if (!enable1099nec) {
    navigate('/taxes/annual')
  }

  return (
    <File1099sPage
      currentStep={0}
      isLoading={fetchingPayer}
      contentHeader={contentHeader}
      content={content}
    />
  )
}

export default VerifyPayerInfoPanel
