import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Grid, Divider, Embed } from 'semantic-ui-react'

import PayrollSetupList from './PayrollSetupList'
import {
  FETCH_COMPANY_ONBOARDING_STATUS_KEY,
  fetchCompanyOnboardingStatus,
  ONBOARDING_FLOWS,
  POST_ACCEPT_COMPANY_TOS_KEY,
  postAcceptCompanyTOS,
  postCreateGustoFlow,
  PUT_FINISH_ONBOARDING_KEY,
  putFinishOnboarding,
} from '../payrollActions'
import {
  GEP_ENROLL_PATHS,
  ONBOARDING_STEPS,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import {
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching, selectIsFetchingForKeys } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../Amplitude'

const PayrollEnrollGustoFlow = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.gustoFlow)

  const isFetchingStatus = useReselector(
    getIsFetching,
    FETCH_COMPANY_ONBOARDING_STATUS_KEY
  )
  const isPosting = useReselector(selectIsFetchingForKeys, [
    POST_ACCEPT_COMPANY_TOS_KEY,
    PUT_FINISH_ONBOARDING_KEY,
  ])

  const [link, setLink] = useState<string>()
  const [isComplete, setIsComplete] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const checkCompletion = useCallback(async () => {
    const res = await fetchCompanyOnboardingStatus()(dispatch)

    const isCompleteRes =
      Boolean(res) && !res?.onboarding_steps.some((step) => !step.completed)

    setIsComplete(isCompleteRes)
    return isCompleteRes
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      const complete = await checkCompletion()
      if (complete) {
        return
      }

      const res = await postCreateGustoFlow(ONBOARDING_FLOWS)(dispatch)

      if (res) {
        setLink(res.url)
      }
    }

    fetch()
  }, [checkCompletion, dispatch])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    checkCompletion()
  }, [checkCompletion])

  const completeSignup = useCallback(async () => {
    const finishOnboardingRes = await putFinishOnboarding()(dispatch)

    // The user accepted TOS by continuing on /payroll/enroll/overview but the company isn't created at that time.
    // Make it here instead
    const acceptTermsRes = await postAcceptCompanyTOS()(dispatch)

    if (finishOnboardingRes && acceptTermsRes) {
      track(
        'completed payroll step - gusto iframe onboarding flows and tos acceptance'
      )

      navigate(GEP_ENROLL_PATHS.complete)
    }
  }, [dispatch, navigate, track])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              <Grid>
                {isComplete ? (
                  <GridRowColumn>
                    <Text as="h2">
                      Select <b>Complete</b> to finish your onboarding
                    </Text>
                  </GridRowColumn>
                ) : (
                  <>
                    <GridRowColumn>
                      <Text as="h2">Finish up on Gusto</Text>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Text>
                        You&apos;re almost done! We have a few more steps that
                        need your attention.
                      </Text>
                      <ul>
                        <li>
                          <Text>Add bank account</Text>
                        </li>
                        <li>
                          <Text>State tax information</Text>
                        </li>
                        <li>
                          <Text>Sign Documents</Text>
                        </li>
                      </ul>
                    </GridRowColumn>
                    <GridRowColumn short>
                      <Text>
                        You will be redirected to Gusto in order to complete the
                        final steps of the payroll process.
                      </Text>
                    </GridRowColumn>
                  </>
                )}
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Button
                      fullWidth
                      onClick={() => navigate(GEP_ENROLL_PATHS.schedule)}
                      variant="secondary"
                    >
                      Back
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={4} />
                  <Grid.Column width={6}>
                    {link && !isComplete && (
                      <Button
                        onClick={() => setIsOpen(true)}
                        fullWidth
                        loading={isFetchingStatus}
                      >
                        Finish up on Gusto
                      </Button>
                    )}
                    {isComplete && (
                      <Button
                        onClick={completeSignup}
                        fullWidth
                        loading={isPosting}
                      >
                        Complete
                      </Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
          <Modal open={isOpen} size="fullscreen" closeIcon onClose={closeModal}>
            <Modal.Content>
              <Embed active url={link} iframe={{ scrolling: true }} />
            </Modal.Content>
          </Modal>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollGustoFlow
