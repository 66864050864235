import { Grid } from 'semantic-ui-react'

import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../../utils/deviceWidthHelpers'
import { formatCurrency } from '../../../../../utils/currencyHelpers'
import { useCatchupBookkeepingPrice } from '../../hooks'
import {
  useFetchResponse,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectProductDescription } from '../../../../../selectors/user.selectors'
import {
  Card,
  GridRowColumn,
  Label,
  Loader,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import DetailsAccordion from './DetailsAccordion'
import { DateTime } from 'luxon'
import currency from 'currency.js'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { selectApproximatePracticeStartDate } from '../../../../../selectors/catchupBookkeeping.selectors'
import { useEffect, useMemo, useState } from 'react'
import {
  FETCH_CATCH_UP_BK_COUPON,
  fetchCatchUpBKCoupon,
  fetchCatchUpBKMonths,
} from '../../../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'

const CatchupBookkeepingSection = ({
  selectedAnnualPlan,
  isBasicPlanType,
}: {
  selectedAnnualPlan: boolean
  isBasicPlanType: boolean | null | undefined
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const productDescription = useReselector(selectProductDescription, {
    sections: ['catch_up_bookkeeping'],
  })
  const catchUpBKCoupon = useFetchResponse(
    fetchCatchUpBKCoupon,
    selectedAnnualPlan,
    Boolean(isBasicPlanType)
  )
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )
  const approximatePracticeStartDate = useReselector(
    selectApproximatePracticeStartDate
  )
  const isLoadingCoupon = useReselector(selectIsFetchingForKeys, [
    FETCH_CATCH_UP_BK_COUPON(selectedAnnualPlan, Boolean(isBasicPlanType)),
  ])

  const currentYear = DateTime.now().year
  const lastYear = currentYear - 1
  const includePriorYearTaxes =
    Boolean(taxFiling?.isLateJoiner) && Number(currentTaxYear) < currentYear

  const dispatch = useAppDispatch()
  const [monthsSoFar, setMonthsSoFar] = useState<number>(0)
  const [monthsLastYear, setMonthsLastYear] = useState<number>(
    includePriorYearTaxes ? 12 : 0
  )

  useEffect(() => {
    const catchUpBkMonths = async () => {
      const res = await fetchCatchUpBKMonths()(dispatch)
      if (res) {
        setMonthsSoFar(res.monthsThisYear)
        setMonthsLastYear(res.monthsLastYear)
      }
    }
    catchUpBkMonths()
  }, [dispatch])

  const additionalTooltipTextNoTaxes = useMemo(() => {
    if (approximatePracticeStartDate?.year === currentYear) {
      return `Since you started your practice in ${approximatePracticeStartDate.toFormat('MMMM')} of this year, we need to perform Catch Up Bookkeeping for ${catchupBookkeepingPrice?.monthCount} ${catchupBookkeepingPrice?.monthCount === 1 ? 'month' : 'months'}.`
    }
    return `Since you started your practice ${approximatePracticeStartDate?.year ? `in ${approximatePracticeStartDate.year}` : 'before this year'}, we need to perform Catch Up Bookkeeping for all of ${currentYear}.`
  }, [
    approximatePracticeStartDate,
    currentYear,
    catchupBookkeepingPrice?.monthCount,
  ])

  const additionalTooltipTextWithTaxes = useMemo(() => {
    if (approximatePracticeStartDate?.year === Number(currentTaxYear)) {
      return `Since you started your practice in ${approximatePracticeStartDate.year}, and are also having us help you with your taxes for ${currentTaxYear}, we need to perform Catch Up Bookkeeping for all of ${currentYear}, and the months in ${currentTaxYear} starting with ${approximatePracticeStartDate.toFormat('MMMM')}.`
    }
    return `Since you started your practice ${approximatePracticeStartDate?.year ? `in ${approximatePracticeStartDate.year}` : `before ${currentTaxYear}`}, and are also having us help you with your taxes for ${currentTaxYear}, we need to perform Catch Up Bookkeeping for all of ${currentTaxYear} and all of ${currentYear}.`
  }, [approximatePracticeStartDate, currentYear, currentTaxYear])

  const formattedAmountWithDiscount = useMemo(() => {
    if (!catchupBookkeepingPrice) {
      return null
    }
    return formatCurrency(
      currency(catchupBookkeepingPrice.amount).subtract(
        currency(catchupBookkeepingPrice.amount).multiply(
          (catchUpBKCoupon?.percent_off ?? 0) / 100
        )
      ),
      {
        hideDecimalsIfZero: true,
      }
    )
  }, [catchupBookkeepingPrice, catchUpBKCoupon])

  if (
    !catchupBookkeepingPrice ||
    (!includePriorYearTaxes && monthsSoFar === 0)
  ) {
    return null
  }

  const pricePerMonth = currency(catchupBookkeepingPrice.costPerMonth)
  const costForThisYear = pricePerMonth.multiply(monthsSoFar)

  return (
    <GridRowColumn>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Text as={isMobile ? 'h2' : 'h1'}>Catch Up Bookkeeping</Text>
          </Grid.Column>
          <Grid.Column textAlign="right" width={7}>
            <Loader loading={isLoadingCoupon} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: 8,
              }}
            >
              {catchUpBKCoupon?.percent_off && (
                <Label color="red">
                  {catchUpBKCoupon.percent_off === 100
                    ? 'FREE'
                    : `Save ${catchUpBKCoupon.percent_off}%`}
                </Label>
              )}
              <Text as={isMobile ? 'h2' : 'h1'} style={{ display: 'inline' }}>
                {formattedAmountWithDiscount}
              </Text>
            </div>
            {catchUpBKCoupon?.percent_off && (
              <Text style={{ textDecoration: 'line-through' }} color="darkGray">
                {formatCurrency(catchupBookkeepingPrice.amount, {
                  hideDecimalsIfZero: true,
                })}
              </Text>
            )}
            <Text color="darkGray" as="bodySm">
              One time fee
            </Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn short>
          <Card type="subsection" backgroundColor="natural">
            {monthsSoFar !== 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  {monthsSoFar} months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of catch up bookkeeping for {currentYear} taxes
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text>
                            In order to ensure that we have the most accurate
                            picture of your finances, a Heard bookkeeper will
                            review, categorize and validate all your previous
                            transactions for accuracy.
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn>
                          <Text>{additionalTooltipTextNoTaxes}</Text>
                        </GridRowColumn>
                      </Grid>
                    }
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(costForThisYear, {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
            {includePriorYearTaxes && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text as="bodyLg" color="forest">
                  {monthsLastYear} months at{' '}
                  {formatCurrency(pricePerMonth, {
                    hideDecimalsIfZero: true,
                  })}
                  /mo of catch up bookkeeping for {lastYear} taxes
                  <Popup
                    content={
                      <Grid>
                        <GridRowColumn>
                          <Text>
                            The first step in preparing your {lastYear} taxes is
                            a review of your books. A Heard bookkeeper will
                            review, categorize and validate all your {lastYear}{' '}
                            transactions for accuracy. This ensures that Heard
                            finds all of the deductions that you qualify for.
                          </Text>
                        </GridRowColumn>
                        <GridRowColumn>
                          <Text>{additionalTooltipTextWithTaxes}</Text>
                        </GridRowColumn>
                      </Grid>
                    }
                  />
                </Text>
                <Text as="bodyLg" color="forest">
                  {formatCurrency(pricePerMonth.multiply(monthsLastYear), {
                    hideDecimalsIfZero: true,
                  })}
                </Text>
              </div>
            )}
            {catchUpBKCoupon?.percent_off && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>{catchUpBKCoupon.percent_off}% special discount</Text>
                <Text color="red">
                  (
                  {formatCurrency(
                    currency(catchupBookkeepingPrice.amount)
                      .multiply((catchUpBKCoupon?.percent_off ?? 0) / 100)
                      .multiply(-1),
                    { hideDecimalsIfZero: true }
                  )}
                  )
                </Text>
              </div>
            )}
          </Card>
        </GridRowColumn>
        <GridRowColumn className="short">
          <DetailsAccordion
            productDescription={productDescription}
            hideTitle
            forOneTimePayment
          />
        </GridRowColumn>
      </Grid>
    </GridRowColumn>
  )
}
export default CatchupBookkeepingSection
