import {
  PRICE_IDS_WITH_FREE_PERSONAL_FILING,
  StripeSubscription,
} from './subscription.slice'

export const getSubscriptionIncludesFreePersonalFiling = (
  subscriptionList?: StripeSubscription[]
) =>
  Boolean(
    subscriptionList?.some(
      (sub) =>
        sub.plan?.id &&
        PRICE_IDS_WITH_FREE_PERSONAL_FILING.includes(sub.plan.id)
    )
  )
