export const FORM_1040_TYPE = 'form_1040'
export const FORM_1120_S_TYPE = 'form_1120_s'

export enum TaxFormType {
  form1040 = 'form1040',
  form1120s = 'form1120s',
}

export const ANNUAL_TAX_FILING_FORM_TYPES = {
  form_1040: 'form_1040',
  form_1120: 'form_1120',
  form_1120_s: 'form_1120_s',
  form_1065: 'form_1065',
  schedule_c: 'schedule_c',
}
