import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  BorderedIcon,
  GridRowColumn,
  Link,
  Loader,
  Text,
} from '../../BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { isEmpty } from 'lodash'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import InsightsTrendGraph from './InsightsTrendGraph'
import {
  INSIGHTS_TYPE,
  getInsightsDateRange,
  processDataForChart,
} from '../shared/utils'
import { fetchInsightsBarchartData } from '../shared/financeActions'
import { ProfitLossInsights } from './ProfitLossInsights'
import {
  InsightsDatePicker,
  useInsightsDatePicker,
} from '../shared/InsightsDatePicker'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { InsightOverviewCards } from './InsightOverviewCard'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  useAnalyticsTrack,
  useAnalyticsView,
} from '../../../features/Amplitude'

const PracticeOverview = () => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const [trendData, setTrendData] = useState<
    { [key: string]: string | number | undefined }[]
  >([])
  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const [searchParams] = useSearchParams()

  const { monthParam, yearParam } = useMemo(() => {
    return {
      monthParam: searchParams.get('month'),
      yearParam: searchParams.get('year'),
    }
  }, [searchParams])

  const { yearFilter, monthFilter, inputDate, setYearFilter, setMonthFilter } =
    useInsightsDatePicker(monthParam, yearParam)

  const currentDate = useMemo(() => {
    return inputDate
      ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
      : DateTime.now()
  }, [inputDate])

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)
  const dateRange = useMemo(
    () => getInsightsDateRange(getEndDate()),
    [getEndDate]
  )

  useEffect(() => {
    pageView('practice insights')
  }, [pageView])

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const fetchTrendData = async () => {
      const [startDate, endDate] = dateRange
      const fetchedData = await fetchInsightsBarchartData(
        INSIGHTS_TYPE.PROFIT,
        startDate,
        endDate
      )(dispatch)

      if (fetchedData) {
        const chartData = processDataForChart(
          fetchedData,
          dateRange,
          INSIGHTS_TYPE.PROFIT
        )
        setTrendData(chartData)
      }
    }
    fetchTrendData()
  }, [dispatch, dateRange])

  const monthDateRange = useMemo(() => {
    if (yearFilter && monthFilter) {
      const month = parseInt(monthFilter) + 1
      const end = DateTime.fromObject(
        { year: yearFilter, month },
        { zone: 'utc' }
      ).endOf('month')
      const start = end.startOf('month')
      return [
        start.toFormat(DATE_FORMATS_LUXON.YEAR_MONTH_DAY),
        end.toFormat(DATE_FORMATS_LUXON.YEAR_MONTH_DAY),
      ]
    }
    return ['', '']
  }, [yearFilter, monthFilter])

  const dateRangeString = useMemo(() => {
    if (!dateRange) return ''
    const [startDate, endDate] = dateRange
    const start = DateTime.fromFormat(
      startDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    const end = DateTime.fromFormat(
      endDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    return `Last 12 months (${start} - ${end})`
  }, [dateRange])

  const handleMonthFilterChangeWithTrack = useCallback(
    (month: string) => {
      track('clicked month filter', {
        month,
        year: yearFilter ?? null,
        screen: 'practice insights',
      })
      setMonthFilter(month)
    },
    [track, yearFilter, setMonthFilter]
  )

  const handleYearFilterChangeWithTrack = useCallback(
    (year: number) => {
      track('clicked year filter', {
        year,
        month: monthFilter ?? null,
        screen: 'practice insights',
      })
      setYearFilter(year)
    },
    [track, monthFilter, setYearFilter]
  )

  return (
    <div style={{ marginLeft: -40 }}>
      <Grid style={{ marginLeft: '40px', marginRight: '20px' }}>
        <GridRowColumn
          columnStyle={{
            display: 'flex',
            flexDirection: isMobileOrTablet ? 'column' : 'row',
            justifyContent: 'space-between',
            paddingRight: 24,
            gap: 12,
          }}
        >
          <Text as="h1">Practice Insights</Text>
          <InsightsDatePicker
            monthFilter={monthFilter}
            yearFilter={yearFilter}
            handleMonthFilterChange={handleMonthFilterChangeWithTrack}
            handleYearFilterChange={handleYearFilterChangeWithTrack}
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </GridRowColumn>
        {!hasLinkedPlaidAccounts && (
          <GridRowColumn>
            <Alert>
              <Text>
                You do not have a connected bank account.{' '}
                <Link to={'/accounts#connected-institutions'}>
                  Link Business Account
                </Link>
              </Text>
            </Alert>
          </GridRowColumn>
        )}
        <UncategorizedTransactionBanner
          inputDate={inputDate}
          practiceInsightsPage
        />
        <GridRowColumn>
          <div
            style={{
              display: 'flex',
              marginTop: 24,
              marginBottom: 24,
              alignItems: 'center',
              gap: 12,
            }}
          >
            <BorderedIcon
              icon={regular('chart-line')}
              color="white"
              height={32}
              wrapperColor="black"
            />
            <div>
              <Text as="h2" style={{ marginBottom: 8 }}>
                Practice Overview
              </Text>
              <Text as="bodySm" style={{ color: '#6F6F6B' }}>
                Birds eye view of your practice finances
              </Text>
            </div>
          </div>
        </GridRowColumn>
        <GridRowColumn style={{ paddingBottom: 64 }}>
          {yearFilter !== undefined && monthFilter !== undefined ? (
            <InsightOverviewCards
              year={yearFilter}
              month={parseInt(monthFilter) + 1}
            />
          ) : (
            <Loader loading />
          )}
        </GridRowColumn>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={10}>
            <Text as="h3">Practice Finances Over Time</Text>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <Text as="bodySm"> {dateRangeString} </Text>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn style={{ paddingBottom: 64 }}>
          <InsightsTrendGraph data={trendData} />
        </GridRowColumn>
        <GridRowColumn>
          {monthDateRange[0] && monthDateRange[1] ? (
            <ProfitLossInsights
              startDate={monthDateRange[0]}
              endDate={monthDateRange[1]}
            />
          ) : (
            <Loader loading />
          )}
        </GridRowColumn>
      </Grid>
    </div>
  )
}

export default PracticeOverview
