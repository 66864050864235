import { useStringFlagValue } from '@openfeature/react-sdk'
import { sendUpriseCallZap } from '../../actions/zapierActions'
import { User } from '../../reducers/auth/userReducer'
import { getStore } from '../../store'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { DateTime } from 'luxon'
import {
  createOrUpdateFinancialAdvisoryProfile,
  FinancialAdvisoryFeatureFlagValues,
  FinancialAdvisoryPilotStatus,
  FinancialAdvisoryProfilesStatus,
  FinancialAdvisoryProfile,
} from './financialAdvisory.slice'
import { UserFinancialAdvisoryCallEvent } from './userFinancialAdvisoryCallEvents.slice'
/**
 *
 * Intended to house logic related to advisory logics
 *
 */
export const createIntroCallScheduledAfterEffects = async ({
  currentUser,
}: {
  currentUser: User | null
  eventUri?: string
}) => {
  const store = getStore()
  if (!store) {
    return false
  }
  // Create Advisory Profile + Update to Event Scheduled
  const faProfile = await createOrUpdateFinancialAdvisoryProfile({
    userId: currentUser?.id,
    status: FinancialAdvisoryProfilesStatus.intro_call_scheduled,
  })(store.dispatch)
  // Add to Slack Channel Notif
  await sendUpriseCallZap(faProfile, currentUser)
  return true
}

/**
 * Constants
 */
export const UPRISE_INBOX_EVENT_CODE = 'launch_inbox'
export const UPRISE_SESSION_EXPIRED_EVENT_CODE = 'session_expired'

/**
 * Advisory Status Helpers
 */

export const userHasFinancialAdvisoryAccess = () => {
  const store = getStore()
  if (!store) {
    return false
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ffValue = useStringFlagValue(
    FEATURE_FLAG_KEYS.financialAdvisoryPilot,
    FinancialAdvisoryFeatureFlagValues.noPilot
  )
  // Check advisory profile exists
  const financialAdvisoryProfile = store.getState().financialAdvisoryProfile
  return (
    ffValue !== FinancialAdvisoryFeatureFlagValues.noPilot &&
    financialAdvisoryProfile
  )
}

/**
 * Discount Calculation Helpers
 */

export const ADVISORY_DISCOUNT_COUPON_IDS = {
  staging: {
    annual: '79GR9c3s',
    monthly: 'tCfo5Kqs',
  },
  production: {
    annual: 'rvfqJmPT',
    monthly: 'L5JA9W01',
  },
}

export const getAdvisoryCouponId = (isAnnualPlan: boolean) => {
  const isProd = process.env.VITE_IS_PROD === 'true'
  return isProd
    ? ADVISORY_DISCOUNT_COUPON_IDS.production[
        isAnnualPlan ? 'annual' : 'monthly'
      ]
    : ADVISORY_DISCOUNT_COUPON_IDS.staging[isAnnualPlan ? 'annual' : 'monthly']
}

export const hasAdvisoryDiscount = (
  faProfile: FinancialAdvisoryProfile,
  introCallEvent: UserFinancialAdvisoryCallEvent
) => {
  if (!introCallEvent) {
    return false
  }
  const introCallDate = DateTime.fromISO(introCallEvent?.startTime)
  const now = DateTime.now()
  const diffDays = now.diff(introCallDate, 'days').days
  return (
    faProfile.pilotStatus ===
      FinancialAdvisoryPilotStatus.open_launch_group_three && diffDays <= 7
  )
}
/**
 * Returns the coupon ID for the user if they get 3 months free
 */
export const userGets3MonthsFreeAdvisoryCouponId = (
  faProfile: FinancialAdvisoryProfile,
  introCallEvent: UserFinancialAdvisoryCallEvent,
  isAnnualPlan: boolean
) => {
  if (!introCallEvent) {
    return false
  }
  const userGetsDiscount = hasAdvisoryDiscount(faProfile, introCallEvent)
  if (userGetsDiscount) {
    return getAdvisoryCouponId(isAnnualPlan)
  }
  return null
}
