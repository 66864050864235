import { Grid, Image, List } from 'semantic-ui-react'
import {
  Alert,
  BorderedIcon,
  Button,
  Card,
  Checkbox,
  CircularWrapper,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useNavigate } from 'react-router-dom'
import {
  selectAnnualTaxFilingForCurrentTaxYear,
  selectExtensionRequestSurveyLink,
  selectExtensionRequestType,
  selectTaxOptInEligibility,
} from '../annualTaxFilings.selector'
import {
  ExtensionReason,
  ExtensionRequestType,
  fetchAnnualTaxFilingsIfNeeded,
  REQUEST_EXTENSION,
  requestExtension,
} from '../annualTaxFilings.slice'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { selectFirstErrorMessageForKeys } from '../../../../reducers/fetch'
import {
  fetchUserYearEndModuleStatuses,
  YearEndModuleType,
} from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { getModuleNameCopy } from '../../../YearEndModuleStatus/yearEndModuleStatus.helpers'
import { DateTime } from 'luxon'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'

const StarredText = ({ children }: { children: ReactNode }) => {
  return (
    <Grid.Row>
      <Grid.Column width={1} style={{ margin: 'auto' }}>
        <BorderedIcon
          icon={solid('star')}
          size="xs"
          height={32}
          wrapperColor="black"
          color="white"
        />
      </Grid.Column>
      <Grid.Column width={15}>{children}</Grid.Column>
    </Grid.Row>
  )
}

const getForcedExtensionCopy = (moduleType: YearEndModuleType) => {
  return `Since the deadline to ${getModuleNameCopy(moduleType)} has passed, we are proactively filing an extension so you, your bookkeeper, and your tax preparer have enough time to get your taxes done right.`
}

const WhatIsAnExtensionCard = () => {
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const lateJoinerDate = convertUtcToLocalDate(
    taxDetails?.new_user_cutoff_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)

  const forcedExtensionContext = useMemo(() => {
    const reason = filing?.extensionReason
    if (!reason) {
      return null
    }
    switch (reason) {
      case ExtensionReason.lateJoiner:
        return `Since you joined Heard after ${lateJoinerDate}, we need to file an extension so you, your bookkeeper, and your tax preparer have enough time to get your taxes done right.`
      case ExtensionReason.tskOverdue:
        return 'Since the deadline to complete the Tax Season Kickoff has passed, we are proactively filing an extension so you, your bookkeeper, and your tax preparer have enough time to get your taxes done right.'
      case ExtensionReason.taxEntityUnconfirmed:
        return getForcedExtensionCopy(YearEndModuleType.verifyTaxEntity)
      case ExtensionReason.bookkeepingTasksIncomplete:
        return getForcedExtensionCopy(YearEndModuleType.eoyBookkeeping)
      case ExtensionReason.booksNotLocked:
        return getForcedExtensionCopy(YearEndModuleType.eoyBookkeepingFollowup)
      case ExtensionReason.taxQuestionnaireOverdue:
        return getForcedExtensionCopy(YearEndModuleType.taxQuestionnaire)
      case ExtensionReason.userRequested:
      case ExtensionReason.filedExternally:
      case ExtensionReason.filedWithRegularFiling:
        return null
      default:
        return reason satisfies never
    }
  }, [filing?.extensionReason, lateJoinerDate])

  if (!forcedExtensionContext) {
    return null
  }

  return (
    <Card
      variant="regular"
      type="section"
      backgroundColor="stone40"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid>
        <GridRowColumn short>
          <Text as="h2">What is an extension, and why do I need one?</Text>
        </GridRowColumn>
        {forcedExtensionContext && (
          <StarredText>
            <Text>{forcedExtensionContext}</Text>
          </StarredText>
        )}
        <StarredText>
          <Text>
            Filing an extension is a common practice that just{' '}
            <b>buys you more time</b> to gather relevant documents and
            information.
          </Text>
        </StarredText>
        <StarredText>
          <Text>
            We will be here to guide you through the process. Your tax preparer
            will help you determine the estimated amount you should pay to
            minimize any fees and interest.
          </Text>
        </StarredText>
      </Grid>
      <Image
        src="https://heard-images.s3.amazonaws.com/assets/calendar_warning_bottom.svg"
        alt="calendar warning"
        style={{ width: 298, height: 264, backgroundColor: 'transparent' }}
      />
    </Card>
  )
}

const NumberedText = ({
  number,
  title,
  content,
  image,
}: {
  number: number
  title: ReactNode
  content: ReactNode
  image: ReactNode
}) => {
  return (
    <Grid.Row>
      <Grid.Column width={1} style={{ margin: 'auto' }}>
        <CircularWrapper wrapperColor="green" height={32}>
          <Text as="h3" color="white">
            {number}
          </Text>
        </CircularWrapper>
      </Grid.Column>
      <Grid.Column width={11}>
        <Grid>
          <GridRowColumn>
            <Text as="h3">{title}</Text>
          </GridRowColumn>
          <GridRowColumn short>{content}</GridRowColumn>
        </Grid>
      </Grid.Column>
      <Grid.Column width={3}>{image}</Grid.Column>
    </Grid.Row>
  )
}

const ReminderCard = () => {
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const form1040IRSdueDate = convertUtcToLocalDate(
    taxDetails?.form_1040_irs_due_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  if (!form1040IRSdueDate) {
    return null
  }

  return (
    <Card
      type="subsection"
      backgroundColor="sunrise"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 24,
      }}
    >
      <Grid>
        <GridRowColumn>
          <Text as="eyebrow" color="orange">
            Reminder
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="h2">
            Estimated payments for personal taxes are still due on{' '}
            {form1040IRSdueDate}
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            Extensions give you extra time to file, but not extra time to pay.
            Pay the estimated amount of taxes to minimize late fees and
            interest.
          </Text>
        </GridRowColumn>
      </Grid>
      <Image
        src="https://heard-images.s3.amazonaws.com/assets/Sparkles.svg"
        alt="calendar warning"
        style={{ width: 80, height: 80, backgroundColor: 'transparent' }}
      />
    </Card>
  )
}

const BusinessOnlyTipCard = () => {
  const optInFlowEnabled = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable2024OptOutFlow,
    false
  )
  const { personalOptInEligible } = useReselector(
    selectTaxOptInEligibility,
    optInFlowEnabled
  )

  const navigate = useNavigate()
  return (
    <Card
      type="subsection"
      backgroundColor="lightYellow"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 24,
      }}
    >
      <Grid>
        <GridRowColumn>
          <Text as="eyebrow">Tip</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="h2">This extension is for business taxes only</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text>
            We recommend that you also file an extension for your personal taxes
            with your tax preparer.{' '}
            {personalOptInEligible &&
              'If you prefer, Heard can prepare both your personal tax extension request and filing.'}
          </Text>
        </GridRowColumn>
        {personalOptInEligible && (
          <GridRowColumn>
            <Button
              onClick={() =>
                navigate('/taxes/annual/opt-in', {
                  state: { fromExtensionRequest: true },
                })
              }
              variant="link"
              style={{ display: 'flex', gap: 8 }}
            >
              File personal extension with Heard{' '}
              <Icon icon={regular('arrow-right')} />
            </Button>
          </GridRowColumn>
        )}
      </Grid>
      <Image
        src="https://heard-images.s3.amazonaws.com/assets/simple_home.svg"
        alt="calendar warning"
        style={{ width: 80, height: 80, backgroundColor: 'transparent' }}
      />
    </Card>
  )
}

const AcceptExtensionTerms = ({
  checked,
  setChecked,
}: {
  checked: boolean
  setChecked: () => void
}) => {
  const navigate = useNavigate()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const extensionType = useReselector(selectExtensionRequestType, taxYear)
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const businessExtensionRequestDueDate = convertUtcToLocalDate(
    taxDetails?.form_1120_s_extension_survey_due_date
  )?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)
  const personalExtensionRequestDueDate = convertUtcToLocalDate(
    taxDetails?.form_1040_extension_survey_due_date
  )?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)

  const extensionDueDate = useMemo(() => {
    if (
      extensionType === ExtensionRequestType.BUSINESS ||
      extensionType === ExtensionRequestType.COMBINED
    ) {
      return businessExtensionRequestDueDate
    }
    return personalExtensionRequestDueDate
  }, [
    extensionType,
    businessExtensionRequestDueDate,
    personalExtensionRequestDueDate,
  ])

  const optInFlowEnabled = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable2024OptOutFlow,
    false
  )
  const { personalOptInEligible } = useReselector(
    selectTaxOptInEligibility,
    optInFlowEnabled
  )

  const paymentDueDate = convertUtcToLocalDate(
    taxDetails?.form_1040_irs_due_date
  )?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)
  return (
    <Card backgroundColor="stone40" type="subsection">
      <Grid>
        <GridRowColumn>
          <Text as="bodySm">
            {!filing?.extensionRequestedAt
              ? 'By clicking “Request Extension,” you acknowledge:'
              : 'By clicking “Start Extension Request,” you acknowledge:'}
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <List bulleted>
            {filing?.extensionForced && (
              <List.Item>
                <Text as="bodySm">
                  You’ll need to complete Heard’s Extension Request Survey by{' '}
                  {extensionDueDate}, or we will not be able to file your
                  extension for you. This could put you at risk for incurring
                  penalties and interest.
                </Text>
              </List.Item>
            )}
            {!filing?.extensionRequestedAt && (
              <List.Item>
                <Text as="bodySm">
                  This request cannot be canceled once submitted. You’ll need to
                  complete Heard’s Extension Request Survey by{' '}
                  {extensionDueDate}, or we will not be able to file your
                  extension for you. This could put you at risk for incurring
                  penalties and interest.
                </Text>
              </List.Item>
            )}
            <List.Item>
              <Text as="bodySm">
                You must file your extension with your preparer before
                continuing with Heard’s remaining annual tax activities.
              </Text>
            </List.Item>
            <List.Item>
              <Text as="bodySm">
                Both federal and state payments (if applicable) are due{' '}
                {paymentDueDate}. Failure to file the extension and pay on time
                could result in penalty and interest fees.
              </Text>
            </List.Item>
            {!filing?.extensionRequestedAt && (
              <List.Item>
                <Text as="bodySm">
                  You’ll see updated timelines in Heard to complete your taxes
                </Text>
              </List.Item>
            )}
            {extensionType === ExtensionRequestType.BUSINESS && (
              <List.Item>
                <Text as="bodySm">
                  Heard is not preparing an extension for your personal taxes.
                  You will need to work with your tax preparer to file one
                  separately.{' '}
                  {personalOptInEligible && (
                    <>
                      If you prefer, you can{' '}
                      <Button
                        size="small"
                        style={{ display: 'inline' }}
                        onClick={() =>
                          navigate('/taxes/annual/opt-in', {
                            state: { fromExtensionRequest: true },
                          })
                        }
                        variant="secondaryLink"
                      >
                        request
                      </Button>{' '}
                      Heard prepare an extension and file your personal taxes.
                    </>
                  )}
                </Text>
              </List.Item>
            )}
          </List>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="bodySm">
            Note: Not completing activities by Heard’s extended deadlines may
            prevent you from completing your {taxYear} taxes with Heard.
          </Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Checkbox
            value={checked}
            onClick={setChecked}
            label="I have read and acknowledge the above."
          />
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

const RequestExtension = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [termsAcknowledged, setTermsAcknowledged] = useState(false)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const extensionRequestLink = useReselector(
    selectExtensionRequestSurveyLink,
    taxYear
  )
  const extensionType = useReselector(selectExtensionRequestType, taxYear)
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const extensionDueDate = useMemo(() => {
    if (
      extensionType === ExtensionRequestType.BUSINESS ||
      extensionType === ExtensionRequestType.COMBINED
    ) {
      return convertUtcToLocalDate(
        taxDetails?.form_1120_s_extension_survey_due_date
      )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
    }
    return convertUtcToLocalDate(
      taxDetails?.form_1040_extension_survey_due_date
    )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  }, [extensionType, taxDetails])
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)
  const businessIRSDueDate = convertUtcToLocalDate(
    taxDetails?.form_1120_s_irs_due_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  const personalIRSDueDate = convertUtcToLocalDate(
    taxDetails?.form_1040_irs_due_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  const businesIRSExtendedDueDate = convertUtcToLocalDate(
    taxDetails?.form_1120_s_irs_extended_due_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  const personalIRSExtendedDueDate = convertUtcToLocalDate(
    taxDetails?.form_1040_irs_extended_due_date
  )?.toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG_SHORT_DAY)
  const extensionSurveyStartDate = convertUtcToLocalDate(
    taxDetails?.extension_survey_start_date
  )

  const extensionSurveyIsLaunched =
    extensionSurveyStartDate && DateTime.now() >= extensionSurveyStartDate

  const paymentEstimateContent = useMemo(() => {
    const irsLink = (
      <Link
        newPage
        href="https://support.joinheard.com/hc/en-us/articles/5404985316503-How-to-submit-a-federal-extension-payment"
      >
        due to the IRS
      </Link>
    )
    if (
      extensionType === ExtensionRequestType.COMBINED ||
      extensionType === ExtensionRequestType.BUSINESS
    ) {
      return (
        <Text as="bodySm">
          Your preparer will also provide an estimated payment amount for
          personal taxes, which are {irsLink} <b>{personalIRSDueDate}</b>. Some
          states have franchise taxes, which are due <b>{businessIRSDueDate}</b>
          . Talk to your tax preparer to discuss if this applies to you.
        </Text>
      )
    }
    return (
      <Text as="bodySm">
        Your preparer will also provide an estimated payment amount for personal
        taxes, which will be {irsLink} on <b>{personalIRSDueDate}</b>.
      </Text>
    )
  }, [extensionType, personalIRSDueDate, businessIRSDueDate])
  const moreTimeToFileContent = useMemo(() => {
    if (extensionType === 'BUSINESS') {
      return (
        <Text as="bodySm">
          Your Business Tax Return (Form 1120-S) filing deadline will be
          extended to <b>{businesIRSExtendedDueDate}</b>.
        </Text>
      )
    }
    if (extensionType === ExtensionRequestType.COMBINED) {
      return (
        <Text as="bodySm">
          Your Business Tax Return (Form 1120-S) filing deadline will be
          extended to <b>{businesIRSExtendedDueDate}</b>. Your Personal Tax
          Return (Form 1040) deadline will be extended to{' '}
          <b>{personalIRSExtendedDueDate}</b>.
        </Text>
      )
    }
    return (
      <Text as="bodySm">
        Your Personal Tax Return (Form 1040) deadline will be extended to{' '}
        <b>{personalIRSExtendedDueDate}</b>.
      </Text>
    )
  }, [extensionType, personalIRSExtendedDueDate, businesIRSExtendedDueDate])

  const error = useReselector(selectFirstErrorMessageForKeys, [
    REQUEST_EXTENSION,
  ])
  const openExtensionSurvey = useCallback(async () => {
    if (!filing?.extensionRequestedAt) {
      const res = await dispatch(
        requestExtension(ExtensionReason.userRequested)
      )
      if (!res?.extensionRequestedAt) {
        return false
      }
    }
    navigate(extensionRequestLink)
    return true
  }, [dispatch, filing?.extensionRequestedAt, extensionRequestLink, navigate])

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserYearEndModuleStatuses())
  }, [dispatch])

  useEffect(() => {
    // if they've started the extension form, navigate directly to the form
    if (filing?.extensionStartedAt) {
      navigate(extensionRequestLink)
    }
  }, [filing?.extensionStartedAt, extensionRequestLink, navigate])

  useEffect(() => {
    // if it's before the launch date, redirect to the annual taxes page
    if (extensionSurveyIsLaunched === false) {
      navigate('/taxes/annual')
    }
  }, [extensionSurveyIsLaunched, navigate])

  return (
    <>
      <Grid>
        <GridRowColumn>
          <Button onClick={() => navigate('/taxes/annual')} variant="link">
            <Icon icon={regular('arrow-left')} style={{ marginRight: 10 }} />
            Back to Annual Taxes
          </Button>
        </GridRowColumn>
      </Grid>
      <WhatIsAnExtensionCard />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid style={{ maxWidth: 520 }}>
          <Grid.Row />
          <Grid.Row />
          <GridRowColumn>
            <Text as="display2">Extension Request</Text>
          </GridRowColumn>
          {!filing?.extensionForced && (
            <GridRowColumn>
              <Text>
                Filing for an extension on a tax return is a common practice
                that gives you and your tax preparer additional time to file and
                obtain financial records and tax documents.
              </Text>
            </GridRowColumn>
          )}
          <GridRowColumn short>
            <Text>
              This survey should take about <b>25 minutes</b> to complete.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h2">How do extensions work?</Text>
          </GridRowColumn>
          <NumberedText
            number={1}
            title="Fill out extension survey"
            content={
              <Text as="bodySm">
                You’ll verify your details and report your income. Make sure to
                submit by <b>{extensionDueDate}</b>.
              </Text>
            }
            image={
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/form-fill.svg"
                alt="form"
                style={{ height: 80, width: 80 }}
              />
            }
          />
          <NumberedText
            number={2}
            title="Get matched with a preparer"
            content={
              <Text as="bodySm">
                Connect with your preparer via a chat portal. They’ll file an
                extension for your business
                {extensionType === ExtensionRequestType.COMBINED
                  ? ' and personal taxes'
                  : ''}
                .
              </Text>
            }
            image={
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/messages.svg"
                alt="form"
                style={{ height: 80, width: 80 }}
              />
            }
          />
          {extensionType !== ExtensionRequestType.BUSINESS && (
            <NumberedText
              number={3}
              title="Receive payment estimate"
              content={paymentEstimateContent}
              image={
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/check_book_green.svg"
                  alt="form"
                  style={{ height: 80, width: 80 }}
                />
              }
            />
          )}
          {extensionType === ExtensionRequestType.BUSINESS && (
            <NumberedText
              number={3}
              title="Pay estimated taxes"
              content={
                <Text as="bodySm">
                  Some states have franchise taxes, which are due{' '}
                  <b>{businessIRSDueDate}</b>. Talk to your tax preparer to
                  discuss if this applies to you.
                </Text>
              }
              image={
                <Image
                  src="https://heard-images.s3.amazonaws.com/assets/check_book_green.svg"
                  alt="form"
                  style={{ height: 80, width: 80 }}
                />
              }
            />
          )}
          {extensionType !== ExtensionRequestType.BUSINESS && <ReminderCard />}
          {extensionType === ExtensionRequestType.BUSINESS && (
            <BusinessOnlyTipCard />
          )}
          <NumberedText
            number={4}
            title="More time to file"
            content={moreTimeToFileContent}
            image={
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/calendar_month.svg"
                alt="form"
                style={{ height: 80, width: 80 }}
              />
            }
          />
          <GridRowColumn>
            <Link
              newPage
              as="secondaryLink"
              href="https://support.joinheard.com/hc/en-us/articles/4560662501271-Filing-for-an-Extension-as-an-S-Corporation"
            >
              Read more about extensions{' '}
              <Icon
                size="xs"
                icon={regular('arrow-right')}
                style={{ marginRight: 10 }}
              />
            </Link>
          </GridRowColumn>
          <AcceptExtensionTerms
            checked={termsAcknowledged}
            setChecked={() => setTermsAcknowledged(!termsAcknowledged)}
          />
          {error && (
            <GridRowColumn>
              <Alert type="error">{error}</Alert>
            </GridRowColumn>
          )}
          <GridRowColumn centerContent>
            <Button onClick={openExtensionSurvey} disabled={!termsAcknowledged}>
              {filing?.extensionRequestedAt
                ? 'Start Extension Request'
                : 'Request Extension'}
            </Button>
          </GridRowColumn>
        </Grid>
      </div>
    </>
  )
}

export default RequestExtension
