import { useCallback, useState } from 'react'

import {
  Button,
  Modal,
  Text,
  TextArea,
} from '../../../components/BaseComponents'
import { useAsyncCallback, useReselector } from '../../../utils/sharedHooks'
import { selectAnnualTaxFilingForId } from './adminAnnualTaxFilings.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { updateAdminAnnualTaxFiling } from './adminAnnualTaxFilings.slice'

const NotesModal = ({
  filingId,
  onClose,
}: {
  filingId: number
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const filing = useReselector(selectAnnualTaxFilingForId, filingId)
  const filingTaxForms = filing?.annualTaxFilingForms

  const [notes, setNotes] = useState(filing?.additionalTaxInformation || '')

  const onSave = useCallback(async () => {
    if (!filing?.id) {
      return
    }

    const success = await dispatch(
      updateAdminAnnualTaxFiling(filing.id, {
        // empty notes saved as null
        additionalTaxInformation: notes || null,
      })
    )

    if (success) {
      onClose()
    }
  }, [dispatch, filing?.id, notes, onClose])

  const saveCallback = useAsyncCallback(onSave)

  const checklistSubmitted = filingTaxForms?.some(
    (form) => form.questionnaireResponseStatus === 'submitted'
  )

  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>Notes to send to tax preparer</Modal.Header>
      <Modal.Content>
        <Text color="red">
          {checklistSubmitted
            ? 'The checklist has been submitted and notes cannot be changed'
            : 'The user will be able to see these notes from downloaded tax organizer doc'}
        </Text>
        <TextArea
          onChange={setNotes}
          value={notes}
          placeholder="Notes to send to tax preparer"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={saveCallback.callback}
          disabled={saveCallback.loading || !filing || checklistSubmitted}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default NotesModal
