import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { convertUtcToLocalDate } from '../../../../utils/dateHelpers'

/*
Tax Season Kickoff (TSK) due date:

For users who joined before 1/1/2024:
- Use the default due date of 2/10/2025

For S-corps who join 1/1/2024 onwards:
- If joined before 2/12/2025: Rolling two weeks from join date, capped at 2/26/2025
- If joined after 2/12/2025: Fixed due date of 2/26/2025

For Sole Props who join 1/1/2024 onwards:
- If joined before 3/12/2025: Rolling two weeks from join date, capped at 3/26/2025
- If joined after 3/12/2025: Fixed due date of 3/26/2025
*/

interface TskDueDateParams {
  membershipStartDate: string | undefined
  taxEntityType: TAX_ENTITY_TYPES | null | undefined
  eoySurveyStartDate: string | undefined
  newUserCutOffAt: string | undefined
  taxSeasonKickoffDueAt: string | undefined
  form1040TqDueDate: string | undefined
  form1120STqDueDate: string | undefined
}

export const getTskDueDateForUser = ({
  membershipStartDate,
  taxEntityType,
  newUserCutOffAt,
  taxSeasonKickoffDueAt,
  form1040TqDueDate,
  form1120STqDueDate,
}: TskDueDateParams) => {
  // If membershipStartDate is undefined, fall back to taxSeasonKickoffDueAt or return null
  if (!membershipStartDate) {
    return taxSeasonKickoffDueAt
      ? convertUtcToLocalDate(taxSeasonKickoffDueAt)
      : null
  }

  const joinDate = convertUtcToLocalDate(membershipStartDate)
  if (!joinDate) return null

  // Use original due date for users who joined before 1/1/2025
  const cutoffYear = convertUtcToLocalDate(newUserCutOffAt)
  if (!cutoffYear) return null

  if (joinDate < cutoffYear) {
    return convertUtcToLocalDate(taxSeasonKickoffDueAt)
  }

  const twoWeeksFromJoin = joinDate.plus({ days: 14 })

  // S-Corp
  if (
    taxEntityType === TAX_ENTITY_TYPES.form_1120_s ||
    taxEntityType === TAX_ENTITY_TYPES.form_1120
  ) {
    const scorpCutoffDate = convertUtcToLocalDate(form1120STqDueDate)?.minus({
      days: 14,
    })
    const scorpFixedDueDate = convertUtcToLocalDate(form1120STqDueDate)
    if (!scorpCutoffDate || !scorpFixedDueDate) return null

    // If joined before cutoff date, use rolling two weeks but capped at fixed due date
    if (joinDate <= scorpCutoffDate) {
      return twoWeeksFromJoin <= scorpFixedDueDate
        ? twoWeeksFromJoin
        : scorpFixedDueDate
    }
    // If joined after cutoff date, use fixed due date
    return scorpFixedDueDate
  }

  // Sole Prop
  if (taxEntityType === TAX_ENTITY_TYPES.form_1040) {
    const solePropCutoffDate = convertUtcToLocalDate(form1040TqDueDate)?.minus({
      days: 14,
    })
    const solePropFixedDueDate = convertUtcToLocalDate(form1040TqDueDate)
    if (!solePropCutoffDate || !solePropFixedDueDate) return null

    // If joined before cutoff date, use rolling two weeks but capped at fixed due date
    if (joinDate <= solePropCutoffDate) {
      return twoWeeksFromJoin <= solePropFixedDueDate
        ? twoWeeksFromJoin
        : solePropFixedDueDate
    }
    // If joined after cutoff date, use fixed due date
    return solePropFixedDueDate
  }

  // Default fallback to taxSeasonKickoffDueAt or null if undefined
  return taxSeasonKickoffDueAt
    ? convertUtcToLocalDate(taxSeasonKickoffDueAt)
    : null
}
