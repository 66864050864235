import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useFetchResponse } from '../../../utils/sharedHooks'
import { fetchAnnualTaxFilingsIfNeeded } from './annualTaxFilings.slice'
import { useEffect, useMemo, useCallback } from 'react'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  fetchUserCompleteOnboardingDate,
  fetchUserEventKind,
  createUserOnboardingEvent,
} from '../../Onboarding/UserOnboardingSteps/onboarding.actions'

import { DateTime } from 'luxon'

export enum UserMilestoneEventKind {
  completedOnboarding = 'completed_onboarding',
  completeCatchUpBookkeeping = 'complete_catch_up_bookkeeping',
  completeStartedCards = 'complete_started_cards',
  userSnapImmediately = 'user-snap-immediately',
  userSnapNewUsers = 'user-snap-new-user',
}

const UsersnapNewUserExperienceandExpectations = () => {
  const completedDate = useFetchResponse(fetchUserCompleteOnboardingDate)

  const fetchUserEvent = useFetchResponse(fetchUserEventKind, {
    kind: UserMilestoneEventKind.userSnapNewUsers,
  })
  const dispatch = useAppDispatch()

  const isAlreadySubmitted = useMemo(() => {
    return fetchUserEvent?.eventSubmitted
  }, [fetchUserEvent])

  const shouldShowSurvey = useMemo(() => {
    let showSurvey = false
    if (completedDate?.status) {
      const currentDate = DateTime.now()
      const completedDateLuxon = completedDate?.mostRecentCompletedAt
        ? DateTime.fromISO(String(completedDate.mostRecentCompletedAt))
        : null
      if (completedDateLuxon) {
        const differenceInDays = currentDate.diff(
          completedDateLuxon,
          'days'
        ).days
        if (differenceInDays > 30 && differenceInDays < 90) {
          showSurvey = true
        }
      }
    }
    return showSurvey
  }, [completedDate])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  const onSubmit = useCallback(() => {
    dispatch(
      createUserOnboardingEvent({
        kind: UserMilestoneEventKind.userSnapNewUsers,
      })
    )
  }, [dispatch])

  useUsersnapCustomDisplayLogicWidget({
    projectKey: process.env.VITE_USERSNAP_NEW_USER_EXPERIENCE,
    shouldShow: shouldShowSurvey && !isAlreadySubmitted,
    maxTimesShown: 3,
    showAgainAfterDays: 3,
    onSubmit,
  })

  return null
}

export default UsersnapNewUserExperienceandExpectations
