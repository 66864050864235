import { createIsSomeEnum, filterNulls } from '../../utils/typeHelpers'
import { DropdownOption } from '../../components/BaseComponents'
import { STATES } from '../../constants/locationConstants'
import { shortNames } from '../../constants/statesShortNamesConstants'

export const NO_STATE_QUARTERLY_TAX_ESTIMATES = [
  'AK',
  'FL',
  'NV',
  'SD',
  'TX',
  'TN',
  'WA',
  'WY',
]

export const STATE_QUARTERLY_TAX_PAYMENTS: {
  [key: string]: {
    paymentLink: string
    helpArticle?: string
    helpArticleId?: string
  }
} = {
  AL: {
    paymentLink: 'https://myalabamataxes.alabama.gov/tap/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418612882199-Paying-State-Income-Tax-in-Alabama',
    helpArticleId: '4418612882199',
  },
  AK: { paymentLink: 'https://tax.alaska.gov/' },
  AS: {
    paymentLink: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5161913679255-Paying-State-Income-Tax-in-Arkansas',
    helpArticleId: '5161913679255',
  },
  AZ: {
    paymentLink: 'https://www.aztaxes.gov/Home/PaymentIndividual',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418227610391-Paying-State-Income-Tax-in-Arizona',
    helpArticleId: '4418227610391',
  },
  AR: {
    paymentLink: 'https://atap.arkansas.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5161913679255-Paying-State-Income-Tax-in-Arkansas',
    helpArticleId: '5161913679255',
  },
  CA: {
    paymentLink: 'https://www.ftb.ca.gov/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4403962664471-Paying-State-Income-Tax-in-California',
    helpArticleId: '4403962664471',
  },
  CO: {
    paymentLink: 'https://tax.colorado.gov/pay',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4403962498327-Paying-State-Income-Tax-in-Colorado',
    helpArticleId: '4403962498327',
  },
  CT: {
    paymentLink: 'https://drs.ct.gov/eservices/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418634101015-Paying-State-Income-Tax-in-Connecticut',
    helpArticleId: '4418634101015',
  },
  DE: {
    paymentLink: 'https://tax.delaware.gov/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5161987782295-Paying-State-Income-Tax-in-Delaware',
    helpArticleId: '5161987782295',
  },
  DC: {
    paymentLink: 'https://mytax.dc.gov/_/#0',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407473316119-Paying-State-Income-Tax-in-Washington-DC',
    helpArticleId: '4407473316119',
  },
  FM: { paymentLink: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf' },
  FL: {
    paymentLink:
      'https://floridarevenue.com/taxes/eservices/Pages/filepay.aspx',
  },
  GA: {
    paymentLink: 'https://gtc.dor.ga.gov/_/#0',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407472932887-Paying-State-Income-Tax-in-Georgia',
    helpArticleId: '4407472932887',
  },
  GU: { paymentLink: 'https://www.myguamtax.com/pay_4868_instructions.html' },
  HI: {
    paymentLink: 'https://hitax.hawaii.gov/_/#1',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418657464727-Paying-State-Income-Tax-in-Hawaii',
    helpArticleId: '4418657464727',
  },
  ID: {
    paymentLink: 'https://tax.idaho.gov/i-1042.cfm',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407479119383-Paying-State-Income-Tax-in-Idaho',
    helpArticleId: '4407479119383',
  },
  IL: {
    paymentLink:
      'https://www2.illinois.gov/rev/individuals/filingrequirements/Pages/estimatedpayments.aspx',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418226917527-Paying-State-Income-Tax-in-Illinois',
    helpArticleId: '4418226917527',
  },
  IN: {
    paymentLink: 'https://intime.dor.in.gov/eServices/_/#1',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5162084995479-Paying-State-Income-Tax-in-Indiana',
    helpArticleId: '5162084995479',
  },
  IA: {
    paymentLink: 'https://govconnect.iowa.gov/tap/_/#1',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418657538071-Paying-State-Income-Tax-in-Iowa',
    helpArticleId: '4418657538071',
  },
  KS: {
    paymentLink: 'https://www.kansas.gov/payment-portal/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418634943895-Paying-State-Income-Tax-in-Kansas',
    helpArticleId: '4418634943895',
  },
  KY: {
    paymentLink: 'https://epayment.ky.gov/EPAY',
    helpArticle:
      'https://support.joinheard.com/hc/en-us/articles/23704119771031-Paying-State-Income-Tax-in-Kentucky',
    helpArticleId: '23704119771031',
  },
  LA: {
    paymentLink: 'https://latap.revenue.louisiana.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5161834048407-Paying-State-Income-Tax-in-Louisiana',
    helpArticleId: '5161834048407',
  },
  ME: {
    paymentLink: 'https://revenue.maine.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418642221079-Paying-State-Income-Tax-in-Maine',
    helpArticleId: '4418642221079',
  },
  MH: { paymentLink: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf' },
  MD: {
    paymentLink: 'https://interactive.marylandtaxes.gov/individuals/payment/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418226821911-Paying-State-Income-Tax-in-Maryland',
    helpArticleId: '4418226821911',
  },
  MA: {
    paymentLink: 'https://mtc.dor.state.ma.us/mtc/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4403969526935-Paying-State-Income-Tax-in-Massachusetts',
    helpArticleId: '4403969526935',
  },
  MI: {
    paymentLink: 'https://www.michigan.gov/taxes/iit/online-payments',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407479276695-Paying-State-Income-Tax-in-Michigan',
    helpArticleId: '4407479276695',
  },
  MN: {
    paymentLink: 'https://www.revenue.state.mn.us/estimated-tax',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418634515607-Paying-State-Income-Tax-in-Minnesota',
    helpArticleId: '4418634515607',
  },
  MS: {
    paymentLink: 'https://www.dor.ms.gov/E-Services/Pages/Online-Payments.aspx',
    helpArticle:
      'https://support.joinheard.com/hc/en-us/articles/23704194666775-Paying-State-Income-Tax-in-Mississippi',
    helpArticleId: '23704194666775',
  },
  MO: {
    paymentLink: 'https://magic.collectorsolutions.com/magic-ui/Login/MODOR',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418613490327-Paying-State-Income-Tax-in-Missouri',
    helpArticleId: '4418613490327',
  },
  MT: {
    paymentLink: 'https://tap.dor.mt.gov/_/#0',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5161928416023-Paying-State-Income-Tax-in-Montana',
    helpArticleId: '5161928416023',
  },
  NE: {
    paymentLink:
      'https://revenue.nebraska.gov/individuals/estimated-income-tax',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5162143430423-Paying-State-Income-Tax-in-Nebraska',
    helpArticleId: '5162143430423',
  },
  NV: { paymentLink: 'https://www.nevadatax.nv.gov/#' },
  NH: {
    paymentLink: 'https://gtc.revenue.nh.gov/TAP/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418640597271-Paying-State-Income-Tax-in-New-Hampshire',
    helpArticleId: '4418640597271',
  },
  NJ: {
    paymentLink: 'https://www.state.nj.us/treasury/taxation/njit20.shtml',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418231453079-Paying-State-Income-Tax-in-New-Jersey',
    helpArticleId: '4418231453079',
  },
  NM: {
    paymentLink: 'https://tap.state.nm.us/tap/_/',
    helpArticle:
      'https://support.joinheard.com/hc/en-us/articles/23704231383831-Paying-State-Income-Tax-in-New-Mexico',
    helpArticleId: '23704231383831',
  },
  NY: {
    paymentLink: 'https://www.tax.ny.gov/pay/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4403961044631-Paying-State-Income-Tax-in-New-York',
    helpArticleId: '4403961044631',
  },
  NC: {
    paymentLink: 'https://electronic-services.dor.nc.gov/wps/portal/nc40',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407473343383-Paying-State-Income-Tax-in-North-Carolina',
    helpArticleId: '4407473343383',
  },
  ND: {
    paymentLink: 'https://apps.nd.gov/tax/tap/_/',
    helpArticle:
      'https://support.joinheard.com/hc/en-us/articles/23704234654231-Paying-State-Income-Tax-in-North-Dakota',
    helpArticleId: '23704234654231',
  },
  MP: { paymentLink: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf' },
  OH: {
    paymentLink: 'https://myportal.tax.ohio.gov/TAP/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418650354583-Paying-State-Income-Tax-in-Ohio',
    helpArticleId: '4418650354583',
  },
  OK: {
    paymentLink: 'https://oktap.tax.ok.gov/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5162170096535-Paying-State-Income-Tax-in-Oklahoma',
    helpArticleId: '5162170096535',
  },
  OR: {
    paymentLink: 'https://www.oregon.gov/dor/pages/payments.aspx',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4407473025047-Paying-State-Income-Tax-in-Oregon',
    helpArticleId: '4407473025047',
  },
  PW: { paymentLink: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf' },
  PA: {
    paymentLink: 'https://mypath.pa.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418629855895-Paying-State-Income-Tax-in-Pennsylvania',
    helpArticleId: '4418629855895',
  },
  PR: { paymentLink: 'https://www.irs.gov/forms-pubs/about-form-1040-es-pr' },
  RI: { paymentLink: 'https://tax.ri.gov/' },
  SC: {
    paymentLink: 'https://mydorway.dor.sc.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418642386327-Paying-State-Income-Tax-in-South-Carolina',
    helpArticleId: '4418642386327',
  },
  SD: { paymentLink: 'https://dor.sd.gov/businesses/taxes/' },
  TN: {
    paymentLink:
      'https://www.tn.gov/revenue/taxes/franchise---excise-tax/file-and-pay.html',
  },
  TX: { paymentLink: 'https://comptroller.texas.gov/taxes/file-pay/' },
  UT: {
    paymentLink: 'https://incometax.utah.gov/filing/extensions-and-prepayments',
    helpArticle:
      'https://support.joinheard.com/hc/en-us/articles/23704288551319-Paying-State-Income-Tax-in-Utah',
    helpArticleId: '23704288551319',
  },
  VA: {
    paymentLink: 'https://www.tax.virginia.gov/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4403960977815-Paying-State-Income-Tax-in-Virginia',
    helpArticleId: '4403960977815',
  },
  VI: { paymentLink: 'https://bir.vi.gov/' },
  VT: {
    paymentLink: 'https://myvtax.vermont.gov/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/4418650452119-Paying-State-Income-Tax-in-Vermont',
    helpArticleId: '4418650452119',
  },
  WA: { paymentLink: 'https://dor.wa.gov/file-pay-taxes' },
  WV: {
    paymentLink:
      'https://public.wvtax.gov/Individuals/ElectronicFiling/PaymentOptions/Pages/IndividualsPaymentOptions.aspx',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5162227926807-Paying-State-Income-Tax-in-West-Virginia',
    helpArticleId: '5162227926807',
  },
  WI: {
    paymentLink: 'https://tap.revenue.wi.gov/mta/_/',
    helpArticle:
      'https://support.joinheard.com//hc/en-us/articles/5162198170775-Paying-State-Income-Tax-in-Wisconsin',
    helpArticleId: '5162198170775',
  },
  WY: { paymentLink: 'https://revenue.wyo.gov/' },
}

export enum FILING_STATUSES {
  single = 'single',
  married_filing_jointly = 'married_filing_jointly',
  married_filing_separately = 'married_filing_separately',
  head_of_household = 'head_of_household',
  qualifying_widow = 'qualifying_widow',
}

export const isFilingStatusType = (value: string): value is FILING_STATUSES =>
  Object.prototype.hasOwnProperty.call(FILING_STATUSES, value)

export enum TAX_ENTITY_TYPES {
  form_1120 = 'form_1120',
  form_1120_s = 'form_1120_s',
  form_1065 = 'form_1065',
  schedule_c = 'schedule_c',
  form_1040 = 'form_1040',
}

// Unsure how to reference the above strings, below. Use a function to centralize strings?
export type TAX_ENTITY_TYPES_TYPE = keyof typeof TAX_ENTITY_TYPES
export const isTaxEntityType = createIsSomeEnum(TAX_ENTITY_TYPES)

export const TAX_PROFILE_VERSION = 'tax_profile_06-17-2022'

export enum BALANCE_SHEET_CATEGORIES {
  asset = 'asset',
  equity = 'equity',
  liability = 'liability',
}

export type BALANCE_SHEET_CATEGORIES_TYPE =
  keyof typeof BALANCE_SHEET_CATEGORIES

// todo unused now but might be used in future
export const STATE_FORMS: {
  [key: string]: {
    link: string
    line: number | string
    qPercents: [number, number, number, number]
    formName: string
  }
} = {
  Federal: {
    link: 'https://drive.google.com/uc?export=view&id=1WW-aB5rNqua82d690o4vHZWssgoR0ujL',
    line: 24,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 1040',
  },
  AL: {
    link: 'https://drive.google.com/uc?export=view&id=1HQllfcDs0OqUUpDJZEAALbRcDGtinQkm',
    line: 21,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 40',
  },
  AR: {
    link: 'https://drive.google.com/uc?export=view&id=1JIN-SIKpzzuoR5zgaMhgeia2D9Q7taK3',
    line: 33,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form AR1000F',
  },
  AZ: {
    link: 'https://drive.google.com/uc?export=view&id=18uUKPUzfO45Ex3ptS-3x2kZM7-LVbjfQ',
    line: 52,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 140',
  },
  CA: {
    link: 'https://drive.google.com/uc?export=view&id=1IKgYup-Y5e1h0123rZJOOkVI8ZZfqvir',
    line: 35,
    qPercents: [0.3, 0.4, 0, 0.3],
    formName: 'Form 540',
  },
  CO: {
    link: 'https://drive.google.com/uc?export=view&id=1rHAGx80sV-HJY9SjO7s6SuASb7QyZqe1',
    line: 17,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'DR 0104',
  },
  CT: {
    link: 'https://drive.google.com/uc?export=view&id=15pkoWLXAl3qqUmtciJ5uRG8Jl6BnfRZb',
    line: 16,
    qPercents: [0.3, 0.4, 0.1, 0.2],
    formName: 'CT 1040',
  },
  DC: {
    link: 'https://drive.google.com/uc?export=view&id=1HAqKABiffCkZmpy8nKY2HfyZYYMXrDn1',
    line: 20,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'D-40',
  },
  DE: {
    link: 'https://drive.google.com/uc?export=view&id=17-2oJVRCJfTKHSKYWwkpBO9LFhNpZnju',
    line: 25,
    qPercents: [0.5, 0.2, 0.2, 0.1],
    formName: 'PIT-RES',
  },
  GA: {
    link: 'https://drive.google.com/uc?export=view&id=1zaPDDa6nmJ0JW5_6HqdJHPptA5ExXxMb',
    line: 16,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 500',
  },
  GU: {
    link: 'https://drive.google.com/uc?export=view&id=1grBYCRrLipzKu1R6qBc_c0BGf9RJTTke',
    line: 24,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040',
  },
  HI: {
    link: 'https://drive.google.com/uc?export=view&id=1s46ovb7x7o0Gw98RHpRceQU_7P7OvLBy',
    line: 27,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'N-11',
  },
  IA: {
    link: 'https://drive.google.com/uc?export=view&id=1zcGo1246M1s4vWj50eqxu92g1HCl5Yls',
    line: 42,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040',
  },
  ID: {
    link: 'https://drive.google.com/uc?export=view&id=1ZtqPHV5Wr37LWE7Rw3iMxP_JxCTQWrqQ',
    line: 33,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 40',
  },
  IL: {
    link: 'https://drive.google.com/uc?export=view&id=1Rl_M-_olyn2HZPK7ONp2IIokz-WO2Dqc',
    line: 23,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040',
  },
  IN: {
    link: 'https://drive.google.com/uc?export=view&id=1MsuYPAK6By5CKkXo-5Hhmsb-92Ykgqvl',
    line: 11,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IT-40',
  },
  KS: {
    link: 'https://drive.google.com/uc?export=view&id=1ry6dFqZOI4nDN2S0bNYf829k84izwhUE',
    line: 12,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'K-40',
  },
  KY: {
    link: 'https://drive.google.com/uc?export=view&id=1u8htX8d_tV6lxZ1txXN-FbrZePWPbQ0J',
    line: 19,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '740',
  },
  LA: {
    link: 'https://drive.google.com/uc?export=view&id=1CoSJtVuFoWtOFtHK6n8y7Lj6QHm-0L4X',
    line: 11,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IT-540',
  },
  MA: {
    link: 'https://drive.google.com/uc?export=view&id=19QHNnBvGaegHwth4lM8LFCcwLUBBKHCm',
    line: 28,
    qPercents: [0.4, 0.25, 0.25, 0.1],
    formName: 'Form 1',
  },
  MD: {
    link: 'https://drive.google.com/uc?export=view&id=1d7ZRjhNIvzuO986Vs1bB3B4WLYFW5qMz',
    line: 21,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '502',
  },
  ME: {
    link: 'https://drive.google.com/uc?export=view&id=1rgmex6KEa3gKlnDUfU8bQ14Xf3AI6R8L',
    line: 22,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040ME',
  },
  MI: {
    link: 'https://drive.google.com/uc?export=view&id=1VKLPh_9CW5FgfZ-mZeme4taOLrcGD0kv',
    line: 17,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'MI-1040',
  },
  MN: {
    link: 'https://drive.google.com/uc?export=view&id=1dxjaN4vV6FiHCv7jntwi8fcZTnMUsViD',
    line: 15,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'M1',
  },
  MO: {
    link: 'https://drive.google.com/uc?export=view&id=1632FY4AbwaHkG2h33EmwQ1IjGMvU4zCt',
    line: 28,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040',
  },
  MS: {
    link: 'https://drive.google.com/uc?export=view&id=1xDyj-kMhnj2xdzVWWV9-p-Bv9ShadYWw',
    line: 23,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '80-105',
  },
  MT: {
    link: 'https://drive.google.com/uc?export=view&id=1r2z7jT7RTAiDM29XsC-TEMGLiuz8NHK5',
    line: 18,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 2',
  },
  NC: {
    link: 'https://drive.google.com/uc?export=view&id=1U4QsEw_JKOnGQ-xNyzwNKJmZbRPpRzfi',
    line: 15,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'D-400',
  },
  ND: {
    link: 'https://drive.google.com/uc?export=view&id=1YNmRRxFsO85lmsWV0AN_x-WRyEmePMmn',
    line: 19,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'ND-1',
  },
  NE: {
    link: 'https://drive.google.com/uc?export=view&id=1YYYybzMOYZe0__ZdtAqUrkVcRB5ptuwW',
    line: 17,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040N',
  },
  NJ: {
    link: 'https://drive.google.com/uc?export=view&id=1tJQFxirjtG_v89VZAi1-nCoyI9sF60cL',
    line: 53,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'NJ-1040',
  },
  NM: {
    link: 'https://drive.google.com/uc?export=view&id=1sRYQEEWNvFDA_AeH5Yx71IDQWv2zUOfr',
    line: 22,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'PIT-1',
  },
  NY: {
    link: 'https://drive.google.com/uc?export=view&id=1EGNNq0HPqktJlOMvGu87vmLw88V-zzl3',
    line: 46,
    qPercents: [0.25, 0.33, 0.33, 0.33],
    formName: 'IT-201',
  },
  NYC: {
    link: 'https://drive.google.com/uc?export=view&id=14TvhKE6Wx0tsW1USqmf1nO-6trLE5aum',
    line: 58,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IT-201',
  },
  OH: {
    link: 'https://drive.google.com/uc?export=view&id=1KW1fU-SGawJ7DfDHmSrwX8VY5QcqO7da',
    line: '8c',
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IT 1040',
  },
  OK: {
    link: 'https://drive.google.com/uc?export=view&id=12c6Ftk_oQtnspq6wM4OL9jJqItQtiYup',
    line: 14,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '511',
  },
  OR: {
    link: 'https://drive.google.com/uc?export=view&id=1AfOZiYA_QD_hOHRTTle_7NIFz1hOvye8',
    line: 22,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'OR-40',
  },
  PA: {
    link: 'https://drive.google.com/uc?export=view&id=1OeBriGrcXKIzEMW_hh3epZx9ng2cRhRZ',
    line: 12,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'PA-40',
  },
  PR: {
    link: 'https://drive.google.com/uc?export=view&id=1V9mec1H8z9saNsyOgnuDcQ_nlp72wyI0',
    line: 24,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '1040-NR',
  },
  RI: {
    link: 'https://drive.google.com/uc?export=view&id=1eJ7ujuGLnT_2foRqBfeW0tE78cgGYEuh',
    line: '13a',
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'RI-1040',
  },
  SC: {
    link: 'https://drive.google.com/uc?export=view&id=13yVPIGKffAk0MlBxVmsMz4-oXRfwM8hQ',
    line: 10,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'SC-1040',
  },
  UT: {
    link: 'https://drive.google.com/uc?export=view&id=1kne29iA2gUo_wnUWCsKNI-nLCus757C7',
    line: 22,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'TC-40',
  },
  VA: {
    link: 'https://drive.google.com/uc?export=view&id=1N0vqQ4OBF0KH7MAkI2C9ghxgC1PffuVr',
    line: 18,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: '760',
  },
  VT: {
    link: 'https://drive.google.com/uc?export=view&id=1tah_JOasoSCcogNm1plE8z-CIoYmGBXD',
    line: 14,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IN-111',
  },
  WI: {
    link: 'https://drive.google.com/uc?export=view&id=1LRdnxOsQt-iMOiqspwi_5T_t7JY7_U38',
    line: 10,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'Form 1',
  },
  WV: {
    link: 'https://drive.google.com/uc?export=view&id=1W8r2418e1ZbLnabJfEYIo47brITbjHEm',
    line: 8,
    qPercents: [0.25, 0.25, 0.25, 0.25],
    formName: 'IT-140',
  },
}

export const FILING_STATUS_COPY = {
  [FILING_STATUSES.single]: {
    title: 'Single',
    content:
      ' You may use this filing status if you are unmarried, legally separated/divorced, or widowed before the start of the tax year and not remarried.',
  },
  [FILING_STATUSES.married_filing_jointly]: {
    title: 'Married Filing Jointly',
    content:
      'You are married and plan to file a single return for you and your spouse. This will reflect income and deductions for both parties.',
  },
  [FILING_STATUSES.married_filing_separately]: {
    title: 'Married Filing Separately',
    content:
      'You are married and plan to file an annual tax return separate from that of your spouse. This will keep income and deductions between spouses separate.',
  },
  [FILING_STATUSES.head_of_household]: {
    title: 'Head of Household',
    content:
      'You must be considered unmarried for the {year} tax year, have a qualifying child or dependent living in your home for more than half the year, and be responsible for paying more than half of all household expenses.',
  },
  [FILING_STATUSES.qualifying_widow]: {
    title: 'Qualifying Widow(er)',
    content:
      ' You may file as a Qualifying Widow(er) if your spouse has passed away, allowing you to use the tax rates as if you were filing jointly.',
  },
}

// A case-sensitive collection of each database column that our form will map to
export const databaseColumns = {
  filingStatus: 'filingStatus',
  numberOfDependents: 'numberOfDependents',
  itemizedDeduction: 'itemizedDeduction',
  spouseFirstName: 'spouseFirstName',
  spouseLastName: 'spouseLastName',
  spousePaystubDocumentId: 'spousePaystubDocumentId',
  relocatedThisQuarter: 'relocatedThisQuarter',
  relocatedPreviousState: 'relocatedPreviousState',
  relocatedPreviousStateAt: 'relocatedPreviousStateAt',
  homeState: 'homeState',
  sawMultistateClientsThisQuarter: 'sawMultistateClientsThisQuarter',
  percentageIncomePerState: 'percentageIncomePerState',
  taxEntityType: 'taxEntityType',
  taxProfileVersion: 'taxProfileVersion',
  taxProfileLockedAt: 'taxProfileLockedAt',
  wIncomeInCents: 'wIncomeInCents',
  federalWithholdingInCents: 'federalWithholdingInCents',
  stateWithholdingInCents: 'stateWithholdingInCents',
  deductionInCents: 'deductionInCents',
  spouseWIncomeInCents: 'spouseWIncomeInCents',
  spouseFederalWithholdingInCents: 'spouseFederalWithholdingInCents',
  spouseStateWithholdingInCents: 'spouseStateWithholdingInCents',
  spouseIndividualIncomeInCents: 'spouseIndividualIncomeInCents',
  otherIndividualIncomeInCents: 'otherIndividualIncomeInCents',
}
export const TaxEntityTypeOptions = [
  {
    key: TAX_ENTITY_TYPES.form_1065,
    value: TAX_ENTITY_TYPES.form_1065,
    description: 'Form 1065',
    text: 'Partnership',
  },
  {
    key: TAX_ENTITY_TYPES.form_1120,
    value: TAX_ENTITY_TYPES.form_1120,
    description: 'Form 1120',
    text: 'C-Corporation',
  },
  {
    key: TAX_ENTITY_TYPES.form_1120_s,
    value: TAX_ENTITY_TYPES.form_1120_s,
    description: 'Form 1120-S',
    text: 'S Corporation',
  },
  {
    key: TAX_ENTITY_TYPES.form_1040,
    value: TAX_ENTITY_TYPES.form_1040,
    description: 'Form 1040',
    text: 'Sole Proprietorship',
  },
]
export const getTaxEntityName = (taxType?: string | null) =>
  TaxEntityTypeOptions.find((option) => option.value === taxType)?.text ||
  taxType ||
  ''
export const FilingStatusOptions = [
  {
    key: FILING_STATUSES.single,
    value: FILING_STATUSES.single,
    text: 'Single',
  },
  {
    key: FILING_STATUSES.married_filing_jointly,
    value: FILING_STATUSES.married_filing_jointly,
    text: 'Married Filing Jointly',
  },
  {
    key: FILING_STATUSES.married_filing_separately,
    value: FILING_STATUSES.married_filing_separately,
    text: 'Married Filing Separately',
  },
  {
    key: FILING_STATUSES.head_of_household,
    value: FILING_STATUSES.head_of_household,
    text: 'Head of Household',
  },
  {
    key: FILING_STATUSES.qualifying_widow,
    value: FILING_STATUSES.qualifying_widow,
    text: 'Qualifying Widow',
  },
]
export const YesNoOptions = [
  { key: 'yes', value: true, text: 'Yes' },
  { key: 'no', value: false, text: 'No' },
]
export const generateUSAStateOptions = () => {
  const result: DropdownOption[] = []
  STATES.forEach((row) => {
    const state = shortNames[row.text]
    if (state) {
      result.push({
        key: row.value,
        value: row.value,
        text: state,
      })
    }
  })

  return result
}

export const getUnusedOptions = ({
  used,
  all,
}: {
  used: string[]
  all: DropdownOption[]
}) =>
  filterNulls(
    all.map((o) => {
      const obj = { key: '', value: '', text: '', disabled: false }

      if (!o.text || !o.key || !o.value) {
        return null
      }

      if (used.includes(o.value.toString())) {
        obj.disabled = true
      }

      obj.key = o.key.toString()
      obj.value = o.value.toString()
      obj.text = o.text.toString()

      return obj
    })
  )

export const TAX_CENTER_URL_PATHNAME = '/taxes/quarterly'
export const TAX_PROFILE_URL_PATHNAME = '/taxes/profile'
export const USER_DOCUMENTS_URL_PATHNAME = '/practice/documents'
