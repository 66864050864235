import { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
  Text,
  Button,
  BorderedIcon,
  GridRowColumn,
  Link,
} from '../../../BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useFetchResponse } from '../../../../utils/sharedHooks'
import { fetchInsightsTableData } from '../../shared/financeActions'
import { INSIGHTS_TYPE } from '../../shared/utils'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { Colors } from '../../../../styles/theme'
import {
  useIsDeviceWidth,
  DeviceWidthCombo,
} from '../../../../utils/deviceWidthHelpers'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import { useAnalyticsTrack } from '../../../../features/Amplitude'

export interface CategorySumTableProps {
  header: string
  tableData: { id: number; categoryName: string; amountInCents: number }[]
  sumColor: keyof typeof Colors
}

export const CategorySumTable = ({
  header,
  tableData,
  sumColor = 'accentGreen',
}: CategorySumTableProps) => {
  const trackMessage =
    header === 'Income'
      ? 'view-income-transactions'
      : 'view-expenses-transactions'

  return (
    <>
      <Text as="eyebrow" style={{ marginBottom: 16 }}>
        {header}
      </Text>
      <Grid>
        {tableData.map((category) => (
          <Grid.Row key={category.id} style={{ padding: '8px 0px' }}>
            <Grid.Column width={8}>
              <Text as="bodySm"> {category.categoryName} </Text>
            </Grid.Column>
            <Grid.Column width={8}>
              <Text color={sumColor} as="bodySm">
                {formatCurrencyFromCents(category.amountInCents)}
              </Text>
            </Grid.Column>
          </Grid.Row>
        ))}
        <GridRowColumn>
          <Link
            to="/transactions/business"
            as="secondaryLink"
            size="small"
            trackMessage={trackMessage}
            trackAttributes={{ screen: 'practice insights' }}
          >
            View Transactions →
          </Link>
        </GridRowColumn>
      </Grid>
    </>
  )
}

export const ProfitLossInsights = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)

  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const incomeTableResponse = useFetchResponse(
    fetchInsightsTableData,
    INSIGHTS_TYPE.INCOME,
    startDate,
    endDate
  )

  const expensesTableResponse = useFetchResponse(
    fetchInsightsTableData,
    INSIGHTS_TYPE.EXPENSES,
    startDate,
    endDate
  )

  const incomeTableData = useMemo(() => {
    if (!incomeTableResponse) return []
    return incomeTableResponse.slice(0, 5).map((row) => ({
      id: row.transaction_category_id,
      categoryName: row.transaction_category_name,
      amountInCents: row.transactions_sum_in_cents,
    }))
  }, [incomeTableResponse])

  const expensesTableData = useMemo(() => {
    if (!expensesTableResponse) return []
    return expensesTableResponse.slice(0, 5).map((row) => ({
      id: row.transaction_category_id,
      categoryName: row.transaction_category_name,
      amountInCents: row.transactions_sum_in_cents,
    }))
  }, [expensesTableResponse])

  const { inputStartDate, inputEndDate } = useMemo(() => {
    return {
      inputStartDate: DateTime.fromFormat(
        startDate,
        DATE_FORMATS_LUXON.YEAR_MONTH_DAY
      ).toFormat(DATE_FORMATS_LUXON.INPUT),
      inputEndDate: DateTime.fromFormat(
        endDate,
        DATE_FORMATS_LUXON.YEAR_MONTH_DAY
      ).toFormat(DATE_FORMATS_LUXON.INPUT),
    }
  }, [startDate, endDate])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={1} tablet={2} mobile={2}>
          <BorderedIcon
            icon={solid('calculator')}
            wrapperColor="black"
            color="white"
            size="1x"
            height={32}
          />
        </Grid.Column>
        <Grid.Column
          computer={5}
          tablet={14}
          mobile={14}
          style={isMobileOrTablet ? { marginBottom: 24 } : undefined}
        >
          <Text as="h2"> Profit & Loss </Text>
          <Text as="bodySm" style={{ padding: '8px 0px 16px 0px' }}>
            Summarizes your income and expenses over time
          </Text>
          <Button
            variant="secondary"
            onClick={() => {
              navigate(
                `/reports/profit-loss?startDate=${inputStartDate}&endDate=${inputEndDate}&from=insights`
              )
              track('view-full-statement', {
                screen: 'practice insights',
              })
            }}
          >
            View Full Statement
          </Button>
        </Grid.Column>
        <Grid.Column
          computer={5}
          tablet={5}
          mobile={16}
          style={isMobileOrTablet ? { marginBottom: 24 } : undefined}
        >
          <CategorySumTable
            header="INCOME"
            tableData={incomeTableData}
            sumColor="accentGreen"
          />
        </Grid.Column>
        <Grid.Column
          computer={5}
          tablet={5}
          mobile={16}
          style={isMobileOrTablet ? { marginBottom: 24 } : undefined}
        >
          <CategorySumTable
            header="EXPENSES"
            tableData={expensesTableData}
            sumColor="yellow"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
