import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SpaceApi, loadEmbed, loadSpace } from '@usersnap/browser'
import { DateTime } from 'luxon'

import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'

const UsersnapContext = createContext<SpaceApi | null>(null)

export const UsersnapProvider = ({ children }: { children?: ReactNode }) => {
  const [usersnapApi, setUsersnapApi] = useState<SpaceApi | null>(null)
  const [usersnapAttached, setUsersnapAttached] = useState(false)
  const [initializingUsersnap, setInitializingUsersnap] = useState(false)
  const currentUser = useReselector(getCurrentUser)

  useEffect(() => {
    setInitializingUsersnap(true)
    const initUsersnap = async () => {
      if (
        !usersnapAttached &&
        currentUser?.id &&
        !currentUser?.admin &&
        process.env.VITE_USERSNAP_GLOBAL_API_KEY
      ) {
        const api = await loadSpace(process.env.VITE_USERSNAP_GLOBAL_API_KEY)
        await api.init({
          user: {
            email: currentUser?.email,
            userId: String(currentUser?.id),
          },
        })
        setUsersnapApi(api)
        setUsersnapAttached(true)
        setInitializingUsersnap(false)
      }
    }
    if (!initializingUsersnap) {
      initUsersnap()
    }
  }, [
    currentUser?.admin,
    currentUser?.email,
    currentUser?.id,
    initializingUsersnap,
    usersnapAttached,
  ])

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  )
}

export const useUsersnapApi = () => {
  return useContext(UsersnapContext)
}

export const UsersnapSurveyEmbed = ({
  projectKey,
}: {
  projectKey?: string
}) => {
  const currentUser = useReselector(getCurrentUser)
  const spanRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (projectKey) {
      loadEmbed(projectKey).then(
        (api) =>
          spanRef.current &&
          api.init({
            mountNode: spanRef.current,
            user: {
              email: currentUser?.email || '',
              userId: String(currentUser?.id),
            },
          })
      )
    }
  }, [currentUser?.email, currentUser?.id, projectKey])

  return <div ref={spanRef} />
}

/* 
  Make sure the display in usersnap is set to audience "Nobody"
  https://github.com/usersnap/public/blob/master/react-typescript/src/examples/CustomDisplayLogic.tsx
  
  Note: `Select how often you would like to show this widget` option seems to be ignored for their 
  recommended the custom display logic approach. We need manage these options ourselves.
 */
export const useUsersnapCustomDisplayLogicWidget = ({
  projectKey,
  shouldShow,
  onSubmit,
  maxTimesShown,
  showAgainAfterDays,
}: {
  projectKey?: string
  shouldShow?: boolean
  // This needs to be sent as a memoized value
  onSubmit?: () => void
  maxTimesShown?: number
  showAgainAfterDays?: number
}) => {
  const usersnapApi = useUsersnapApi()
  const timesShownKey = `usersnap_timesShown_${projectKey}`
  const lastShownKey = `usersnap_lastShown_${projectKey}`

  useEffect(() => {
    // Verify we can show the widget and that we want to show it given the custom show logic
    if (projectKey && usersnapApi && shouldShow) {
      // If maxTimesShown is set, check if the user has seen the widget the max number of times, don't show if they have
      // If showAgainAfterDays is set and there is a value on lastShownKey, check if the user has seen the widget in the last showAgainAfterDays days, don't show if they have
      if (maxTimesShown) {
        const timesShownItem = localStorage.getItem(timesShownKey)
        const timesShown = timesShownItem
          ? Number(JSON.parse(timesShownItem))
          : 1
        if (timesShown >= maxTimesShown) {
          return
        }
      }
      if (showAgainAfterDays) {
        const lastShownItem = localStorage.getItem(lastShownKey)
        if (lastShownItem) {
          if (
            DateTime.fromISO(lastShownItem).plus({ days: showAgainAfterDays }) >
            DateTime.now()
          ) {
            return
          }
        }
      }
      usersnapApi.show(projectKey)
    }
  }, [
    usersnapApi,
    shouldShow,
    projectKey,
    lastShownKey,
    maxTimesShown,
    showAgainAfterDays,
    timesShownKey,
  ])

  useEffect(() => {
    if (!usersnapApi) {
      return undefined
    }
    const handleOpen = () => {
      const timesShownItem = localStorage.getItem(timesShownKey)
      const timesShown = timesShownItem ? Number(JSON.parse(timesShownItem)) : 0

      localStorage.setItem(lastShownKey, DateTime.now().toJSON())
      localStorage.setItem(timesShownKey, JSON.stringify(timesShown + 1))
    }

    usersnapApi.on('open', handleOpen)
    if (onSubmit) {
      usersnapApi.on('submit', onSubmit)
    }
    return () => {
      usersnapApi.off('open', handleOpen)
      if (onSubmit) {
        usersnapApi.off('submit', onSubmit)
      }
    }
  }, [lastShownKey, onSubmit, projectKey, timesShownKey, usersnapApi])
}
