import { ReactNode, useEffect } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Image, Divider } from 'semantic-ui-react'

import {
  Button,
  Text,
  Icon,
  Label,
  GridRowColumn,
  Accordion,
  Card,
} from '../../../components/BaseComponents'
import {
  GEP_COST_PER_YEAR,
  GEP_PER_PERSON_PRICE,
  GEP_SUB_PRICE,
} from '../helpers'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { useNavigateWithLocation } from '../../../utils/routeHelpers'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import { selectSubscriptionIncludesFreePayroll } from '../../../reducers/subscription.slice'
import { selectIsCurrentUserScorp } from '../../../selectors/user.selectors'

const GreenCheckRow = ({
  content,
  short,
}: {
  content: ReactNode
  short?: boolean
}) => (
  <Grid.Row className={short ? 'short' : ''}>
    <Grid.Column width={1}>
      <Icon icon={light('check')} color="green" />
    </Grid.Column>
    <Grid.Column width={14}>{content}</Grid.Column>
  </Grid.Row>
)

export const PayrollIntroCard = ({
  showStartButton = false,
}: {
  showStartButton?: boolean
}) => {
  const navigate = useNavigateWithLocation()
  const track = useAnalyticsTrack()
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )

  return (
    <>
      <Card backgroundColor="lightYellow" type="subsection">
        {hasFreePayrollWithSubscription && (
          <Label
            color="green"
            style={{ display: 'flex', gap: 8, marginBottom: 10 }}
          >
            <Icon icon={light('star')} color="green" />
            Member Benefit
          </Label>
        )}

        <Text as="h2">Heard Payroll</Text>
        <Text as="h3" color="darkGray" style={{ marginTop: 5 }}>
          Compliance made easy with streamlined features
        </Text>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/checkbooks.svg"
          style={{
            width: 'auto',
            height: 162,
            backgroundColor: Colors.transparent,
          }}
        />
        {showStartButton && (
          <Button
            onClick={() => {
              track('clicked payroll upsell welcome page button')
              navigate('/payroll/interest')
            }}
          >
            Get Started
          </Button>
        )}
        <br />
        <div style={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
          <Text as="h3" color="darkGray">
            Powered by
          </Text>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/gusto_logo.svg"
            style={{ width: 'auto', height: 20 }}
          />
        </div>
      </Card>
      {!hasFreePayrollWithSubscription && (
        <>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Text as="display" style={{ display: 'inline' }}>
              ${GEP_SUB_PRICE}
            </Text>
            <Text as="bodyLg" style={{ display: 'inline' }}>
              /month
            </Text>
          </div>
          <Text as="bodyXs" textAlign="center">
            Plus ${GEP_PER_PERSON_PRICE} per employee / month
          </Text>
          <br />
          <Text textAlign="center" as="bodyXs" color="darkGray">
            If you migrate your Gusto payroll, you’ll only be charged by Heard
            going forward.
          </Text>
        </>
      )}
    </>
  )
}

const LearnMoreFAQs = () => {
  const isScorp = useReselector(selectIsCurrentUserScorp)

  return (
    <Grid>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="Why do I need payroll?"
          content={
            isScorp ? (
              <Text as="bodySm">
                The IRS requires S corporation owners to receive a
                &apos;reasonable salary&apos; through payroll rather than taking
                distributions only. Using payroll ensures you correctly
                calculate and withhold payroll taxes, keeping your business in
                compliance.
              </Text>
            ) : (
              <Text>
                Group practice owners generally need to manage payroll if they
                have any employees on staff as W-2 workers, which means they are
                required to withhold taxes and pay their employees a salary or
                hourly wage.
                <br />
                <br />
                If you only utilize independent contractors (1099s), you may not
                need to handle payroll for those individuals as they are
                responsible for their own taxes.
              </Text>
            )
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="What features are included in Heard payroll?"
          content={
            <Grid>
              <GridRowColumn>
                <Text as="bodySm">
                  Heard Payroll is setup to be a simple, straightforward
                  solution for{' '}
                  {isScorp ? 'S corporation therapists' : 'group practices'}. We
                  provide exactly what you need to stay compliant:
                </Text>
              </GridRowColumn>
              <GreenCheckRow
                short
                content={
                  <Text as="bodySm">Automatic payroll on a set schedule</Text>
                }
              />
              <GreenCheckRow
                short
                content={<Text as="bodySm">Direct deposit of funds</Text>}
              />
              <GreenCheckRow
                short
                content={
                  <Text as="bodySm">
                    Calculation and withholding of state and federal payroll
                    taxes
                  </Text>
                }
              />
              <GreenCheckRow
                short
                content={
                  <Text as="bodySm">
                    Payroll documents automatically imported into Heard
                  </Text>
                }
              />
              <GreenCheckRow
                short
                content={<Text as="bodySm">Payroll history</Text>}
              />
              <GridRowColumn>
                <Text as="bodySm">
                  If you have other employees, you can also:
                </Text>
              </GridRowColumn>
              <GreenCheckRow
                short
                content={
                  <Text as="bodySm">Pay contractors and hourly employees</Text>
                }
              />
              <GreenCheckRow
                short
                content={<Text as="bodySm">Provide workers’ compensation</Text>}
              />
              <GreenCheckRow
                short
                content={
                  <Text as="bodySm">
                    Flexible means for single-rate compensation (e.g. overtime,
                    bonuses, reimbursements)
                  </Text>
                }
              />
            </Grid>
          }
        />
      </GridRowColumn>
      <GridRowColumn>
        <Accordion
          variant="text"
          title="Can I access more advanced payroll features if needed?"
          content={
            <Text as="bodySm">
              At this time, Heard Payroll is setup to be a simple,
              straightforward solution for self-employed therapists. We provide
              what you need to stay compliant, so not all features are
              supported. The following features are not currently supported:
              <ul style={{ paddingLeft: 25 }}>
                <li>Gusto Premium or Contractor Only plans</li>
                <li>Gusto-sponsored or employee benefits</li>
                <li>Time tracking</li>
                <li>100% commission-based payments</li>
                <li>Multiple pay rates for a teammate</li>
              </ul>
            </Text>
          }
        />
      </GridRowColumn>
    </Grid>
  )
}

const whyAddPayroll = [
  {
    title: 'Keep it simple',
    description:
      'Our streamlined set of features means you have exactly what you need to stay compliant.',
  },
  {
    title: 'All-in-one place management',
    description: (
      <Text as="bodySm">
        Reduce the hassle of juggling multiple tools by managing your
        bookkeeping, taxes, and payroll all in one place.
      </Text>
    ),
  },
  {
    title: 'Simplifies tax forms',
    description:
      'Easily generate and store W-2 and 1099 forms for employees and contractors come tax time.',
  },
  {
    title: 'Payroll taxes filed for you',
    description:
      'Gusto files payroll taxes for you every time you run payroll.',
  },
  {
    title: 'Hands-on support and advice',
    description: 'Our payroll team can provide ongoing analysis.',
  },
]

const RightSideContent = () => {
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )

  return (
    <>
      <Text as="h2">Why setup payroll with Heard?</Text>
      <br />
      {hasFreePayrollWithSubscription ? (
        <>
          <Text>
            Pay yourself (and your team) and maintain compliance with our
            streamlined payroll solution. Manage your payroll seamlessly as part
            of your Heard membership (${GEP_COST_PER_YEAR} value).
          </Text>
          <br />
          <Text color="darkGray">
            Already using Gusto? Switch to Heard and save ${GEP_COST_PER_YEAR}
            /year. Additional employees cost ${GEP_PER_PERSON_PRICE}/individual
            per month.
          </Text>
        </>
      ) : (
        <Text>
          Pay your team and maintain compliance with our streamlined payroll
          solution. Manage your payroll seamlessly all in Heard.
        </Text>
      )}
      <br />
      <Grid>
        {whyAddPayroll.map(({ title, description }) => (
          <GreenCheckRow
            key={title}
            content={
              <>
                <Text as="h3" style={{ marginBottom: 5 }}>
                  {title}
                </Text>
                <Text as="bodySm">{description}</Text>
              </>
            }
          />
        ))}
      </Grid>
      <br />
      <Divider />
      <br />
      <Text as="h2">Learn more</Text>
      <br />
      <LearnMoreFAQs />
    </>
  )
}

const WelcomeToPayroll = () => {
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('payroll upsell welcome')
  }, [pageView])

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column
          computer={6}
          tablet={6}
          mobile={16}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PayrollIntroCard showStartButton />
        </Grid.Column>
        <Grid.Column computer={1} tablet={1} mobile={1} />
        <Grid.Column computer={9} tablet={9} mobile={16}>
          <RightSideContent />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default WelcomeToPayroll
