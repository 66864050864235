import { DateTime } from 'luxon'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { updateUserOnboardingStep } from '../../../Onboarding/UserOnboardingSteps/onboarding.actions'
import {
  GettingStartedOnboardingStepId,
  GettingStartedOnboardingStep,
  GettingStartedUserOnboardingStepExpanded,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { selectGettingStartedUserOnboardingStepsWithGroups } from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import OnboardingItemRow, { OnboardingItemRowProps } from './OnboardingItemRow'
import {
  AutomaticStatementUploadModal,
  GroupOnboardingModal,
  ViewMissingStatementsAccordionForCatchupBookkeeping,
} from './OnboardingItemCTAContent'
import { useHeardPlaid } from '../../../../components/shared/plaid/helpers'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getAnnualTaxFilingForYearSelector } from '../../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import { Pill, Text } from '../../../../components/BaseComponents'
import {
  getFinancialProfile,
  selectMembershipStart,
} from '../../../../selectors/user.selectors'
import { getTskDueDateForUser } from '../../../Taxes/AnnualTaxes/TaxSeasonKickoff/utils'

type OnboardingItemBaseContent = Pick<
  OnboardingItemRowProps,
  | 'header'
  | 'description'
  | 'imageUrl'
  | 'timer'
  | 'skippable'
  | 'additionalDetails'
  | 'onActionClick'
  | 'headerExtra'
>

const defaultTimer = {
  unit: 'minutes',
  value: 5,
}

export const useGettingStartedOnboardingSteps = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const gettingStartedSteps = useReselector(
    selectGettingStartedUserOnboardingStepsWithGroups
  )
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const membershipStartDate = useReselector(selectMembershipStart)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const financialProfile = useReselector(getFinancialProfile)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const tskDueDateForUser = getTskDueDateForUser({
    membershipStartDate,
    taxEntityType: financialProfile?.taxEntityType,
    eoySurveyStartDate: annualTaxDetails?.eoy_survey_start_date,
    newUserCutOffAt: annualTaxDetails?.new_user_cutoff_date,
    form1120STqDueDate: annualTaxDetails?.form_1120_s_tq_due_date,
    taxSeasonKickoffDueAt: annualTaxDetails?.taxSeasonKickoffDueAt,
    form1040TqDueDate: annualTaxDetails?.form_1040_tq_due_date,
  })

  const ten99DueAt = annualTaxDetails?.ten99NECFormsDueAt

  const shouldWarnTskNeedsCompletion = annualTaxDetails?.new_user_cutoff_date
    ? DateTime.now() >
      DateTime.fromISO(annualTaxDetails.new_user_cutoff_date).endOf('day')
    : false

  const isLateJoiner = annualTaxFiling?.isLateJoiner

  // Item specific state management
  const [groupOnboardingModalOpen, setGroupOnboardingModalOpen] =
    useState(false)
  const [
    automaticStatementUploadModalOpen,
    setAutomaticStatementUploadModalOpen,
  ] = useState(false)

  const { open: openPlaidModal } = useHeardPlaid({
    redirectLink: '',
  })

  const gettingStartedContentMapping: Record<
    GettingStartedOnboardingStepId,
    OnboardingItemBaseContent
  > = {
    [GettingStartedOnboardingStep.joinGroupOnboardingSession]: {
      header: 'Join a group onboarding session',
      description:
        'Our Onboarding Team will walk you through key features in Heard.',
      imageUrl: 'https://heard-images.s3.amazonaws.com/assets/laptop-video.svg',
      timer: defaultTimer,
      skippable: false,
      additionalDetails: (
        <GroupOnboardingModal
          open={groupOnboardingModalOpen}
          setOpen={setGroupOnboardingModalOpen}
        />
      ),
      onActionClick: () => {
        setGroupOnboardingModalOpen(true)
      },
    },
    [GettingStartedOnboardingStep.uploadBankStatements]: {
      header: 'Upload missing bank statements',
      description:
        'Help us complete your books by uploading your bank statements for catch-up bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/statement-upload-manual.svg',
      timer: defaultTimer,
      skippable: false,
      additionalDetails: (
        <ViewMissingStatementsAccordionForCatchupBookkeeping />
      ),
      onActionClick: () => {
        navigate('/practice/documents?tab=bank', {
          state: {
            initiatedBy: GettingStartedOnboardingStep.uploadBankStatements,
          },
        })
      },
    },
    [GettingStartedOnboardingStep.setupAutomaticStatementUpload]: {
      header: 'Set up automatic statement uploads',
      description:
        'Save time with automatic statement uploads—if there&apos;s a bookkeeping issue, we can handle it automatically. Available for select financial institutions.',
      imageUrl: 'https://heard-images.s3.amazonaws.com/assets/rocket.svg',
      timer: defaultTimer,
      skippable: true,
      additionalDetails: (
        <AutomaticStatementUploadModal
          open={automaticStatementUploadModalOpen}
          setOpen={setAutomaticStatementUploadModalOpen}
        />
      ),
      onActionClick: (stepId) => {
        setAutomaticStatementUploadModalOpen(true)
        if (stepId) {
          dispatch(
            updateUserOnboardingStep({
              id: stepId,
              updatableFields: { completedAt: DateTime.now().toISO() },
            })
          )
        }
      },
    },
    [GettingStartedOnboardingStep.clarifyUncategorizedTransactions]: {
      header: 'Clarify unknown transactions',
      description:
        'We weren&apos;t sure how to categorize some of your transactions. Please clarify so we can complete your catch-up bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/review-transactions.svg',
      timer: defaultTimer,
      skippable: false,
      onActionClick: () => {
        navigate('/transactions/review')
      },
    },
    [GettingStartedOnboardingStep.connectBusinessBankAccount]: {
      header: 'Connect business bank account',
      description:
        'Please connect your primary business bank account. We use this to sync your transactions and start your bookkeeping.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/bank-with-shadow.svg',
      timer: defaultTimer,
      skippable: false,
      onActionClick: () => {
        openPlaidModal()
      },
    },
    [GettingStartedOnboardingStep.yearEndWrapUpSurvey]: {
      header: 'Complete Tax Season Kickoff',
      description: (
        <>
          Let us know if you plan to file annual taxes with Heard in the Tax
          Season Kickoff.
          <br />
          <Text color="orange" as="bodySm">
            {shouldWarnTskNeedsCompletion
              ? `Important: If this isn't completed by ${tskDueDateForUser?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}, we will assume you ${isLateJoiner ? 'no longer' : "don't"} need help with your FY${currentTaxYear.toString().slice(-2)} taxes or filing 1099-NECs.`
              : `Important: If this isn't completed by ${tskDueDateForUser?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}, we'll require that you file an extension request.`}
          </Text>
        </>
      ),
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/end_of_year_cal.svg',
      timer: { unit: 'minutes', value: 10 },
      skippable: false,
      onActionClick: () => navigate('/taxes/annual/kickoff-survey'),
      headerExtra: (
        <Pill color="orange">
          Due {tskDueDateForUser?.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}
        </Pill>
      ),
    },
    [GettingStartedOnboardingStep.complete_1099_survey]: {
      header: 'Complete 1099 Kickoff',
      description: (
        <>
          Let us know if you paid a contractor $600 or more in {currentTaxYear},
          and if so, we can help you file your 1099-NEC, as long as you provide
          us your contractor&apos;s information by{' '}
          {ten99DueAt &&
            convertUtcToLocalDate(ten99DueAt)?.toFormat(
              DATE_FORMATS_LUXON.MONTH_DAY
            )}
          .
          <br />
        </>
      ),
      imageUrl: 'https://heard-images.s3.amazonaws.com/assets/form-fill.svg',
      timer: { unit: 'minutes', value: 10 },
      skippable: true,
      onActionClick: () =>
        navigate('/taxes/annual/file_1099_nec/verify_business'),
      headerExtra: (
        <Pill color="orange">
          Due{' '}
          {ten99DueAt &&
            convertUtcToLocalDate(ten99DueAt)?.toFormat(
              DATE_FORMATS_LUXON.MONTH_DAY
            )}
        </Pill>
      ),
    },
    [GettingStartedOnboardingStep.payrollInterest]: {
      header: 'Get started with payroll',
      description:
        'S Corporation owners are required to pay themselves a reasonable salary via payroll. Share your current status with us, and learn about payroll with Heard.',
      imageUrl:
        'https://heard-images.s3.amazonaws.com/assets/check_book_green.svg',
      timer: defaultTimer,
      skippable: false,
      onActionClick: () => navigate('/payroll/interest?screen=intro'),
    },
  }

  const getContentForStep = (
    step: GettingStartedUserOnboardingStepExpanded
  ): OnboardingItemRowProps => {
    const stepIdentifier = step.onboardingStep.identifier
    const content = gettingStartedContentMapping[stepIdentifier]
    const userOnboardingStepId = step.id

    const userInteractionFields = {
      started: Boolean(step.startedAt),
      completed: Boolean(step.completedAt),
      skipped: Boolean(step.skippedAt),
    }

    const onSkipClick = () => {
      if (!step.skippedAt) {
        dispatch(
          updateUserOnboardingStep({
            id: step.id,
            updatableFields: { skippedAt: DateTime.now().toISO() },
          })
        )
      }
    }
    const onActionClick = (stepId?: number) => {
      if (!step.startedAt) {
        dispatch(
          updateUserOnboardingStep({
            id: step.id,
            updatableFields: { startedAt: DateTime.now().toISO() },
          })
        )
      }
      content.onActionClick(stepId)
    }
    if (content.skippable) {
      return {
        ...content,
        ...userInteractionFields,
        onActionClick,
        onSkipClick,
        stepId: userOnboardingStepId,
      }
    }
    return {
      ...content,
      ...userInteractionFields,
      onActionClick,
      stepId: userOnboardingStepId,
    }
  }

  // Map of step identifier to content
  const gettingStartedStepsContent = gettingStartedSteps.reduce(
    (acc, step) => ({
      ...acc,
      [step.onboardingStep.identifier]: (
        <OnboardingItemRow {...getContentForStep(step)} />
      ),
    }),
    {} as Record<GettingStartedOnboardingStepId, ReactNode>
  )
  return gettingStartedStepsContent
}
