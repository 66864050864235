import { useCallback } from 'react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { track } from '@amplitude/analytics-browser'
import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useReselector } from '../../../utils/sharedHooks'
import { DateTime } from 'luxon'
import { selectMembershipStart } from '../../../selectors/user.selectors'

/**
 *  This is a TEMPORARY onboarding survey to collect feedback on the onboarding experience.
 *
 *  It will be removed after the survey is no longer needed.
 *
 * Survey will run: March 12 - April 12, 2025
 *
 * This is:
 * Survey #3: Post Get Started: Setup flow in Platform Completion
 * https://www.notion.so/heard/Make-Onboarding-Fast-Easy-18f591be52578004a6ffd06df62b8f5c?pvs=4#1ae591be5257805f873ce3010aec2a88
 */
const OnboardingDashboardSurvey = () => {
  const onSubmit = useCallback(() => {
    track('completed onboarding dashboard survey: survey #3')
  }, [])
  const showObSurvey = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.showOnboardingSurveys,
    false
  )

  const membershipStartDate = useReselector(selectMembershipStart)
  // Define the cutoff date
  const cutoffDate = DateTime.fromISO('2025-03-12')
  const shouldShowSurvey =
    showObSurvey &&
    membershipStartDate &&
    DateTime.fromISO(membershipStartDate) >= cutoffDate

  useUsersnapCustomDisplayLogicWidget({
    projectKey: 'ad77c48d-6075-4fdb-87db-68bed7ac2252',
    shouldShow: shouldShowSurvey || false,
    maxTimesShown: 3,
    showAgainAfterDays: 0,
    onSubmit,
  })
  return null
}

export default OnboardingDashboardSurvey
