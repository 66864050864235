import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  TooltipProps,
} from 'recharts'

import { Colors } from '../../../styles/theme'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

type PayloadData = {
  name: NameType
  value: ValueType
}

type CustomTooltipProps = TooltipProps<ValueType, NameType> & {
  active?: boolean
  payload?: PayloadData[]
  label?: string
}
const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  const formatTooltip = (value: number, name: string) => {
    const displayValue = name === 'Expenses' ? Math.abs(value) : value
    const formattedValue = formatCurrencyFromCents(displayValue)
    return [formattedValue, name]
  }

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: Colors.white,
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '4px',
          fontSize: '14px',
        }}
      >
        <p style={{ color: Colors.black, margin: 0, fontWeight: 'bold' }}>
          {label}
        </p>
        {payload.map((entry, index) => {
          if (typeof entry.name !== 'string') {
            return (
              <p
                key={index}
                style={{
                  color: Colors.black,
                  margin: '5px 0',
                }}
              >
                Unknown: N/A
              </p>
            )
          }
          const color =
            entry.name === 'Income'
              ? Colors.accentGreen
              : entry.name === 'Expenses'
                ? Colors.yellow
                : Colors.black
          if (typeof entry.value !== 'number') {
            return (
              <p
                key={index}
                style={{
                  color,
                  margin: '5px 0',
                }}
              >
                {`${entry.name}: N/A`}
              </p>
            )
          }
          return (
            <p
              key={index}
              style={{
                color,
                margin: '5px 0',
              }}
            >
              {`${entry.name}: ${formatTooltip(entry.value, entry.name)[0]}`}
            </p>
          )
        })}
      </div>
    )
  }
  return null
}

const InsightsTrendGraph = ({
  data,
}: {
  data: {
    [key: string]: string | number | undefined
  }[]
}) => {
  const formatYAxis = (value: number) => {
    return formatCurrencyFromCents(value, { hideDecimalsIfZero: true })
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data} stackOffset="sign">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          dataKey="total_income"
          name="Income"
          fill={Colors.greenBlue}
          stackId="a"
          barSize={30}
        />
        <Bar
          dataKey="total_expenses"
          name="Expenses"
          fill={Colors.yellowOrange}
          stackId="a"
          barSize={30}
        />
        <Line
          type="linear"
          dataKey="profit"
          name="Profit"
          stroke="#656A47"
          strokeWidth={3}
          dot={false}
        />
        <ReferenceLine y={0} stroke={Colors.black} strokeWidth={1} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default InsightsTrendGraph
