import { LandingPage } from '../../../../components/LandingPage'
import Text from '../../../../components/BaseComponents/Text'
import './styles.scss'
import Button from '../../../../components/BaseComponents/Button'
import { useNavigate } from 'react-router-dom'
import { CircularWrapper } from '../../../../components/BaseComponents'

export const AllDone = () => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/taxes/annual')
  }

  const steps = [
    {
      title: 'Address follow-ups from bookkeeper',
      description:
        'Provide the information and documents we need to complete your bookkeeping.',
    },
    {
      title: 'Review income and expenses',
      description:
        "You'll have the opportunity to review your bookkeeping. If something needs correcting, send your books back for revision.",
    },
    {
      title: 'Sign off and finalize your books',
      description: (
        <>
          If everything is satisfactory, submit your sign-off to finalize your
          books for taxes.{' '}
          <span style={{ fontWeight: 'bold' }}>
            This is required to continue with the annual tax process.
          </span>
        </>
      ),
    },
  ]

  return (
    <LandingPage
      imageSrc="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-book.svg"
      imageAlt="magnifyingGlassBook"
      imageWidth={300}
      imageHeight={300}
      title="Almost done..."
    >
      <div className="text-wrapper">
        <Text as="bodyMd" style={{ textAlign: 'center', marginBottom: '24px' }}>
          Thanks! We&apos;ll review your submission within 5 business days and
          email you if we need you to follow-up.
        </Text>

        <Text as="h2" style={{ marginBottom: '24px' }}>
          Your next steps
        </Text>

        <div className="numbered-steps">
          {steps.map((step, index) => (
            <div className="step" key={step.title}>
              <CircularWrapper
                className="step-number"
                height={48}
                wrapperColor="moss"
              >
                <Text as="h3">{index + 1}</Text>
              </CircularWrapper>
              <div className="step-content">
                <Text as="h3">{step.title}</Text>
                <Text
                  as="bodySm"
                  style={{
                    lineHeight: '148%',
                  }}
                >
                  {step.description}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="center-button">
        <Button
          className="return-end-of-year"
          variant="secondary"
          size="medium"
          onClick={handleBackClick}
        >
          Return to Year End
        </Button>
      </div>
    </LandingPage>
  )
}
