import { Grid } from 'semantic-ui-react'

import {
  ActiveQuarterTaxSummary,
  LastQuarterTaxSummary,
} from './SingleQuarterTaxSummary'
import {
  selectActiveQTEDetailsAsDates,
  selectIsSameOrAfterChecklistLaunchDate,
} from '../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import QTETimeline from './QTETimeline'
import QTEFAQs from './QTEFAQs'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  getGroupedActiveTaxEstimatesWithinActiveQuarter,
  selectUserCreatedPastQteCutoff,
} from '../userTaxEstimates.selector'
import QTEChecklist from './QTEChecklist'
import { useMemo, useState } from 'react'
import { Button, Link, Text } from '../../../../components/BaseComponents'
import { UserTaxCalculationStatuses } from '../../../Admin/UserTaxCalculations/userTaxCalculation.slice'
import BreakdownBanner from './BreakdownBanner'
import {
  selectCalculationHomeState,
  selectUserTaxCalculationWithinActiveQuarter,
} from '../../../../selectors/userTaxCalculation.selectors'
import UpdateQuarterlyTaxEstimateModal from './UpdateQuarterlyTaxEstimateModal'
import UserJoinedTooLate from './CalculateSafeHarborFlow/UserJoinedTooLate'
import { isFreeTrialPromoCode } from '../../../../selectors/user.selectors'
import { BannerCard } from '../../../../components/BaseComponents/BannerCard'
import { useNavigate } from 'react-router-dom'
import { selectIsBasicPlanWithoutQTE } from '../../../../reducers/subscription.slice'
import { BasicPlanQTEUpsell } from '../BasicPlanUpsell'

const ActiveQuarterTab = () => {
  const navigate = useNavigate()
  const activeQuarterDetails = useReselector(selectActiveQTEDetailsAsDates)

  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const isBasicPlanWithoutQTE = useReselector(selectIsBasicPlanWithoutQTE)

  const activeQuarterText = `Q${activeQuarterDetails?.taxQuarter} ${activeQuarterDetails?.taxYear}`

  const [estimateModalOpen, setEstimateModalOpen] = useState(false)

  const userJoinedTooLate = useReselector(selectUserCreatedPastQteCutoff)

  const calculation = useReselector(selectUserTaxCalculationWithinActiveQuarter)

  const homeState = useReselector(selectCalculationHomeState, calculation?.id)

  const { federalTaxEstimate } = useReselector(
    getGroupedActiveTaxEstimatesWithinActiveQuarter,
    homeState
  )

  const estimatesReleased =
    calculation?.status === UserTaxCalculationStatuses.savedAndNotified

  const afterChecklistLaunchDate = useReselector(
    selectIsSameOrAfterChecklistLaunchDate
  )

  const mainContent = useMemo(() => {
    if (isBasicPlanWithoutQTE) {
      return <BasicPlanQTEUpsell />
    }
    if (hasFreeTrialPromoCode) {
      return (
        <>
          <BannerCard
            headerText="Preview of the Quarterly Tax Estimate Experience"
            imageUrl="https://heard-images.s3.amazonaws.com/assets/coins-stack.svg"
            bodyContent={
              <>
                <Text as="bodyMd">
                  Every quarter, we calculate your estimated federal and state
                  payments and even remind you when to pay so you never miss a
                  deadline.
                </Text>
                <br />
                <Text as="bodyMd">
                  We have shown an example of this process in the section below
                  — note that you will not be able to interact with it.
                </Text>
                <br />
                <Link
                  newPage
                  href="https://support.joinheard.com/hc/en-us/articles/4416208116247"
                  as="secondaryLink"
                >
                  Learn how Heard prepares your Quarterly Tax Estimates
                </Link>
                <br />
                <br />
                <Button
                  variant="primary"
                  onClick={() => navigate('/accounts/choose-plan')}
                >
                  Need help with Taxes? Sign up with Heard
                </Button>
              </>
            }
          />
          <div style={{ opacity: '0.5', pointerEvents: 'none' }}>
            <QTEChecklist />
          </div>
        </>
      )
    }
    if (userJoinedTooLate) {
      return (
        <UserJoinedTooLate
          activeQuarterText={activeQuarterText}
          federalTaxEstimate={federalTaxEstimate}
        />
      )
    }
    if (!afterChecklistLaunchDate) {
      return <LastQuarterTaxSummary />
    }
    if (!estimatesReleased || calculation?.needsRecalculation) {
      return <QTEChecklist />
    }
    return <ActiveQuarterTaxSummary quarterText={activeQuarterText} />
  }, [
    userJoinedTooLate,
    afterChecklistLaunchDate,
    estimatesReleased,
    calculation?.needsRecalculation,
    activeQuarterText,
    federalTaxEstimate,
    hasFreeTrialPromoCode,
    isBasicPlanWithoutQTE,
    navigate,
  ])

  return (
    <Grid stackable>
      <BreakdownBanner />
      <Grid.Row columns={2}>
        <Grid.Column width={11}>{mainContent}</Grid.Column>
        <Grid.Column width={5}>
          {!userJoinedTooLate && <QTETimeline />}
          <QTEFAQs />
        </Grid.Column>
      </Grid.Row>
      {federalTaxEstimate && (
        <UpdateQuarterlyTaxEstimateModal
          open={estimateModalOpen}
          close={() => setEstimateModalOpen(false)}
          estimate={federalTaxEstimate}
        />
      )}
    </Grid>
  )
}
export default ActiveQuarterTab
