import { Grid } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Icon,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { PayrollIntroCard } from './WelcomeToPayroll'
import { useReselector } from '../../../utils/sharedHooks'
import { selectSubscriptionIncludesFreePayroll } from '../../../reducers/subscription.slice'

const GreenCheckRow = ({ text }: { text: string }) => (
  <Grid.Row className="short">
    <Grid.Column width={1}>
      <Icon icon={light('check')} color="green" />
    </Grid.Column>
    <Grid.Column width={14}>{text}</Grid.Column>
  </Grid.Row>
)

export const LearnMorePayrollModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )

  return (
    <Modal open={open} closeIcon onClose={onClose}>
      <Modal.Header>Why setup payroll with Heard?</Modal.Header>
      <Modal.Content>
        <div style={{ display: 'flex', gap: 32 }}>
          <div style={{ width: 228 }}>
            <PayrollIntroCard />
            <br />
            {!hasFreePayrollWithSubscription && (
              <Text as="bodyXs" color="darkGray">
                If you migrate your Gusto payroll, you’ll only be charged by
                Heard going forward.
              </Text>
            )}
          </div>
          <Grid style={{ height: 'fit-content', marginTop: 0, flex: 1 }}>
            <GridRowColumn short>
              <Text>We provide what you need to stay compliant:</Text>
            </GridRowColumn>
            <GreenCheckRow text="Automatic payroll on a set schedule" />
            <GreenCheckRow text="Direct deposit of funds" />
            <GreenCheckRow text="Calculation and withholding of state and federal payroll taxes" />
            <GreenCheckRow text="Payroll documents automatically imported into Heard" />
            <GreenCheckRow text="Payroll history" />
            <GridRowColumn short>
              <Text>If you have other employees, you can also:</Text>
            </GridRowColumn>
            <GreenCheckRow text="Pay contractors and hourly employees" />
            <GreenCheckRow text="Provide workers’ compensation" />
            <GreenCheckRow text="Compensate employees the way they need (e.g. overtime, bonuses, reimbursements)" />
            <GridRowColumn short>
              <Text>
                Not currently supported:{' '}
                <ul style={{ paddingLeft: 25 }}>
                  <li>Gusto Premium or Contractor Only plans</li>
                  <li>Gusto-sponsored or employee benefits</li>
                  <li>Time tracking</li>
                  <li>100% commission-based payments</li>
                  <li>Multiple pay rates for a teammate</li>
                </ul>
              </Text>
            </GridRowColumn>
          </Grid>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default LearnMorePayrollModal
