import { createSelector } from 'reselect'

import { ReduxState } from '../../utils/typeHelpers'
import { TransactionCategory, TransactionAccountType } from './reports.slice'
import { keyBy } from 'lodash'

export const getAllTransactionCategories = (state: ReduxState) =>
  state.transactionCategories

export const getTransactionCategoryById = createSelector(
  [
    getAllTransactionCategories,
    (_: unknown, categoryId?: number | null) => categoryId,
  ],
  (transactionCategories, categoryId) =>
    categoryId ? transactionCategories[categoryId] : null
)

export const selectCategoriesByType = createSelector(
  getAllTransactionCategories,
  (_: unknown, accountType: TransactionAccountType) => accountType,
  (transactionCategories, accountType) => {
    return keyBy(
      Object.keys(transactionCategories)
        .filter((key) => {
          const category = transactionCategories[key]
          return [accountType].includes(category.accountType)
        })
        .map((key) => transactionCategories[key]),
      'id'
    )
  }
)

export const selectNonExpenseAndIncomeCategories = createSelector(
  getAllTransactionCategories,
  (transactionCategories) => {
    return keyBy(
      Object.keys(transactionCategories)
        .filter((key) => {
          const category = transactionCategories[key]
          return !['Expenses', 'Income'].includes(category.accountType)
        })
        .map((key) => transactionCategories[key]),
      'id'
    )
  }
)

export const selectCategoryByIdentifier = createSelector(
  getAllTransactionCategories,
  (_: unknown, identifier?: string) => identifier,
  (transactionCategories, identifier) => {
    return Object.keys(transactionCategories)
      .filter((key) => {
        const category = transactionCategories[key]
        return category.identifier === identifier
      })
      .map((key) => transactionCategories[key])[0]
  }
)

export const selectNonArchivedTransactionCategories = createSelector(
  getAllTransactionCategories,
  (transactionCategories) => {
    return Object.values(transactionCategories).filter(
      (category) => !category.archivedAt
    )
  }
)

export const mapTransactionCategories = (
  transactionCategories: {
    [key: string]: TransactionCategory
  },
  disableArchived = false
) =>
  Object.keys(transactionCategories).map((key) => {
    const category = transactionCategories[key]
    return {
      value: category.id,
      text: category.name,
      key: category.id,
      disabled: disableArchived ? Boolean(category.archivedAt) : false,
      label: { className: category.isTaxCompliant ? 'highlight' : '' },
    }
  })

export const selectTransactionCategoriesAsDropdownOptions = createSelector(
  selectNonArchivedTransactionCategories,
  (transactionCategories) => {
    return transactionCategories.map((category) => ({
      value: category.id,
      text: category.name,
      key: category.id,
      disabled: Boolean(category.archivedAt),
      label: { className: category.isTaxCompliant ? 'highlight' : '' },
    }))
  }
)

export const getTransactionCategorySelector = createSelector(
  [
    getAllTransactionCategories,
    (_: unknown, transactionCategoryId: string | number | null | undefined) =>
      transactionCategoryId,
  ],
  (allTransactionCategories, transactionCategoryId) => {
    if (!transactionCategoryId) {
      return null
    }

    return allTransactionCategories[transactionCategoryId]
  }
)

export const getTransactionCategoryNameSelector = createSelector(
  getTransactionCategorySelector,
  (category) => category?.name || 'Uncategorized'
)

export const getTransactionCategoryOptions = createSelector(
  getAllTransactionCategories,
  (_: unknown, transactionType?: 'expenses' | 'income') => transactionType,
  (_: unknown, __?: unknown, includeUncat?: boolean) => includeUncat,
  (categories, transactionType, includeUncat) => {
    let categoryList = Object.values(categories)

    if (transactionType === 'expenses') {
      categoryList = categoryList.filter((category) =>
        ['Expenses'].includes(category.accountType)
      )
    } else if (transactionType === 'income') {
      categoryList = categoryList.filter((category) =>
        ['Income'].includes(category.accountType)
      )
    }

    const options = categoryList.map((category) => ({
      value: category.id,
      text: category.name,
    }))

    if (includeUncat) {
      options.unshift({ value: -1, text: 'Unselected' })
    }

    return options
  }
)
