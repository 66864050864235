import { isNil, orderBy } from 'lodash'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react'
import { adminFetchSingleUser } from '../../../../actions/admin/adminAllUsersActions'
import {
  AdminBalanceSheetRequestPayload,
  BalanceSheetReportData,
  BalanceSheetDropdownData,
  fetchAdminBalanceSheetDropdownOptions,
  generateAdminBalanceSheet,
} from '../../../../actions/reportActions'
import {
  getUserById,
  selectPrimaryMembershipForUser,
} from '../../../../selectors/user.selectors'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import BalanceSheetBreadcrumbs from './components/BalanceSheetBreadcrumbs'
import BalanceSheetReport from './components/BalanceSheetReport'
import {
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import PdfWrapper, {
  PdfWrapperHandle,
} from '../../../../components/shared/PdfWrapper'
import { Text } from '../../../../components/BaseComponents'
import { DateTime } from 'luxon'
import { useReselector } from '../../../../utils/sharedHooks'
import NeedsManualBalanceSheetLabel from './ManualBalanceSheetLabel'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const AdminBalanceSheetPanel: React.FC = () => {
  const dispatch = useAppDispatch()
  const params = useParams<{ userId: string }>()
  const user = useReselector(getUserById, Number(params.userId))
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const [dropdownLoading, setDropdownLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [dropdownData, setDropdownData] = useState<
    BalanceSheetDropdownData | undefined
  >()
  const [dropdownSelectedIndex, setDropdownSelectedIndex] = useState<
    number | undefined
  >()
  const [reportData, setReportData] = useState<
    BalanceSheetReportData | undefined
  >()
  const pdfRef = useRef<PdfWrapperHandle>(null)
  const membership = useReselector(selectPrimaryMembershipForUser, user?.id)

  // Invoked upon load, bootstraps data
  useEffect(() => {
    async function performAsync(userId: number) {
      setDropdownLoading(true)
      const dropdownResponse =
        await fetchAdminBalanceSheetDropdownOptions(userId)
      if (dropdownResponse.error) {
        setError(dropdownResponse.error)
      } else if (dropdownResponse.data) {
        setDropdownData(dropdownResponse.data)
      }
      setDropdownLoading(false)
    }
    if (params.userId) {
      const userId = Number(params.userId)
      dispatch(adminFetchSingleUser(userId))
      performAsync(userId)
    }
  }, [dispatch, params.userId])

  const dropdownOptions = React.useMemo(() => {
    if (!dropdownData || !currentTaxYear) {
      return []
    }

    // always include EOY as an option for the balance sheet for the current tax year
    const dropdownDataWithEOY = {
      ...dropdownData,
    }
    const eoyKey = `${currentTaxYear}-12`
    if (dropdownDataWithEOY[eoyKey]) {
      dropdownDataWithEOY[eoyKey].mostRecentReconciliationEndingBalanceDate =
        `${currentTaxYear}-12-31T23:59:59.999Z`
    } else {
      dropdownDataWithEOY[eoyKey] = {
        mostRecentReconciliationEndingBalanceDate: `${currentTaxYear}-12-31T23:59:59.999Z`,
        mostRecentReconciliationId: -1,
        financialAccounts: [],
      }
    }

    const sorted = orderBy(
      dropdownDataWithEOY,
      ['mostRecentReconciliationEndingBalanceDate'],
      ['desc']
    )

    return Object.values(sorted).map((item, index) => ({
      text: isoToUTCDateTime(
        item.mostRecentReconciliationEndingBalanceDate
      ).toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT),
      key: item.mostRecentReconciliationId,
      value: index,
    }))
  }, [dropdownData, currentTaxYear])

  const selectedOption = !isNil(dropdownSelectedIndex)
    ? dropdownOptions[dropdownSelectedIndex]
    : null
  const selectedDateDetails =
    isNil(dropdownData) || isNil(selectedOption)
      ? null
      : Object.values(dropdownData).find(
          (a) => a.mostRecentReconciliationId === selectedOption?.key
        )

  // Invoked when the dropdown value changes, generates balance sheet report data
  useEffect(() => {
    async function performAsync(data: AdminBalanceSheetRequestPayload) {
      setTableLoading(true)
      const response = await generateAdminBalanceSheet(data)
      if (response.error) {
        setError(response.error)
      } else if (response.data) {
        setReportData(response.data)
      }
      setTableLoading(false)
    }

    if (!user || !selectedDateDetails) {
      return
    }

    const reconciliationIdsByAccountId: { [key: number]: number[] } = {}

    selectedDateDetails.financialAccounts.forEach((account) => {
      reconciliationIdsByAccountId[account.financialAccountId] =
        account.lifetimeReconciliationIdsFromYearMonthInclusive || []
    })

    const requestData: AdminBalanceSheetRequestPayload = {
      userId: user.id,
      mostRecentReconciliationEndingBalanceDate:
        selectedDateDetails.mostRecentReconciliationEndingBalanceDate,
      reconciliationIdsByAccountId,
    }

    performAsync(requestData)
  }, [dropdownSelectedIndex, user, selectedDateDetails])

  const selectedDateFormatted = useMemo(
    () =>
      selectedDateDetails
        ? isoToUTCDateTime(
            selectedDateDetails.mostRecentReconciliationEndingBalanceDate
          ).toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)
        : 'N/A',
    [selectedDateDetails]
  )

  const pdfFileName = useMemo(() => {
    const customerOrBusinessName =
      user?.financialProfile?.businessName ||
      `${user?.firstName} ${user?.lastName}`
    return `${customerOrBusinessName}_${selectedDateFormatted}_balance_sheet.pdf`
  }, [selectedDateFormatted, user])

  const downloadPdf = () => {
    pdfRef.current?.generatePDF()
  }

  const hasReconciliations = dropdownOptions.length > 0

  const DATE_EXPLAINER = `The date dropdown is populated using reconciliation data, specifically the most-recent 'ending balance date' across all accounts.
    Pulling balance sheets for any other dates will require manual calculation.`

  return (
    <Container id="balanceSheetReport">
      <BalanceSheetBreadcrumbs
        firstName={user?.firstName}
        lastName={user?.lastName}
        userId={user?.id}
      />
      <Divider />

      <div style={{ paddingBottom: '5em' }} className="backdrop">
        <Grid doubling>
          {/* Header */}
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={10}>
              <Header as="h2">Balance Sheet</Header>
              <Text as="bodyMd">
                <b>Effective Date: </b>
                {user?.financialProfile?.entityChangeElectionDate
                  ? DateTime.fromISO(
                      user?.financialProfile?.entityChangeElectionDate
                    )
                      .toUTC()
                      .startOf('day')
                      .toFormat(DATE_FORMATS_LUXON.YEAR_MONTH_DAY)
                  : 'None Added'}
              </Text>
              <Text as="bodyMd">
                <b>User joined Heard on: </b>
                {membership?.startDate
                  ? DateTime.fromISO(membership?.startDate)
                      .toUTC()
                      .startOf('day')
                      .toFormat(DATE_FORMATS_LUXON.YEAR_MONTH_DAY)
                  : 'None Found'}
              </Text>
              {user?.id && (
                <NeedsManualBalanceSheetLabel
                  userId={user.id}
                  year={currentTaxYear}
                />
              )}
            </Grid.Column>
            {dropdownLoading && (
              <Grid.Column width={6}>
                <Loader size="small" style={{ float: 'right' }} active />
              </Grid.Column>
            )}
            {!dropdownLoading && hasReconciliations && (
              <>
                <Grid.Column width={selectedDateDetails ? 3 : 6}>
                  <Dropdown
                    style={{ float: 'right' }}
                    selectOnBlur={false}
                    scrolling
                    fluid
                    value={
                      dropdownSelectedIndex
                        ? dropdownOptions[dropdownSelectedIndex].value
                        : undefined
                    }
                    placeholder="Please select a date"
                    options={dropdownOptions}
                    onChange={(_event, data) =>
                      setDropdownSelectedIndex(Number(data.value))
                    }
                  />
                </Grid.Column>
                {selectedDateDetails && (
                  <Grid.Column width={3}>
                    {/* next story - download button */}
                    <Button
                      content="Download as PDF"
                      onClick={downloadPdf}
                      primary
                      floated="right"
                    />
                  </Grid.Column>
                )}
              </>
            )}
          </Grid.Row>
          <Divider style={{ margin: '0' }} />

          {/* Message banner */}
          {!tableLoading && !error && hasReconciliations && (
            <>
              <Grid.Row>
                <Grid.Column width={16}>
                  {!dropdownLoading && !selectedDateDetails && (
                    <Message
                      className="noBorder"
                      header={'Get started by selecting a date above'}
                      info
                      content={DATE_EXPLAINER}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>

              {/* Report Table */}
              {reportData && selectedDateDetails && (
                <PdfWrapper filename={pdfFileName} ref={pdfRef}>
                  <BalanceSheetReport
                    balanceSheetDate={selectedDateFormatted}
                    reportData={reportData}
                  />
                </PdfWrapper>
              )}
            </>
          )}

          {/* Loading */}
          {tableLoading && (
            <Grid.Row>
              <Loader size="small" style={{ float: 'right' }} active />
            </Grid.Row>
          )}

          {/* Report Unavailable Message */}
          {!error && !dropdownLoading && !hasReconciliations && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Message
                  warning
                  className="noBorder"
                  header="Unavailable"
                  content="User has no financial account reconciliations. These are required for generating a balance sheet."
                />
              </Grid.Column>
            </Grid.Row>
          )}

          {/* Report Error Message */}
          {error && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Message
                  error
                  header="An error occurred"
                  content={error}
                  className="noBorder"
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </Container>
  )
}

export default AdminBalanceSheetPanel
