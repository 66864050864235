import axios from 'axios'

import { fetchWrapper } from '../../../reducers/fetch'
import { PayrollSetup } from '../../../reducers/auth/userReducer'

export enum PayrollNotInterestedReason {
  price = 'price',
  missingFeatures = 'missingFeatures',
  stayingWithCurrentProvider = 'stayingWithCurrentProvider',
  other = 'other',
}

export enum PayrollIneligibleReason {
  multiplePayRates = 'multiplePayRates',
  gustoPlanType = 'gustoPlanType',
  gustoSponsoredBenefits = 'gustoSponsoredBenefits',
  commissionBasedPayments = 'commissionBasedPayments',
  other = 'other',
}

export enum UsedPayrollInPast {
  yesAsEmployee = 'yesAsEmployee',
  yesForCurrentBusiness = 'yesForCurrentBusiness',
  yesForPreviousBusiness = 'yesForPreviousBusiness',
  no = 'no',
}

export enum GustoPlan {
  simple = 'simple',
  plus = 'plus',
  premium = 'premium',
  concierge = 'concierge',
  contractor = 'contractor',
  notSure = 'notSure',
}

export interface PayrollInterestSurvey {
  id: number
  userId: number
  updatedAt: string
  createdAt: string
  payrollProvider?: string
  gustoPlan?: string
  interestedInGepAt?: string
  gustoOnboardedAt?: string
  payrollSetup?: PayrollSetup
  gepRequestFormSubmittedAt?: string
  multiplePayRates?: boolean
  gustoSponsoredBenefits?: boolean
  timeTracking?: boolean
  commissionOnlyEmployees?: boolean
  needPayrollForSelf?: boolean
  needPayrollForEmployees?: boolean
  needPayrollForContractors?: boolean
  needPayrollForOther?: string
  usedGustoInPast?: UsedPayrollInPast
  notInterestedReason?: PayrollNotInterestedReason
  otherNotInterestedReason?: string
  missingFeaturesNotInterestedReason?: string
  ineligibleReason?: PayrollIneligibleReason | null
  ineligibleReasonOther?: string | null
  adminReviewedAt?: string
  gepEligible?: boolean
}

export const submitPayrollInterestSurvey = (
  payload: Omit<
    PayrollInterestSurvey,
    'id' | 'userId' | 'updatedAt' | 'createdAt'
  >
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .post<PayrollInterestSurvey>(
          '/finances/api/v2/payroll/payroll_interest',
          payload
        )
        .then((res) => res.data),
  })

export const getMostRecentPayrollInterestSurvey = () =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<PayrollInterestSurvey | null>(
          '/finances/api/v2/payroll/payroll_interest/'
        )
        .then((res) => res.data),
  })

export const adminGetMostRecentPayrollInterestSurveyForUser = (
  userId: number
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .get<PayrollInterestSurvey | null>(
          `/finances/api/v2/admin/payroll/payroll_interest/${userId}`
        )
        .then((res) => res.data),
  })

export const adminUpdatePayrollInterestSurveyResponse = (
  surveyId: number,
  payload: Omit<
    PayrollInterestSurvey,
    'id' | 'userId' | 'updatedAt' | 'createdAt'
  >
) =>
  fetchWrapper({
    fetchFunction: () =>
      axios
        .put<PayrollInterestSurvey>(
          `/finances/api/v2/admin/payroll/payroll_interest/${surveyId}`,
          payload
        )
        .then((res) => res.data),
  })
