import { useState, useEffect, useCallback } from 'react'
import { Divider, Grid, List } from 'semantic-ui-react'
import {
  Modal,
  GridRowColumn,
  Text,
  Button,
  Table,
  Toggle,
} from '../../BaseComponents'
import {
  deletePlaidItem,
  markPlaidItemInactive,
  updateAdminUserFinancialAccounts,
} from '../../../actions/admin/adminFinancialAccountActions'
import { useAnalyticsTrack } from '../../../features/Amplitude'
import { PlaidItem } from '../../../reducers/finances/plaidItemReducer'
import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { FinancialAccountWithAdminInfo } from '../../../reducers/admin/financialAccountsReducer'

interface AdminConfirmModalProps {
  open: boolean
  toggleOpen: () => void
  plaidItem: PlaidItem
  userId: number
  setError: (errorType: string) => void
  accounts?: FinancialAccount[]
}

export const AdminDeleteModal = ({
  open,
  toggleOpen,
  plaidItem,
  userId,
  setError,
}: AdminConfirmModalProps) => {
  const [deleting, setDeleting] = useState(false)

  const dispatch = useAppDispatch()

  const confirmDelete = async () => {
    setDeleting(true)
    const res = await deletePlaidItem(plaidItem.id, userId)(dispatch)
    if (!res) {
      setError('delete')
    }
    setDeleting(false)
    toggleOpen()
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Are you sure you want to permanently delete this connection?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>Deleting this connection will:</Text>
            <List as="ul">
              <List.Item as="li">
                <Text>
                  <b>Permanently remove all transactions</b> from this
                  institution, both old and new.
                </Text>
              </List.Item>
              <List.Item as="li">
                <Text>
                  Remove this institution both from the user&apos;s page and
                  Admin.
                </Text>
              </List.Item>
            </List>
            <Text>
              This should only be used as a final option and is typically
              reserved for deleting financial institutions with all personal
              accounts.
            </Text>
            <br />
            <Text as="h3"> This cannot be undone.</Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Cancel
        </Button>
        <Button variant="warning" loading={deleting} onClick={confirmDelete}>
          Delete Permanently
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminRemoveModal = ({
  open,
  toggleOpen,
  plaidItem,
  userId,
  accounts,
  setError,
}: AdminConfirmModalProps) => {
  const [removing, setRemoving] = useState(false)
  const [excludeChanges, setExcludeChanges] = useState<{
    [accountId: string]: boolean
  }>()

  const dispatch = useAppDispatch()
  const track = useAnalyticsTrack(true)

  const confirmRemove = async () => {
    setRemoving(true)
    const res = await markPlaidItemInactive(plaidItem.id, userId)(dispatch)
    if (!res) {
      setError('mark inactive')
    }
    track('submitted plaid remove connection', {
      fi_name: plaidItem.institutionName,
    })
    if (excludeChanges) {
      const accountsToUpdate = Object.keys(excludeChanges)
      for (const accountId of accountsToUpdate) {
        const account = (accounts ?? []).find(
          (a) => a.id === parseInt(accountId)
        )
        if (
          !account ||
          account?.excludeFromReports === excludeChanges[accountId]
        )
          continue
        await dispatch(
          updateAdminUserFinancialAccounts(userId, parseInt(accountId), {
            excludeFromReports: excludeChanges[accountId],
          })
        )
      }
    }
    setRemoving(false)
    toggleOpen()
  }

  const createDefaultExcludeChangesObj = useCallback(() => {
    if (removing) return
    const finAccounts = accounts ?? []
    const defaultObj: {
      [accountId: string]: boolean
    } = {}
    for (const account of finAccounts) {
      defaultObj[account.id] = Boolean(account.excludeFromReports)
    }
    setExcludeChanges(defaultObj)
  }, [accounts, removing])

  useEffect(() => {
    createDefaultExcludeChangesObj()
  }, [createDefaultExcludeChangesObj])

  const handleChangeToggle = (checked: boolean, accountId: number) => {
    setExcludeChanges((prev) => ({ ...prev, [accountId]: checked }))
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Are you sure you want to remove this connection?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>Removing this connection will:</Text>
            <List as="ul">
              <List.Item as="li">
                <Text>
                  <b>Stop syncing new transactions from all accounts.</b> Past
                  transactions will remain.
                </Text>
              </List.Item>
              <List.Item as="li">
                <Text>
                  Hide this financial institution from the user&apos;s Settings
                  page. Admins can still view it but cannot perform any actions.
                </Text>
              </List.Item>
            </List>
            <Text>
              This is typically done when all reconnection attempts have been
              exhausted and the user needs to re-add the institution as a new
              connection.
            </Text>
            <br />
            <Text as="h3"> This cannot be undone.</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Divider />
            <Text as="h3" style={{ marginBottom: 8 }}>
              Should these accounts be excluded from financial reports?
            </Text>
            <Text>
              Exclude if these accounts encountered Plaid issues, which forced
              the same account be be activated under a different Plaid ID.
            </Text>
            {excludeChanges && (
              <Table>
                <Table.Row>
                  <Table.HeaderCell>Account Name</Table.HeaderCell>
                  <Table.HeaderCell>
                    Exclude From Financial Reports
                  </Table.HeaderCell>
                </Table.Row>
                {(accounts ?? []).map((account) => (
                  <Table.Row key={account.id}>
                    <Table.Cell>
                      {account.name}: {account.mask}
                    </Table.Cell>
                    <Table.Cell>
                      <Toggle
                        checked={Boolean(excludeChanges?.[account.id])}
                        onChange={(checked) =>
                          handleChangeToggle(checked, account.id)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            )}
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Cancel
        </Button>
        <Button variant="warning" loading={removing} onClick={confirmRemove}>
          Remove connection
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminFinancialSuccessModal = ({
  open,
  message,
  toggleOpen,
}: {
  open: boolean
  message: string
  toggleOpen: () => void
}) => {
  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Success</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>{message}</Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={toggleOpen}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminFinancialErrorModal = ({
  open,
  toggleOpen,
}: {
  open: boolean
  toggleOpen: () => void
}) => {
  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">Error</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              There was an error performing this action. No changes were made to
              this connection. Please try again.
            </Text>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={toggleOpen}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminBankAccessStatusModal = ({
  open,
  toggleOpen,
  accounts,
  plaidItem,
}: {
  open: boolean
  toggleOpen: () => void
  accounts: FinancialAccount[]
  plaidItem: PlaidItem
}) => {
  const dispatch = useAppDispatch()

  const handleChangeToggle = async (checked: boolean, accountId: number) => {
    await dispatch(
      updateAdminUserFinancialAccounts(plaidItem.userId, accountId, {
        bankAccessEnabledAt: checked ? new Date().toISOString() : null,
      })
    )
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Updating Bank Account Statuses for {plaidItem.institutionName}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Table>
              <Table.Row>
                <Table.HeaderCell>Account Name</Table.HeaderCell>
                <Table.HeaderCell>Limited Bank Access Enabled</Table.HeaderCell>
              </Table.Row>
              {accounts.map((account) => (
                <Table.Row key={account.id}>
                  <Table.Cell>
                    {account.name}: {account.mask}
                  </Table.Cell>
                  <Table.Cell>
                    <Toggle
                      checked={Boolean(account.bankAccessEnabledAt)}
                      onChange={(checked) =>
                        handleChangeToggle(checked, account.id)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminExcludeReportsModal = ({
  open,
  toggleOpen,
  accounts,
  userId,
}: {
  open: boolean
  toggleOpen: () => void
  accounts: (FinancialAccountWithAdminInfo | FinancialAccount)[]
  userId: number
}) => {
  const dispatch = useAppDispatch()
  const [excludeChanges, setExcludeChanges] = useState<{
    [accountId: string]: boolean
  }>()
  const [saving, setSaving] = useState(false)

  const createDefaultExcludeChangesObj = useCallback(() => {
    if (saving) return
    const defaultObj: {
      [accountId: string]: boolean
    } = {}
    for (const account of accounts) {
      defaultObj[account.id] = Boolean(account.excludeFromReports)
    }
    setExcludeChanges(defaultObj)
  }, [accounts, saving])

  useEffect(() => {
    createDefaultExcludeChangesObj()
  }, [createDefaultExcludeChangesObj])

  const handleChangeToggle = (checked: boolean, accountId: number) => {
    setExcludeChanges((prev) => ({ ...prev, [accountId]: checked }))
  }

  const confirmChanges = async () => {
    if (!excludeChanges) return
    setSaving(true)
    const accountsToUpdate = Object.keys(excludeChanges)
    for (const accountId of accountsToUpdate) {
      const account = accounts.find((a) => a.id === parseInt(accountId))
      if (!account || account?.excludeFromReports === excludeChanges[accountId])
        continue
      await dispatch(
        updateAdminUserFinancialAccounts(userId, parseInt(accountId), {
          excludeFromReports: excludeChanges[accountId],
        })
      )
    }
    setSaving(false)
    toggleOpen()
  }

  return (
    <Modal open={open} closeIcon onClose={toggleOpen}>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="h2">
              Should this account be excluded from financial reports?
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Exclude if this account encountered Plaid issues, which forced the
              same account be be activated under a different Plaid ID.
            </Text>
            {excludeChanges && (
              <Table>
                <Table.Row>
                  <Table.HeaderCell>Account Name</Table.HeaderCell>
                  <Table.HeaderCell>
                    Exclude From Financial Reports
                  </Table.HeaderCell>
                </Table.Row>
                {accounts.map((account) => (
                  <Table.Row key={account.id}>
                    <Table.Cell>
                      {account.name}: {account.mask}
                    </Table.Cell>
                    <Table.Cell>
                      <Toggle
                        checked={Boolean(excludeChanges?.[account.id])}
                        onChange={(checked) =>
                          handleChangeToggle(checked, account.id)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            )}
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="secondary"
          style={{ marginRight: 8 }}
          onClick={toggleOpen}
        >
          Close
        </Button>
        <Button onClick={confirmChanges} loading={saving}>
          Save Changes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export const AdminExcludeReportsButton = ({
  account,
  userId,
}: {
  account: FinancialAccountWithAdminInfo
  userId: number
}) => {
  const [open, setOpen] = useState(false)

  const { excludeFromReports } = account

  return (
    <Grid>
      <GridRowColumn centerContent style={{ margin: 8 }}>
        <Button onClick={() => setOpen(true)} size="medium">
          {excludeFromReports ? 'Include In Reports' : 'Exclude From Reports'}
        </Button>
      </GridRowColumn>
      {open && (
        <AdminExcludeReportsModal
          open={open}
          toggleOpen={() => setOpen(false)}
          accounts={[account]}
          userId={userId}
        />
      )}
    </Grid>
  )
}
