import { Grid } from 'semantic-ui-react'

import AnnualTaxFaq from './AnnualTaxFaq'
import {
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { useNavigate } from 'react-router-dom'

const FreeTrialAnnualTaxPreview = () => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)
  const year = useReselector(selectCurrentAnnualTaxYear)
  const navigate = useNavigate()

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={isMobileOrTablet ? 16 : 9}>
          <Card backgroundColor="natural" type="subsection">
            <Grid>
              <GridRowColumn
                columnStyle={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Text as="h2">Preview the Annual Tax Filing Experience</Text>
              </GridRowColumn>
              <GridRowColumn>
                {/* Todo: UPDATE LOOM */}
                <iframe
                  src="https://www.loom.com/embed/a8860aa90adb41708ba88c4b874e34c3"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  width="100%"
                  height="300px"
                  title="Preview the Annual Tax Filing Experience"
                  sandbox="allow-scripts allow-same-origin allow-popups"
                />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="bodyLg">
                  Explore how Heard can help therapists like yourself with your
                  Annual taxes! Note that you&#39;re viewing a preview of the{' '}
                  {year} Annual Tax Filing experience. Feel free to click around
                  and get comfortable – just keep in mind that any information
                  entered won&#39;t be saved or submitted.
                </Text>
              </GridRowColumn>
              <Link
                newPage
                href="https://support.joinheard.com/hc/en-us/articles/4560803976599-All-About-Heard-s-Tax-Checklist-Process"
                as="secondaryLink"
              >
                Learn more about the Annual Tax Experience
              </Link>
              <GridRowColumn>
                <Button
                  variant="primary"
                  onClick={() => navigate('/accounts/choose-plan')}
                >
                  Need help with Taxes? Sign up with Heard
                </Button>
                <br />

                <Text as="bodySm" color="darkGray">
                  Sign up by Dec 31, {year} in order to receive support for the{' '}
                  {year} tax year
                </Text>
              </GridRowColumn>
            </Grid>
          </Card>
        </Grid.Column>
        <Grid.Column width={isMobileOrTablet ? 16 : 6}>
          <Grid>
            <GridRowColumn>
              <AnnualTaxFaq />
            </GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
    </Grid>
  )
}

export default FreeTrialAnnualTaxPreview
