import { useEffect, useMemo, useState } from 'react'

import { fetchPayrollAuthLink } from '../payrollActions'
import {
  Checkbox,
  Link,
  Text,
  Button,
} from '../../../components/BaseComponents'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { EnrollmentIntro } from '../Enroll'

const MigratePayroll = () => {
  const dispatch = useAppDispatch()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [authLink, setAuthLink] = useState('')
  const pageView = useAnalyticsView()

  useEffect(() => {
    const fetchLink = async () => {
      const res = await fetchPayrollAuthLink()(dispatch)
      if (res) {
        setAuthLink(res.authUrl)
      }
    }

    fetchLink()
    pageView('payroll set up')
  }, [dispatch, pageView])

  const enrollButton = useMemo(
    () => (
      <>
        <Checkbox
          checked={acceptTerms}
          onChange={(checked) => setAcceptTerms(checked)}
          variant="default"
          label={
            <Text>
              I have viewed and accepted the{' '}
              <Link href="https://flows.gusto.com/terms" newPage>
                Embedded Payroll Terms of Service
              </Link>
            </Text>
          }
        />
        <Button
          onClick={() => window.open(authLink, '_self')}
          disabled={!acceptTerms}
          style={{ marginTop: 20 }}
        >
          Set Up Payroll in Heard
        </Button>
      </>
    ),
    [acceptTerms, authLink]
  )

  return <EnrollmentIntro enrollButton={enrollButton} />
}

export default MigratePayroll
