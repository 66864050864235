import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useFetchResponse,
  useReselector,
  useScrollRef,
  useToggle,
} from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { getUserById } from '../../../../selectors/user.selectors'
import {
  selectDocumentsByUserId,
  selectAdminTaxDocumentsByYearAndCategory,
} from '../../../UserDocuments/userDocuments.selector'
import { getPlaidOnlyFinancialAccountsByUserId } from '../../../../selectors/financeSelectors'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getFetchError } from '../../../../reducers/fetch'
import {
  adminFetchSingleUser,
  FETCH_ADMIN_SINGLE_USER_KEY,
} from '../../../../actions/admin/adminAllUsersActions'
import {
  fetchUserFinancialAccounts,
  REQUEST_USER_FINANCIAL_ACCOUNTS_KEY,
} from '../../../../actions/admin/adminFinancialAccountActions'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  fetchAdminUsersDocuments,
  REQUEST_ADMIN_USER_DOCUMENTS_KEY,
} from '../../../../actions/admin/userDocumentsActions'
import {
  DepreciableAssetTransaction,
  EndOfYearReviewSubmission,
  KeyedFetchError,
  AdminStepUpdaterArgs,
  OnAdminNoteSaveCallback,
  OnDocumentReUploadSaveCallback,
  AdminNoteState,
  DocumentReUploadState,
  StatementReUploadState,
  OnStatementReUploadSaveCallback,
  ConfirmModalState,
} from '../types'
import {
  adminGetPriorYearRetainedEarnings,
  adminGetSubmission,
  adminListDepreciableAssets,
  adminUpdateBookkeepingStep,
  adminUpdatePriorYearRetainedEarnings,
  FETCH_ADMIN_UPDATE_STEP_KEY,
  FETCH_ADMIN_GET_SUBMISSION_KEY,
  FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY,
  FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY,
  FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY,
  adminSubmitBookkeepingModule,
  FETCH_ADMIN_SUBMIT_YE_BOOKKEEPING_KEY,
} from '../actions'
import { AdminYearEndReviewContext } from './context'
import { prevOrNewErrors } from './helpers'
import {
  AdminNoteModal,
  ConfirmHandoffModal,
  DocumentReUploadModal,
  StatementReUploadModal,
} from './modals'
import MainContent from './main-content'
import { fetchPayrollForUser } from '../../../Payroll/payrollActions'
import { fetchTaxUserDocumentsIfNeededForUser } from '../../../Taxes/AnnualTaxes/taxUserDocuments.slice'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

const AdminBookkeepingEndOfYearReview = () => {
  const dispatch = useAppDispatch()
  const [loading, toggleLoading] = useToggle(true)
  const [submission, setSubmission] =
    useState<EndOfYearReviewSubmission | null>(null)
  const [priorYearRetainedEarnings, setPriorYearRetainedEarnings] = useState<
    number | null
  >(null)
  const [errors, setErrors] = useState<KeyedFetchError[]>([])
  const { userId: userIdString, year } = useParams<{
    userId: string
    year: string
  }>()
  const userId = Number(userIdString)
  const user = useReselector(getUserById, userId)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const financialAccounts = useReselector(
    getPlaidOnlyFinancialAccountsByUserId,
    userId
  )
  const balanceSheetDocs = useReselector(
    selectAdminTaxDocumentsByYearAndCategory,
    userId,
    taxYear,
    UserDocumentCategoryIdentifier.balanceSheet
  )

  const [adminNote, setAdminNote] = useState<AdminNoteState>({
    note: null,
    open: false,
    loading: false,
    onSave: null,
  })
  const [statementReUpload, setStatementReUpload] =
    useState<StatementReUploadState>({
      note: null,
      open: false,
      loading: false,
      onSave: null,
    })
  const [documentReUpload, setDocumentReUpload] =
    useState<DocumentReUploadState>({
      note: null,
      open: false,
      loading: false,
      onSave: null,
    })
  const [confirmModal, setConfirmModal] = useState<ConfirmModalState>({
    open: false,
    loading: false,
  })
  const userDocuments = useReselector(selectDocumentsByUserId, userId)
  const payrollProfile = useFetchResponse(fetchPayrollForUser, userId)
  const [depreciableAssets, setDepreciableAssets] = useState<
    DepreciableAssetTransaction[] | null
  >(null)

  const { scrollRef: scrollTopRef, scrollToRef: scrollToTopRef } =
    useScrollRef()

  // Check for fetch errors
  const submissionFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_GET_SUBMISSION_KEY
  )
  const accountsFetchError = useReselector(
    getFetchError,
    REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId)
  )
  const userFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_SINGLE_USER_KEY
  )
  const stepUpdateError = useReselector(
    getFetchError,
    FETCH_ADMIN_UPDATE_STEP_KEY
  )
  const currentAnnualTaxDetailsError = useReselector(
    getFetchError,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )
  const documentsFetchError = useReselector(
    getFetchError,
    REQUEST_ADMIN_USER_DOCUMENTS_KEY(userId)
  )
  const depreciableAssetsFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY(userId)
  )
  const getPriorYearRetainedEarningsFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY(userId)
  )
  const updatePriorYearRetainedEarningsFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY(userId)
  )
  const adminSubmitModuleFetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_SUBMIT_YE_BOOKKEEPING_KEY(userId)
  )

  useEffect(() => {
    setErrors((prevErrors) => {
      const newErrors = [
        { key: FETCH_ADMIN_GET_SUBMISSION_KEY, ...submissionFetchError },
        {
          key: REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId),
          ...accountsFetchError,
        },
        { key: FETCH_ADMIN_SINGLE_USER_KEY, ...userFetchError },
        { key: FETCH_ADMIN_UPDATE_STEP_KEY, ...stepUpdateError },
        {
          key: FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
          ...currentAnnualTaxDetailsError,
        },
        {
          key: REQUEST_ADMIN_USER_DOCUMENTS_KEY(userId),
          ...documentsFetchError,
        },
        {
          key: FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY(userId),
          ...depreciableAssetsFetchError,
        },
        {
          key: FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY(userId),
          ...getPriorYearRetainedEarningsFetchError,
        },
        {
          key: FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY(userId),
          ...updatePriorYearRetainedEarningsFetchError,
        },
        {
          key: FETCH_ADMIN_SUBMIT_YE_BOOKKEEPING_KEY(userId),
          ...adminSubmitModuleFetchError,
        },
      ]
      return prevOrNewErrors(prevErrors, newErrors)
    })
  }, [
    submissionFetchError,
    accountsFetchError,
    userFetchError,
    userId,
    stepUpdateError,
    currentAnnualTaxDetailsError,
    documentsFetchError,
    depreciableAssetsFetchError,
    getPriorYearRetainedEarningsFetchError,
    updatePriorYearRetainedEarningsFetchError,
    adminSubmitModuleFetchError,
  ])

  // Fetch data
  useEffect(() => {
    const fetch = async (taxYear: string) => {
      const fetchSubmission = async (taxYear: string) => {
        const submission = await adminGetSubmission(userId, taxYear)(dispatch)
        setSubmission(submission)
      }
      const fetchDepreciableAssets = async (taxYear: string) => {
        const assets = await adminListDepreciableAssets(
          userId,
          taxYear
        )(dispatch)
        setDepreciableAssets(assets)
      }
      const fetchPriorYearRetainedEarnings = async (taxYear: string) => {
        const amountInCents = await adminGetPriorYearRetainedEarnings(
          userId,
          taxYear
        )(dispatch)
        setPriorYearRetainedEarnings(amountInCents)
      }

      await Promise.all([
        dispatch(adminFetchSingleUser(userId)),
        dispatch(fetchUserFinancialAccounts(userId)),
        fetchAllAnnualTaxDetailsIfNeeded(),
        fetchSubmission(taxYear),
        dispatch(fetchAdminUsersDocuments(userId)),
        fetchDepreciableAssets(taxYear),
        fetchPriorYearRetainedEarnings(taxYear),
        dispatch(fetchTaxUserDocumentsIfNeededForUser(userId)),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
      ])

      toggleLoading()
    }

    if (year) {
      fetch(year)
    }
  }, [dispatch, userId, year, toggleLoading])

  const updateStep = useCallback(
    async (args: AdminStepUpdaterArgs) => {
      const updatedStep = await adminUpdateBookkeepingStep({
        ...args,
        userId,
      })(dispatch)
      if (updatedStep) {
        setSubmission((prev) => {
          if (prev === null) return prev
          const filteredSteps =
            prev?.steps?.filter((s) => s.id !== updatedStep.id) ?? []
          return {
            ...prev,
            steps: [...filteredSteps, updatedStep],
          }
        })
      }
      return updatedStep
    },
    [userId, dispatch, setSubmission]
  )

  const adminSubmitModule = useCallback(async () => {
    const updatedSubmission =
      await adminSubmitBookkeepingModule(userId)(dispatch)
    if (updatedSubmission) {
      setSubmission(updatedSubmission)
    }
    scrollToTopRef()
  }, [userId, dispatch, setSubmission, scrollToTopRef])

  const openAdminNoteModal = useCallback(
    (initialNote: string | null, onSave: OnAdminNoteSaveCallback) => {
      setAdminNote({
        open: true,
        loading: false,
        note: initialNote,
        onSave,
      })
    },
    [setAdminNote]
  )

  const openStatementReUploadModal = useCallback(
    (initialNote: string | null, onSave: OnStatementReUploadSaveCallback) => {
      setStatementReUpload({
        open: true,
        loading: false,
        note: initialNote,
        onSave,
      })
    },
    [setStatementReUpload]
  )

  const openDocumentReUploadModal = useCallback(
    (initialNote: string | null, onSave: OnDocumentReUploadSaveCallback) => {
      setDocumentReUpload({
        open: true,
        loading: false,
        note: initialNote,
        onSave,
      })
    },
    [setDocumentReUpload]
  )

  const openConfirmModal = useCallback(
    () => setConfirmModal({ open: true, loading: false }),
    [setConfirmModal]
  )

  const priorYearRetainedEarningsOnChange = useCallback(
    async (amountInCents: number) => {
      await adminUpdatePriorYearRetainedEarnings({
        userId,
        taxYear,
        amountInCents,
      })(dispatch)

      setPriorYearRetainedEarnings(amountInCents)
    },
    [setPriorYearRetainedEarnings, taxYear, userId, dispatch]
  )

  return (
    <AdminYearEndReviewContext.Provider
      value={{
        taxYear,
        updateStep,
        openAdminNoteModal,
        openStatementReUploadModal,
        openDocumentReUploadModal,
        openConfirmModal,
        priorYearRetainedEarnings,
        priorYearRetainedEarningsOnChange,
        payrollProfile,
      }}
    >
      <span ref={scrollTopRef} />
      <MainContent
        user={user}
        submission={submission}
        financialAccounts={financialAccounts}
        userDocuments={userDocuments}
        depreciableAssets={depreciableAssets}
        loading={loading}
        errors={errors}
        isBalanceSheetUploaded={Boolean(balanceSheetDocs?.length > 0)}
      />
      <AdminNoteModal state={adminNote} setState={setAdminNote} />
      <StatementReUploadModal
        state={statementReUpload}
        setState={setStatementReUpload}
      />
      <DocumentReUploadModal
        state={documentReUpload}
        setState={setDocumentReUpload}
      />
      <ConfirmHandoffModal
        state={confirmModal}
        setState={setConfirmModal}
        submitModule={adminSubmitModule}
      />
    </AdminYearEndReviewContext.Provider>
  )
}

export default AdminBookkeepingEndOfYearReview
