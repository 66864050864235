import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { uniq } from 'lodash'

import {
  Accordion,
  Button,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
  useTriggerSet,
} from '../../../../../utils/sharedHooks'
import UploadCard from './UploadCard'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../../UserDocuments/userDocuments.slice'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../annualTaxFilingForms.slice'
import { fetchUserTaxQuestionnaire } from '../taxChecklistQuestion.actions'
import {
  selectAllRequiredDocsUploaded,
  selectDocumentProgress,
  selectUploadedNonUploadedDocs,
} from '../taxChecklist.selectors'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'
import useProgressSteps from '../Shared/ReviewStepsandProgresses/useProgressSteps'
import { useNavigateWithLocation } from '../../../../../utils/routeHelpers'
import { updateStepDebounced } from '../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectTqFilingIsReadOnly } from '../../annualTaxFilings.selector'
import PageHeader from '../../../../../components/shared/PageHeader'
import {
  FETCH_TAX_USER_DOCUMENTS_KEY,
  fetchTaxUserDocumentsIfNeeded,
} from '../../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { UPLOAD_DOCUMENTS } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { tqBigSpace } from '../helpers'
import useMissingAnswerValidation from '../Shared/UseMissingAnswerValidation'

// works for both skipped categories and needs assistance categories
export const useCheckedDocumentCategories = () => {
  const dispatch = useAppDispatch()
  const progress = useReselector(selectDocumentProgress)
  // This is separated out from redux state for more responsive UI
  const [skippedCategories, setSkippedCategories] = useState<string[]>()
  const [helpCategories, setHelpCategories] = useState<string[]>()

  // Set the categories once progress is loaded
  useEffect(() => {
    if (progress) {
      setSkippedCategories(
        progress?.responses?.[0]?.skippedDocumentCategories || []
      )
      setHelpCategories(progress?.responses?.[0]?.needsHelp || [])
    }
  }, [progress])

  const updateSkipped = useCallback(
    (identifier: string, checked: boolean) => {
      const newSkippedCategories = checked
        ? uniq([...(skippedCategories || []), identifier])
        : (skippedCategories || []).filter((cat) => cat !== identifier)

      setSkippedCategories(newSkippedCategories)

      if (progress?.id) {
        dispatch(
          updateStepDebounced({
            id: progress.id,
            responses: [
              {
                skippedDocumentCategories: newSkippedCategories,
                needsHelp: helpCategories || [],
              },
            ],
          })
        )
      }
    },
    [dispatch, helpCategories, progress?.id, skippedCategories]
  )

  const updateHelped = useCallback(
    (identifier: string, checked: boolean) => {
      const newHelpCategories = checked
        ? uniq([...(helpCategories || []), identifier])
        : (helpCategories || []).filter((cat) => cat !== identifier)

      setHelpCategories(newHelpCategories)

      if (progress?.id) {
        dispatch(
          updateStepDebounced({
            id: progress.id,
            responses: [
              {
                skippedDocumentCategories: skippedCategories || [],
                needsHelp: newHelpCategories,
              },
            ],
          })
        )
      }
    },
    [dispatch, helpCategories, progress?.id, skippedCategories]
  )

  return { skippedCategories, updateSkipped, helpCategories, updateHelped }
}

const UploadDocuments = () => {
  // Note the url contains the form id but document upload can encompass the current form plus the other if matching
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithLocation()
  const form = useReselector(selectAnnualTaxFilingFormById, formId)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const readOnly = useReselector(selectTqFilingIsReadOnly, currentTaxYear)

  const { errorAlert, triggerError, scrollToRef } = useMissingAnswerValidation(
    'Please upload all required documentation. For optional documentation, you can mark "Unsure" or "needs follow up" to be able to skip uploading that document, and your tax preparer will follow up with you.'
  )

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: UPLOAD_DOCUMENTS }
  )

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_USER_DOCUMENTS_KEY,
    FETCH_TAX_USER_DOCUMENTS_KEY,
    FETCH_DOCUMENT_CATEGORIES_KEY,
    ...progressFetchKeys,
  ])

  const allRequiredDocsUploaded = useReselector(
    selectAllRequiredDocsUploaded,
    currentTaxYear
  )

  useEffect(() => {
    if (form?.year) {
      dispatch(fetchUserTaxQuestionnaire(form.year))
    }
  }, [dispatch, form?.year])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  const {
    uploadedRequired: uploadedRequiredFromState,
    nonUploadedRequired: nonUploadedRequiredFromState,
    uploadedOptional: uploadedOptionalFromState,
    nonUploadedOptional: nonUploadedOptionalFromState,
  } = useReselector(selectUploadedNonUploadedDocs, form?.year || currentTaxYear)

  const loaded = Boolean(form && !isLoading)
  const uploadedRequired = useTriggerSet(uploadedRequiredFromState, loaded)

  const nonUploadedRequired = useTriggerSet(
    nonUploadedRequiredFromState,
    !isLoading
  )
  const uploadedOptional = useTriggerSet(uploadedOptionalFromState, loaded)
  const nonUploadedOptional = useTriggerSet(
    nonUploadedOptionalFromState,
    loaded
  )

  const saveAndReturn = useAsyncCallback(async () => {
    const progressUpdatesSuccessful = await updateProgressData({
      completedSteps: UPLOAD_DOCUMENTS,
    })

    if (progressUpdatesSuccessful) {
      navigate('/taxes/annual/tax_checklist/')
    }
  })

  const onBack = () => navigate('/taxes/annual/tax_checklist')

  return (
    <>
      {errorAlert}
      {readOnly && (
        <PageHeader
          backControl={{
            link: '/taxes/annual/tax_checklist/',
            text: 'Back to Tax Checklist',
          }}
          header=""
        />
      )}
      <Grid>
        <Grid.Row />
        <GridRowColumn centerContent>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/tax_form_arrow.svg"
            style={{ width: 180, height: 180 }}
          />
        </GridRowColumn>
        <GridRowColumn short {...tqBigSpace}>
          <Text as="h1" textAlign="center">
            Upload documents
          </Text>
        </GridRowColumn>
        {!readOnly && (
          <GridRowColumn {...tqBigSpace}>
            <Text>
              Based on your answers in the questionnaire, we’ve identified the
              documents you need to upload for your tax return.
            </Text>
          </GridRowColumn>
        )}
        <Grid.Row />
        <GridRowColumn {...tqBigSpace}>
          <Accordion
            title={<Text as="h2">To be uploaded</Text>}
            backgroundColor="white"
            initialOpen
            content={
              <Grid>
                {nonUploadedRequired.map((category) => (
                  <GridRowColumn short key={category}>
                    <UploadCard
                      readOnly={readOnly}
                      docCategoryIdentifier={category}
                      isRequired
                    />
                  </GridRowColumn>
                ))}
                {nonUploadedOptional.map((category) => (
                  <GridRowColumn short key={category}>
                    <UploadCard
                      readOnly={readOnly}
                      docCategoryIdentifier={category}
                    />
                  </GridRowColumn>
                ))}
              </Grid>
            }
          />
        </GridRowColumn>

        <GridRowColumn {...tqBigSpace}>
          <Text as="h2">Other Documents</Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <UploadCard docCategoryIdentifier="" readOnly={readOnly} isOtherCat />
        </GridRowColumn>

        <GridRowColumn {...tqBigSpace}>
          <Accordion
            title={<Text as="h2">Uploaded</Text>}
            backgroundColor="white"
            initialOpen
            content={
              <Grid>
                {uploadedRequired.map((category) => (
                  <GridRowColumn short key={category}>
                    <UploadCard
                      readOnly={readOnly}
                      docCategoryIdentifier={category}
                      isRequired
                    />
                  </GridRowColumn>
                ))}
                {uploadedOptional.map((category) => (
                  <GridRowColumn short key={category}>
                    <UploadCard
                      readOnly={readOnly}
                      docCategoryIdentifier={category}
                    />
                  </GridRowColumn>
                ))}
              </Grid>
            }
          />
        </GridRowColumn>

        <Grid.Row />

        {!readOnly && (
          <FormFlowFooter
            onBack={onBack}
            continueDisabled={saveAndReturn.loading}
            onForward={
              !allRequiredDocsUploaded ? triggerError : saveAndReturn.callback
            }
            loading={isLoading || saveAndReturn.loading}
            extraButton={
              <Button fullWidth variant="secondary" onClick={onBack}>
                Save
              </Button>
            }
          />
        )}
      </Grid>
    </>
  )
}

export default UploadDocuments
