import { AxiosError } from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import { captureException } from '@sentry/react'

import { Dispatch, GetState, inOperator } from './typeHelpers'

import { userLogout } from '../reducers/rootReducer.actions'

export const logHeardError = (error: unknown, context?: object) => {
  datadogRum.addError(error, context)
}

export const DEFAULT_ERROR = 'Something went wrong. Please try again.'

export function isAxiosError(error: unknown): error is AxiosError {
  return error !== null && typeof error === 'object' && 'isAxiosError' in error
}

export function isStandardError(error: unknown): error is Error {
  return (
    error !== null &&
    typeof error === 'object' &&
    'name' in error &&
    'message' in error
  )
}

// Dig through response and look for message.  Important for Gusto errors
const parseMessageFromData = (data: unknown): string | null => {
  if (Array.isArray(data)) {
    for (const item of data) {
      const message = parseMessageFromData(item)
      if (message) {
        return message
      }
    }
  } else if (typeof data === 'object' && data) {
    if (
      inOperator('full_message', data) &&
      typeof data.full_message === 'string'
    ) {
      return data.full_message
    }
    if (inOperator('message', data) && typeof data.message === 'string') {
      return data.message
    }
    if (
      inOperator('errors', data) &&
      Array.isArray(data.errors) &&
      typeof data.errors[0] === 'string'
    ) {
      return data.errors[0]
    }
    for (const item of Object.values(data)) {
      const message = parseMessageFromData(item)
      if (message) {
        return message
      }
    }
  }
  return null
}

export function parseErrorFromCatch(
  err: unknown,
  defaultError = DEFAULT_ERROR
): string {
  if (
    isAxiosError(err) &&
    err.response?.data &&
    typeof err.response?.data === 'object'
  ) {
    const messageFromData = parseMessageFromData(err.response.data)

    if (messageFromData) {
      return messageFromData
    } else if (
      inOperator('error_message', err.response.data) &&
      typeof err.response.data.error_message === 'string'
    ) {
      return err.response.data.error_message
    } else {
      return JSON.stringify(err.response.data)
    }
  } else if (
    isAxiosError(err) &&
    err.response?.data &&
    typeof err.response?.data === 'string'
  ) {
    return err.response.data
  } else if (isStandardError(err)) {
    return err.message
  } else if (typeof err === 'string') {
    return err
  } else {
    return defaultError
  }
}

export const handleApiError =
  (error: unknown) => (dispatch: Dispatch, getState: GetState) => {
    // If we get a 401 session has expired.  These should not be logged
    if (isAxiosError(error) && error.response?.status === 401) {
      dispatch(userLogout())
    } else {
      logHeardError(error)
      captureException(error, {
        user: { id: getState().auth.user?.id?.toString() },
      })
    }
  }
