import { Grid, Segment } from 'semantic-ui-react'
import moment from 'moment'

import {
  Transaction,
  TransactionOrSplit,
} from '../../../reducers/admin/allTransactions.slice'
import {
  Card,
  GridRowColumn,
  Popup,
  Text,
} from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import EditableTransactionTypeLabel from '../../../components/shared/EditableTransactionTypeLabel'
import { DisplayRowIcon } from './TransactionTableRow'
import { useReselector, useToggle } from '../../../utils/sharedHooks'
import TransactionCategoryLabel from '../../../components/shared/TransactionCategoryLabel'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { Colors } from '../../../styles/theme'
import { getLockedTransactionDescription } from '../copyConstants'
import {
  selectIsLockedTransaction,
  selectIsSplitTransaction,
} from '../transactions.selectors'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { UploadDocumentType } from '../../../constants/businessConstants'
import TransactionDetailsMenu from './TransactionDetailsMenu'
import { selectIsUserManualAccount } from '../../../selectors/financeSelectors'

const TypeField = ({
  transaction,
  review,
}: {
  transaction: TransactionOrSplit
  review?: boolean
}) => {
  const isSplitTransaction = useReselector(
    selectIsSplitTransaction,
    transaction.id
  )

  if (isSplitTransaction) {
    return (
      <Text as="bodySm" color="darkGray">
        Split Transaction
      </Text>
    )
  }
  return (
    <EditableTransactionTypeLabel transaction={transaction} review={review} />
  )
}

const AmountField = ({ transaction }: { transaction: TransactionOrSplit }) => {
  const isSplitTransaction = useReselector(
    selectIsSplitTransaction,
    transaction.id
  )
  return (
    <>
      {isSplitTransaction && (
        <s>
          <Text as="bodySm">
            <CurrencyFormatLabel
              value={centsToDollars(transaction.amountInCents)}
            />
          </Text>
        </s>
      )}
      {!isSplitTransaction && (
        <Text as="bodySm">
          <CurrencyFormatLabel
            value={centsToDollars(transaction.amountInCents)}
          />
        </Text>
      )}
    </>
  )
}

const LastRow = ({
  transaction,
  review,
  reports,
}: {
  transaction: TransactionOrSplit
  review?: boolean
  reports?: boolean
}) => {
  const locked = useReselector(
    selectIsLockedTransaction,
    transaction.splitFrom || transaction.id
  )

  const isManualTransaction = useReselector(
    selectIsUserManualAccount,
    transaction.financialAccountId
  )

  const [fileModalOpen, toggleFileModal] = useToggle(false)

  return (
    <>
      <Grid.Row style={{ paddingTop: 4.5 }}>
        <Grid.Column floated="left" width={2} verticalAlign="middle">
          <DisplayRowIcon
            isLocked={locked}
            isAutoCategorized={Boolean(transaction.autocategorizedAt)}
            isManualTransaction={isManualTransaction}
          />
        </Grid.Column>
        <Grid.Column
          floated="right"
          width={14}
          textAlign="right"
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            gap: 20.5,
          }}
        >
          <TransactionCategoryLabel
            editable={!reports}
            variant="text"
            transaction={transaction}
            review={review}
            filtered
            useRecategorizationModal
          />
          {!reports && !locked && (
            <TransactionDetailsMenu
              transaction={transaction}
              review={review}
              toggleFileModal={toggleFileModal}
              isManualTransaction={isManualTransaction}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <FileUploadModal
        open={fileModalOpen}
        close={toggleFileModal}
        documentType={UploadDocumentType.RECEIPT}
        userFacing
        extraPayload={{
          transactionId: Number(transaction.splitFrom || transaction.id),
        }}
      />
    </>
  )
}

const SplitTransactionRow = ({
  transaction,
  review,
  reports,
}: {
  transaction: TransactionOrSplit
  review?: boolean
  reports?: boolean
}) => {
  return (
    <Card type="subsection" backgroundColor="stone40" fullWidth>
      <Grid>
        <Grid.Row>
          {!reports && (
            <Grid.Column floated="left" width={8}>
              <TypeField transaction={transaction} review={review} />
            </Grid.Column>
          )}
          <Grid.Column floated="right" width={8} textAlign="right">
            <AmountField transaction={transaction} />
          </Grid.Column>
        </Grid.Row>
        <LastRow transaction={transaction} review={review} reports={reports} />
      </Grid>
    </Card>
  )
}

const RowContent = ({
  transaction,
  review,
  reports,
}: {
  transaction: Transaction
  review?: boolean
  reports?: boolean
}) => {
  const isSplitTransaction = useReselector(
    selectIsSplitTransaction,
    transaction.id
  )
  const locked = useReselector(selectIsLockedTransaction, transaction.id)
  return (
    <Segment
      disabled={locked && !reports}
      vertical
      style={
        locked && !reports
          ? {
              backgroundColor: Colors.stone,
              borderBottom: '1px solid rgba(34,36,38,.15)',
            }
          : {}
      }
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: 8 }}>
          <Grid.Column width={3}>
            <Text as="bodySm">
              <b>
                {moment
                  .utc(transaction.date)
                  .format(DATE_FORMATS.DISPLAY_SHORT)}
              </b>
            </Text>
          </Grid.Column>
          <Grid.Column width={3} textAlign="right">
            <AmountField transaction={transaction} />
          </Grid.Column>
          <Grid.Column width={6} textAlign="left">
            <Text as="bodySm">
              <i>{transaction.nickname}</i>
            </Text>
            <Text as="bodySm">{transaction.description}</Text>
          </Grid.Column>
          {reports && (
            <Grid.Column width={4}>
              <TransactionCategoryLabel
                editable={!reports}
                transaction={transaction}
                review={review}
                filtered
                useRecategorizationModal
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {!reports && (
          <GridRowColumn short style={{ paddingBottom: 8 }}>
            <TypeField transaction={transaction} review={review} />
          </GridRowColumn>
        )}
        {!isSplitTransaction && !reports && (
          <LastRow
            transaction={transaction}
            review={review}
            reports={reports}
          />
        )}
        {isSplitTransaction &&
          !reports &&
          transaction.splitTransactions?.map((splitTransaction) => (
            <GridRowColumn
              key={splitTransaction.id}
              className="split-transaction-row"
            >
              <SplitTransactionRow
                transaction={splitTransaction}
                review={review}
                reports={reports}
              />
            </GridRowColumn>
          ))}
      </Grid>
    </Segment>
  )
}

const MobileTableRow = ({
  transaction,
  review,
  reports,
}: {
  transaction: Transaction
  review?: boolean
  reports?: boolean
}) => {
  const locked = useReselector(selectIsLockedTransaction, transaction.id)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  if (locked && !reports) {
    return (
      <GridRowColumn style={{ padding: 0 }}>
        <Popup
          content={
            getLockedTransactionDescription().transactionRowLockIconTooltip
          }
          trigger={<RowContent transaction={transaction} review={review} />}
          style={isMobile ? { padding: 0 } : {}}
        />
      </GridRowColumn>
    )
  } else {
    return (
      <GridRowColumn>
        <RowContent
          transaction={transaction}
          review={review}
          reports={reports}
        />
      </GridRowColumn>
    )
  }
}

export default MobileTableRow
