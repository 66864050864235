import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import '../payroll.scss'

import PayrollSetupList from './PayrollSetupList'
import {
  FETCH_COMPANY_FEDERAL_TAXES_KEY,
  fetchCompanyFederalTaxes,
  fetchCompanyIndustry,
  PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY,
  PUT_UPDATE_COMPANY_INDUSTRY_KEY,
  putUpdateCompanyFederalTaxes,
  putUpdateCompanyIndustry,
} from '../payrollActions'
import {
  GEP_ENROLL_PATHS,
  INDUSTRY_OPTIONS,
  mapTaxType,
  ONBOARDING_STEPS,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import { getCurrentUser } from '../../../selectors/user.selectors'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  GridRowColumn,
  Link,
  makeReqStringSchema,
  Popup,
  Text,
  Icon,
  Alert,
  getFieldName,
  makeSsnSchema,
  einRegex,
} from '../../../components/BaseComponents'
import {
  getIsFetchingOrNotStarted,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import { updateFinancialProfile } from '../../../actions/financialProfileActions'
import {
  Gusto_CompanyFederalTaxes,
  Gusto_EntityType,
} from '../generated_gusto_types'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../Amplitude'

const TAX_PAYER_TYPES: Gusto_EntityType[] = [
  'C-Corporation',
  'S-Corporation',
  'Sole proprietor',
  'Limited partnership',
  'General partnership',
  'LLC',
]
const taxPayerTypeOptions = TAX_PAYER_TYPES.map((val) => {
  if (val === 'S-Corporation') {
    return { text: 'S corporation', value: val }
  }
  return { text: val, value: val }
})

const validationSchema = yup.object({
  industry: makeReqStringSchema({ field: 'industry' }),
  taxPayerType: makeReqStringSchema({ field: 'tax payer type' }),
  ein: yup
    .string()
    .when('taxPayerType', ([taxPayerType], schema) =>
      taxPayerType !== 'Sole proprietor'
        ? yup
            .string()
            .nullable()
            .length(10, 'Must be exactly 9 digits')
            .matches(einRegex, 'Please enter a valid value')
            .required()
        : schema
    ),
  ssn: yup
    .string()
    .when('taxPayerType', ([taxPayerType], schema) =>
      taxPayerType === 'Sole proprietor' ? makeSsnSchema({}) : schema
    ),
})

const getDefaultEin = (einNum?: string | null) => {
  if (!einNum) {
    return ''
  }

  return /^\d{9}$/.test(einNum)
    ? `${einNum.slice(0, 2)}-${einNum.slice(2)}`
    : einNum
}

const FederalForm = ({
  industry,
  federalTaxes,
}: {
  industry?: string | null
  federalTaxes?: Gusto_CompanyFederalTaxes
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const user = useReselector(getCurrentUser)
  const isPosting = useReselector(selectIsFetchingForKeys, [
    PUT_UPDATE_COMPANY_INDUSTRY_KEY,
    PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY,
  ])

  const formik = useFormik({
    initialValues: {
      industry: industry || '',
      taxPayerType:
        federalTaxes?.tax_payer_type ||
        mapTaxType(user?.financialProfile?.taxEntityType),
      filingForm: federalTaxes?.filing_form || ('' as const),
      ein: getDefaultEin(user?.financialProfile?.einNumber),
      ssn: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ filingForm, taxPayerType, industry, ein, ssn }) => {
      if (!filingForm || !taxPayerType || !federalTaxes?.version) {
        return
      }

      const hasEin = taxPayerType !== 'Sole proprietor'

      // Update the financial profile with the ein
      if (hasEin && user?.financialProfile) {
        const fpRes = await updateFinancialProfile(user.financialProfile.id, {
          einNumber: ein,
        })(dispatch)

        if (!fpRes) {
          return
        }
      }

      const industryRes = await putUpdateCompanyIndustry(
        INDUSTRY_OPTIONS[industry]
      )(dispatch)

      if (!industryRes) {
        return
      }

      const federalTaxesRes = await putUpdateCompanyFederalTaxes({
        version: federalTaxes.version,
        taxable_as_scorp: taxPayerType === 'S-Corporation',
        tax_payer_type: taxPayerType,
        filing_form: filingForm,
        ein: hasEin ? ein : ssn,
      })(dispatch)

      if (federalTaxesRes) {
        track('completed payroll step - set company federal taxes and industry')
        navigate(GEP_ENROLL_PATHS.locations)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Add Tax Information</Text>
        </GridRowColumn>
        <GridRowColumn short>
          <Text as="bodyLg">Please add and confirm the following details.</Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={10}>
            <FormikDropdown
              name={getFieldName<typeof formik.values>('taxPayerType')}
              options={taxPayerTypeOptions}
              placeholder="Select Tax Entity"
              required
              fullWidth
              label={
                <>
                  Tax Entity{'  '}
                  <Link
                    href="https://support.joinheard.com/hc/en-us/articles/4761703149463"
                    newPage
                  >
                    Help me with this
                  </Link>
                </>
              }
            />
          </Grid.Column>
          <Grid.Column width={1}>
            <Popup
              trigger={
                <Icon
                  className="tooltip"
                  icon={regular('info-circle')}
                  style={{ marginTop: 35 }}
                />
              }
              content="If your business is organized as a PLLC, please select LLC as they are treated the same way."
              hoverable
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn width={10}>
          {formik.values.taxPayerType === 'Sole proprietor' ? (
            <FormikInput
              name={getFieldName<typeof formik.values>('ssn')}
              label="SSN"
              required
              fullWidth
              format="###-##-####"
              componentType="pattern"
            />
          ) : (
            <FormikInput
              name={getFieldName<typeof formik.values>('ein')}
              required
              label="EIN"
              fullWidth
              format="##-#######"
              componentType="pattern"
            />
          )}
        </GridRowColumn>
        <GridRowColumn width={10}>
          <FormikDropdown
            name={getFieldName<typeof formik.values>('filingForm')}
            optionValues={['941', '944']}
            label={
              <>
                Federal Filing Form{'  '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/7479704593687"
                  newPage
                >
                  Help me with this
                </Link>
              </>
            }
            description="Choose the filing form assigned to you by the IRS"
            required
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDropdown
            name={getFieldName<typeof formik.values>('industry')}
            optionValues={Object.keys(INDUSTRY_OPTIONS)}
            description="When setting up your business for payroll, the federal government requires us to provide more context on your profession. We have pre-filled this field with our best recommendation."
            label="Industry Information"
            required
            fullWidth
          />
        </GridRowColumn>
        <Grid.Row />
        <Grid.Row />
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              variant="secondary"
              fullWidth
              onClick={() => navigate(GEP_ENROLL_PATHS.company)}
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button onClick={formik.submitForm} fullWidth loading={isPosting}>
              Save & Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

const PayrollEnrollFederal = () => {
  const isFetchingTaxes = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_COMPANY_FEDERAL_TAXES_KEY
  )
  const industry = useFetchResponse(fetchCompanyIndustry)?.title
  const federalTaxes = useFetchResponse(fetchCompanyFederalTaxes)
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.companyFederalTaxes)

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <PayrollError fetchKey={PUT_UPDATE_COMPANY_INDUSTRY_KEY} />
          <PayrollError fetchKey={PUT_UPDATE_COMPANY_FEDERAL_TAXES_KEY} />
          {!isFetchingTaxes && !federalTaxes && (
            <Alert type="error">Something went wrong</Alert>
          )}
          <Grid.Row>
            <Grid.Column width={9}>
              <FederalForm federalTaxes={federalTaxes} industry={industry} />
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollFederal
