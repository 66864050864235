import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useFetchResponse } from '../../../utils/sharedHooks'
import { useMemo, useCallback } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'

import {
  fetchUserCompleteOnboardingDate,
  fetchUserEventKind,
  createUserOnboardingEvent,
} from '../../Onboarding/UserOnboardingSteps/onboarding.actions'
import { DateTime } from 'luxon'

export enum UserMilestoneEventKind {
  userSnapImmediately = 'user-snap-immediately',
}

const UsersnapImmediately = () => {
  const completedDate = useFetchResponse(fetchUserCompleteOnboardingDate)

  const dispatch = useAppDispatch()

  const fetchUserEventImmediately = useFetchResponse(fetchUserEventKind, {
    kind: UserMilestoneEventKind.userSnapImmediately,
  })

  const isAlreadySubmitted = useMemo(() => {
    return fetchUserEventImmediately?.eventSubmitted
  }, [fetchUserEventImmediately])

  const shouldShowSurvey = useMemo(() => {
    let showSurvey = false
    if (completedDate?.status) {
      const currentDate = DateTime.now()
      const completedDateLuxon = completedDate?.mostRecentCompletedAt
        ? DateTime.fromISO(String(completedDate.mostRecentCompletedAt))
        : null
      if (completedDateLuxon) {
        const differenceInDays = currentDate.diff(
          completedDateLuxon,
          'days'
        ).days
        if (differenceInDays <= 30) {
          showSurvey = true
        }
      }
    }
    return showSurvey
  }, [completedDate])

  const onSubmit = useCallback(() => {
    dispatch(
      createUserOnboardingEvent({
        kind: UserMilestoneEventKind.userSnapImmediately,
      })
    )
  }, [dispatch])

  useUsersnapCustomDisplayLogicWidget({
    projectKey: process.env.VITE_USERSNAP_IMMEDIATELY,
    shouldShow: shouldShowSurvey && !isAlreadySubmitted,
    maxTimesShown: 3,
    showAgainAfterDays: 3,
    onSubmit,
  })

  return null
}

export default UsersnapImmediately
