import { useEffect, useState } from 'react'
import {
  Container,
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { Divider, Grid } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { getUserById } from '../../../../selectors/user.selectors'
import BalanceSheetBreadcrumbs from './components/BalanceSheetBreadcrumbs'
import { useReselector } from '../../../../utils/sharedHooks'
import { adminFetchSingleUser } from '../../../../actions/admin/adminAllUsersActions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  checkAdminBalanceSheet,
  CHECK_BALANCE_SHEET_KEY,
} from '../../../../actions/reportActions'
import { getIsFetching } from '../../../../reducers/fetch'

export const AdminAutoBalanceSheetPanel = () => {
  const params = useParams<{ userId: string }>()
  const user = useReselector(getUserById, Number(params.userId))
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const [validationStatus, setValidationStatus] = useState<boolean>()

  const dispatch = useAppDispatch()

  const isFetchingStatus = useReselector(getIsFetching, CHECK_BALANCE_SHEET_KEY)

  useEffect(() => {
    if (params.userId) {
      const userId = Number(params.userId)
      dispatch(adminFetchSingleUser(userId))
    }
  }, [dispatch, params.userId])

  const checkBalanceSheetValidation = async () => {
    if (!user?.id || !currentTaxYear) return
    const response = await checkAdminBalanceSheet(
      user?.id,
      currentTaxYear
    )(dispatch)
    if (response) {
      setValidationStatus(response.validationStatus)
    }
  }

  const downloadBalanceSheet = () => {
    if (!user?.id || !currentTaxYear) return
    window.open(
      `${process.env.VITE_LOCAL_API_ENDPOINT}finances/api/v1/admin/${user.id}/balance_sheets/${currentTaxYear}/download`
    )
  }

  return (
    <Container id="balanceSheetReport">
      <BalanceSheetBreadcrumbs
        firstName={user?.firstName}
        lastName={user?.lastName}
        userId={user?.id}
      />
      <Divider />
      <Grid>
        <GridRowColumn>
          <Text as="h2">
            Automated Balance Sheet for {currentTaxYear} Tax Year
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Button
            onClick={checkBalanceSheetValidation}
            loading={isFetchingStatus}
          >
            Check Balance Sheet Validation Status
          </Button>
          <Text>
            {validationStatus !== undefined && (
              <Text>
                {validationStatus === true
                  ? 'Balance Sheet has passed the checks'
                  : 'Balance Sheet did not pass the automated checks'}
              </Text>
            )}
          </Text>
        </GridRowColumn>
        {validationStatus === true && (
          <GridRowColumn>
            <Button onClick={downloadBalanceSheet}>
              Download Balance Sheet File
            </Button>
          </GridRowColumn>
        )}
      </Grid>
    </Container>
  )
}
