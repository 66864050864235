import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import {
  useFetchResponse,
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import {
  FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY,
  fetchInsightsUncategorizedTransactionsData,
} from './financeActions'
import { Alert, GridRowColumn, Link, Loader, Text } from '../../BaseComponents'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import { getIsFetching } from '../../../reducers/fetch'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'

export const UncategorizedTransactionBanner = ({
  inputDate,
  practiceInsightsPage,
}: {
  inputDate?: string
  practiceInsightsPage?: boolean
}) => {
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const currentDate = inputDate
    ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
    : DateTime.now()

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  const { endDate, month, year } = useMemo(() => {
    const endDate = getEndDate()

    return {
      endDate: endDate.toFormat(DATE_FORMATS_LUXON.TIMESTAMP),
      month: endDate.toFormat('LLLL'),
      year: endDate.year,
    }
  }, [getEndDate])

  const uncategorizedTransactionData = useFetchResponse(
    fetchInsightsUncategorizedTransactionsData,
    endDate
  )

  const isLoading = useReselector(
    getIsFetching,
    FETCH_INSIGHTS_UNCATEGORIZED_TRANSACTIONS_KEY
  )

  if (isLoading)
    return (
      <GridRowColumn>
        <Loader loading />
      </GridRowColumn>
    )

  if (!uncategorizedTransactionData?.total_transactions) return null

  const transactionCount =
    Number(uncategorizedTransactionData.total_transactions) || 0
  const totalAmount = formatCurrencyFromCents(
    uncategorizedTransactionData.total_amount_in_cents || 0
  )
  const needsReview = uncategorizedTransactionData?.needs_review
  const linkTo = needsReview
    ? '/transactions/review'
    : '/transactions?categoryId=-1'
  const linkText = needsReview ? 'Review Transactions' : 'View Transactions'

  const secondSentenceText = () => {
    if (hasFreeTrialPromoCode) {
      return 'Categorize your transactions to include them.'
    }
    const baseText =
      'These numbers might change after our bookkeeping team has categorized them.'
    return needsReview
      ? `${baseText} There are some transactions that need your review.`
      : baseText
  }

  const commonText = (
    <>
      You have <b>{transactionCount} uncategorized</b> transaction
      {transactionCount > 1 ? 's ' : ' '}
      (totaling {totalAmount}) not included on this page.
    </>
  )

  if (practiceInsightsPage) {
    return (
      <GridRowColumn>
        <Alert type="warn">
          <Text>
            {commonText} These numbers might change after our bookkeeping team
            has categorized them.
          </Text>
          <Link
            to={linkTo}
            trackMessage={'view-uncategorized-transactions'}
            trackAttributes={{ screen: 'practice insights' }}
          >
            View Transactions
          </Link>
        </Alert>
      </GridRowColumn>
    )
  }

  return (
    <GridRowColumn>
      <Alert>
        <Text>
          {commonText} up to {month} {year}. {secondSentenceText()}
        </Text>
        <Link to={linkTo}>{linkText}</Link>
      </Alert>
    </GridRowColumn>
  )
}
