import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { Grid, List } from 'semantic-ui-react'

import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  fetchUserYearEndModuleStatuses,
  YearEndModuleType,
} from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { getAnnualTaxFilingForYearSelector } from '../../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { getYearEndModuleStatusesByType } from '../../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import { useFinalReviewStepContext, useYearFromParams } from '../hooks'
import { userSubmitBookkeepingModule } from '../../end-of-year-review/actions'
import {
  Alert,
  Checkbox,
  GridRowColumn,
  Loader,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import FinalReviewWrapper from '../final-review-wrapper'
import './styles.scss'

export interface ContentProps {
  year?: string
  notesAdded: boolean
  completedTQ: boolean
  optedOutOfTaxes: boolean
  navigate: NavigateFunction
  onComplete: () => Promise<void>
  completing: boolean
}

export const Content = ({
  year,
  notesAdded,
  completedTQ,
  optedOutOfTaxes,
  navigate,
  onComplete,
  completing,
}: ContentProps) => {
  const [isChecked, setIsChecked] = useState(notesAdded || false)

  useEffect(() => {
    if (notesAdded) {
      setIsChecked(true)
    }
  }, [notesAdded])

  const onAcknowledgeClick = () => {
    setIsChecked(!isChecked)
  }

  const getBulletList = () => {
    if (completedTQ) {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
          <List.Item>
            Your finalized books will be automatically sent to your tax preparer{' '}
            along with your completed Tax Questionnaire.
          </List.Item>
        </List>
      )
    } else if (optedOutOfTaxes) {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
        </List>
      )
    } else {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
          <List.Item>
            Your finalized books will be automatically sent to your tax preparer{' '}
            after you complete my Tax Questionnaire.
          </List.Item>
        </List>
      )
    }
  }

  return (
    <FinalReviewWrapper
      year={year}
      step={3}
      stepBack={() =>
        navigate(
          `/taxes/annual/review-and-close-books/${year}/final-review-expenses`
        )
      }
      stepForward={onComplete}
      saveAndExit={() => navigate('/taxes/annual')}
      canComplete={isChecked}
      completing={completing}
      isComplete
      completeButtonText={notesAdded ? 'Submit Books for Review' : undefined}
    >
      <Grid>
        <GridRowColumn centerContent className="header-container">
          <StepHeaders
            customColor="green"
            description={
              notesAdded
                ? 'Our bookkeeping team will review your notes and make any corrections. You`ll then have another opportunity to review your books before signing off.'
                : `If everything looks satisfactory, finalize your ${year} books by signing off below.`
            }
            imageSrc={
              notesAdded
                ? 'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg'
                : 'https://heard-images.s3.amazonaws.com/assets/bookkeeping.svg'
            }
            imageAlt="bookkeeping"
            imageWidth={180}
            imageHeight={180}
            title={
              notesAdded ? "We'll take another look!" : 'Sign off on your books'
            }
          >
            {!notesAdded && (
              <GridRowColumn>
                <div className="alert-footer">
                  <Alert
                    title="You acknowledge that:"
                    style={{ width: '520px' }}
                    customIcon="none"
                    contentStyle={{ textAlign: 'left' }}
                    type="acknowledge"
                  >
                    {getBulletList()}
                    <Checkbox
                      id={'acknowledge'}
                      label="I acknowledge the above and sign off"
                      checked={isChecked}
                      onClick={onAcknowledgeClick}
                    />
                  </Alert>
                </div>
              </GridRowColumn>
            )}
          </StepHeaders>
        </GridRowColumn>
      </Grid>
    </FinalReviewWrapper>
  )
}

export const FinalSignOff = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const year = useYearFromParams()
  const { finalReviewContext } = useFinalReviewStepContext()
  const [loading, setLoading] = useState(true)
  const [completing, setCompleting] = useState(false)

  const { optedOutAt } =
    useReselector(getAnnualTaxFilingForYearSelector, year) ?? {}
  const tqModule = useReselector(getYearEndModuleStatusesByType)[
    YearEndModuleType.taxQuestionnaire
  ]

  const notesAdded = useMemo(() => {
    return Boolean(finalReviewContext?.updatedTransactionIds?.length)
  }, [finalReviewContext])

  const onComplete = useCallback(async () => {
    setCompleting(true)
    await dispatch(userSubmitBookkeepingModule())
    setCompleting(false)
    navigate('/taxes/annual')
  }, [dispatch, navigate])

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchUserYearEndModuleStatuses()),
        dispatch(fetchAnnualTaxFilingsIfNeeded()),
      ])

      setLoading(false)
    }

    fetch()
  }, [dispatch, year])

  return loading ? (
    <Loader loading />
  ) : (
    <Content
      year={year}
      notesAdded={notesAdded}
      completedTQ={Boolean(tqModule?.completedAt)}
      optedOutOfTaxes={Boolean(optedOutAt)}
      navigate={navigate}
      onComplete={onComplete}
      completing={completing}
    />
  )
}
