import { createSelector } from 'reselect'
import {
  selectHeardProductSubscription,
  selectIsBasicPlan,
} from '../../reducers/subscription.slice'
import {
  getCurrentUser,
  membershipIsIn,
  selectPrimaryMembership,
} from '../../selectors/user.selectors'
import { MembershipScopes } from '../../reducers/auth/userReducer'
import { DateTime } from 'luxon'
import { selectIsPayrollActive } from '../Payroll/payroll.selectors'
import { hasTaxfyleJobOrExtensionInProgress } from '../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { STRIPE_PRICE_MONTH_INTERVAL } from '../../actions/settings/billingActions'
/**
 * A user is eligble for the cancellation discount if they:
 * 1. are not on a basic plan
 * 2. have not received the discount before
 * 3. do not have any other current discounts
 * 4. are not currently on a trial
 * 5. were not on a trial within the last year
 */
export const isEligibleForDiscount = createSelector(
  getCurrentUser,
  selectIsBasicPlan,
  selectHeardProductSubscription,
  selectPrimaryMembership,
  (_: unknown, discountFlagOn: boolean) => discountFlagOn,
  (user, isBasicPlan, sub, membership, discountFlagOn) =>
    discountFlagOn &&
    !isBasicPlan &&
    user?.cancellationDiscountApplied !== true &&
    sub?.discounts.length === 0 &&
    !membershipIsIn(MembershipScopes.trial, membership) &&
    (!membership?.trialStartDate ||
      DateTime.fromISO(membership.trialStartDate) <
        DateTime.now().minus({ years: 1 }))
)

/**
 * Returns true if the user has an annual commitment on their primary membership
 * NOTE: Do not use for active commitment checks, use selectUserHasActiveAnnualCommitment instead
 */
export const selectHasAnnualCommitment = createSelector(
  selectPrimaryMembership,
  (primaryMembership) => Boolean(primaryMembership?.hasAnnualCommitment)
)

/**
 * Returns true if the user has an active annual commitment
 */
export const selectUserHasActiveAnnualCommitment = createSelector(
  selectHeardProductSubscription,
  selectPrimaryMembership,
  (heardPrimarySubscription, primaryMembership) => {
    const primarySubscriptionInterval =
      heardPrimarySubscription?.items?.default?.interval
    if (
      primaryMembership?.hasAnnualCommitment &&
      primarySubscriptionInterval === STRIPE_PRICE_MONTH_INTERVAL &&
      primaryMembership.startDate
    ) {
      return (
        DateTime.now() <
        DateTime.fromISO(primaryMembership.startDate).plus({ years: 1 })
      )
    } else {
      return false
    }
  }
)

export const selectCanSelfServeCancel = createSelector(
  selectUserHasActiveAnnualCommitment,
  selectIsPayrollActive,
  hasTaxfyleJobOrExtensionInProgress,
  (hasActiveAnnualCommitment, isPayrollActive, hasTaxfyleJobInProgress) =>
    !hasActiveAnnualCommitment && !isPayrollActive && !hasTaxfyleJobInProgress
)
