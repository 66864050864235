import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Card, Icon, Text } from '../../BaseComponents'
import {
  INSIGHTS_CHANGE_TYPE,
  INSIGHTS_TYPE,
  formatPercentage,
  insightsCopyConstants,
} from './utils'
import { getFetchError } from '../../../reducers/fetch'
import { useReselector } from '../../../utils/sharedHooks'
import { FETCH_INSIGHTS_SUMMARY_KEY } from './financeActions'

export const getSummaryIcon = (type: INSIGHTS_CHANGE_TYPE) => {
  switch (type) {
    case INSIGHTS_CHANGE_TYPE.INCREASE:
      return solid('circle-arrow-up')
    case INSIGHTS_CHANGE_TYPE.SAME:
      return solid('circle-pause')
    default:
      return solid('circle-arrow-down')
  }
}

export const SummaryTextForExpenses = ({
  formattedPercentChange,
  formattedPercentCounterpart,
  changeType,
  amount,
  currentMonth,
  prevMonth,
  prevMonthTotal,
  textForDashboardCard,
}: {
  formattedPercentChange: string
  formattedPercentCounterpart: string
  changeType?: INSIGHTS_CHANGE_TYPE
  amount?: string
  currentMonth?: string
  prevMonth?: string
  prevMonthTotal?: string
  textForDashboardCard?: boolean
}) => {
  switch (changeType) {
    case INSIGHTS_CHANGE_TYPE.INCREASE:
      return (
        <Text color="darkGray">
          Your practice&apos;s expense spending of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} higher</b> than {prevMonth}.{' '}
          {formattedPercentCounterpart !== '0%' &&
            ` Your expenses were ${formattedPercentCounterpart} of your income.`}
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.DECREASE:
      return (
        <Text color="darkGray">
          {textForDashboardCard && (
            <>
              <b>Great news!</b>
              <br />
            </>
          )}
          Your practice&apos;s expense spending of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} lower</b> than {prevMonth}.{' '}
          {formattedPercentCounterpart !== '0%' &&
            ` Your expenses were ${formattedPercentCounterpart} of your income.`}
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.SAME:
      return (
        <Text color="darkGray">
          Your practice&apos;s expense spending of {amount} in {currentMonth} is
          about <b>the same as</b> your expense spending in {prevMonth}. Keep
          going!
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.LAST_MONTH_EMPTY:
      return (
        <Text color="darkGray">
          Your practice&apos;s expense spending was {amount} in {currentMonth}{' '}
          compared to $0 in {prevMonth}.
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.EMPTY:
      return (
        <Text color="darkGray">
          Your practice&apos;s expense spending was $0 in {currentMonth}{' '}
          compared to {prevMonthTotal} in {prevMonth}.
        </Text>
      )
    case undefined:
      return (
        <Text color="darkGray">
          How much your monthly expense spending increases or decreases will
          show up here.
        </Text>
      )
    default:
      return changeType satisfies never
  }
}

const SummaryTextForIncome = ({
  formattedPercentChange,
  changeType,
  amount,
  currentMonth,
  prevMonth,
  prevMonthTotal,
}: {
  formattedPercentChange: string
  changeType?: INSIGHTS_CHANGE_TYPE
  amount?: string
  currentMonth?: string
  prevMonth?: string
  prevMonthTotal?: string
}) => {
  switch (changeType) {
    case INSIGHTS_CHANGE_TYPE.INCREASE:
      return (
        <Text color="darkGray">
          Your practice&apos;s income of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} higher</b> than {prevMonth}. Great job
          increasing your income!
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.DECREASE:
      return (
        <Text color="darkGray">
          Your practice&apos;s income of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} lower</b> than {prevMonth}. Look at your
          income transactions to understand why your income might be less.
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.SAME:
      return (
        <Text color="darkGray">
          Your practice&apos;s income of {amount} in {currentMonth} is about{' '}
          <b>the same as</b> your expense spending in {prevMonth}. Keep going!
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.LAST_MONTH_EMPTY:
      return (
        <Text color="darkGray">
          Your practice&apos;s income was {amount} in {currentMonth} compared to
          $0 in {prevMonth}.
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.EMPTY:
      return (
        <Text color="darkGray">
          Your practice&apos;s income was $0 in {currentMonth} compared to{' '}
          {prevMonthTotal} in
          {prevMonth}.
        </Text>
      )
    case undefined:
      return (
        <Text color="darkGray">
          How much your monthly income increases or decreases will show up here.
        </Text>
      )
    default:
      return changeType satisfies never
  }
}

const SummaryTextForProfit = ({
  formattedPercentChange,
  changeType,
  amount,
  currentMonth,
  prevMonth,
}: {
  formattedPercentChange: string
  changeType?: INSIGHTS_CHANGE_TYPE
  amount?: string
  currentMonth?: string
  prevMonth?: string
}) => {
  switch (changeType) {
    case INSIGHTS_CHANGE_TYPE.INCREASE:
      return (
        <Text color="darkGray">
          Your practice&apos;s profit of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} higher</b> than {prevMonth}. Great job!
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.DECREASE:
      return (
        <Text color="darkGray">
          Your practice&apos;s profit of {amount} in {currentMonth} is{' '}
          <b>{formattedPercentChange} lower</b> than {prevMonth}. Look at your
          income and expenses from last month to understand why your profit
          might be less.
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.SAME:
      return (
        <Text color="darkGray">
          Your practice&apos;s profit of {amount} in {currentMonth} is about{' '}
          <b>the same as</b> your profit in {prevMonth}.
        </Text>
      )
    case INSIGHTS_CHANGE_TYPE.LAST_MONTH_EMPTY:
      return (
        <Text color="darkGray">
          Your practice&apos;s profit was {amount} in {currentMonth}.
        </Text>
      )

    case INSIGHTS_CHANGE_TYPE.EMPTY:
    case undefined:
      return (
        <Text color="darkGray">
          How much your monthly profit increases or decreases will show up here.
        </Text>
      )
    default:
      return changeType satisfies never
  }
}

const getSummaryTextExpenses = (
  insightsType: INSIGHTS_TYPE,
  changeType?: INSIGHTS_CHANGE_TYPE,
  amount?: string,
  percentChange?: number,
  currentMonth?: string,
  prevMonth?: string,
  percentCounterpart?: number,
  prevMonthTotal?: string
) => {
  const formattedPercentChange = formatPercentage(percentChange)
  const formattedPercentCounterpart = formatPercentage(percentCounterpart)

  switch (insightsType) {
    case INSIGHTS_TYPE.EXPENSES:
      return (
        <SummaryTextForExpenses
          formattedPercentChange={formattedPercentChange}
          formattedPercentCounterpart={formattedPercentCounterpart}
          changeType={changeType}
          amount={amount}
          currentMonth={currentMonth}
          prevMonth={prevMonth}
          prevMonthTotal={prevMonthTotal}
        />
      )
    case INSIGHTS_TYPE.INCOME:
      return (
        <SummaryTextForIncome
          formattedPercentChange={formattedPercentChange}
          changeType={changeType}
          amount={amount}
          currentMonth={currentMonth}
          prevMonth={prevMonth}
          prevMonthTotal={prevMonthTotal}
        />
      )
    case INSIGHTS_TYPE.PROFIT:
      return (
        <SummaryTextForProfit
          formattedPercentChange={formattedPercentChange}
          changeType={changeType}
          amount={amount}
          currentMonth={currentMonth}
          prevMonth={prevMonth}
        />
      )
    case undefined:
      return (
        <Text color="darkGray">
          {insightsCopyConstants[insightsType].emptyCurrentSummary}
        </Text>
      )
    default:
      return insightsType satisfies never
  }
}

export const CurrentSummaryCard = ({
  headerText,
  type,
  amount,
  percentChange,
  currentMonth,
  prevMonth,
  percentCounterpart,
  insightsType,
  isResponseEmpty,
  prevMonthTotal,
}: {
  headerText?: string
  type: INSIGHTS_CHANGE_TYPE
  amount?: string
  percentChange?: number
  currentMonth?: string
  prevMonth?: string
  percentCounterpart?: number
  insightsType: INSIGHTS_TYPE
  isResponseEmpty: boolean
  prevMonthTotal?: string
}) => {
  const insightsSummaryError = useReselector(
    getFetchError,
    FETCH_INSIGHTS_SUMMARY_KEY
  )

  if (insightsSummaryError) {
    return (
      <Card
        type="subsection"
        backgroundColor="stone40"
        style={{ padding: 20, minHeight: 150 }}
      >
        <Text as="h3" style={{ marginBottom: 16 }}>
          {headerText}
        </Text>
        <Text color="darkGray">
          Something went wrong - please try again in a bit.
        </Text>
      </Card>
    )
  }

  if (isResponseEmpty) {
    return (
      <Card
        type="subsection"
        backgroundColor="stone40"
        style={{ padding: 20, minHeight: 150 }}
      >
        <Text as="h3" style={{ marginBottom: 16 }}>
          <Icon
            icon={getSummaryIcon(type)}
            style={{ marginRight: 8 }}
            rotation={type === 'same' ? 90 : undefined}
          />
          {headerText}
        </Text>
        <Text color="darkGray">
          {insightsCopyConstants[insightsType].emptyCurrentSummary}
        </Text>
      </Card>
    )
  }

  return (
    <Card
      type="subsection"
      backgroundColor="sunrise"
      style={{ padding: 20, minHeight: 150 }}
    >
      <Text as="h3" style={{ marginBottom: 16 }}>
        <Icon
          icon={getSummaryIcon(type)}
          style={{ marginRight: 8 }}
          rotation={type === 'same' ? 90 : undefined}
        />
        {headerText}
      </Text>
      {getSummaryTextExpenses(
        insightsType,
        type,
        amount,
        percentChange,
        currentMonth,
        prevMonth,
        percentCounterpart,
        prevMonthTotal
      )}
    </Card>
  )
}
