import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Button, Form, Header, Message, Modal } from 'semantic-ui-react'

import {
  AnnualTaxFilingExtensionPayment,
  updateAdminAnnualTaxFilingForm,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { AnnualTaxFiling } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import ExtensionPaymentsForm from './ExtensionPaymentsForm'
import { updateAdminAnnualTaxFiling } from './adminAnnualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useTaxFilingFormUploadModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [modalUserContext, setModalUserContext] = useState('')
  const [modalFilingFormIdContext, setModalFilingFormIdContext] = useState<
    number | undefined
  >()
  const [modalFilingFormTypeNameContext, setModalFilingFormTypeNameContext] =
    useState<string | undefined>()
  const [
    modalFilingFormExtensionPaymentsContext,
    setModalFilingFormExtensionPaymentsContext,
  ] = useState<AnnualTaxFilingExtensionPayment[]>()
  const [filingFormIsExtendedContext, setFilingFormIsExtendedContext] =
    useState<boolean>()

  const handleModalOpen = useCallback(
    (
      modalView: string,
      modalUserContext: string,
      filingFormId?: number,
      filingFormTypeName?: string,
      filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[] | null,
      filingFormIsExtended?: boolean | null
    ) => {
      setModalView(modalView)
      setModalUserContext(modalUserContext)
      if (filingFormId) {
        setModalFilingFormIdContext(filingFormId)
      }
      if (filingFormTypeName) {
        setModalFilingFormTypeNameContext(filingFormTypeName)
      }
      if (filingFormExtensionPayments) {
        setModalFilingFormExtensionPaymentsContext(filingFormExtensionPayments)
      }
      setFilingFormIsExtendedContext(filingFormIsExtended || false)
      setModalOpen(true)
    },
    []
  )
  return {
    modalOpen,
    setModalOpen,
    closeModal: () => setModalOpen(false),
    modalView,
    setModalView,
    modalUserContext,
    modalFilingFormIdContext,
    modalFilingFormTypeNameContext,
    modalFilingFormExtensionPaymentsContext,
    filingFormIsExtendedContext,
    handleModalOpen,
  }
}

interface TaxFilingFormUploadModalProps {
  modalOpen: boolean
  closeModal: () => void
  modalView: string
  setModalView: Dispatch<SetStateAction<string>>
  modalUserContext: string
  filingId: number
  refreshData: () => void
  filingFormId?: number
  filingFormTypeName?: string
  filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[]
  filingFormIsExtended?: boolean
  taxFilingUserId: number
}

const TaxFilingFormUploadModal = ({
  modalOpen,
  closeModal,
  modalView,
  setModalView,
  modalUserContext,
  filingId,
  refreshData,
  filingFormId,
  filingFormTypeName,
  filingFormExtensionPayments,
  filingFormIsExtended,
  taxFilingUserId,
}: TaxFilingFormUploadModalProps) => {
  const dispatch = useAppDispatch()
  const [googleSheetUrl, setGoogleSheetUrl] = useState<string>()

  const renderModalMessage = (
    modalView: string,
    user: string,
    formTypeName?: string
  ) => {
    switch (modalView) {
      case 'Update Google Sheet URL':
        return <p>{`You are updating the google sheet url for ${user}`}</p>
      case 'Extension Information':
        return (
          <p>{`You are updating ${formTypeName} extension details for ${user}`}</p>
        )
      default:
        return <p>{`You are updating files for ${user}`}</p>
    }
  }

  const updateGoogleSheetUrl = useCallback(
    async (annualTaxFilingId: number) => {
      const data = { googleSheetUrl } as Pick<AnnualTaxFiling, 'googleSheetUrl'>

      const res = await updateAdminAnnualTaxFiling(
        annualTaxFilingId,
        data
      )(dispatch)
      if (res) {
        closeModal()
        setGoogleSheetUrl(undefined)
        refreshData()
      }
    },
    [closeModal, dispatch, googleSheetUrl, refreshData]
  )

  const updateExtensionInfo = useCallback(
    async ({
      extensionPayments,
      filingFormId,
    }: {
      extensionPayments: AnnualTaxFilingExtensionPayment[]
      filingFormId: number
    }) => {
      const res = await updateAdminAnnualTaxFilingForm(filingFormId, {
        extensionPayments,
      })(dispatch)

      if (res) {
        closeModal()
        refreshData()
      }
    },
    [closeModal, dispatch, refreshData]
  )

  return (
    <Modal size="small" dimmer="inverted" open={modalOpen} onClose={closeModal}>
      <Modal.Content>
        <Header as="h3">{modalView}</Header>
        <Message className="noBorder green">
          {renderModalMessage(modalView, modalUserContext, filingFormTypeName)}
        </Message>
        <br />
        {modalView === 'Update Google Sheet URL' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Input
                  fluid
                  required
                  name="googleSheetUrl"
                  placeholder="Google Sheet URL"
                  label="Google Sheet URL"
                  onChange={(e, target) => setGoogleSheetUrl(target.value)}
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setGoogleSheetUrl(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  disabled={!googleSheetUrl}
                  onClick={() => updateGoogleSheetUrl(filingId)}
                >
                  Update Google Sheet URL
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Update Miscellaneous' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Dropdown
                  fluid
                  selection
                  required
                  label="Select Update Option"
                  placeholder="Select Update Option"
                  options={[
                    {
                      text: 'Update Google Sheet URL',
                      value: 'Update Google Sheet URL',
                    },
                  ]}
                  onChange={(_name, target) =>
                    setModalView(target.value as string)
                  }
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setGoogleSheetUrl(undefined)
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Extension Information' &&
          filingFormId &&
          filingFormTypeName && (
            <div>
              <ExtensionPaymentsForm
                paymentInfo={filingFormExtensionPayments}
                isEditing
                userId={taxFilingUserId}
                filingFormId={filingFormId}
                filingFormType={filingFormTypeName}
                closeModal={closeModal}
                isFormExtended={filingFormIsExtended}
                updateExtensionInfo={updateExtensionInfo}
              />
            </div>
          )}
      </Modal.Content>
    </Modal>
  )
}

export default TaxFilingFormUploadModal
