import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { GridRowColumn, Text, Alert, Link } from '../../BaseComponents'
import { Grid } from 'semantic-ui-react'
import { ArticleCard } from '../shared/ArticleCard'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { CurrentPanel } from '../shared/CurrentPanel'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { isEmpty } from 'lodash'
import { BannerCard } from '../../BaseComponents/BannerCard'
import {
  useInsightsDatePicker,
  InsightsDatePicker,
} from '../shared/InsightsDatePicker'
import { InsightBreadcrumb } from '../shared/InsightBreadcrumb'

const InsightsProfitPanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const trackInsights = useTrackFinancialInsightsButtonClick()

  const [searchParams] = useSearchParams()

  const { monthParam, yearParam } = useMemo(() => {
    return {
      monthParam: searchParams.get('month'),
      yearParam: searchParams.get('year'),
    }
  }, [searchParams])

  const { yearFilter, monthFilter, inputDate, setYearFilter, setMonthFilter } =
    useInsightsDatePicker(monthParam, yearParam)

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.PROFIT,
    })
  }

  return (
    <Grid padded={isMobile}>
      <GridRowColumn>
        <InsightBreadcrumb month={monthFilter} year={yearFilter} />
      </GridRowColumn>
      <GridRowColumn
        short
        columnStyle={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: 24,
        }}
      >
        <Text as="h1"> Profit </Text>
        <InsightsDatePicker
          monthFilter={monthFilter}
          yearFilter={yearFilter}
          handleMonthFilterChange={setMonthFilter}
          handleYearFilterChange={setYearFilter}
          insightsType={INSIGHTS_TYPE.INCOME}
        />
      </GridRowColumn>
      <GridRowColumn short />
      {displayBanner && (
        <GridRowColumn>
          <BannerCard
            headerText="What is Profit?"
            imageUrl="https://heard-images.s3.amazonaws.com/assets/coins-stack.svg"
            onClose={closeBanner}
            bodyContent={
              <>
                Your profit is the income you make minus your business expenses.
                It&apos;s the amount of money you have left after you have paid
                off all your business expenses each month.
                <br />
                <br />
                Building a profitable practice is the same as building a
                practice that can last, where you are taking care of yourself as
                well as your clients. Profit affects how much you are able to
                pay yourself (Owner&apos;s Draw) and save for things like
                retirement.
              </>
            }
          />
        </GridRowColumn>
      )}
      {!hasLinkedPlaidAccounts && (
        <GridRowColumn>
          <Alert>
            <Text>
              You do not have a connected bank account.{' '}
              <Link to={'/accounts#connected-institutions'}>
                Link Business Account
              </Link>
            </Text>
          </Alert>
        </GridRowColumn>
      )}
      <UncategorizedTransactionBanner inputDate={inputDate} />
      <CurrentPanel insightsType={INSIGHTS_TYPE.PROFIT} inputDate={inputDate} />
      <TrackInsightsSection type={INSIGHTS_TYPE.PROFIT} inputDate={inputDate} />
      <GridRowColumn>
        <Text as="h2" style={{ marginBottom: 12 }}>
          How can I build a profitable practice?
        </Text>
      </GridRowColumn>
      <Grid.Row className="short" columns={isMobile ? 1 : 3}>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="How To Build a Profitable Therapy Practice"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/how-to-build-a-profitable-therapy-practice"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="How To Keep Your Therapy Practice Profitable"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/how-to-keep-your-therapy-practice-profitable"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
        <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
          <ArticleCard
            title="The Complete Guide to Financial Planning for Therapists"
            type={ARTICLE_TYPE.ARTICLE}
            url="https://www.joinheard.com/articles/the-complete-guide-to-financial-planning-for-therapists"
            insightsType={INSIGHTS_TYPE.PROFIT}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export default InsightsProfitPanel
