import { DateTime } from 'luxon'
import { Button, Link, Text } from '../../../../../components/BaseComponents'
import { selectIsCurrentUserScorp } from '../../../../../selectors/user.selectors'
import { convertUtcToLocalDate } from '../../../../../utils/dateHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxDetailsByYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  getAnnualTaxFilingForYearSelector,
  selectAnnualTaxFilingForCurrentTaxYear,
  selectHasBeenConnectedToTaxfyle,
} from '../../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import {
  selectHasSubmitted1099sForYear,
  selectIsAfter1099NECSubmitToAboundDueDate,
} from '../../aboundAnnualTaxFilings.selector'
import { fetchAboundTaxDocumentsForPayer } from '../../aboundAnnualTaxFilings.slice'
import { useCallback, useEffect, useState } from 'react'
import Ten99OptOutModal from '../../Ten99Filing/Ten99OptOutModal'
import { useNavigate } from 'react-router-dom'
import {
  FETCH_TAX_CONSULTATIONS_KEY,
  fetchTaxConsultationRequests,
  inProgressConsultationStatuses,
  TaxConsultationRequest,
  TaxConsultationServiceRequestStatus,
} from '../../../Advisory/taxConsultationRequest.actions'
import TaxOptOutModal from '../../TaxOptIn/TaxOptOutModal'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'

export const FAQsSection = () => {
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)

  if (filing?.optedOutAt) {
    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <Text as="h2">FAQs</Text>
      <div>
        <Link
          href="https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs"
          newPage
        >
          Why can’t I connect with a tax preparer yet?
        </Link>
      </div>
      <div>
        <Link
          as="secondaryLink"
          color="forest"
          href="https://support.joinheard.com/hc/en-us/articles/20989977806231-Annual-Tax-FAQs"
          newPage
        >
          View All FAQs
        </Link>
      </div>
    </div>
  )
}

export const OptIntoExtension = ({ taxYear }: { taxYear: string }) => {
  const navigate = useNavigate()
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const now = DateTime.now()
  const filing = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, taxYear)
  if (!annualTaxDetails) {
    return null
  }
  if (filing?.extensionRequestedAt || filing?.optedOutAt) {
    return null
  }
  const scorpExtensionRequestCutoffDate = convertUtcToLocalDate(
    annualTaxDetails.form_1120_s_extension_survey_cutoff_date
  )?.endOf('day')
  const sPropExtensionRequestCutoffDate = convertUtcToLocalDate(
    annualTaxDetails.form_1040_extension_survey_cutoff_date
  )?.endOf('day')
  if (
    isScorp &&
    scorpExtensionRequestCutoffDate &&
    now > scorpExtensionRequestCutoffDate
  ) {
    return null
  } else if (
    !isScorp &&
    sPropExtensionRequestCutoffDate &&
    now > sPropExtensionRequestCutoffDate
  ) {
    return null
  }
  return (
    <div>
      <Button
        variant="link"
        onClick={() => navigate('/taxes/annual/extension_request')}
      >
        Get more time to file →
      </Button>
      <Text as="bodySm" style={{ paddingTop: 4 }}>
        Need more time? Requesting an extension is quick and easy.
      </Text>
    </div>
  )
}

export const OptInto1099NEC = ({ taxYear }: { taxYear: string }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const isAfter1099NECSubmitToAboundDueDate = useReselector(
    selectIsAfter1099NECSubmitToAboundDueDate
  )
  if (isAfter1099NECSubmitToAboundDueDate) {
    return null
  }

  return (
    <div>
      <Button variant="link" onClick={() => setModalOpen(true)}>
        Opt into 1099-NEC filing →
      </Button>
      <Text as="bodySm" style={{ paddingTop: 4 }}>
        If you didn’t pay a contractor $600 or more in {taxYear}, you don’t need
        to file a 1099-NEC.
      </Text>
      <Ten99OptOutModal
        year={taxYear}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        optIn
      />
    </div>
  )
}

export const OptOutOf1099NEC = ({ taxYear }: { taxYear: string }) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  // This should be visible through Jan 21, and then should disappear.
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, taxYear)
  const hasSubmitted1099NEC = useReselector(
    selectHasSubmitted1099sForYear,
    taxYear
  )

  useEffect(() => {
    dispatch(fetchAboundTaxDocumentsForPayer())
  })

  if (!annualTaxDetails || hasSubmitted1099NEC) {
    return null
  }

  return (
    <div>
      <Button variant="link" onClick={() => setModalOpen(true)}>
        Opt out of 1099-NEC filing →
      </Button>
      <Text as="bodySm" style={{ paddingTop: 4 }}>
        If you didn’t pay a contractor $600 or more in {taxYear}, you don’t need
        to file a 1099-NEC.
      </Text>
      <Ten99OptOutModal
        year={taxYear}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        optIn={false}
      />
    </div>
  )
}

export const OptIntoTaxesWithHeard = ({ taxYear }: { taxYear: string }) => {
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const annualTaxDetails = useReselector(selectTaxDetailsByYear, taxYear)
  const navigate = useNavigate()

  if (!annualTaxDetails) {
    return null
  }
  const scorpDueDate = convertUtcToLocalDate(
    annualTaxDetails.form_1120_s_tq_due_date
  )?.endOf('day')
  const sPropDueDate = convertUtcToLocalDate(
    annualTaxDetails.form_1040_tq_due_date
  )?.endOf('day')

  // Opt into taxes (valid through Mar 3 and Apr 1 for S-corps and Sole Props respectively)
  // after that, don't show this option
  const now = DateTime.now()
  if (isScorp && scorpDueDate && now > scorpDueDate) {
    return null
  } else if (!isScorp && sPropDueDate && now > sPropDueDate) {
    return null
  }

  return (
    <div>
      <Button variant="link" onClick={() => navigate('/taxes/annual/opt-in')}>
        Opt in to annual taxes →
      </Button>
      <Text as="bodySm" style={{ paddingTop: 4 }}>
        Opt back into filing your taxes with Heard. In order to make sure you
        have enough time, we&apos;ll help you file an extension request.
      </Text>
    </div>
  )
}

export const OptOutOfTaxesWithHeard = ({
  setOpenModal,
}: {
  setOpenModal: (open: boolean) => void
}) => {
  const hasConnectedtoTaxPro = useReselector(selectHasBeenConnectedToTaxfyle)
  if (hasConnectedtoTaxPro) {
    return null
  }
  return (
    <div>
      <Button variant="link" onClick={() => setOpenModal(true)}>
        Opt out of annual taxes →
      </Button>
      <Text as="bodySm" style={{ paddingTop: 4 }}>
        If you no longer require tax services from Heard, start the opt out
        process here.
      </Text>
    </div>
  )
}

export const OptInOrOutWithHeard = ({
  taxYear,
  optedOutOfTaxesWithHeard,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
}) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      <TaxOptOutModal open={openModal} close={() => setOpenModal(false)} />
      {optedOutOfTaxesWithHeard ? (
        <OptIntoTaxesWithHeard taxYear={taxYear} />
      ) : (
        <OptOutOfTaxesWithHeard setOpenModal={setOpenModal} />
      )}
    </>
  )
}

export const GetTaxAdvice = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const filing = useReselector(selectAnnualTaxFilingForCurrentTaxYear)
  const [inProgressConsultationRequest, setInProgressConsultationRequest] =
    useState<TaxConsultationRequest>()
  const [hasPreviousConsultationRequest, setHasPreviousConsultationRequest] =
    useState(false)
  const loading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_CONSULTATIONS_KEY,
  ])
  const goToConsultPage = useCallback(() => {
    if (inProgressConsultationRequest) {
      navigate(
        `/taxes/annual/tax_consultations/${inProgressConsultationRequest.id}/confirmed`
      )
    } else {
      navigate('/taxes/annual/tax_consultations')
    }
  }, [inProgressConsultationRequest, navigate])

  useEffect(() => {
    const loadRequests = async () => {
      const res = (await fetchTaxConsultationRequests()(dispatch)) ?? []
      setInProgressConsultationRequest(
        res.find((r) => inProgressConsultationStatuses.includes(r.status))
      )
      setHasPreviousConsultationRequest(
        res.some(
          (r) => r.status === TaxConsultationServiceRequestStatus.completed
        )
      )
    }
    loadRequests()
  }, [dispatch])

  if (filing?.optedOutAt) {
    return null
  }

  return (
    <div>
      <Button loading={loading} variant="link" onClick={goToConsultPage}>
        Get{hasPreviousConsultationRequest ? ' more' : ''} tax advice →
      </Button>
      {!loading && (
        <Text as="bodySm" style={{ paddingTop: 4 }}>
          {inProgressConsultationRequest
            ? 'Reach out to your tax preparer via the chat portal to book a 1:1 consult with them.'
            : hasPreviousConsultationRequest
              ? 'You can book another live call with your tax preparer for a fee.'
              : 'After you complete your tax questionnaire, you can book a 1:1 consult with your tax preparer.'}
        </Text>
      )}
    </div>
  )
}
