import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Dimmer, Loader } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
  TooltipProps,
} from 'recharts'

import { Card, Dropdown, Text } from '../../BaseComponents'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import {
  DEFAULT_YEAR,
  YEAR_OPTIONS,
} from '../../../constants/businessConstants'
import { fetchCashflow } from '../../../actions/reportActions'
import { Colors } from '../../../styles/theme'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'

// Shortens numbers into using k and M
const formatter = Intl.NumberFormat('en', { notation: 'compact' })

const legendFormatter = (value: string, _entry: unknown) => {
  return <span style={{ color: Colors.darkGray }}>{value}</span>
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (!active || !payload) {
    return null
  }

  const expense = payload.find((item) => item.name === 'Expenses')
  const income = payload.find((item) => item.name === 'Income')

  if (!expense || !income) {
    return null
  }

  const profit = income.payload?.total_sum || 0
  return (
    <div style={{ backgroundColor: Colors.white, padding: 10 }}>
      <Text color="green">{label}</Text>
      <div>
        <Text className="desc">
          <b style={{ textTransform: 'capitalize' }}>{income.name}: </b>
          <CurrencyFormatLabel value={income.value} />
        </Text>
      </div>
      <div>
        <Text className="desc">
          <b style={{ textTransform: 'capitalize' }}>{expense.name}: </b>
          <CurrencyFormatLabel value={expense.value} />
        </Text>
      </div>
      <div>
        <Text className="desc">
          <b style={{ textTransform: 'capitalize' }}>Profit: </b>
          <CurrencyFormatLabel value={profit} />
        </Text>
      </div>
    </div>
  )
}

const CashflowReport = () => {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState<
    Array<{
      expenses: number
      income: number
      month_start: string
      total_sum: number
      year_month: string
    }>
  >([])
  const [year, setYear] = useState(DEFAULT_YEAR)

  useEffect(() => {
    const fetch = async () => {
      setFetching(true)
      const data = await fetchCashflow(year)
      if (data) {
        setData(
          data.map(
            ({ expenses, income, month_start, total_sum, year_month }) => ({
              expenses: Math.abs(parseFloat(expenses)),
              income: parseFloat(income),
              month_start,
              total_sum: parseFloat(total_sum || '0'),
              year_month: moment(year_month).format(DATE_FORMATS.MONTH_YEAR),
            })
          )
        )
      }
      setFetching(false)
    }

    fetch()
  }, [year])

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  return (
    <Card type="subsection" backgroundColor="stone40">
      <div className={!isMobile ? 'flexbox' : undefined}>
        {isMobile && (
          <Dropdown
            options={YEAR_OPTIONS}
            onChange={setYear}
            value={year}
            variant="text"
          />
        )}
        <Text as="h2">Annual Cashflow Report</Text>
        {!isMobile && (
          <Dropdown
            options={YEAR_OPTIONS}
            onChange={setYear}
            value={year}
            variant="text"
          />
        )}
      </div>
      <br />
      {fetching ? (
        <Dimmer active inverted>
          <Loader>Fetching</Loader>
        </Dimmer>
      ) : (
        <ResponsiveContainer width="99%" height={250}>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={Colors.lightGreen}
                  stopOpacity={0.6}
                />
                <stop
                  offset="95%"
                  stopColor={Colors.lightGreen}
                  stopOpacity={0.6}
                />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={Colors.green} stopOpacity={0.6} />
                <stop offset="55%" stopColor={Colors.green} stopOpacity={0.7} />
              </linearGradient>
            </defs>
            <XAxis dataKey="year_month" />
            <YAxis
              domain={[0, 'auto']}
              type="number"
              allowDecimals={false}
              tickFormatter={(value) => `$${formatter.format(value)}`}
            />
            <Tooltip<number, string>
              content={(props) => <CustomTooltip {...props} />}
            />
            <Legend
              iconType="square"
              align="left"
              formatter={legendFormatter}
            />
            <Area
              type="linear"
              dataKey="income"
              name="Income"
              stroke={Colors.greenBlue}
              fillOpacity={1}
              fill={Colors.greenBlue}
            />
            <Area
              type="linear"
              dataKey="expenses"
              name="Expenses"
              stroke={Colors.yellowOrange}
              fillOpacity={1}
              fill={Colors.yellowOrange}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Card>
  )
}

export default CashflowReport
