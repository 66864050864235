// /*
//   This is used to manage stripe payments
//
//   NOTE – API utilizes a copy of this file. If changes are made here, they must also be updated
//          within API
//
// */

const isProduction = process.env.VITE_IS_PROD === 'true'

/* 
  Pricing Updates 
  — Updated on Oct 3, 2022
  - April 21, 2023
    Clarification on naming - A "Product" on Stripe is referred to as a "Plan" in code
    Structure - implemented StripePlans, StripePlan, StripePlanDetails, StripePlanList
  — May 4, 2023
    Sets the correct monthly & annual production pricing for Basic Solo and Basic Group
  - Oct 4, 2023
    Adds products for the new subscription tiers
*/

export enum StripePlans {
  basic_solo = 'basic_solo',
  basic_group = 'basic_group',
  sole_prop_solo = 'sole_prop_solo',
  scorp_solo = 'scorp_solo',
  basic_no_qte = 'basic_no_qte',
}

export interface StripePlanDetails {
  // When monthly and annual prices are separate products
  productId?: string

  priceId: string
  amount: string
  amountInCents: number
}

export interface StripePlan {
  // When monthly and annual prices are contained in the same product (Basic plan only)
  productId?: string
  productName?: string
  annual: StripePlanDetails
  monthly: StripePlanDetails
}

export type StripePlanList = {
  [key in StripePlans]: StripePlan
}

const TEST_STRIPE_PLANS: StripePlanList = {
  sole_prop_solo: {
    productName: 'Solo Practice - Sole Proprietor',
    monthly: {
      productId: 'prod_Ol4nyBTNpDGfPQ',
      priceId: 'price_1NxYeIJy69P78a4E3KMMDyLY',
      amount: '100',
      amountInCents: 10000,
    },
    annual: {
      productId: 'prod_Ol4s4rwpZepQe4',
      priceId: 'price_1NxYiYJy69P78a4EMsxI4LVF',
      amount: '900',
      amountInCents: 90000,
    },
  },
  scorp_solo: {
    productName: 'Solo Practice - S Corporation',
    monthly: {
      productId: 'prod_Ol4unCLsUQkbl3',
      priceId: 'price_1NxYkPJy69P78a4ED61gvQf1',
      amount: '200',
      amountInCents: 20000,
    },
    annual: {
      productId: 'prod_Ol4vGR4g4FjJSf',
      priceId: 'price_1NxYlFJy69P78a4EbX7BO7Gd',
      amount: '1800',
      amountInCents: 180000,
    },
  },
  basic_solo: {
    productId: 'prod_Nlz5AMsS0MzNYt',
    annual: {
      priceId: 'price_1R58mPJy69P78a4EXJfkY6Xd',
      amount: '1548',
      amountInCents: 154800,
    },
    monthly: {
      priceId: 'price_1R58m4Jy69P78a4Ejjh7RUzX',
      amount: '149',
      amountInCents: 14900,
    },
  },
  basic_group: {
    productId: 'prod_Nlz7Wdg9VNQvno',
    annual: {
      priceId: 'price_1R58j1Jy69P78a4EGJBfgB9f',
      amount: '1548',
      amountInCents: 154800,
    },
    monthly: {
      priceId: 'price_1R58iNJy69P78a4EwRYzozA0',
      amount: '149',
      amountInCents: 14900,
    },
  },
  basic_no_qte: {
    productId: 'prod_Ry6BPAoMOfOOWi',
    annual: {
      priceId: 'price_1R5EcPJy69P78a4EYu7fPVry',
      amount: '1020',
      amountInCents: 102000,
    },
    monthly: {
      priceId: 'price_1R5EcAJy69P78a4EJqksBcuV',
      amount: '99',
      amountInCents: 9900,
    },
  },
}

const PROD_STRIPE_PLANS: StripePlanList = {
  sole_prop_solo: {
    productName: 'Solo Practice - Sole Proprietor',
    monthly: {
      productId: 'prod_OnixhO5vKVHn8j',
      priceId: 'price_1QDZLnJy69P78a4EPsR8OoOb',
      amount: '199',
      amountInCents: 19900,
    },
    annual: {
      productId: 'prod_OnizZsS5Ey7KhW',
      priceId: 'price_1QDZK1Jy69P78a4EXe7bdT35',
      amount: '2028',
      amountInCents: 202800,
    },
  },
  scorp_solo: {
    productName: 'Solo Practice - S Corporation',
    monthly: {
      productId: 'prod_Onizu0f68MwUej',
      priceId: 'price_1O07XWJy69P78a4EkhKht82j',
      amount: '299',
      amountInCents: 29900,
    },
    annual: {
      productId: 'prod_OnizVpGMzi4ply',
      priceId: 'price_1O07XaJy69P78a4ERbJdor45',
      amount: '3060',
      amountInCents: 306000,
    },
  },
  basic_solo: {
    productId: 'prod_Npbu9p0ENImpqa',
    annual: {
      priceId: 'price_1R58pqJy69P78a4EjIBjvuY0',
      amount: '1548',
      amountInCents: 154800,
    },
    monthly: {
      priceId: 'price_1R58paJy69P78a4EiQlJABRb',
      amount: '149',
      amountInCents: 14900,
    },
  },
  basic_group: {
    productId: 'prod_NpbwnfSa8HiQ6z',
    annual: {
      priceId: 'price_1R58nsJy69P78a4ES36y4DCZ',
      amount: '1548',
      amountInCents: 154800,
    },
    monthly: {
      priceId: 'price_1R58nXJy69P78a4E9J9zH64E',
      amount: '149',
      amountInCents: 14900,
    },
  },
  basic_no_qte: {
    productId: 'prod_Ry6PfWX6z3AbA0',
    annual: {
      priceId: 'price_1R4ACOJy69P78a4EfskVbeNM',
      amount: '1020',
      amountInCents: 102000,
    },
    monthly: {
      priceId: 'price_1R4ACOJy69P78a4EW3KPxI4M',
      amount: '99',
      amountInCents: 9900,
    },
  },
}
export const fetchStripePlans = () => {
  if (isProduction) {
    return PROD_STRIPE_PLANS
  } else {
    return TEST_STRIPE_PLANS
  }
}

export const CATCHUP_BOOKKEEPING_PRICE_PER_MONTH = 99
export const testCatchupBkPriceId = 'price_1PO7PGJy69P78a4EjM4pDmaA'
export const prodCatchupBkPriceId = 'price_1PO3m0Jy69P78a4EYiXdSuLO'
export const testTrialCatchupBkPriceId = 'price_1PyL1mJy69P78a4E4wPU6jkp'
export const prodTrialCatchupBkPriceId = 'price_1Q0RIyJy69P78a4EVzukHYgT'

const TEST_STRIPE_PAYROLL_PRICING = {
  payrollBase: {
    priceId: 'price_1LNfrVJy69P78a4El6OuHDOn',
    amountInDollarsPerMonth: '39',
  },
  payrollPerSeat: {
    priceId: 'price_1LNfuDJy69P78a4Eo8NetskP',
    amountInDollars: '6',
  },
}

const PROD_STRIPE_PAYROLL_PRICING = {
  payrollBase: {
    priceId: 'price_1LWou5Jy69P78a4EMqMu83GT',
    amountInDollarsPerMonth: '39',
  },
  payrollPerSeat: {
    priceId: 'price_1LWou5Jy69P78a4EDwPQBDkJ',
    amountInDollars: '6',
  },
}

export const fetchStripePayrollPrices = () => {
  if (isProduction) {
    return PROD_STRIPE_PAYROLL_PRICING
  } else {
    return TEST_STRIPE_PAYROLL_PRICING
  }
}

// These are Financial Advisory Products
const TEST_ADVISORY_PRICING = {
  productName: 'Financial Advisory',
  monthly: {
    productId: 'prod_RFDUlSbJq8PMjb',
    priceId: 'price_1QMj3JJy69P78a4EqLoTF1d4',
    amount: '29',
  },
  annual: {
    productId: 'prod_RFDWEcFZX1mcRl',
    priceId: 'price_1QMj54Jy69P78a4ES3kyTVHA',
    amount: '300',
  },
}

export const PROD_ADVISORY_PRICING = {
  productName: 'Financial Advisory',
  monthly: {
    productId: 'prod_RFDYx4Gh1Dsjpl',
    priceId: 'price_1QMj7WJy69P78a4EnpzpB28B',
    amount: '29',
  },
  annual: {
    productId: 'prod_RFDYCjurz5LwwH',
    priceId: 'price_1QMj7SJy69P78a4ECg5RDPEy',
    amount: '300',
  },
}

export const fetchAdvisoryPrices = () => {
  if (isProduction) {
    return PROD_ADVISORY_PRICING
  } else {
    return TEST_ADVISORY_PRICING
  }
}
