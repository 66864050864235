import { useEffect, useMemo } from 'react'
import { Grid, Container, Divider } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Card,
  FormikCheckbox,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  makeReqBoolSchema,
  Text,
} from '../../../components/BaseComponents'
import { SIGNUP_PATHS } from '../helpers'
import SignupHeader from '../SignupHeader'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import {
  useNavigateWithPersistParams,
  useReselector,
} from '../../../utils/sharedHooks'
import { useSaveAnnualTaxFilingFormData } from '../../Taxes/AnnualTaxes/helpers'
import {
  ExtensionReason,
  fetchAnnualTaxFilingsIfNeeded,
  requestExtension,
} from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  getAnnualTaxFilingForYearSelector,
  selectAnnualTaxNotAvailable,
} from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { getFinancialProfile } from '../../../selectors/user.selectors'
import TaxTerms from './TaxTerms'
import {
  selectCanOptIntoPreviousTaxYear,
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'
import OptInAndSaveCard from './OptInAndSaveCard'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'

const ActivePreviousYearTaxForm = () => {
  const fp = useReselector(getFinancialProfile)
  const navigate = useNavigateWithPersistParams()
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const dispatch = useAppDispatch()
  const canOptIntoPreviousYearTaxes = useReselector(
    selectCanOptIntoPreviousTaxYear
  )

  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  const saveTaxFiling = useSaveAnnualTaxFilingFormData(currentTaxYear)
  const formik = useFormik({
    initialValues: {
      needPreviousTaxYearReturn: taxFiling
        ? taxFiling?.optedOutAt === null
        : null,
      confirmedTerms: undefined,
    },
    enableReinitialize: true,
    onSubmit: async ({ needPreviousTaxYearReturn, confirmedTerms }) => {
      // If user opts into tax filing, we confirm and create an AnnualTaxFiling with the late joiner flag
      // Users will still need to confirm via TSK, but these fields enable us to confirm a User's tax filing needs
      if (needPreviousTaxYearReturn) {
        const res = await saveTaxFiling({
          year: currentTaxYear,
          isLateJoiner: true,
          isLateJoinerTermsAcceptedAt: confirmedTerms
            ? new Date().toISOString()
            : null,
          lateJoinerInitiallyOptedIn: true,
          optedOutAt: null,
        })
        await requestExtension(ExtensionReason.lateJoiner)(dispatch)

        if (res) {
          navigate(SIGNUP_PATHS.choosePlan)
        }
      } else {
        const res = await saveTaxFiling({
          year: currentTaxYear,
          optedOutAt: new Date().toISOString(),
          // used to track when users accepted opt out terms
          lateJoinerInitiallyOptedIn: false,
          isLateJoiner: false,
          isLateJoinerTermsAcceptedAt: confirmedTerms
            ? new Date().toISOString()
            : null,
        })
        if (res) {
          navigate(SIGNUP_PATHS.choosePlan)
        }
      }
    },
  })
  const activeDetails = useReselector(selectCurrentAnnualTaxDetails)
  const irsFilingDeadline = useMemo(() => {
    if (fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s) {
      if (activeDetails?.irsFormDueDates?.form_1120_s?.irs.dueDate) {
        return convertUtcToLocalDate(
          activeDetails?.irsFormDueDates?.form_1120_s?.irs.dueDate
        )
      }
    } else {
      if (activeDetails?.irsFormDueDates?.form_1040?.irs.dueDate) {
        return convertUtcToLocalDate(
          activeDetails?.irsFormDueDates?.form_1040?.irs.dueDate
        )
      }
    }
    return null
  }, [fp?.taxEntityType, activeDetails])

  const form1120sLateJoinerOptInCutoff = convertUtcToLocalDate(
    activeDetails?.form_1120_s_new_user_tax_cutoff_date
  )
  const form1040LateJoinerOptInCutoff = convertUtcToLocalDate(
    activeDetails?.form_1040_new_user_tax_cutoff_date
  )
  const lateJoinerOptInCutoff =
    fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s
      ? form1120sLateJoinerOptInCutoff
      : form1040LateJoinerOptInCutoff

  if (!activeDetails?.taxQuestionnaireDueDates) {
    return null
  }

  // Used to toggle highlight of sign up header dependent on whether we show late sign up screen
  const currentStep = canOptIntoPreviousYearTaxes ? 3 : 2

  const { values, submitForm, isSubmitting, isValid } = formik

  return (
    <Container style={{ paddingTop: 64 }}>
      <FormikProvider value={formik}>
        <Grid>
          <SignupHeader currentStep={currentStep} />
          <GridRowColumn>
            <Text as="display2">
              Tell us your {currentTaxYear} tax preparation needs
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text>
              By doing the following, you’re helping Heard set you up for a
              successful {currentTaxYear} tax season.
            </Text>
          </GridRowColumn>
          <Grid.Row />
          <Grid>
            <Grid.Row>
              <Grid.Column computer={11} tablet={11} mobile={16}>
                <Grid>
                  <GridRowColumn>
                    <Text as="h2">{currentTaxYear} Tax preparation terms</Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text as="bodyLg">
                      Heard is here to help you with your {currentTaxYear}{' '}
                      taxes, but as the{' '}
                      {irsFilingDeadline?.toFormat(
                        DATE_FORMATS_LUXON.MONTH_DAY_LONG
                      )}{' '}
                      tax deadline approaches, we want to ensure we provide you
                      with clear instructions and expectations for what the
                      process looks like to have us file your taxes.
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text as="bodyLg">
                      When you join, we’ll make it easy to keep track of
                      everything you have to do, and by when.
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <TaxTerms
                      taxEntityType={fp?.taxEntityType}
                      currentTaxYear={currentTaxYear}
                    />
                  </GridRowColumn>
                  {isMobile && (
                    <Grid.Column width={16}>
                      <OptInAndSaveCard />
                    </Grid.Column>
                  )}
                  <GridRowColumn>
                    <FormikLabelError
                      name={getFieldName<typeof values>(
                        'needPreviousTaxYearReturn'
                      )}
                      schema={yup.boolean().nullable().required()}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text as="h2">
                      Would you like Heard to prepare your {currentTaxYear}{' '}
                      taxes?
                    </Text>
                  </GridRowColumn>
                  <Grid.Row className="short">
                    <Grid.Column computer={3} tablet={3} mobile={8}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>(
                          'needPreviousTaxYearReturn'
                        )}
                        value
                        fullWidth
                      >
                        Yes
                      </FormikRadioToggleButton>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={3} mobile={8}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>(
                          'needPreviousTaxYearReturn'
                        )}
                        value={false}
                        fullWidth
                      >
                        No
                      </FormikRadioToggleButton>
                    </Grid.Column>
                  </Grid.Row>
                  {values.needPreviousTaxYearReturn && (
                    <GridRowColumn>
                      <Card
                        type="subsection"
                        variant="noShadow"
                        backgroundColor="stone40"
                      >
                        By opting in to {currentTaxYear} taxes, you agree to all
                        of the above terms. You understand that meeting these
                        deadlines is necessary to avoid additional penalties and
                        interest on your tax payment.
                        <br />
                        <br />
                        <FormikCheckbox
                          label="I have read and agreed to all the terms."
                          name={getFieldName<typeof values>('confirmedTerms')}
                          schema={makeReqBoolSchema({
                            error: 'The terms and conditions must be accepted.',
                          })}
                        />
                      </Card>
                    </GridRowColumn>
                  )}

                  {values.needPreviousTaxYearReturn === false && (
                    <GridRowColumn>
                      <Card
                        type="subsection"
                        variant="noShadow"
                        backgroundColor="stone40"
                      >
                        By opting out, you understand that Heard will not do
                        your personal or business taxes for {currentTaxYear}. If
                        you want to opt back in later, you can do so through the
                        Heard app until{' '}
                        {lateJoinerOptInCutoff?.toFormat(
                          DATE_FORMATS_LUXON.MONTH_DAY_LONG
                        )}
                        .
                        <br />
                        <br />
                        <FormikCheckbox
                          name={getFieldName<typeof values>('confirmedTerms')}
                          label="I have read and agreed to all the terms."
                          schema={makeReqBoolSchema({
                            error: 'The terms and conditions must be accepted.',
                          })}
                        />
                      </Card>
                    </GridRowColumn>
                  )}
                  <Grid.Row />
                </Grid>
              </Grid.Column>
              {!isMobile && (
                <Grid.Column width={5}>
                  <OptInAndSaveCard />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <GridRowColumn>
            <Divider />
          </GridRowColumn>
          {!isMobile && (
            <Grid.Row>
              <Grid.Column width={2}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navigate(SIGNUP_PATHS.practiceJourney)}
                >
                  Back
                </Button>
              </Grid.Column>
              <Grid.Column width={12} />
              <Grid.Column width={2}>
                <Button
                  fullWidth
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  id="btn-signup-previous-year-form"
                  loading={isSubmitting}
                >
                  Continue
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
          {isMobile && (
            <>
              <GridRowColumn>
                <Button
                  fullWidth
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  id="btn-signup-previous-year-form"
                  loading={isSubmitting}
                >
                  Continue
                </Button>
              </GridRowColumn>
              <GridRowColumn>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
                >
                  Back
                </Button>
              </GridRowColumn>
            </>
          )}
          <Grid.Row />
          <Grid.Row />
        </Grid>
      </FormikProvider>
    </Container>
  )
}

const DisqualifiedPage = () => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const navigate = useNavigateWithPersistParams()
  const canOptIntoPreviousYearTaxes = useReselector(
    selectCanOptIntoPreviousTaxYear
  )
  const currentStep = canOptIntoPreviousYearTaxes ? 3 : 2
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid>
        <SignupHeader currentStep={currentStep} />
        <GridRowColumn>
          <Text as="bodyLg">
            <b>
              We&apos;re working hard to prepare for this year&apos;s tax
              season.
            </b>
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Helping you file your taxes for this year (
            {Number(currentTaxYear) + 1}) is part of your plan.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            However, we are unable to help you file your taxes for last year (
            {currentTaxYear}). If you still need help with your {currentTaxYear}{' '}
            taxes, we recommend seeking assistance outside of Heard.
          </Text>
        </GridRowColumn>

        <Divider />
        {!isMobile && (
          <Grid.Row>
            <Grid.Column width={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.practiceJourney)}
              >
                Back
              </Button>
            </Grid.Column>
            <Grid.Column width={12} />
            <Grid.Column width={2}>
              <Button
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.choosePlan)}
                id="btn-signup-previous-year-form"
              >
                Continue
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        {isMobile && (
          <>
            <GridRowColumn>
              <Button
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.choosePlan)}
                id="btn-signup-previous-year-form"
              >
                Continue
              </Button>
            </GridRowColumn>
            <GridRowColumn>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => navigate(SIGNUP_PATHS.aboutYourPractice)}
              >
                Back
              </Button>
            </GridRowColumn>
          </>
        )}
      </Grid>
    </Container>
  )
}
const PreviousYearTaxForm = () => {
  const dispatch = useAppDispatch()
  const pageView = useAnalyticsView()

  const isNotAvailable = useReselector(selectAnnualTaxNotAvailable)

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    pageView('previous year tax form')
  }, [pageView])

  useSignupPageUpdate(SIGNUP_PATHS.previousYearTax)

  if (isNotAvailable) {
    return <DisqualifiedPage />
  } else {
    return <ActivePreviousYearTaxForm />
  }
}

export default PreviousYearTaxForm
