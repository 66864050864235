import { useMemo, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Dropdown, valsToDropdownOptions } from '../../BaseComponents'
import { DropdownType, InsightsDividedCard } from './InsightsDividedCard'
import { useGetEndDateForFinancialInsights } from '../../../utils/sharedHooks'
import { MONTHS_ARR, INSIGHTS_TYPE } from './utils'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchInsightsTableDropdownOptions } from './financeActions'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'

export const useInsightsDatePicker = (
  monthParam?: string | null,
  yearParam?: string | null
) => {
  const [yearFilter, setYearFilter] = useState<number>()
  const [monthFilter, setMonthFilter] = useState<string>()

  useEffect(() => {
    if (yearParam) {
      setYearFilter(parseInt(yearParam))
    }
    if (monthParam) {
      setMonthFilter(monthParam)
    }
  }, [yearParam, monthParam])

  const inputDate = useMemo(() => {
    if (yearFilter && monthFilter) {
      const month = parseInt(monthFilter) + 1

      // default to middle of following month to get data for current month
      const datetimeObj = DateTime.fromObject({
        year: yearFilter,
        month,
      })
        .endOf('month')
        .plus({ days: 15 })
      return datetimeObj.toFormat(DATE_FORMATS_LUXON.INPUT)
    }
    return undefined
  }, [yearFilter, monthFilter])

  return {
    yearFilter,
    monthFilter,
    inputDate,
    setYearFilter,
    setMonthFilter,
  }
}

export const InsightsDatePicker = ({
  monthFilter,
  yearFilter,
  handleMonthFilterChange,
  handleYearFilterChange,
  insightsType = INSIGHTS_TYPE.PROFIT,
}: {
  monthFilter?: string
  yearFilter?: number
  handleMonthFilterChange: (month: string) => void
  handleYearFilterChange: (year: number) => void
  insightsType?: INSIGHTS_TYPE
}) => {
  const [years, setYears] = useState<number[]>([])

  const dispatch = useAppDispatch()

  const currentDate = useMemo(() => DateTime.now(), [])

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  useEffect(() => {
    if (years && !yearFilter) {
      handleYearFilterChange(years[0])
    }
  }, [years, yearFilter, handleYearFilterChange])

  const { filteredYearOptions, filteredMonthOptions } = useMemo(() => {
    const endDate = getEndDate()

    const yearOptions = [...years]

    const filteredYears = [
      endDate.year,
      ...yearOptions.filter((year) => year < endDate.year),
    ]

    const monthsWithoutYTD = MONTHS_ARR.filter((month) => month.value !== 'ytd')
    let filteredMonths = [...monthsWithoutYTD]
    if (yearFilter === endDate.year) {
      filteredMonths = filteredMonths.filter(
        (monthOption) => parseInt(monthOption.value) < endDate.month
      )

      const foundMonth = filteredMonths.findIndex(
        (month) => month.value === monthFilter
      )

      if (!monthFilter || foundMonth === -1) {
        handleMonthFilterChange(filteredMonths[filteredMonths.length - 1].value)
      }
    }

    return {
      filteredYearOptions: valsToDropdownOptions(filteredYears),
      filteredMonthOptions: filteredMonths,
    }
  }, [yearFilter, getEndDate, monthFilter, handleMonthFilterChange, years])

  useEffect(() => {
    const fetchYears = async () => {
      const yearsData =
        await fetchInsightsTableDropdownOptions(insightsType)(dispatch)
      setYears(yearsData?.length ? yearsData : [])
    }
    fetchYears()
  }, [dispatch, insightsType])

  return (
    <InsightsDividedCard
      columns={[
        {
          content: (
            <Dropdown
              variant="checked"
              value={yearFilter}
              options={filteredYearOptions}
              onChange={handleYearFilterChange}
              fullWidth
            />
          ),
        },
        {
          content: (
            <Dropdown
              variant="checked"
              value={monthFilter}
              options={filteredMonthOptions}
              onChange={handleMonthFilterChange}
              fullWidth
            />
          ),
        },
      ]}
      type={DropdownType.dropdown}
    />
  )
}
