import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Grid, Container, Divider, Message } from 'semantic-ui-react'
import moment from 'moment'

import SignupHeader from '../SignupHeader'
import {
  Button,
  Text,
  GridRowColumn,
  Loader,
  Card,
  Checkbox,
} from '../../../components/BaseComponents'
import {
  FREE_TRIAL_PROMO,
  SIGNUP_PATHS,
  fetchFreeTrialLength,
} from '../helpers'
import {
  useNavigateWithPersistParams,
  useReselector,
  useScrollRef,
} from '../../../utils/sharedHooks'
import {
  FETCH_STRIPE_PRODUCTS_KEY,
  StripeProduct,
  fetchCheckoutSession,
  fetchEligibleStripeProducts,
  FETCH_CHECKOUT_SESSION_KEY,
  StripeLineItem,
  fetchBillingPortalSession,
} from '../../../actions/settings/billingActions'
import {
  sendOnboardingZap,
  sendUserCRMCreationZap,
} from '../../../actions/zapierActions'
import {
  getCurrentUser,
  getFreeTrialStatus,
  selectIsFreeTrialEnabled,
} from '../../../selectors/user.selectors'
import { useSignupPageUpdate } from '../../../services/onboardingService'
import { useAnalyticsView } from '../../Amplitude'
import { TrialStatus } from '../../../reducers/auth/userReducer'
import {
  getFetchError,
  getIsFetching,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import StripeProductCard from './StripeProductCard'
import { fetchBasicProductsV2 } from './helpers'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getAnnualTaxFilingForYearSelector } from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import {
  selectCanOptIntoPreviousTaxYear,
  selectCurrentAnnualTaxYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useCatchupBookkeepingPrice } from './hooks'
import { selectSubscription } from '../../../reducers/subscription.slice'
import {
  fetchNeedsTwoCheckouts,
  setCatchUpBookkeepingPaymentRequirements,
} from '../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { selectHasAnnualCommitment } from '../../SelfServeCancellationFlow/userCancellation.selectors'

const ChoosePlan = ({ isUpsell }: { isUpsell: boolean }) => {
  const navigate = useNavigateWithPersistParams()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>()
  const [annualCommitmentConfirmation, setAnnualCommitmentConfirmation] =
    useState(false)
  const user = useReselector(getCurrentUser)
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const loading = useReselector(getIsFetching, FETCH_CHECKOUT_SESSION_KEY)
  const subscription = useReselector(selectSubscription)
  const [selectedProduct, setSelectedProduct] = useState<StripeProduct>()
  const [searchParams] = useSearchParams()
  const basicPlanSearchParamValue = searchParams.get('subscription')
  const useBasicPlan = basicPlanSearchParamValue === 'new'
  const useBasicNoQtePlan = basicPlanSearchParamValue === 'basic'
  const useAnyBasicPlan = useBasicPlan || useBasicNoQtePlan
  const catchupBookkeepingPrice = useCatchupBookkeepingPrice()
  const [useTwoCheckoutFlow, setUseTwoCheckoutFlow] = useState(false)
  const canOptIntoPreviousYearTaxes = useReselector(
    selectCanOptIntoPreviousTaxYear
  )
  const hasAnnualCommitment = useReselector(selectHasAnnualCommitment)

  /* Free-trial related */
  // e.g. active, inactive, unredeemed
  const userFreeTrialStatus = useReselector(getFreeTrialStatus)

  // Based on user's current & past activity, should we enable the trial
  const freeTrialSearchParamValue = searchParams.get('trial')
  const freeTrialEnabled = useReselector(
    selectIsFreeTrialEnabled,
    freeTrialSearchParamValue,
    useAnyBasicPlan
  )
  const hasFreeTrialPromoCode = freeTrialSearchParamValue === FREE_TRIAL_PROMO
  useSignupPageUpdate(SIGNUP_PATHS.choosePlan, isUpsell)

  const pageView = useAnalyticsView()

  useEffect(() => {
    if (isUpsell) {
      pageView('upsell flow choose plan')
    } else {
      pageView('sign up choose plan')
    }
  }, [pageView, isUpsell])

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useEffect(() => {
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const checkForMultiDiscounts = async () => {
      const res = await fetchNeedsTwoCheckouts()(dispatch)
      setUseTwoCheckoutFlow(Boolean(res))
    }

    if (catchupBookkeepingPrice?.priceId) {
      checkForMultiDiscounts()
    }
  }, [dispatch, catchupBookkeepingPrice?.priceId])

  const fetchingStripeProducts =
    useReselector(getIsFetchingOrNotStarted, FETCH_STRIPE_PRODUCTS_KEY) &&
    !useAnyBasicPlan

  const fetchingStripeProductsError = useReselector(
    getFetchError,
    FETCH_STRIPE_PRODUCTS_KEY
  )
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    currentTaxYear
  )

  useEffect(() => {
    async function performAsync() {
      if (useBasicPlan) {
        const basicProducts = fetchBasicProductsV2()
        const pertinentStripeProducts =
          user?.financialProfile?.practiceType === 'group'
            ? basicProducts.BASIC_GROUP_PRODUCTS
            : basicProducts.BASIC_SOLO_PRODUCTS

        setStripeProducts(pertinentStripeProducts)
      } else if (useBasicNoQtePlan) {
        const basicProducts = fetchBasicProductsV2()
        const pertinentStripeProducts = basicProducts.BASIC_NO_QTE_PRODUCTS
        setStripeProducts(pertinentStripeProducts)
      } else {
        const data = await fetchEligibleStripeProducts()(dispatch)
        // Sort annual products first (currently we only expect 2 products - monthly & annual)
        if (data && data.length > 1) {
          data.sort(
            (a, b) =>
              -a.default_price.recurring.interval.localeCompare(
                b.default_price.recurring.interval
              )
          )
        }
        setStripeProducts(data)
      }
    }

    performAsync()
  }, [
    dispatch,
    useBasicPlan,
    useBasicNoQtePlan,
    user?.financialProfile?.practiceType,
  ])

  const navToPayment = useCallback(async () => {
    if (!user?.id || !selectedProduct?.default_price.id) {
      return
    }

    const lineItems: StripeLineItem[] = [
      {
        priceId: selectedProduct.default_price.id,
        quantity: 1,
      },
    ]
    const metadata: Record<string, string> = {}

    if (catchupBookkeepingPrice?.priceId) {
      if (freeTrialEnabled) {
        // for free trials, catchup bk invoice will be created on backend
        metadata.catchupBkPriceId = catchupBookkeepingPrice?.priceId
        metadata.catchupBkQuantity =
          catchupBookkeepingPrice?.monthCount?.toString()

        // if 14 day free trial and during the CUBK promo time (100% off), we use priceId instead of
        // freeTrialPriceId so we can automatically set CUBK as paid
        const lineItemPrice = hasFreeTrialPromoCode
          ? catchupBookkeepingPrice.priceId
          : catchupBookkeepingPrice.freeTrialPriceId
        lineItems.push({
          priceId: lineItemPrice,
          quantity: catchupBookkeepingPrice.monthCount,
        })
      } else if (!useTwoCheckoutFlow) {
        // if user does not have multiple coupons, they can go through the normal single checkout flow
        // users w/ multiple coupons need to checkout out separately from subscription
        lineItems.push({
          priceId: catchupBookkeepingPrice.priceId,
          quantity: catchupBookkeepingPrice.monthCount,
        })
      }
    }
    await setCatchUpBookkeepingPaymentRequirements()(dispatch)

    let subscriptionCheckoutUrl: string | null = null
    if (Object.keys(subscription).length === 0) {
      const res = await fetchCheckoutSession({
        userId: user?.id,
        lineItems,
        success_url: {
          url: useTwoCheckoutFlow
            ? '/accounts/suspended/catch-up-bookkeeping'
            : SIGNUP_PATHS.success,
        },
        // Navigate back to current page with query params
        cancel_url: {
          url: `${location.pathname}${location.search}`,
        },
        freeTrialEnabled: isUpsell ? undefined : freeTrialEnabled,
        freeTrialSearchParamValue: isUpsell ? null : freeTrialSearchParamValue,
        metadata,
      })(dispatch)
      if (res) {
        subscriptionCheckoutUrl = res.url
      }
      // This means this is a free trial to paid conversion
    } else if (Object.keys(subscription).length > 0) {
      const res = await fetchBillingPortalSession(isUpsell)(dispatch)
      if (res) {
        subscriptionCheckoutUrl = res.url
      }
    }

    if (subscriptionCheckoutUrl !== null) {
      window.location.href = subscriptionCheckoutUrl

      // Only relevant for new users, not for upsells, as this was already done on sign up
      if (!isUpsell) {
        sendUserCRMCreationZap({
          createdAt: user.createdAt,
          email: user.email,
          financialProfile: user.financialProfile,
          firstName: user.firstName,
          lastName: user.lastName,
        })

        sendOnboardingZap({
          email: user.email,
          screenName: SIGNUP_PATHS.choosePlan,
        })()
      }
    }
  }, [
    user?.id,
    user?.createdAt,
    user?.email,
    user?.financialProfile,
    user?.firstName,
    user?.lastName,
    selectedProduct?.default_price.id,
    catchupBookkeepingPrice?.priceId,
    catchupBookkeepingPrice?.monthCount,
    location.pathname,
    location.search,
    freeTrialEnabled,
    freeTrialSearchParamValue,
    dispatch,
    catchupBookkeepingPrice?.freeTrialPriceId,
    subscription,
    useTwoCheckoutFlow,
    isUpsell,
    hasFreeTrialPromoCode,
  ])

  /** Caluclate Free Trial Days based on referral date */
  const freeTrialDays = fetchFreeTrialLength(freeTrialSearchParamValue)
  const trialEndDate = moment()
    .add(freeTrialDays, 'days')
    .format(DATE_FORMATS.DISPLAY_SIMPLE)
  // Used to toggle highlight of sign up header dependent on whether we show late sign up screen
  const currentStep = canOptIntoPreviousYearTaxes ? 4 : 3

  const planSubText = useMemo(() => {
    if (freeTrialEnabled && freeTrialDays === 14 && !isUpsell) {
      return `Try Heard for ${freeTrialDays} days. You won't be charged when your trial ends on ${trialEndDate}.`
    } else if (freeTrialEnabled && !isUpsell) {
      return `Try Heard for the first ${freeTrialDays} days before getting billed. You won't be charged until your trial ends on ${trialEndDate}. Cancel anytime.`
    } else {
      if (hasAnnualCommitment) {
        return "Based on your responses, we've selected the best plan for your practice. Please note that all plans require a 12-month commitment, regardless of monthly or annual payment choice."
      } else {
        return 'Based on your answers, the plan below fits your practice.'
      }
    }
  }, [
    freeTrialEnabled,
    freeTrialDays,
    isUpsell,
    hasAnnualCommitment,
    trialEndDate,
  ])

  const headerText = () => {
    if (isUpsell) {
      return (
        <GridRowColumn>
          <Text as="h1">Ready to unlock the full experience?</Text>
          <br />
          <Text as="bodyLg">
            Join thousands of therapist entrepreneurs who trust Heard to handle
            their finances. By purchasing Heard, you will gain full access full
            bookkeeping services, tax support, and financial tools designed
            specifically for your practice.
          </Text>
        </GridRowColumn>
      )
    } else {
      return (
        <GridRowColumn>
          <Text as="h1">
            Choose to pay annually or monthly, whatever meets your needs.
          </Text>
        </GridRowColumn>
      )
    }
  }

  const annualCommitmentConfirmCard = useMemo(() => {
    if (hasAnnualCommitment) {
      const label =
        "By checking this box, I understand I'm committing to a 12-month membership with Heard, payable either upfront (Annual Payment Plan) or monthly (Monthly Payment Plan)."
      return (
        <Card type="subsection" variant="noShadow" backgroundColor="natural">
          <Checkbox
            id="annual-commitment-confirmation"
            label={label}
            checked={annualCommitmentConfirmation}
            variant="default"
            onChange={(checked) => setAnnualCommitmentConfirmation(checked)}
          />
        </Card>
      )
    }
    return null
  }, [annualCommitmentConfirmation, hasAnnualCommitment])

  const actionButtons = useMemo(() => {
    const continueDisabled =
      loading || (hasAnnualCommitment && !annualCommitmentConfirmation)

    if (isUpsell) {
      return (
        <Grid.Row>
          <Grid.Column width={14} />
          <Grid.Column width={2}>
            {selectedProduct && (
              <Button
                onClick={navToPayment}
                disabled={continueDisabled}
                loading={loading}
              >
                Purchase
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      )
    } else {
      return (
        <Grid.Row>
          <Grid.Column width={2}>
            <Button
              variant="secondary"
              onClick={() =>
                navigate(
                  canOptIntoPreviousYearTaxes &&
                    freeTrialSearchParamValue !== FREE_TRIAL_PROMO &&
                    !useAnyBasicPlan
                    ? SIGNUP_PATHS.previousYearTax
                    : SIGNUP_PATHS.practiceJourney
                )
              }
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={12} />
          <Grid.Column width={2}>
            {selectedProduct && (
              <Button
                onClick={navToPayment}
                disabled={continueDisabled}
                loading={loading}
              >
                Continue
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      )
    }
  }, [
    loading,
    hasAnnualCommitment,
    annualCommitmentConfirmation,
    isUpsell,
    selectedProduct,
    navToPayment,
    navigate,
    canOptIntoPreviousYearTaxes,
    freeTrialSearchParamValue,
    useAnyBasicPlan,
  ])
  return (
    <Container style={{ paddingTop: 64 }}>
      <Grid stackable>
        <span ref={scrollRef} />
        {!isUpsell && <SignupHeader currentStep={currentStep} />}
        {headerText()}
        {userFreeTrialStatus === TrialStatus.inactive && (
          <GridRowColumn>
            <Message
              warning
              content={
                <Text>
                  Your {freeTrialDays}-day free trial has ended. Please choose a
                  plan and complete payment to access your heard account.
                </Text>
              }
            />
          </GridRowColumn>
        )}
        {userFreeTrialStatus !== TrialStatus.inactive && !isUpsell && (
          <GridRowColumn short>
            <Text as="bodyLg">{planSubText}</Text>
          </GridRowColumn>
        )}
        <GridRowColumn>
          {/* Fetching products */}
          <Loader
            fetchKey={FETCH_STRIPE_PRODUCTS_KEY}
            loading={fetchingStripeProducts}
          />
          {/* Error fetching products */}
          {fetchingStripeProductsError && (
            <Message
              info
              header="Missing information"
              content={<Text>{fetchingStripeProductsError.message}</Text>}
            />
          )}
          {!fetchingStripeProducts && stripeProducts && (
            <StripeProductCard
              stripeProducts={stripeProducts}
              selectedProduct={selectedProduct}
              onSelect={setSelectedProduct}
              freeTrialEnabled={freeTrialEnabled}
              basicPlanEnabled={useAnyBasicPlan}
              isLateJoiner={taxFiling?.isLateJoiner}
              freeTrialSearchParamValue={freeTrialSearchParamValue}
              basicPlanSearchParamValue={basicPlanSearchParamValue}
            />
          )}

          {/* No products found - in the event products aren't properly configured on Stripe.com */}
          {!fetchingStripeProducts &&
            !fetchingStripeProductsError &&
            !stripeProducts && (
              <Text>
                We could not find any matching Products for you. Please email
                contact@joinheard.com for further assistance.
              </Text>
            )}
        </GridRowColumn>
        <GridRowColumn>{annualCommitmentConfirmCard}</GridRowColumn>
        <GridRowColumn>
          <Divider />
        </GridRowColumn>
        {actionButtons}
      </Grid>
    </Container>
  )
}

export default ChoosePlan
