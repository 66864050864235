import { ReactNode } from 'react'
import moment from 'moment'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  QuarterlyTaxEstimateDetail,
  QuarterlyTaxEstimateDetailStatuses,
} from '../quarterlyTaxEstimateDetails.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import { IconButton, Table } from '../../../../components/BaseComponents'

interface Props {
  details: QuarterlyTaxEstimateDetail
  onEditClick: () => void
}

const StyledCell = ({
  children,
  status,
}: {
  children: ReactNode
  status: QuarterlyTaxEstimateDetailStatuses
}) => (
  <Table.Cell positive={status === QuarterlyTaxEstimateDetailStatuses.active}>
    {children}
  </Table.Cell>
)

const QuarterlyTaxEstimateDetailsRow = ({ details, onEditClick }: Props) => (
  <Table.Row>
    <StyledCell status={details.status}>
      {details.status !== QuarterlyTaxEstimateDetailStatuses.past && (
        <IconButton icon={regular('pencil')} onClick={onEditClick} />
      )}
    </StyledCell>
    <StyledCell status={details.status}>{details.taxYear}</StyledCell>
    <StyledCell status={details.status}>Q{details.taxQuarter}</StyledCell>
    <StyledCell status={details.status}>{details.status}</StyledCell>
    <StyledCell status={details.status}>
      {moment
        .utc(details.irsQuarterStartsAt)
        .format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment.utc(details.irsQuarterEndsAt).format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment.utc(details.irsPaymentDueAt).format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment.utc(details.newUserCutOffAt).format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {details.checklistLaunchDate
        ? moment
            .utc(details.checklistLaunchDate)
            .format(DATE_FORMATS.DISPLAY_SHORT)
        : '--'}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment
        .utc(details.calculationStartsAt)
        .format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment.utc(details.clientNotifiedAt).format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment
        .utc(details.bookkeepingPeriodStartsAt)
        .format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment
        .utc(details.bookkeepingPeriodEndsAt)
        .format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
    <StyledCell status={details.status}>
      {details.bookkeepingPeriodTotalMonths}
    </StyledCell>
    <StyledCell status={details.status}>
      {details.edgeCaseUserIds ? details.edgeCaseUserIds.join(', ') : ''}
    </StyledCell>
    <StyledCell status={details.status}>
      {moment(details.updatedAt).format(DATE_FORMATS.DISPLAY_SHORT)}
    </StyledCell>
  </Table.Row>
)

export default QuarterlyTaxEstimateDetailsRow
