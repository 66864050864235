import { useEffect } from 'react'
import { LaunchDarklyClientProvider } from '@openfeature/launchdarkly-client-provider'
import {
  OpenFeature,
  InMemoryProvider,
  ProviderStatus,
  ClientProviderEvents,
} from '@openfeature/react-sdk'
import { LDLogger } from 'launchdarkly-js-client-sdk'
import { LaunchDarklyProviderOptions } from '@openfeature/launchdarkly-client-provider/src/lib/launchdarkly-provider-options'

import { isProduction } from '../../utils/envHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import { logSentryMessage } from '../../utils/sentryHelpers'

const logger: LDLogger = {
  debug: (_: string) => {},
  info: (_: string) => {},
  warn: (message: string) => logSentryMessage(message, { level: 'warning' }),
  error: (message: string) => logSentryMessage(message, { level: 'error' }),
}

export const initOpenFeature = () => {
  const ldClientSideId = process.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID

  if (ldClientSideId) {
    const ldOptions: LaunchDarklyProviderOptions = {
      streaming: true,
      logger: isProduction() ? logger : console,
    }
    const ldOpenFeatureProvider = new LaunchDarklyClientProvider(
      ldClientSideId,
      ldOptions
    )
    return OpenFeature.setProviderAndWait(
      'openFeatureClient',
      ldOpenFeatureProvider
    )
  } else {
    return OpenFeature.setProviderAndWait(new InMemoryProvider())
  }
}

export const useSetOpenFeatureTargetingKey = () => {
  const user = useReselector(getCurrentUser)

  useEffect(() => {
    if (user?.uuid) {
      OpenFeature.setContext({
        kind: 'user',
        targetingKey: user.uuid,
        email: user.email.toLowerCase(),
        createdAt: user.createdAt,
      })
    }
  }, [user?.email, user?.uuid, user?.createdAt])
}

export const clearOpenFeatureTargetingKey = async () =>
  await OpenFeature.clearContext()

export enum HISTORICAL_BOOKKEEPING_FEE_COHORTS {
  cohort1 = 'COHORT_1',
  cohort2 = 'COHORT_2',
  none = 'NONE',
}

export const getReadyOpenFeatureClient = async () => {
  const client = OpenFeature.getClient('openFeatureClient')

  // If the client is in a NOT_READY state add handler to listen to readiness
  if (client.providerStatus === ProviderStatus.NOT_READY) {
    await new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error('open feature not ready within 5 secs')),
        5000
      )

      client.addHandler(ClientProviderEvents.Ready, () => {
        clearTimeout(timeout)
        resolve()
      })
    })
  }

  // If client is reconciling then it is in the process of updating targeting, wait for this to complete
  if (client.providerStatus === ProviderStatus.RECONCILING) {
    await new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error('open feature not ready within 5 secs')),
        5000
      )

      client.addHandler(ClientProviderEvents.ContextChanged, () => {
        clearTimeout(timeout)
        resolve()
      })
    })
  }

  return client
}

// When adding a new value, be sure to also add to heard-api/src/api/integrations/open_feature/config.ts
// Please keep in alphabetical order!!
export enum FEATURE_FLAG_KEYS {
  allowAnnualTaxAdHocDeadlineExtension = 'allow-annual-tax-ad-hoc-deadline-extension',
  allowEditReleasedUserTaxEstimates = 'allow-edit-released-user-tax-estimates',
  cancellationSaveFlowStaticValues = 'cancellation-save-flow-static-values',
  catchupBookkeepingFee = 'historical-bookkeeping-fee',
  consolidatedReportsFinancialOverview = 'consolidated-reports-and-financial-overview',
  enableCancellationDiscounts = 'enable-cancellation-discounts',
  enableLowerFrictionStatements = 'enable-lower-friction-statements',
  enablePlaidStatementDisabling = 'enable-plaid-statement-disabling',
  enableQ4FreeTrial = 'enable-q4-free-trial',
  enable2024TaxChecklist = 'enable-2024-tax-checklist',
  enable2024OptOutFlow = 'enable-2024-opt-out-flow',
  enable1099nec = 'enable-2024-1099-nec',
  enableYeDeadlineBanner = 'enable-ye-deadline-banner',
  financialAdvisoryPilot = 'financial-advisory-pilot',
  insightsDatePicker = 'insights-date-picker',
  maintenanceMessage = 'maintenance-message',
  multiStatementUpload = 'multi-statement-upload',
  oneStatementRequired = 'one-statement-required',
  qteRealTimeRecalculation = 'qte-real-time-recalculation',
  scorpUpsellCard = 's-corp-upsell-card',
  showQteConversationAlert = 'show-qte-conversation-alert',
  showStatementModal = 'show-statements-modal',
  taxAdvisoryPilot = 'tax-advisory-pilot',
  usePaginatedConversations = 'use-paginated-conversations',
  disableTransactionUpdates = 'disable-transaction-updates',
  enableTaxConsultations = '2024-enable-tax-consultations',
  showFinancialInsightsCta = 'show-financial-insights-cta',
  showOnboardingSurveys = 'show-onboarding-surveys',
  adminCatchupBKToggle = 'admin-catchup-bk-toggle',
  canAccessTaxSeasonKickoffSurvey = 'can-access-tax-season-kickoff-survey',
  enableEarlyMessagesAccess = 'enable-early-messages-access',
}
