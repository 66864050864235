import { Fragment, useEffect, useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'

import {
  Button,
  Icon,
  Label,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { AnnualTaxFilingForm } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { fetchAllEoyReviewStepsIfNeeded } from '../../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import {
  fetchUserEoyReviewForUser,
  UserEoyReviewProgress,
} from '../../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import {
  getProgressFromIdentifier,
  getTaxChecklistSections,
} from '../../Taxes/AnnualTaxes/TaxChecklist/taxChecklist.selectors'
import { getAllEoyReviewSteps } from '../../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/endOfYearReviewSteps.selector'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'
import { adminFetchSubscriptionsByUserId } from '../../../actions/adminActions'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { getSubscriptionIncludesFreePersonalFiling } from '../../../reducers/subscription.helpers'

const getLabel = (progress: UserEoyReviewProgress | undefined) => {
  if (!progress) {
    return <Label color="gray">Not Started</Label>
  } else if (progress.completedAt) {
    return (
      <Label color="green">
        <Icon icon={regular('check')} style={{ marginRight: 10 }} />
        Completed
      </Label>
    )
  }

  return <Label color="yellow">In Progress</Label>
}

const FormProgressModal = ({
  form,
  forms,
  onClose,
}: {
  form: AnnualTaxFilingForm
  forms: AnnualTaxFilingForm[]
  onClose: () => void
}) => {
  const dispatch = useAppDispatch()
  const steps = useReselector(getAllEoyReviewSteps)

  const progresses = useFetchResponse(
    fetchUserEoyReviewForUser,
    form.year,
    form.user_id
  )

  const subscriptions = useFetchResponse(
    adminFetchSubscriptionsByUserId,
    form.user_id
  )

  const subscriptionIncludesFreePersonalFiling = useMemo(
    () => getSubscriptionIncludesFreePersonalFiling(subscriptions),
    [subscriptions]
  )
  const checklistSections = useMemo(
    () =>
      getTaxChecklistSections(
        subscriptionIncludesFreePersonalFiling,
        forms,
        form.id
      ),
    [form.id, forms, subscriptionIncludesFreePersonalFiling]
  )

  useEffect(() => {
    dispatch(fetchAllEoyReviewStepsIfNeeded())
  }, [dispatch])

  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>{form.formType?.displayName} Form Progress</Modal.Header>
      <Modal.Content>
        <Grid>
          {checklistSections.map((sections, i) => (
            <Fragment key={sections[0]}>
              {i !== 0 && <Divider />}
              {sections.map((section) => {
                const { progress } = getProgressFromIdentifier(
                  section,
                  steps,
                  progresses || {}
                )
                const date = progress?.completedAt || progress?.updatedAt

                return (
                  <Grid.Row key={section} className="short">
                    <Grid.Column width={10}>
                      <Text>{section.replaceAll('-', ' ')}</Text>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Text>
                        {date
                          ? formatISOFromUTC(
                              date,
                              DATE_FORMATS_LUXON.DISPLAY_SHORT
                            )
                          : '-'}
                      </Text>
                    </Grid.Column>
                    <Grid.Column width={3}>{getLabel(progress)}</Grid.Column>
                  </Grid.Row>
                )
              })}
            </Fragment>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default FormProgressModal
