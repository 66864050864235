import { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Divider } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import PayrollSetupList from './PayrollSetupList'
import {
  postCreateEmployeeBankAccount,
  fetchEmployeeBankAccounts,
  fetchEmployeePaymentMethod,
  fetchEmployees,
  putUpdateEmployeePaymentMethod,
  deleteEmployeeBankAccount,
  fetchAllEmployeeForms,
  POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY,
  PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY,
} from '../payrollActions'
import { selectFirstEmployee } from '../payroll.selectors'
import GustoFormsTable from '../PayrollDocuments/GustoFormsTable'
import {
  GEP_ENROLL_PATHS,
  ONBOARDING_STEPS,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  GridRowColumn,
  Icon,
  makeNumberSchema,
  makeReqStringSchema,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Gusto_EmployeeBankAccount,
  Gusto_GustoForm,
} from '../generated_gusto_types'
import { useAnalyticsTrack } from '../../Amplitude'

// Dev note - Routing number 102001017 and account number 01234567 pass gusto validation

const validationSchema = yup.object({
  routingNumber: makeNumberSchema({
    field: 'routing number',
    numDigits: 9,
    allowLeadingZero: true,
  }),
  accountNumber: makeNumberSchema({
    field: 'account number',
    allowLeadingZero: true,
  }),
  accountType: makeReqStringSchema({ field: 'account type' }),
})

const BankAccountModal = ({
  employeeUuid,
  onClose,
  isOpen,
}: {
  employeeUuid: string
  onClose: () => void
  isOpen: boolean
}) => {
  const dispatch = useAppDispatch()
  const isSaving = useReselector(
    getIsFetching,
    POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY
  )

  const formik = useFormik({
    initialValues: {
      routingNumber: '',
      accountNumber: '',
      accountType: 'Checking' as 'Checking' | 'Savings',
    },
    validationSchema,
    onSubmit: async ({ routingNumber, accountNumber, accountType }) => {
      const bankAccountRes = await postCreateEmployeeBankAccount(employeeUuid, {
        name: 'Bank Account',
        routing_number: routingNumber,
        account_type: accountType,
        account_number: accountNumber,
      })(dispatch)

      if (bankAccountRes) {
        onClose()
      }
    },
  })

  return (
    <Modal open={isOpen} size="tiny" closeIcon onClose={onClose}>
      <Modal.Header>Add Personal Bank Account</Modal.Header>
      <Modal.Content>
        <FormikProvider value={formik}>
          <Grid>
            <PayrollError fetchKey={POST_CREATE_EMPLOYEE_BANK_ACCOUNT_KEY} />
            <GridRowColumn>
              <FormikInput
                name="routingNumber"
                label="Routing Number (9 digits)"
                required
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikInput
                name="accountNumber"
                label="Account Number"
                required
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn>
              <FormikDropdown
                name="account_type"
                optionValues={['Checking', 'Savings']}
                required
                label="Account Type"
                fullWidth
              />
            </GridRowColumn>
          </Grid>
        </FormikProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          loading={isSaving}
          disabled={isSaving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const BankForm = ({ employeeUuid }: { employeeUuid: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const [paymentMethodVersion, setPaymentMethodVersion] = useState<string>()
  const [bankAccount, setBankAccount] = useState<Gusto_EmployeeBankAccount>()
  const [bankAccountFetched, setBankAccountFetched] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [forms, setForms] = useState<Gusto_GustoForm[]>([])
  const isPosting = useReselector(
    getIsFetching,
    PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY
  )
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.employeeBank)

  const fetchBankAccount = useCallback(async () => {
    const bankAccountRes =
      await fetchEmployeeBankAccounts(employeeUuid)(dispatch)

    if (bankAccountRes) {
      setBankAccountFetched(true)

      if (bankAccountRes.length) {
        setBankAccount(bankAccountRes[0])
      } else {
        setBankAccount(undefined)
      }
    }
  }, [dispatch, employeeUuid])

  const fetchForms = useCallback(async () => {
    const res = await fetchAllEmployeeForms(employeeUuid)(dispatch)
    if (res) {
      setForms(res)
    }
  }, [dispatch, employeeUuid])

  // Bank account fetch
  useEffect(() => {
    const fetch = async () => {
      await fetchBankAccount()

      if (!bankAccount?.uuid) {
        return
      }

      const res = await fetchEmployeePaymentMethod(employeeUuid)(dispatch)

      if (res) {
        setPaymentMethodVersion(res.version)
      }
    }
    fetch()
  }, [bankAccount?.uuid, dispatch, employeeUuid, fetchBankAccount])

  // Forms fetch
  useEffect(() => {
    fetchForms()
  }, [fetchForms])

  const submit = useCallback(async () => {
    if (!paymentMethodVersion || !bankAccount?.uuid) {
      return
    }

    const paymentMethodRes = await putUpdateEmployeePaymentMethod(
      employeeUuid,
      {
        type: 'Direct Deposit',
        version: paymentMethodVersion,
        split_by: 'Percentage',
        splits: [
          {
            uuid: bankAccount.uuid,
            name: bankAccount.name,
            priority: 1,
            split_amount: 100,
          },
        ],
      }
    )(dispatch)

    if (!paymentMethodRes) {
      return
    }

    track(
      'completed payroll step - create or update employee bank account and sign forms'
    )

    navigate(GEP_ENROLL_PATHS.schedule)
  }, [
    paymentMethodVersion,
    bankAccount?.uuid,
    bankAccount?.name,
    employeeUuid,
    dispatch,
    track,
    navigate,
  ])

  const deleteBankAccount = useCallback(async () => {
    if (!bankAccount?.uuid) {
      return
    }

    const res = await deleteEmployeeBankAccount(
      employeeUuid,
      bankAccount?.uuid
    )(dispatch)

    if (res) {
      await fetchBankAccount()
    }
  }, [bankAccount?.uuid, dispatch, employeeUuid, fetchBankAccount])

  const onClose = useCallback(() => {
    fetchBankAccount()
    // Forms are refetched here because there may be a new one to sign after bank added
    fetchForms()
    setModalOpen(false)
  }, [fetchBankAccount, fetchForms])

  if (!bankAccountFetched) {
    return null
  }

  // Form used for styling
  return (
    <Grid>
      <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_PAYMENT_METHOD_KEY} />
      <GridRowColumn>
        <Text as="h2">Add yourself as an employee</Text>
      </GridRowColumn>
      <Divider />
      <GridRowColumn>
        <Text as="h2">Enter Employee Payment Information</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          We will use Direct Deposit for your payment. Add the details of the
          personal bank account where you’d like to receive payment.
        </Text>
      </GridRowColumn>

      <BankAccountModal
        employeeUuid={employeeUuid}
        onClose={onClose}
        isOpen={modalOpen}
      />
      {!bankAccount && bankAccountFetched && (
        <>
          <GridRowColumn>
            <Text as="bodyLg">Employee Bank Account</Text>
            <Text>
              Please connect your <b>personal</b> bank account.
            </Text>
          </GridRowColumn>
          <GridRowColumn width={9}>
            <Button onClick={() => setModalOpen(true)} fullWidth>
              Add Bank Account
            </Button>
          </GridRowColumn>
        </>
      )}
      {bankAccount && bankAccountFetched && (
        <GridRowColumn width={12}>
          <div className="bank-account-container">
            <div className="title-wrapper">
              <Text color="green">
                <b>
                  <Icon
                    icon={regular('university')}
                    style={{ marginRight: 8 }}
                  />
                  {bankAccount.account_type} Account
                </b>
              </Text>
              <Button onClick={deleteBankAccount} variant="link">
                Delete
              </Button>
            </div>

            <Text style={{ marginBottom: 0 }}>
              {bankAccount.routing_number}
            </Text>
            <Text>
              <b>{bankAccount.hidden_account_number}</b>
            </Text>
          </div>
        </GridRowColumn>
      )}
      <Grid.Row />
      <GridRowColumn>
        <Text as="h2">Sign Documents</Text>
      </GridRowColumn>
      <GridRowColumn>
        <GustoFormsTable
          forms={forms}
          employeeUuid={employeeUuid}
          allowSign
          onSign={fetchForms}
        />
      </GridRowColumn>
      <Grid.Row />
      <Grid.Row>
        <Grid.Column width={6}>
          <Button
            fullWidth
            variant="secondary"
            onClick={() => navigate(GEP_ENROLL_PATHS.employeeState)}
          >
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={4} />
        <Grid.Column width={6}>
          <Button
            onClick={submit}
            fullWidth
            disabled={
              forms.some((form) => form.requires_signing) || !bankAccount
            }
            loading={isPosting}
          >
            Save & Continue
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const PayrollEnrollBank = () => {
  const dispatch = useAppDispatch()
  const employee = useReselector(selectFirstEmployee)

  useEffect(() => {
    fetchEmployees()(dispatch)
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              {employee && <BankForm employeeUuid={employee.uuid} />}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollBank
