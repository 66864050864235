import { Truncate } from '@re-dev/react-truncate'

import { UserDocument } from '../../userDocuments.slice'
import { UserDocumentCategory } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { getDocumentName } from '../../service'
import { Table } from '../../../../components/BaseComponents'

interface DocumentFileNameCellProps {
  document: UserDocument
  category?: UserDocumentCategory
}

const DocumentFileNameCell = ({
  document,
  category,
}: DocumentFileNameCellProps) => {
  const documentName = getDocumentName(document, category)
  return (
    <Table.Cell style={{ fontWeight: 'bold' }}>
      {document.signedUrl ? (
        <a href={document.signedUrl} target="_blank" rel="noreferrer">
          <Truncate width={200}>{documentName}</Truncate>
        </a>
      ) : (
        documentName
      )}
    </Table.Cell>
  )
}

export default DocumentFileNameCell
