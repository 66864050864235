import { useMemo } from 'react'
import { Grid, Loader } from 'semantic-ui-react'

import { Card, GridRowColumn } from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { ChecklistOpenHeader, ChecklistDoneHeader } from './ChecklistHeaders'
import { selectQTEChecklistStatusAndDates } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import {
  UpdateTaxInfoCTA,
  ReviewTxnCTA,
  BooksStatus,
  BooksIncompleteInfo,
  UpdateQTEPaymentsCTA,
  UploadNeededBankStatementsCTA,
} from './ChecklistItems'
import { selectTransactionsNeedReviewForQTE } from '../../../../Transactions/transactions.selectors'
import { selectForQTEAllBooksReconciledForRequiredMonths } from '../../../../../selectors/bookkeepingReportsSelectors'
import {
  selectAllQTEChecklistItemsComplete,
  selectAreAllEstimatesPaidSoFar,
  selectTaxProfileReviewedInRequiredRangeForQTE,
} from '../../userTaxEstimates.selector'
import { USER_TRANSACTION_KEY } from '../../../../Transactions/transactions.slice'
import { FETCH_BOOKKEEPING_REPORT_KEY } from '../../../../../actions/bookkeepingReportActions'
import { selectIsFetchingForKeys } from '../../../../../reducers/fetch'
import { FETCH_TAX_ESTIMATES_KEY } from '../../userTaxEstimates.slice'
import { FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY } from '../../../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'

const QTEChecklist = () => {
  const checklistStatus = useReselector(selectQTEChecklistStatusAndDates)
  const { rest: activeQTEDetails } = checklistStatus
  const checklistComplete = useReselector(
    selectAllQTEChecklistItemsComplete,
    activeQTEDetails?.taxYear
  )
  const updateTaxInfoIsDone = useReselector(
    selectTaxProfileReviewedInRequiredRangeForQTE
  )
  const { allReviewed, count } = useReselector(
    selectTransactionsNeedReviewForQTE
  )
  const { allRequiredBooksAreReconciled } = useReselector(
    selectForQTEAllBooksReconciledForRequiredMonths
  )
  const allEstimatesPaid = useReselector(
    selectAreAllEstimatesPaidSoFar,
    activeQTEDetails?.taxYear
  )
  const fetching = useReselector(selectIsFetchingForKeys, [
    USER_TRANSACTION_KEY,
    FETCH_BOOKKEEPING_REPORT_KEY,
    FETCH_TAX_ESTIMATES_KEY,
    FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
  ])
  const isFetching = fetching || !activeQTEDetails

  const headerContent = useMemo(() => {
    if (isFetching) {
      return (
        <GridRowColumn centerContent>
          <Loader active inline />
        </GridRowColumn>
      )
    } else if (checklistComplete) {
      return <ChecklistDoneHeader />
    }
    return <ChecklistOpenHeader />
  }, [checklistComplete, isFetching])

  const contents = useMemo(() => {
    if (
      isFetching ||
      (checklistComplete &&
        activeQTEDetails.now >
          activeQTEDetails.withOffset.initialCalculationLock)
    ) {
      return null
    }
    return (
      <>
        <GridRowColumn short>
          <UpdateTaxInfoCTA isDone={Boolean(updateTaxInfoIsDone)} />
          {Number(activeQTEDetails.taxQuarter) !== 1 && (
            <GridRowColumn short>
              <UpdateQTEPaymentsCTA isDone={allEstimatesPaid} />
            </GridRowColumn>
          )}
        </GridRowColumn>
        <GridRowColumn short>
          <ReviewTxnCTA isDone={allReviewed} count={count} />
        </GridRowColumn>
        <GridRowColumn short>
          <UploadNeededBankStatementsCTA />
        </GridRowColumn>
        {allReviewed && (
          <>
            <GridRowColumn short>
              <BooksStatus booksReconciled={allRequiredBooksAreReconciled} />
            </GridRowColumn>
            {!allRequiredBooksAreReconciled && (
              <GridRowColumn short>
                <BooksIncompleteInfo />
              </GridRowColumn>
            )}
          </>
        )}
      </>
    )
  }, [
    isFetching,
    checklistComplete,
    activeQTEDetails?.now,
    activeQTEDetails?.withOffset.initialCalculationLock,
    activeQTEDetails?.taxQuarter,
    updateTaxInfoIsDone,
    allEstimatesPaid,
    allReviewed,
    count,
    allRequiredBooksAreReconciled,
  ])

  return (
    <Card type="section">
      <Grid>
        <GridRowColumn>{headerContent}</GridRowColumn>
        {contents}
      </Grid>
    </Card>
  )
}

export default QTEChecklist
