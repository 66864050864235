import { Grid } from 'semantic-ui-react'
import { useMemo } from 'react'
import {
  Card,
  Text,
  GridRowColumn,
  Link,
  Icon,
  GridColumnSpacer,
} from '../../../BaseComponents'
import { useFetchResponse } from '../../../../utils/sharedHooks'
import { fetchInsightsSummary } from '../../shared/financeActions'
import {
  INSIGHTS_TYPE,
  formatSummary,
  formatPercentage,
} from '../../shared/utils'
import { Colors } from '../../../../styles/theme'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  DeviceWidthCombo,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'

export interface InsightOverviewCardProps {
  headerText: string
  headerColor?: keyof typeof Colors
  type: string
  percentChange?: number
  month?: number
  year?: number
}

const getPercentChangeDescription = (percentChange = 0) => {
  const formattedPercentChange = formatPercentage(percentChange)
  if (percentChange > 0) {
    return `${formattedPercentChange} higher than last month`
  } else if (percentChange < 0) {
    return `${formattedPercentChange} lower than last month`
  } else {
    return ''
  }
}

const getIcon = (percentChange = 0) => {
  if (percentChange > 0) {
    return <Icon icon={regular('arrow-up-right')} style={{ marginRight: 8 }} />
  } else if (percentChange < 0) {
    return (
      <Icon icon={regular('arrow-down-right')} style={{ marginRight: 8 }} />
    )
  }
  return null
}

export const InsightOverviewCard = ({
  headerText,
  type,
  headerColor,
  percentChange,
  month,
  year,
}: InsightOverviewCardProps) => {
  const insightsLink = useMemo(() => {
    let baseLink = `/insights/${type}?`
    if (month) {
      baseLink += `month=${month - 1}&`
    }
    if (year) {
      baseLink += `year=${year}`
    }
    return baseLink
  }, [type, month, year])

  const trackMessage = `view-${type}-breakdown`

  return (
    <Card type="subsection" backgroundColor="natural">
      <Grid>
        <GridRowColumn>
          <Text as="display2" color={headerColor} style={{ marginBottom: 4 }}>
            {headerText}
          </Text>
          <Text as="eyebrow">{type}</Text>
        </GridRowColumn>
        <GridRowColumn className="short">
          <Text as="bodySm" color="darkGray">
            {getIcon(percentChange)}
            {getPercentChangeDescription(percentChange)}
          </Text>
        </GridRowColumn>
        <GridColumnSpacer />
        <GridRowColumn
          verticalAlign="bottom"
          style={{ position: 'absolute', bottom: 0 }}
        >
          <Link
            to={insightsLink}
            size="small"
            trackMessage={trackMessage}
            trackAttributes={{ screen: 'practice insights' }}
          >
            View {type} breakdown →
          </Link>
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

export const InsightOverviewCards = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  const isMobileOrTablet = useIsDeviceWidth(DeviceWidthCombo.mobileOrTablet)

  const incomeSummaryResponse = useFetchResponse(
    fetchInsightsSummary,
    INSIGHTS_TYPE.INCOME,
    month,
    year
  )

  const expenseSummaryResponse = useFetchResponse(
    fetchInsightsSummary,
    INSIGHTS_TYPE.EXPENSES,
    month,
    year
  )

  const profitSummaryResponse = useFetchResponse(
    fetchInsightsSummary,
    INSIGHTS_TYPE.PROFIT,
    month,
    year
  )

  const formattedIncome = useMemo(() => {
    return formatSummary(incomeSummaryResponse?.[0], INSIGHTS_TYPE.INCOME)
  }, [incomeSummaryResponse])

  const formattedExpense = useMemo(() => {
    return formatSummary(expenseSummaryResponse?.[0], INSIGHTS_TYPE.EXPENSES)
  }, [expenseSummaryResponse])

  const formattedProfit = useMemo(() => {
    return formatSummary(profitSummaryResponse?.[0], INSIGHTS_TYPE.PROFIT)
  }, [profitSummaryResponse])

  return (
    <Grid>
      <Grid.Row stretched centered>
        <Grid.Column
          computer={5}
          tablet={5}
          mobile={16}
          style={isMobileOrTablet ? { marginBottom: 12 } : undefined}
        >
          <InsightOverviewCard
            headerText={formattedIncome?.month_total || '-'}
            headerColor="accentGreen"
            type="income"
            percentChange={formattedIncome?.percent_change}
            month={month}
            year={year}
          />
        </Grid.Column>
        <Grid.Column
          computer={5}
          tablet={5}
          mobile={16}
          style={isMobileOrTablet ? { marginBottom: 12 } : undefined}
        >
          <InsightOverviewCard
            headerText={formattedExpense?.month_total || '-'}
            headerColor="yellow"
            type="expenses"
            percentChange={formattedExpense?.percent_change}
            month={month}
            year={year}
          />
        </Grid.Column>
        <Grid.Column
          computer={5}
          tablet={5}
          mobile={16}
          style={isMobileOrTablet ? { marginBottom: 12 } : undefined}
        >
          <InsightOverviewCard
            headerText={formattedProfit?.month_total || '-'}
            type="profit"
            percentChange={formattedProfit?.percent_change}
            month={month}
            year={year}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
