import {
  GridRowColumn,
  Text,
  Table,
} from '../../../../components/BaseComponents'
import ReviewPayrollInterestModal from './ReviewPayrollInterestModal'
import { useFetchResponseWithUtils } from '../../../../utils/sharedHooks'
import { adminGetMostRecentPayrollInterestSurveyForUser } from '../../../Payroll/Upsell/payrollInterestSurvey.actions'
import { formatISOFromUTC } from '../../../../utils/dateHelpers'
import { Loader } from 'semantic-ui-react'

const camelCaseToWords = (str?: string | null) =>
  str
    ?.replace(/([A-Z])(?=[A-Z])/g, '$1 ')
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join(' ') || ''

const AdminGepInterestSurvey = ({ userId }: { userId: number }) => {
  const {
    result: gepSurvey,
    refetch,
    loading,
  } = useFetchResponseWithUtils(
    adminGetMostRecentPayrollInterestSurveyForUser,
    userId
  )

  if (loading) {
    return <Loader inline active />
  }

  if (!gepSurvey) {
    return null
  }

  return (
    <GridRowColumn>
      <Text as="h2">Payroll Interest Flow</Text>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Payroll interest Form completed at:</Table.Cell>
            <Table.Cell>
              {formatISOFromUTC(gepSurvey.gepRequestFormSubmittedAt)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Payroll Interest Provider</Table.Cell>
            <Table.Cell>
              {gepSurvey.payrollSetup}
              {gepSurvey.payrollProvider && (
                <>
                  <br />
                  {gepSurvey.payrollProvider}
                </>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Interested In Heard Payroll?</Table.Cell>
            <Table.Cell>
              {gepSurvey.interestedInGepAt ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>If not interested why?</Table.Cell>
            <Table.Cell>
              {camelCaseToWords(gepSurvey.notInterestedReason)}
              {gepSurvey.otherNotInterestedReason && (
                <>
                  <br />
                  {gepSurvey.otherNotInterestedReason}
                </>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>If Gusto User, Gusto Plan?</Table.Cell>
            <Table.Cell>{gepSurvey.gustoPlan}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Previous Gusto User?</Table.Cell>
            <Table.Cell>
              {camelCaseToWords(gepSurvey.usedGustoInPast)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <br />
      <Text as="h2">Heard Payroll Eligibility</Text>
      <ReviewPayrollInterestModal gepSurvey={gepSurvey} onSave={refetch} />
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Eligible For Payroll</Table.Cell>
            <Table.Cell>
              {gepSurvey.gepEligible === null
                ? ''
                : gepSurvey.gepEligible
                  ? 'Yes'
                  : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>If ineligible, why?</Table.Cell>
            <Table.Cell>
              {camelCaseToWords(gepSurvey.ineligibleReason)}
              {gepSurvey.ineligibleReasonOther && (
                <>
                  <br />
                  {gepSurvey.ineligibleReasonOther}
                </>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Last Update Time</Table.Cell>
            <Table.Cell>
              {formatISOFromUTC(gepSurvey.adminReviewedAt)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <br />
      <Text as="h2">Who Does user pay?</Text>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Themself</Table.Cell>
            <Table.Cell>
              {/*question is not always asked so null will mean yes*/}
              {gepSurvey.needPayrollForSelf !== false ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Contractor</Table.Cell>
            <Table.Cell>
              {gepSurvey.needPayrollForContractors ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>W2 employee</Table.Cell>
            <Table.Cell>
              {gepSurvey.needPayrollForEmployees ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Other</Table.Cell>
            <Table.Cell>
              {gepSurvey.needPayrollForOther
                ? `Yes, ${gepSurvey.needPayrollForOther}`
                : 'No'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <br />
      <Text as="h2">What Features does user use?</Text>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Multiple Pay rates</Table.Cell>
            <Table.Cell>{gepSurvey.multiplePayRates ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Gusto sponsored benefits</Table.Cell>
            <Table.Cell>
              {gepSurvey.gustoSponsoredBenefits ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Time Tracking</Table.Cell>
            <Table.Cell>{gepSurvey.timeTracking ? 'Yes' : 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commission Only employees</Table.Cell>
            <Table.Cell>
              {gepSurvey.commissionOnlyEmployees ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </GridRowColumn>
  )
}

export default AdminGepInterestSurvey
