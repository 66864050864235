import { Link } from 'react-router-dom'
import { Button, Card, Text } from '../../BaseComponents'

const FinancialReportsCard = ({
  userId,
  isFreeTrial,
}: {
  userId: number
  isFreeTrial?: boolean
}) => (
  <Card fullWidth>
    <Card.Header>
      <Text as="h3">Financial Reports</Text>
    </Card.Header>
    {isFreeTrial && (
      <div>
        <Text as="bodyMd" color="red">
          This User is in a 14 day free trial without any Bookkeeping Service.
          Do not categorize or reconcile their books.
        </Text>
      </div>
    )}
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/profit-loss`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">User Profit and Loss &gt;&gt;&gt;</Button>
      </Link>
    </Card.Content>
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/profit-loss?confirmed=true`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">
          Journal Entry Profit and Loss &gt;&gt;&gt;
        </Button>
      </Link>
    </Card.Content>
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/general-ledger`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">User General Ledger &gt;&gt;&gt;</Button>
      </Link>
    </Card.Content>
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/general-journal`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">User General Journal &gt;&gt;&gt;</Button>
      </Link>
    </Card.Content>
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/balance-sheet`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">Balance Sheet &gt;&gt;&gt; </Button>
      </Link>
    </Card.Content>
    <Card.Content>
      <Link
        to={`/admin/finances/financial-reports/${userId}/auto-balance-sheet`}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button className="link">
          2024 Auto-Generated Balance Sheet &gt;&gt;&gt;
        </Button>
      </Link>
    </Card.Content>
  </Card>
)

export default FinancialReportsCard
