import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Header, Table, Button } from 'semantic-ui-react'
import moment from 'moment'

import { Text } from '../../BaseComponents'
import BookkeepingStatusLabel from './BookkeepingStatusLabel'
import {
  fetchAdminUsersBookkeepingReports,
  createBookkeepingReportsFromYear,
} from '../../../actions/admin/bookkeepingReportActions'
import { User } from '../../../reducers/auth/userReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { selectBookkeepingReportsByUserId } from '../../../selectors/bookkeepingReportsSelectors'
import GenerateMissingReportsModal from './GenerateMissingReportsModal'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { adminIsUserNoServiceFreeTrial } from '../../../selectors/user.selectors'

const BookkeepingReportsCard = ({ user }: { user: User }) => {
  const dispatch = useAppDispatch()
  const [creatingReports, setCreatingReports] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    fetchAdminUsersBookkeepingReports(user.id)(dispatch)
  }, [dispatch, user.id])

  const createReports = async (year: string) => {
    setCreatingReports(true)
    await createBookkeepingReportsFromYear({
      userId: user.id,
      year,
    })(dispatch)
    setCreatingReports(false)
    setModalOpen(false)
  }

  const reports = useReselector(selectBookkeepingReportsByUserId, user.id)
  const isFreeTrial = useReselector(adminIsUserNoServiceFreeTrial, user.id)
  return (
    <Card fluid className="bookkeepingReportsCard">
      <Card.Header>
        <Header as="h4">Monthly Books</Header>
        <Button
          variant="link"
          loading={creatingReports}
          disabled={creatingReports}
          onClick={() => setModalOpen(true)}
        >
          Generate Missing Books...
        </Button>
        <br />
      </Card.Header>
      {isFreeTrial && (
        <Card.Content>
          <Text as="bodyMd" color="red">
            This User is in a 14 day free trial without any Bookkeeping Service.
            Do not categorize or reconcile
          </Text>
        </Card.Content>
      )}
      {!isFreeTrial && (
        <Card.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>Month</Table.HeaderCell>
                <Table.HeaderCell width={6}>Status</Table.HeaderCell>
                <Table.HeaderCell width={4} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {reports.map((report) => (
                <Table.Row key={report.id}>
                  <Table.Cell>
                    <b>{moment(report.date).format(DATE_FORMATS.MONTH_YEAR)}</b>
                  </Table.Cell>
                  <Table.Cell>
                    <BookkeepingStatusLabel reportId={report.id.toString()} />
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`/admin/finances/records/${user.id}/monthly-books/${report.id}`}
                    >
                      <Button className="link">view</Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
      )}
      {modalOpen && (
        <GenerateMissingReportsModal
          onClose={() => setModalOpen(false)}
          loading={creatingReports}
          onCreateReports={createReports}
        />
      )}
    </Card>
  )
}

export default BookkeepingReportsCard
