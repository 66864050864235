import { useCallback, useEffect, useState } from 'react'
import { Divider, Grid } from 'semantic-ui-react'

import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import QuarterlyTaxEstimateDetailsForm from './components/QuarterlyTaxEstimateDetailsForm'
import QuarterlyTaxEstimateDetailsRow from './components/QuarterlyTaxEstimateDetailsRow'
import { selectAllQuarterlyTaxEstimateDetailsSorted } from './quarterlyTaxEstimateDetails.selector'
import {
  fetchAllQuarterlyTaxEstimateDetailsIfNeeded,
  FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
  QuarterlyTaxEstimateDetail,
} from './quarterlyTaxEstimateDetails.slice'
import { useReselector } from '../../../utils/sharedHooks'
import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Loader,
  Modal,
  Table,
  Text,
} from '../../../components/BaseComponents'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { useAppDispatch } from '../../../utils/typeHelpers'

const QuarterlyTaxEstimateDetailsPanel = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
    dispatch(fetchAllUsersIfNeeded())
  }, [dispatch])

  const [qteEdit, setQteEdit] = useState<QuarterlyTaxEstimateDetail>()
  const [modalOpen, setModalOpen] = useState(false)

  const allQuarterlyTaxEstimateDetailsSorted = useReselector(
    selectAllQuarterlyTaxEstimateDetailsSorted
  )
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY
  )
  const error = useReselector(
    getFetchError,
    FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY
  )

  const closeModal = useCallback(() => {
    setModalOpen(false)
    setQteEdit(undefined)
  }, [])

  return (
    <Card style={{ overflow: 'scroll' }}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Quarterly Tax Estimate Details</Text>
        </GridRowColumn>

        {loading && <Loader />}
        {!loading && !error && (
          <>
            <GridRowColumn width={3}>
              <Button
                variant="secondary"
                content="Create a future entry"
                onClick={() => setModalOpen(true)}
              />
            </GridRowColumn>

            <GridRowColumn>
              <Alert title="All dates are inclusive">
                Changing values can impact QTE calculations & Tax Center
                content. Please double-check your changes before applying.
              </Alert>
            </GridRowColumn>

            <GridRowColumn>
              <Table style={{ marginBottom: '8em' }} collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Tax Year</Table.HeaderCell>
                    <Table.HeaderCell>Tax Quarter</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>IRS Quarter Start</Table.HeaderCell>
                    <Table.HeaderCell>IRS Quarter End</Table.HeaderCell>
                    <Table.HeaderCell>IRS Payment Due</Table.HeaderCell>
                    <Table.HeaderCell>New User Cut-Off</Table.HeaderCell>
                    <Table.HeaderCell>Checklist Launch Date</Table.HeaderCell>
                    <Table.HeaderCell>Calculation Starts</Table.HeaderCell>
                    <Table.HeaderCell>Client Notified</Table.HeaderCell>
                    <Table.HeaderCell>
                      Bookkeeping Period Start
                    </Table.HeaderCell>
                    <Table.HeaderCell>Bookkeeping Period End</Table.HeaderCell>
                    <Table.HeaderCell>
                      Bookkeeping Period Total Months
                    </Table.HeaderCell>
                    <Table.HeaderCell>Edge Case User IDs</Table.HeaderCell>
                    <Table.HeaderCell>Last Updated</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {allQuarterlyTaxEstimateDetailsSorted.map((details) => (
                  <QuarterlyTaxEstimateDetailsRow
                    onEditClick={() => {
                      setModalOpen(true)
                      setQteEdit(details)
                    }}
                    key={`detail-${details.taxQuarter}-${details.taxYear}`}
                    details={details}
                  />
                ))}
              </Table>
            </GridRowColumn>
          </>
        )}
        {error && (
          <GridRowColumn>
            <Alert type="error">
              An error occurred, refresh page to try again. &quot;
              {error.message}
              &quot;
            </Alert>
          </GridRowColumn>
        )}
      </Grid>
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={false}
        open={modalOpen}
        onClose={closeModal}
      >
        <Modal.Content>
          <Text as="h2">
            {qteEdit
              ? `Update ${qteEdit.taxYear} Q${qteEdit.taxQuarter}`
              : 'Create a future entry'}
          </Text>
          <Divider />
          <QuarterlyTaxEstimateDetailsForm
            onCancel={() => setModalOpen(false)}
            details={qteEdit}
          />
        </Modal.Content>
      </Modal>
    </Card>
  )
}

export default QuarterlyTaxEstimateDetailsPanel
