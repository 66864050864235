import { RefObject } from 'react'
import { createSelector } from 'reselect'
import moment from 'moment'
import { FormikProps, FormikValues } from 'formik'
import { DateTime } from 'luxon'

import { ReduxState } from '../../../utils/typeHelpers'
import {
  getAnnualTaxFilingForms,
  select1040FormForCurrentYear,
  select1040FormForYear,
  select1120sFormForCurrentYear,
  select1120sFormForYear,
  selectAnnualTaxFilingFormById,
  selectFilingFormsForCurrentTaxYear,
  selectFilingFormsForYear,
} from './annualTaxFilingForms.selector'
import {
  AnnualTaxFiling,
  DependentRelationship,
  ExtensionRequestType,
  needContractorFilingOption,
} from './annualTaxFilings.slice'
import { AnnualTaxFilingNonNormalized } from '../../Admin/Taxes/adminAnnualTaxFilings.slice'
import {
  ANNUAL_TAX_FILING_FORM_TYPES,
  TaxFormType,
} from './Questionnaires/constants'
import {
  getFinancialProfile,
  getFreeTrialStatus,
  getUserIsAdmin,
  selectIsCurrentUserScorp,
  selectIsCurrentUserSoleProp,
  selectMembershipStart,
  selectPrimaryMembership,
} from '../../../selectors/user.selectors'
import {
  selectAnnualTaxDetails,
  selectCanOptIntoPreviousTaxYear,
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  AnnualTaxFilingForm,
  AnnualTaxFilingFormQuestionnaireStatus,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from './annualTaxFilingForms.slice'
import {
  selectAboundTaxDocsForPayerForYear,
  selectCurrent1099sCount,
  selectLastFiled1099,
} from './aboundAnnualTaxFilings.selector'
import { AnnualTaxFilingStepStatus } from './constants'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { AnnualTaxDetail } from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { TAX_ENTITY_TYPES } from '../taxConstants'
import { AboundTaxDocument } from './aboundAnnualTaxFilings.slice'
import { selectIsBasicPlan } from '../../../reducers/subscription.slice'
import {
  MEMBERSHIP_STATUS,
  TrialStatus,
} from '../../../reducers/auth/userReducer'
import {
  getCompleteYearEndModuleStatuses,
  getYearEndModuleStatuses,
  selectAllYearEndModulesComplete,
  selectTaxQuestionnaireYearEndModuleStatus,
} from '../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import {
  YearEndModuleStatus,
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../../YearEndModuleStatus/yearEndModuleStatus.slice'

const getAnnualTaxFilings = (state: ReduxState) => state.annualTaxFilings

export const getAnnualTaxFilingById = createSelector(
  getAnnualTaxFilings,
  (_: unknown, id: number | undefined) => id,
  (annualTaxFilings, id) =>
    id && annualTaxFilings[id] ? annualTaxFilings[id] : undefined
)

const selectAnnualTaxFilingByFormId = createSelector(
  getAnnualTaxFilings,
  selectAnnualTaxFilingFormById,
  (taxFilings, form) =>
    Object.values(taxFilings).find(
      (filing) => filing.id === form?.annualTaxFilingId
    )
)

export const getAnnualTaxFilingForYearSelector = createSelector(
  [getAnnualTaxFilings, (_: unknown, year: string) => year],
  (annualTaxFilings, year) =>
    Object.values(annualTaxFilings).find(
      (annualTaxFiling) => annualTaxFiling.year === year
    )
)

export const selectCurrentAnnualTaxFiling = createSelector(
  selectCurrentAnnualTaxYear,
  getAnnualTaxFilings,
  (year, annualTaxFilings) =>
    Object.values(annualTaxFilings).find(
      (annualTaxFiling) => annualTaxFiling.year === year
    )
)

export const selectAnnualTaxFilingForCurrentTaxYear = createSelector(
  [getAnnualTaxFilings, selectCurrentAnnualTaxYear],
  (annualTaxFilings, taxYear) =>
    Object.values(annualTaxFilings).find(
      (annualTaxFiling) => annualTaxFiling.year === taxYear
    )
)

const selectTaxDetailsByFormYear = createSelector(
  selectAnnualTaxFilingFormById,
  selectAnnualTaxDetails,
  (form, annualTaxDetails) =>
    form
      ? Object.values(annualTaxDetails).find((d) => d.taxYear === form.year)
      : null
)

export const selectExpectedAnnualTaxFormNeeds = createSelector(
  getAnnualTaxFilingForYearSelector,
  getFinancialProfile,
  (annualTaxFiling, fp) => {
    if (annualTaxFiling?.annualTaxFormNeeds) {
      return annualTaxFiling.annualTaxFormNeeds
    } else if (fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s) {
      return [TaxFormType.form1120s]
    }
    return [TaxFormType.form1040]
  }
)

const selectExpectedAnnualTaxFormNeedsForCurrentTaxYear = createSelector(
  selectAnnualTaxFilingForCurrentTaxYear,
  getFinancialProfile,
  (annualTaxFiling, fp) => {
    if (annualTaxFiling?.annualTaxFormNeeds) {
      return annualTaxFiling.annualTaxFormNeeds
    } else if (fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s) {
      return [TaxFormType.form1120s]
    }
    return [TaxFormType.form1040]
  }
)

export enum LateFilingStatus {
  notRelevant = 'NOT_RELEVANT',
  tskMissed = 'TSK_MISSED',
  taxChecklistNeeded = 'TAX_CHECKLIST_NEEDED',
  taxChecklistMissed = 'TAX_CHECKLIST_MISSED',
}

interface LateFilingStatusType {
  status: LateFilingStatus
  applicableTo: TaxFormType[] //notRelevant status will have empty array
}

interface LateFilingDeadlines {
  form1040: {
    cybDeadline: DateTime | null
    taxChecklistDeadline: DateTime | null
  }
  form1120s: {
    cybDeadline: DateTime | null
    taxChecklistDeadline: DateTime | null
  }
}

export const daysBeforeToShowLateFilingAlert = 14

const selectLateTaxFilingDeadlinesForCurrentTaxYear = createSelector(
  selectCurrentAnnualTaxDetails,
  (taxDetails): LateFilingDeadlines => ({
    form1040: {
      cybDeadline:
        convertUtcToLocalDate(
          taxDetails?.taxQuestionnaireDueDates?.form_1040?.cutoffDate
        )
          ?.minus({ days: 16 })
          .endOf('day') ?? null,
      taxChecklistDeadline:
        convertUtcToLocalDate(
          taxDetails?.taxQuestionnaireDueDates?.form_1040?.cutoffDate
        )?.endOf('day') ?? null,
    },
    form1120s: {
      cybDeadline:
        convertUtcToLocalDate(
          taxDetails?.taxQuestionnaireDueDates?.form_1120_s?.cutoffDate
        )
          ?.minus({ days: 17 })
          .endOf('day') ?? null,
      taxChecklistDeadline:
        convertUtcToLocalDate(
          taxDetails?.taxQuestionnaireDueDates?.form_1120_s?.cutoffDate
        )?.endOf('day') ?? null,
    },
  })
)

export const selectDidMissTskCutoffDate = createSelector(
  selectCurrentAnnualTaxDetails,
  selectCanOptIntoPreviousTaxYear,
  selectIsCurrentUserScorp,
  selectCurrentAnnualTaxFiling,
  (taxDetails, canOptIntoPreviousTaxYear, isScorp, annualTaxFiling) => {
    const tskCutoffAt = isScorp
      ? convertUtcToLocalDate(taxDetails?.form_1120_s_tq_due_date)?.endOf('day')
      : convertUtcToLocalDate(taxDetails?.form_1040_tq_due_date)?.endOf('day')

    return (
      !annualTaxFiling?.pretaxSurveySubmittedAt &&
      (!canOptIntoPreviousTaxYear ||
        (tskCutoffAt && DateTime.now() > tskCutoffAt))
    )
  }
)

export const selectLateFilingStatusType = createSelector(
  selectAnnualTaxFilingForCurrentTaxYear,
  selectFilingFormsForCurrentTaxYear,
  selectExpectedAnnualTaxFormNeedsForCurrentTaxYear,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (filing, filingForms, formNeeds, deadlines): LateFilingStatusType => {
    const allTqCompleted =
      filingForms.length > 0 &&
      filingForms.every(
        (f) =>
          f.questionnaireResponseStatus ===
          AnnualTaxFilingFormQuestionnaireStatus.submitted
      )

    //if the user is a single 1040 filer, use the 1040 CYB deadline. For 1120-S or two form filers, always use the 1120-S CYB deadline
    const cybDeadline =
      formNeeds.length === 1 && formNeeds.includes(TaxFormType.form1040)
        ? deadlines.form1040.cybDeadline
        : deadlines.form1120s.cybDeadline

    const now = DateTime.now()

    if (filing?.optedOutAt || allTqCompleted) {
      return {
        status: LateFilingStatus.notRelevant,
        applicableTo: [],
      }
      //the deadline for completing their TSK is the same as the CYB deadline
    } else if (
      !filing?.pretaxSurveySubmittedAt &&
      cybDeadline &&
      now >= cybDeadline
    ) {
      return {
        status: LateFilingStatus.tskMissed,
        applicableTo: formNeeds,
      }
    } else if (formNeeds.length === 1) {
      //if the user is a single filer, the copy is the same so there's no need to set applicableTo
      //since we check in the first condition whether all of the TQs are completed, we can assume the TQ for this form isn't completed
      const deadline = formNeeds.includes(TaxFormType.form1040)
        ? deadlines.form1040.taxChecklistDeadline
        : deadlines.form1120s.taxChecklistDeadline
      // if the deadline doesn't exist, don't show alerts or take them out of the process
      if (!deadline) {
        return {
          status: LateFilingStatus.notRelevant,
          applicableTo: [],
        }
      } else if (now < deadline) {
        return {
          status: LateFilingStatus.taxChecklistNeeded,
          applicableTo: formNeeds,
        }
      } else {
        return {
          status: LateFilingStatus.taxChecklistMissed,
          applicableTo: formNeeds,
        }
      }
    } else if (formNeeds.length > 1) {
      const status1120s = filingForms.find(
        (form) =>
          form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
      )?.questionnaireResponseStatus

      //The 1120-S is a prereq for the 1040, so if the 1120-S TQ is incomplete, the status applies to both forms
      if (status1120s !== AnnualTaxFilingFormQuestionnaireStatus.submitted) {
        const deadline = deadlines.form1120s.taxChecklistDeadline
        // if the deadline doesn't exist, don't show alerts or take them out of the process
        if (!deadline) {
          return {
            status: LateFilingStatus.notRelevant,
            applicableTo: [],
          }
        } else if (now < deadline) {
          return {
            status: LateFilingStatus.taxChecklistNeeded,
            applicableTo: formNeeds,
          }
        } else {
          return {
            status: LateFilingStatus.taxChecklistMissed,
            applicableTo: formNeeds,
          }
        }
        //we know that not all TQs are complete, so this means only the 1040 TQ is incomplete
      } else {
        const deadline = deadlines.form1040.taxChecklistDeadline
        // if the deadline doesn't exist, don't show alerts or take them out of the process
        if (!deadline) {
          return {
            status: LateFilingStatus.notRelevant,
            applicableTo: [],
          }
        } else if (now < deadline) {
          return {
            status: LateFilingStatus.taxChecklistNeeded,
            applicableTo: [TaxFormType.form1040],
          }
        } else {
          return {
            status: LateFilingStatus.taxChecklistMissed,
            applicableTo: [TaxFormType.form1040],
          }
        }
      }
    } else {
      //shouldn't reach here, but default to notRelevant
      return {
        status: LateFilingStatus.notRelevant,
        applicableTo: [],
      }
    }
  }
)

export const selectRelevantLateFilingDeadline = createSelector(
  selectLateFilingStatusType,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (lateFilingStatusType, allDeadlines) => {
    const { status, applicableTo } = lateFilingStatusType
    const relevantTaxFormType = applicableTo.includes(TaxFormType.form1120s)
      ? TaxFormType.form1120s
      : TaxFormType.form1040
    if (status === LateFilingStatus.tskMissed) {
      return allDeadlines[relevantTaxFormType].cybDeadline
    } else if (
      [
        LateFilingStatus.taxChecklistMissed,
        LateFilingStatus.taxChecklistNeeded,
      ].includes(status)
    ) {
      return allDeadlines[relevantTaxFormType].taxChecklistDeadline
    } else {
      return null
    }
  }
)

const selectShowTaxSeasonKickoff = createSelector(
  selectPrimaryMembership,
  selectIsBasicPlan,
  (primaryMembership, isBasicPlan) =>
    !isBasicPlan && primaryMembership?.status !== MEMBERSHIP_STATUS.trial
)

export const selectShowDashboardTaxSeasonHeroBanner = createSelector(
  selectShowTaxSeasonKickoff,
  selectAllYearEndModulesComplete,
  getYearEndModuleStatuses,
  (
    showTaxSeasonKickoff,
    allYearEndModulesComplete,
    allYearEndModuleStatuses
  ) => {
    if (allYearEndModuleStatuses.length === 0) {
      // Show the banner if there are no year end modules and the user is eligible
      return showTaxSeasonKickoff
    }
    // If eligible, show the banner unless all year end modules are complete
    return showTaxSeasonKickoff && !allYearEndModulesComplete
  }
)

export const selectShowLateFilingAlert = createSelector(
  selectLateFilingStatusType,
  selectLateTaxFilingDeadlinesForCurrentTaxYear,
  (lateFilingStatusType, allDeadlines) => {
    const relevantTaxFormType = lateFilingStatusType.applicableTo?.includes(
      TaxFormType.form1120s
    )
      ? TaxFormType.form1120s
      : TaxFormType.form1040

    const now = DateTime.now()

    const status = lateFilingStatusType.status

    const deadline =
      status === LateFilingStatus.taxChecklistNeeded
        ? allDeadlines[relevantTaxFormType].taxChecklistDeadline
        : //if the status isn't cybNeeded or taxChecklistNeeded, don't show the alert
          null

    return Boolean(
      deadline &&
        now > deadline.minus({ days: daysBeforeToShowLateFilingAlert })
    )
  }
)

/**
 * This selector is used in the signup flow to see whether the current time is past when the user is
 * eligible to receive annual tax services for the current annual tax year
 */
export const selectAnnualTaxNotAvailable = createSelector(
  getFinancialProfile,
  selectCurrentAnnualTaxDetails,
  (fp, annualTaxDetails) => {
    const is1040 = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040

    if (!annualTaxDetails?.taxQuestionnaireDueDates) {
      return true
    }

    const now = DateTime.now()

    const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1040
        .lateJoinerFinalCutoffDate
    )?.endOf('day')

    const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1120_s
        .lateJoinerFinalCutoffDate
    )?.endOf('day')
    if (is1040) {
      return (
        form1040LateJoinerFinalCutoffDate &&
        now > form1040LateJoinerFinalCutoffDate
      )
    } else {
      // We will use the 1120-s cutoff for non s-props, including s-corps, c-corps, and other
      return (
        form1120sLateJoinerFinalCutoffDate &&
        now > form1120sLateJoinerFinalCutoffDate
      )
    }
  }
)

/**
 * This selector is used in the annual tax center to determine if a user started
 * at heard early enough to receive annual tax services for the current annual tax year
 */
const selectAnnualTaxDisqualified = createSelector(
  getFinancialProfile,
  selectMembershipStart,
  selectCurrentAnnualTaxDetails,
  (fp, membershipStart, annualTaxDetails) => {
    const is1040 = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1040
    if (!membershipStart) return true

    if (!annualTaxDetails?.taxQuestionnaireDueDates) {
      return true
    }

    const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1040
        .lateJoinerFinalCutoffDate
    )?.endOf('day')

    const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
      annualTaxDetails.taxQuestionnaireDueDates.form_1120_s
        .lateJoinerFinalCutoffDate
    )?.endOf('day')
    if (is1040) {
      return (
        form1040LateJoinerFinalCutoffDate &&
        DateTime.fromISO(membershipStart) > form1040LateJoinerFinalCutoffDate
      )
    } else {
      // We will use the 1120-s cutoff for non s-props, including s-corps, c-corps, and other
      return (
        form1120sLateJoinerFinalCutoffDate &&
        DateTime.fromISO(membershipStart) > form1120sLateJoinerFinalCutoffDate
      )
    }
  }
)

export interface DependentForm {
  key: string
  firstName: string
  lastName: string
  relationship: DependentRelationship
  dob: string
  ssn: string
  formRef: RefObject<FormikProps<FormikValues>>
}

export const FILTER_TAX_FILING_UNANSWERED = 'unanswered'
export const FILTER_TAX_FILING_NO_SERVICES = 'noServices'

export enum AnnualTaxPanelDisplay {
  userDisqualified,
  userMissedDeadline,
  noTaxFiling,
  taxSeasonKickoffIncomplete,
  noTaxServices,
  incompleteTaxFilingForm,
  annualTaxProgress,
}

export const selectAnnualTaxDisplay = createSelector(
  selectAnnualTaxDisqualified,
  selectLateFilingStatusType,
  selectAnnualTaxFilingForCurrentTaxYear,
  selectFilingFormsForCurrentTaxYear,
  (_: unknown, skipDeadline?: boolean) => skipDeadline,
  (
    userDisqualified,
    lateFilingStatusType,
    taxFiling,
    filingForms,
    skipDeadline
  ) => {
    if (userDisqualified) {
      return AnnualTaxPanelDisplay.userDisqualified
    } else if (
      !skipDeadline &&
      [
        LateFilingStatus.tskMissed,
        LateFilingStatus.taxChecklistMissed,
      ].includes(lateFilingStatusType.status)
    ) {
      return AnnualTaxPanelDisplay.userMissedDeadline
    } else if (!taxFiling) {
      return AnnualTaxPanelDisplay.noTaxFiling
    } else if (!taxFiling.pretaxSurveySubmittedAt) {
      return AnnualTaxPanelDisplay.taxSeasonKickoffIncomplete
    } else if (taxFiling.optedOutAt || taxFiling.annualTaxFormNeeds === null) {
      return AnnualTaxPanelDisplay.noTaxServices
    } else if (
      taxFiling.pretaxSurveySubmittedAt &&
      filingForms.some(
        ({ questionnaireResponseStatus }) =>
          questionnaireResponseStatus !==
          AnnualTaxFilingFormQuestionnaireStatus.submitted
      )
    ) {
      return AnnualTaxPanelDisplay.incompleteTaxFilingForm
    }

    return AnnualTaxPanelDisplay.annualTaxProgress
  }
)

// Step 1 User starts annual taxes with this entry point
const getTaxSeasonKickoffStatus = (
  taxFiling: AnnualTaxFiling | null | undefined,
  taxDetails: AnnualTaxDetail | null | undefined
) => {
  if (!taxFiling?.pretaxSurveySubmittedAt) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (moment(taxDetails?.taxSeasonKickoffDueAt).isBefore()) {
    return AnnualTaxFilingStepStatus.completeLocked
  }

  return AnnualTaxFilingStepStatus.completeUnlocked
}

export const selectTaxSeasonKickoffStatusByYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectTaxDetailsByYear,
  getTaxSeasonKickoffStatus
)

const selectTaxSeasonKickoffStatusByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectTaxDetailsByFormYear,
  getTaxSeasonKickoffStatus
)

// Step 2 Nec contractor filing.  This is optional based on tsk responses
const getContractorNecStartDateFromTaxDetails = (
  taxDetails: AnnualTaxDetail | undefined | null
) =>
  taxDetails?.ten99NECFormsStartAt
    ? isoToUTCDateTime(taxDetails?.ten99NECFormsStartAt)
    : null

const selectContractorNecStartDateByFormYear = createSelector(
  selectTaxDetailsByFormYear,
  getContractorNecStartDateFromTaxDetails
)

const selectContractorNecStartDateByYear = createSelector(
  selectTaxDetailsByYear,
  getContractorNecStartDateFromTaxDetails
)

const selectContractorNecEndByYear = createSelector(
  selectTaxDetailsByYear,
  (taxDetails) =>
    taxDetails?.ten99NECFormsStartAt
      ? isoToUTCDateTime(taxDetails?.ten99NECFormsDueAt)
      : null
)

/**
 * Would be better to put this in the aboundAnnualTaxFilings.selector,
 * but it's creating a circular dependency. Can maybe refactor later.
 */
export const selectSubmitted1099sAfterDeadline = createSelector(
  selectContractorNecEndByYear,
  selectAboundTaxDocsForPayerForYear,
  (dueDate, docs) =>
    docs.some(
      (d) =>
        (!d.submittedAt && dueDate && DateTime.now() > dueDate) ||
        (d.submittedAt && dueDate && isoToUTCDateTime(d.submittedAt) > dueDate)
    )
)

const getFileNecStatus = (
  current1099sCount: number,
  lastFiled1099: AboundTaxDocument | null,
  contractorNecStart: DateTime | null
) => {
  if (lastFiled1099) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (current1099sCount !== 0) {
    return AnnualTaxFilingStepStatus.inProgress
  }

  return contractorNecStart && DateTime.now() > contractorNecStart
    ? AnnualTaxFilingStepStatus.notStarted
    : AnnualTaxFilingStepStatus.upcoming
}

export const selectFileNecStatusByYear = createSelector(
  selectCurrent1099sCount,
  selectLastFiled1099,
  selectContractorNecStartDateByYear,
  getFileNecStatus
)

const selectFileNecStatusByFormId = createSelector(
  selectCurrent1099sCount,
  selectLastFiled1099,
  selectContractorNecStartDateByFormYear,
  getFileNecStatus
)
export const NEC_CUTOFF = DateTime.fromObject({ month: 4, day: 1, year: 2024 })

const shouldShowNeedContractorFiling = (
  taxFiling: AnnualTaxFiling | AnnualTaxFilingNonNormalized | undefined,
  necStatus: ReturnType<typeof getFileNecStatus>
) =>
  Boolean(
    taxFiling?.needContractorFiling &&
      [
        needContractorFilingOption.yes,
        needContractorFilingOption.unsure,
      ].includes(taxFiling?.needContractorFiling) &&
      ([
        AnnualTaxFilingStepStatus.completeLocked,
        AnnualTaxFilingStepStatus.inProgress,
      ].includes(necStatus) ||
        DateTime.now() < NEC_CUTOFF)
  )

const selectShowNeedContractorFilingByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectFileNecStatusByFormId,
  shouldShowNeedContractorFiling
)

const selectShowNeedContractorFilingByYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectFileNecStatusByYear,
  shouldShowNeedContractorFiling
)

// Step 3 Extension requirement
const selectExtensionStartDate = createSelector(
  selectCurrentAnnualTaxDetails,
  (taxDetails) =>
    taxDetails?.extension_survey_start_date
      ? convertUtcToLocalDate(taxDetails.extension_survey_start_date)
      : null
)

const shouldShow1040Extension = (
  filing: AnnualTaxFiling | undefined,
  form: AnnualTaxFilingForm | undefined,
  taxDetails: AnnualTaxDetail
) => {
  if (!filing?.extensionRequestedAt) {
    return false
  }
  const NOW = DateTime.now()
  const needs1040Extension = Boolean(
    form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
  )
  const lateJoinerRequiresExtension = convertUtcToLocalDate(
    taxDetails.taxQuestionnaireDueDates?.form_1040
      .lateJoinerAutomaticExtensionCutoffDate
  )
  const isBefore1040ExtensionSurveyCutoff =
    lateJoinerRequiresExtension && NOW < lateJoinerRequiresExtension
  return (
    needs1040Extension &&
    (isBefore1040ExtensionSurveyCutoff || Boolean(filing?.extensionTaxfyleJob))
  )
}

const shouldShow1120sExtension = (
  filing: AnnualTaxFiling | undefined,
  form: AnnualTaxFilingForm | undefined,
  taxDetails: AnnualTaxDetail
) => {
  if (!filing?.extensionRequestedAt) {
    return false
  }

  const NOW = DateTime.now()
  const needs1120sExtension = Boolean(
    form?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )

  const lateJoinerRequiresExtension = convertUtcToLocalDate(
    taxDetails.taxQuestionnaireDueDates?.form_1120_s
      .lateJoinerAutomaticExtensionCutoffDate
  )
  const isBefore1120sExtensionSurveyCutoff =
    lateJoinerRequiresExtension && NOW < lateJoinerRequiresExtension
  return (
    needs1120sExtension &&
    (isBefore1120sExtensionSurveyCutoff || Boolean(filing?.extensionTaxfyleJob))
  )
}

const shouldShowFileExtension = (
  filing: AnnualTaxFiling | undefined,
  forms: AnnualTaxFilingForm[],
  taxDetails?: AnnualTaxDetail
) => {
  if (!taxDetails || !filing?.extensionRequestedAt) {
    return false
  }
  const form1040 = forms.find(
    (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
  )
  const show1040Extension = shouldShow1040Extension(
    filing,
    form1040,
    taxDetails
  )
  const form1120s = forms.find(
    (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  )
  const show1120sExtension = shouldShow1120sExtension(
    filing,
    form1120s,
    taxDetails
  )

  return show1040Extension || show1120sExtension
}

export const selectShowFileExtensionForYear = createSelector(
  selectFilingFormsForYear,
  getAnnualTaxFilingForYearSelector,
  selectCurrentAnnualTaxDetails,
  (forms, filing, taxDetails) => {
    return shouldShowFileExtension(filing, forms, taxDetails)
  }
)

export const selectShowFileExtensionByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  getAnnualTaxFilingForms,
  selectCurrentAnnualTaxDetails,
  (filing, forms, taxDetails) => {
    return shouldShowFileExtension(filing, Object.values(forms), taxDetails)
  }
)

/**
 * Returns if the current date time is before the filing extension deadline
 */
const selectIsBeforeExtensionDeadline = createSelector(
  selectCurrentAnnualTaxDetails,
  getAnnualTaxFilingForms,
  (taxDetailsForYear, allForms) => {
    const formsForYear = Object.values(allForms).filter(
      (f) => f.year === taxDetailsForYear?.taxYear
    )
    if (formsForYear.length === 0) return false
    const personalFiling = formsForYear.find(
      (form) => form.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
    )
    if (personalFiling) {
      const form1040IRSdueDate = convertUtcToLocalDate(
        taxDetailsForYear?.irsFormDueDates.form_1040.irs.dueDate
      )?.endOf('day')
      if (form1040IRSdueDate) {
        return DateTime.now() < form1040IRSdueDate
      }
      return false
    }

    const form1120sIRSDueDate = convertUtcToLocalDate(
      taxDetailsForYear?.irsFormDueDates.form_1120_s.irs.dueDate
    )?.endOf('day')
    if (form1120sIRSDueDate) {
      return DateTime.now() < form1120sIRSDueDate
    }
    return false
  }
)

const selectIsBefore1120sDeadline = createSelector(
  selectAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
  (taxDetails, taxYear) => {
    const taxDetailsForYear = Object.values(taxDetails).find(
      (d) => d.taxYear === taxYear
    )
    if (taxDetailsForYear?.irsFormDueDates.form_1120_s.irs.dueDate) {
      const utcDate = isoToUTCDateTime(
        taxDetailsForYear.irsFormDueDates.form_1120_s.irs.dueDate
      )
      const endOfDayUserLocalTime = DateTime.fromObject({
        year: utcDate.year,
        month: utcDate.month,
        day: utcDate.day,
      }).endOf('day')
      return (
        taxDetailsForYear?.irsFormDueDates?.form_1120_s?.irs?.dueDate &&
        DateTime.now() < endOfDayUserLocalTime
      )
    }
    return false
  }
)

const getFileExtensionStatus = (
  filing: AnnualTaxFiling | undefined,
  needsNec: boolean,
  tskStatus: ReturnType<typeof getTaxSeasonKickoffStatus>,
  necFilingStatus: ReturnType<typeof getFileNecStatus>,
  isBeforeExtensionDeadline: boolean,
  extensionStartDate: DateTime | null,
  filingForms: AnnualTaxFilingForm[]
) => {
  if (
    filingForms.every((form) => Boolean(form.extensionFiledAt)) ||
    !isBeforeExtensionDeadline
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (filing?.extensionStartedAt) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (extensionStartDate && extensionStartDate <= DateTime.now()) {
    return AnnualTaxFilingStepStatus.notStarted
  } else if (
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(tskStatus) &&
    (!needsNec || necFilingStatus === AnnualTaxFilingStepStatus.completeLocked)
  ) {
    return AnnualTaxFilingStepStatus.comingSoon
  }

  return AnnualTaxFilingStepStatus.upcoming
}

export const selectFileExtensionStatusForYear = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectIsBeforeExtensionDeadline,
  selectExtensionStartDate,
  selectFilingFormsForCurrentTaxYear,
  getFileExtensionStatus
)

const selectShowFileExtensionStatusByFormId = createSelector(
  selectAnnualTaxFilingByFormId,
  selectShowNeedContractorFilingByFormId,
  selectTaxSeasonKickoffStatusByFormId,
  selectFileNecStatusByFormId,
  selectIsBeforeExtensionDeadline,
  selectExtensionStartDate,
  selectFilingFormsForCurrentTaxYear,
  getFileExtensionStatus
)

export const selectExtensionSurveyComplete = createSelector(
  getAnnualTaxFilingForYearSelector,
  (filing) => Boolean(filing?.extensionRequestSurveyCompletedAt)
)

// This is subtly different than above - this will only return true if there is a survey and it's not complete.
export const selectHasIncompleteExtension = createSelector(
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  (showExtensionSurvey, extensionStatus) =>
    showExtensionSurvey &&
    extensionStatus !== AnnualTaxFilingStepStatus.completeLocked
)

// Step 4 Tax questionnaire or tax checklist
export const getTQStatusForForm = (
  filingForm: AnnualTaxFilingForm | null | undefined,
  annualTaxDetail: AnnualTaxDetail | null | undefined,
  needsNec: boolean,
  tskStatus: ReturnType<typeof getTaxSeasonKickoffStatus>,
  necFilingStatus: ReturnType<typeof getFileNecStatus>,
  needFileExtension: boolean,
  fileExtensionStatus: ReturnType<typeof getFileExtensionStatus>
) => {
  if (
    filingForm?.questionnaireResponseStatus ===
    AnnualTaxFilingFormQuestionnaireStatus.started
  ) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (
    filingForm?.questionnaireResponseStatus ===
    AnnualTaxFilingFormQuestionnaireStatus.submitted
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  }

  const isForm1120_s =
    filingForm?.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s

  const startTime = isForm1120_s
    ? annualTaxDetail?.taxQuestionnaireDueDates?.form_1120_s.startAt
    : annualTaxDetail?.taxQuestionnaireDueDates?.form_1040.startAt

  if (
    needFileExtension &&
    fileExtensionStatus !== AnnualTaxFilingStepStatus.completeLocked
  ) {
    return AnnualTaxFilingStepStatus.upcoming
  }

  if (startTime && DateTime.fromISO(startTime) <= DateTime.now()) {
    return AnnualTaxFilingStepStatus.notStarted
  } else if (
    [
      AnnualTaxFilingStepStatus.completeLocked,
      AnnualTaxFilingStepStatus.completeUnlocked,
    ].includes(tskStatus) &&
    (!needsNec ||
      necFilingStatus === AnnualTaxFilingStepStatus.completeLocked) &&
    (!needFileExtension ||
      fileExtensionStatus === AnnualTaxFilingStepStatus.completeLocked)
  ) {
    return AnnualTaxFilingStepStatus.comingSoon
  }

  return AnnualTaxFilingStepStatus.upcoming
}

const selectFormTQStatus = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxDetailsByFormYear,
  selectShowNeedContractorFilingByFormId,
  selectTaxSeasonKickoffStatusByFormId,
  selectFileNecStatusByFormId,
  selectShowFileExtensionByFormId,
  selectShowFileExtensionStatusByFormId,
  getTQStatusForForm
)

const formHasStatus = (
  filingForm: AnnualTaxFilingForm | null | undefined,
  steps: TaxfyleJobStep[],
  statuses: TaxfyleJobStatus[] = []
) =>
  Boolean(
    filingForm?.taxfyleJob &&
      // Check if the steps match any of the ones sent in
      ((filingForm.taxfyleJob.jobStep &&
        steps.includes(filingForm.taxfyleJob.jobStep)) ||
        // If statues are sent in check if any of filing forms include that status
        statuses.includes(filingForm.taxfyleJob.jobStatus))
  )

// Step 5 Tax preparation status
const TAX_PREP_IN_PROGRESS_STEPS = [TaxfyleJobStep.openItems]

const TAX_PREP_IN_PROGRESS_STATUSES = [
  TaxfyleJobStatus.unclaimed,
  TaxfyleJobStatus.claimed,
  TaxfyleJobStatus.idle,
  TaxfyleJobStatus.onHold,
  TaxfyleJobStatus.infoGathering,
]

const TAX_PREP_COMPLETE = [
  TaxfyleJobStep.jobStarted,
  TaxfyleJobStep.draftInReview,
  TaxfyleJobStep.draftApproved,
  TaxfyleJobStep.draftRejected,
  TaxfyleJobStep.authorizationRequested,
  TaxfyleJobStep.authorizationSigned,
  TaxfyleJobStep.returnFiled,
]

/**
 * Returns true if user has any filings jobs in a status that
 * a tax pro would normally be assigned
 */
export const hasFilingJobAssigned = createSelector(
  selectFilingFormsForCurrentTaxYear,
  (forms) =>
    forms.some(
      (form) =>
        form.taxfyleJob?.jobStatus &&
        [
          TaxfyleJobStatus.claimed,
          TaxfyleJobStatus.idle,
          TaxfyleJobStatus.onHold,
        ].includes(form.taxfyleJob?.jobStatus)
    )
)

export const selectTaxPreparationStatusForForm = createSelector(
  selectAnnualTaxFilingFormById,
  selectFormTQStatus,
  (form, tqStatus) => {
    if (formHasStatus(form, TAX_PREP_COMPLETE)) {
      return AnnualTaxFilingStepStatus.completeLocked
    } else if (
      formHasStatus(
        form,
        TAX_PREP_IN_PROGRESS_STEPS,
        TAX_PREP_IN_PROGRESS_STATUSES
      )
    ) {
      return AnnualTaxFilingStepStatus.inProgress
    } else if (tqStatus === AnnualTaxFilingStepStatus.completeLocked) {
      // Once the TQ for the form is complete tax prep should display as coming soon
      return AnnualTaxFilingStepStatus.comingSoon
    }

    return AnnualTaxFilingStepStatus.upcoming
  }
)

// Step 7 Tax return filing
const TAX_RETURN_FILING_AUTHORIZATION_REQUESTED = [
  TaxfyleJobStep.authorizationRequested,
]
const TAX_RETURN_FILING_IN_PROGRESS = [
  TaxfyleJobStep.authorizationSigned,
  TaxfyleJobStep.draftApproved,
]
const TAX_RETURN_FILING_COMPLETE = [TaxfyleJobStep.returnFiled]

const getFilingStatusForForm = (
  form: AnnualTaxFilingForm | null | undefined
) => {
  if (
    formHasStatus(form, TAX_RETURN_FILING_COMPLETE, [
      TaxfyleJobStatus.completed,
    ])
  ) {
    return AnnualTaxFilingStepStatus.completeLocked
  } else if (formHasStatus(form, TAX_RETURN_FILING_IN_PROGRESS)) {
    return AnnualTaxFilingStepStatus.inProgress
  } else if (formHasStatus(form, TAX_RETURN_FILING_AUTHORIZATION_REQUESTED)) {
    return AnnualTaxFilingStepStatus.readyToSign
  }

  return AnnualTaxFilingStepStatus.upcoming
}

export const selectFilingStatusForForm = createSelector(
  selectAnnualTaxFilingFormById,
  getFilingStatusForForm
)

const selectFormsWithCompletedTqForYear = createSelector(
  selectFilingFormsForYear,
  selectTaxDetailsByYear,
  selectShowNeedContractorFilingByYear,
  selectTaxSeasonKickoffStatusByYear,
  selectFileNecStatusByYear,
  selectShowFileExtensionForYear,
  selectFileExtensionStatusForYear,
  (forms, ...rest) =>
    forms.filter(
      (f) =>
        getTQStatusForForm(f, ...rest) ===
        AnnualTaxFilingStepStatus.completeLocked
    )
)

// Some particular sections (like deductions and credits) are tied to a form, while others are tied to a filing (like documents)
// Use selectTqFormIsReadOnly or selectTqFilingIsReadOnly based on this
export const selectTqFormIsReadOnly = createSelector(
  selectFormTQStatus,
  (status) => status === AnnualTaxFilingStepStatus.completeLocked
)

export const selectTqFilingIsReadOnly = createSelector(
  selectFilingFormsForYear,
  selectFormsWithCompletedTqForYear,
  (allFormsForYear, completedFormsForYear) =>
    allFormsForYear.length === completedFormsForYear.length
)
/**
 * Returns if user has a taxfyle job or extension for current tax year
 */
export const hasTaxfyleJobOrExtensionInProgress = createSelector(
  getAnnualTaxFilingForYearSelector,
  selectFilingFormsForYear,
  selectShowFileExtensionForYear,
  // getFileExtensionStatus,
  (filing, forms, fileExtended) => {
    if (!filing) return false
    // Checks if filing has extension and if extension in progress
    if (fileExtended) {
      return false
    }
    // Checks if any of the forms associated to the tax filing as a Taxfyle Job
    return forms.some(
      (form: AnnualTaxFilingForm) =>
        form.taxfyleJob &&
        form.taxfyleJob.jobStep !== TaxfyleJobStep.returnFiled &&
        form.taxfyleJob.jobStatus !== TaxfyleJobStatus.completed
    )
  }
)

export const selectTaxOptInEligibility = createSelector(
  selectCurrentAnnualTaxDetails,
  selectIsCurrentUserScorp,
  selectAnnualTaxFilingForCurrentTaxYear,
  selectTaxQuestionnaireYearEndModuleStatus,
  (_: unknown, optInFlowEnabled: boolean) => optInFlowEnabled,
  (taxDetails, isSCorp, taxFiling, yearEndTQStatus, optInFlowEnabled) => {
    if (!taxDetails || !taxFiling || !optInFlowEnabled)
      return {
        businessOptInEligible: false,
        personalOptInEligible: false,
      }

    const form1120sTQDueDate = convertUtcToLocalDate(
      taxDetails.form_1120_s_tq_due_date
    )?.endOf('day')
    const businessOptInCutOff = form1120sTQDueDate ?? null
    const form1040TQDueDate = convertUtcToLocalDate(
      taxDetails.form_1040_tq_due_date
    )?.endOf('day')
    const personalOptInCutOff = form1040TQDueDate ?? null

    const NOW = DateTime.now()
    const isBeforeBusinessOptInCutOff = Boolean(
      businessOptInCutOff && NOW < businessOptInCutOff
    )
    const isBeforePersonalOptInCutOff = Boolean(
      personalOptInCutOff && NOW < personalOptInCutOff
    )

    const alreadyOptedIntoBusiness = Boolean(
      taxFiling.annualTaxFormNeeds?.includes(TaxFormType.form1120s)
    )
    const businessOptInEligible =
      isSCorp && !alreadyOptedIntoBusiness && isBeforeBusinessOptInCutOff

    const alreadyOptedIntoPersonal = Boolean(
      taxFiling.annualTaxFormNeeds?.includes(TaxFormType.form1040)
    )
    const personalOptInEligible = Boolean(
      !alreadyOptedIntoPersonal &&
        ((!isSCorp && isBeforePersonalOptInCutOff) ||
          (isSCorp &&
            isBeforeBusinessOptInCutOff &&
            yearEndTQStatus !== YearEndModuleStatusOptions.complete))
    )

    return {
      businessOptInEligible,
      personalOptInEligible,
    }
  }
)

export const selectExtensionRequestType = createSelector(
  selectIsBefore1120sDeadline,
  select1040FormForYear,
  select1120sFormForYear,
  (isBefore1120sDeadline, form1040, form1120s) => {
    const needsExtended1040 = Boolean(form1040)
    const needsExtended1120s = Boolean(form1120s && isBefore1120sDeadline)
    const hasTwoExtendedForms = needsExtended1040 && needsExtended1120s

    if (hasTwoExtendedForms) {
      return ExtensionRequestType.COMBINED
    } else if (needsExtended1120s) {
      return ExtensionRequestType.BUSINESS
    } else if (needsExtended1040) {
      return ExtensionRequestType.PERSONAL
    }
    return ExtensionRequestType.NONE
  }
)

export const selectExtensionRequestSurveyLink = createSelector(
  selectExtensionRequestType,
  (extensionType) => {
    if (extensionType === ExtensionRequestType.COMBINED) {
      return '/taxes/annual/extension_request/combined'
    } else if (extensionType === ExtensionRequestType.BUSINESS) {
      return '/taxes/annual/extension_request/business'
    } else if (extensionType === ExtensionRequestType.PERSONAL) {
      return '/taxes/annual/extension_request/personal'
    }
    return '/taxes/annual'
  }
)

export const selectLaterJoinerOptedOutDuringSignup = createSelector(
  selectAnnualTaxFilingForCurrentTaxYear,
  (filing) => filing?.lateJoinerInitiallyOptedIn === false
)

/**
 * Returns true if user has been connected to a Taxfyle pro for the current tax year
 */
export const selectHasBeenConnectedToTaxfyle = createSelector(
  selectCurrentAnnualTaxFiling,
  selectFilingFormsForCurrentTaxYear,
  (filing, forms) => {
    const hasExtensionJob = Boolean(
      filing?.extensionTaxfyleJob &&
        ![
          TaxfyleJobStatus.underConstruction,
          TaxfyleJobStatus.unclaimed,
        ].includes(filing.extensionTaxfyleJob.jobStatus)
    )
    if (hasExtensionJob) return true
    return forms.some((form) =>
      Boolean(
        form.taxfyleJob &&
          ![
            TaxfyleJobStatus.underConstruction,
            TaxfyleJobStatus.unclaimed,
          ].includes(form.taxfyleJob.jobStatus)
      )
    )
  }
)

const shouldDisplayModuleInBanner = ({
  type,
  dueDate,
  allApplicableModules,
  completedModules,
  includeAfterDue,
}: {
  type: YearEndModuleType
  dueDate: string | undefined
  allApplicableModules: YearEndModuleStatus[]
  completedModules: YearEndModuleStatus[]
  includeAfterDue?: boolean
}) => {
  const moduleCompletedOrNotApplicable =
    !allApplicableModules.some(
      (applicableMod) => applicableMod.moduleType === type
    ) || completedModules.some((module) => module.moduleType === type)

  if (!dueDate || moduleCompletedOrNotApplicable) {
    return false
  }

  const now = DateTime.now()
  const oneWeekFromNow = now.plus({ weeks: 1 })
  const due = convertUtcToLocalDate(dueDate)?.endOf('day')

  if (!due) {
    return false
  }

  if (includeAfterDue && now > due) {
    return true
  }

  return now <= due && due <= oneWeekFromNow
}

const taxDetailsToFormattedDate = (dueDate: string | undefined) =>
  convertUtcToLocalDate(dueDate)
    ?.endOf('day')
    .toFormat(DATE_FORMATS_LUXON.MONTH_DAY)

export const selectDeadlineBannerCopy = createSelector(
  getUserIsAdmin,
  selectIsBasicPlan,
  getFreeTrialStatus,
  selectIsCurrentUserScorp,
  selectIsCurrentUserSoleProp,
  selectAllYearEndModulesComplete,
  getCompleteYearEndModuleStatuses,
  selectAnnualTaxFilingForCurrentTaxYear,
  select1120sFormForCurrentYear,
  select1040FormForCurrentYear,
  selectCurrentAnnualTaxDetails,
  getYearEndModuleStatuses,
  (
    isAdminUser,
    isBasicPlan,
    trialStatus,
    isScorp,
    isSoleProp,
    completedAllModules,
    completedModules,
    annualTaxFiling,
    form1120s,
    form1040,
    taxDetails,
    allApplicableModules
  ) => {
    const hasActiveTrial = trialStatus === TrialStatus.active
    const { pretaxSurveySubmittedAt, optedOutAt, extensionRequestedAt } =
      annualTaxFiling || {}

    if (
      // Everything complete
      completedAllModules ||
      // Pre tax survey not finished
      !pretaxSurveySubmittedAt ||
      // Not shown for basic plan
      isBasicPlan ||
      // Not for users in trial
      hasActiveTrial ||
      // Not for admins
      isAdminUser ||
      // User opted out
      optedOutAt ||
      !taxDetails
    ) {
      return null
    }

    const baseText = "Don't Forget! You need to"
    const endText = 'otherwise Heard will help you file an extension.'

    if (isSoleProp) {
      const extensionNotRequestedOrRejected =
        !extensionRequestedAt || form1040?.extensionAccepted === false

      const personalTaxMessageNotCompleteInRange = shouldDisplayModuleInBanner({
        type: YearEndModuleType.file1040,
        dueDate: taxDetails.form_1040_irs_due_date,
        allApplicableModules,
        completedModules,
        includeAfterDue: true,
      })

      if (
        personalTaxMessageNotCompleteInRange &&
        !(
          form1040?.extensionAccepted ||
          form1040?.taxfyleJob?.jobStep === TaxfyleJobStep.returnFiled ||
          form1040?.taxfyleJob?.jobStatus === TaxfyleJobStatus.completed
        )
      ) {
        return {
          bannerCopy: `Urgent! File your personal tax return, due on ${taxDetailsToFormattedDate(taxDetails.form_1040_irs_due_date)}, as soon as possible to minimize your penalties & interest.`,
          buttonCopy: 'Complete your business return',
        }
      }

      if (
        extensionNotRequestedOrRejected &&
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.eoyBookkeeping,
          dueDate: taxDetails.form_1040_eoy_survey_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `${baseText} complete your end of year Accounting Survey by ${taxDetailsToFormattedDate(taxDetails.form_1040_eoy_survey_due_date)}, ${endText}`,
          buttonCopy: 'Submit your survey',
        }
      }

      if (
        extensionNotRequestedOrRejected &&
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.eoyBookkeepingFollowup,
          dueDate: taxDetails.form_1040_close_books_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `Urgent! Address all remaining questions from your bookkeeper to finalize your ${taxDetails.taxYear} books by ${taxDetailsToFormattedDate(taxDetails.form_1040_close_books_due_date)}, ${endText}`,
          buttonCopy: 'Finalize your books',
        }
      }

      if (
        extensionNotRequestedOrRejected &&
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.taxQuestionnaire,
          dueDate: taxDetails.form_1040_tq_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `Urgent! Complete your Tax Questionnaire by ${taxDetailsToFormattedDate(taxDetails.form_1040_tq_due_date)}, ${endText}`,
          buttonCopy: 'Complete Tax Questionnaire',
        }
      }

      if (
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.submitExtensionRequest,
          dueDate: taxDetails.form_1040_extension_survey_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `${baseText} complete your extension request survey by ${taxDetailsToFormattedDate(taxDetails.form_1040_extension_survey_due_date)} in order for Heard to help you file an extension.`,
          buttonCopy: 'Submit your survey',
        }
      }

      if (
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.fileExtensionRequest,
          dueDate: taxDetails.form_1040_irs_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `Urgent! Complete your personal extension, due on ${taxDetailsToFormattedDate(taxDetails.form_1040_irs_due_date)}, as soon as possible to give you ample time to file your return without incurring penalties.`,
          buttonCopy: 'Submit your survey',
        }
      }
    } else if (isScorp) {
      const extensionAccepted = form1120s?.extensionAccepted

      const busTaxMessageNotCompleteInRange = shouldDisplayModuleInBanner({
        type: YearEndModuleType.file1120S,
        dueDate: taxDetails.form_1120_s_irs_due_date,
        allApplicableModules,
        completedModules,
        includeAfterDue: true,
      })

      if (
        busTaxMessageNotCompleteInRange &&
        !(
          extensionAccepted ||
          form1120s?.taxfyleJob?.jobStep === TaxfyleJobStep.returnFiled ||
          form1120s?.taxfyleJob?.jobStatus === TaxfyleJobStatus.completed
        )
      ) {
        return {
          bannerCopy: `Urgent! File your business tax return, due on ${taxDetailsToFormattedDate(taxDetails.form_1120_s_irs_due_date)}, as soon as possible to minimize your penalties & interest.`,
          buttonCopy: 'Complete your business return',
        }
      }

      if (
        !extensionAccepted &&
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.eoyBookkeepingFollowup,
          dueDate: taxDetails.form_1120_s_close_books_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `${baseText} address all Bookkeeper follow-ups by ${taxDetailsToFormattedDate(taxDetails.form_1120_s_close_books_due_date)}, ${endText}`,
          buttonCopy: 'Address Follow-ups',
        }
      }

      if (
        !extensionAccepted &&
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.taxQuestionnaire,
          dueDate: taxDetails.form_1120_s_tq_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `${baseText} complete your Tax Questionnaire by ${taxDetailsToFormattedDate(taxDetails.form_1120_s_tq_due_date)}, ${endText}`,
          buttonCopy: 'Complete Tax Questionnaire',
        }
      }

      if (
        shouldDisplayModuleInBanner({
          type: YearEndModuleType.submitExtensionRequest,
          dueDate: taxDetails.form_1120_s_extension_survey_due_date,
          allApplicableModules,
          completedModules,
        })
      ) {
        return {
          bannerCopy: `${baseText} complete your extension request survey by ${taxDetailsToFormattedDate(taxDetails.form_1120_s_extension_survey_due_date)} in order for Heard to help you file an extension.`,
          buttonCopy: 'Submit your survey',
        }
      }
    }

    return null
  }
)

/**
 * Returns true if user accepted late joiner terms
 * (late joiners who opt out of taxes will have isLateJoiner flag set to false but will have accepted late joiner terms)
 */
export const selectUserAcceptedLateJoinerTerms = createSelector(
  selectCurrentAnnualTaxFiling,
  (annualTaxFiling) => Boolean(annualTaxFiling?.isLateJoinerTermsAcceptedAt)
)
