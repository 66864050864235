import moment from 'moment'
import { CardProps } from 'semantic-ui-react'
import { Card, Label, Text } from '../../components/BaseComponents'
import {
  getFreeTrialEndsAt,
  isFreeTrialPromoCode,
} from '../../selectors/user.selectors'
import { Colors } from '../../styles/theme'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { FREE_TRIAL_DAYS, FREE_TRIAL_14_DAYS } from './helpers'
import { useReselector } from '../../utils/sharedHooks'

const labelStyle = {
  backgroundColor: Colors.sunrise,
  borderRadius: '100px !important',
  marginBottom: 12,
  color: Colors.forest,
  fontWeight: 'bold',
}

const FreeTrialBanner = (props: CardProps) => {
  const trialEndsAt = useReselector(getFreeTrialEndsAt)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  const trialLength = hasFreeTrialPromoCode
    ? FREE_TRIAL_14_DAYS
    : FREE_TRIAL_DAYS

  return (
    <Card {...props}>
      <Label style={labelStyle}>{trialLength}-DAY FREE TRIAL</Label>
      <Text as="bodySm">
        Your trial ends on{' '}
        {trialEndsAt
          ? moment(trialEndsAt).format(DATE_FORMATS.DISPLAY_SIMPLE)
          : ''}
      </Text>
    </Card>
  )
}

export default FreeTrialBanner
