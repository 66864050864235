import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { CalendlyEventDetails } from '../Onboarding/calendlyActions'
import { UserFinancialAdvisoryCallEvent } from './userFinancialAdvisoryCallEvents.slice'

export enum FinancialAdvisoryProfilesStatus {
  profile_generated = 'profile_generated',
  intro_call_scheduled = 'intro_call_scheduled',
  onboarding = 'onboarding',
  active = 'active',
  plan_completed = 'plan_completed',
  canceled = 'canceled',
  not_interested = 'not_interested',
  intro_call_completed = 'intro_call_completed',
  waitlisted = 'waitlisted',
}

export enum FinancialAdvisoryPilotStatus {
  // Design Partner: receives experience for free
  design_partner = 'design_partner',
  // Beta User: 150 Call Price
  beta_150 = 'beta_150',
  // Beta User: 200 Call Price
  beta_200 = 'beta_200',
  // Open Launch Group 3: 3 months free
  open_launch_group_three = 'open_launch_group_three',
}

export enum FinancialAdvisoryFeatureFlagValues {
  noPilot = 'no_pilot',
  pilotEnabled = 'pilot_enabled',
  openLaunchGroupOne = 'open_launch_group_one',
  openLaunchGroupThree = 'open_launch_group_three',
}

export interface FinancialAdvisoryProfile {
  id: number
  userId: number
  upriseUserUuid?: string
  upriseSubscriptionUuid?: string
  upriseFinancialPlanUuid?: string
  createdAt: string
  deletedAt?: string
  updatedAt: string
  onboardingStartedAt?: string
  onboardingCompletedAt?: string
  introCallAt?: string
  waitlistedAt?: string
  status: keyof typeof FinancialAdvisoryProfilesStatus
  pilotStatus: keyof typeof FinancialAdvisoryPilotStatus
  stripeSubscription?: string
  subscriptionStatus?: string

  // Non model Params
  upriseSessionId?: string
}

const initialState: FinancialAdvisoryProfile | null = null

/**
 * Advisory Profile Slice
 */

const financialAdvisoryProfileSlice = createSlice({
  name: 'financialAdvisoryProfile',
  initialState: initialState as FinancialAdvisoryProfile | null,
  reducers: {
    setFinancialAdvisoryProfile: (
      state,
      action: PayloadAction<FinancialAdvisoryProfile | null>
    ) => action.payload,
    updateFinancialAdvisoryProfile: (
      state,
      action: PayloadAction<FinancialAdvisoryProfile | null>
    ) => action.payload,
  },
})

export default financialAdvisoryProfileSlice.reducer

export const { setFinancialAdvisoryProfile, updateFinancialAdvisoryProfile } =
  financialAdvisoryProfileSlice.actions

export const FETCH_ADVISORY_PROFILE_KEY = 'FETCH_ADVISORY_PROFILE_KEY'

export const fetchFinancialAdvisoryProfileIfNeeded = (alwaysFetch?: boolean) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ADVISORY_PROFILE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.get<FinancialAdvisoryProfile | null>(
        '/finances/api/v1/financial_advisory_profiles'
      )
      dispatch(setFinancialAdvisoryProfile(res.data))
      return res.data
    },
    alwaysFetch,
  })

export const POST_ADVISORY_PROFILE_KEY = 'POST_ADVISORY_PROFILE_KEY'
export const createOrUpdateFinancialAdvisoryProfile = (
  payload: Partial<FinancialAdvisoryProfile>
) =>
  fetchWrapper({
    fetchKey: POST_ADVISORY_PROFILE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<FinancialAdvisoryProfile>(
        '/finances/api/v1/financial_advisory_profiles',
        payload
      )
      dispatch(setFinancialAdvisoryProfile(res.data))
      return res.data
    },
  })

export const putFinancialAdvisoryProfile = (
  id: number,
  data: Partial<FinancialAdvisoryProfile>
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<FinancialAdvisoryProfile>(
        `/finances/api/v1/financial_advisory_profiles/${id}`,
        data
      )
      dispatch(setFinancialAdvisoryProfile(res.data))
      return res.data
    },
  })

/**
 *
 * Uprise Routes
 *
 */

export const getUpriseSession = () =>
  fetchWrapper({
    fetchFunction: async () => {
      const res = await axios.get('/finances/api/v1/advisory/session')
      return res.data
    },
  })

export const createUpriseAccount = () =>
  fetchWrapper({
    fetchFunction: async () => {
      const res = await axios.get('/finances/api/v1/advisory/enroll')
      return res.data
    },
  })

/** Calendly Actions */
export const GET_UPRISE_CALENDLY_EVENT_DETAILS_KEY =
  'GET_UPRISE_CALENDLY_EVENT_DETAILS_KEY'
export const getUpriseCalendlyEventDetails = (eventUri: string) =>
  fetchWrapper({
    fetchKey: GET_UPRISE_CALENDLY_EVENT_DETAILS_KEY,
    fetchFunction: async () => {
      const json = await axios.post<CalendlyEventDetails>(
        '/finances/api/v1/advisory/event_details',
        { eventUri }
      )
      return json.data
    },
  })

/*
  //////
  Actions below can be called by admins only 
  /////
*/

export const ADMIN_FETCH_ADVISORY_PROFILE_KEY =
  'ADMIN_FETCH_ADVISORY_PROFILE_KEY'
export const adminFetchAdvisoryProfile = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_ADVISORY_PROFILE_KEY,
    defaultErrorMessage: 'Error fetching user advisory financial profile',
    fetchFunction: async () => {
      const json = await axios.get<FinancialAdvisoryProfile>(
        `/finances/api/v1/admin/financial_advisory_profiles/${userId}`
      )

      return json.data
    },
  })

export const ADMIN_FETCH_USER_UPRISE_MESSAGES_KEY =
  'ADMIN_FETCH_USER_UPRISE_MESSAGES_KEY'
export const adminFetchUpriseMessages = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_USER_UPRISE_MESSAGES_KEY,
    defaultErrorMessage: 'Error fetching Uprise Messages',
    fetchFunction: async () => {
      const json = await axios.get(
        `/finances/api/v1/admin/financial_advisory_profiles/${userId}/uprise_messages`
      )

      return json.data
    },
  })

export const ADMIN_FETCH_USER_ADVISORY_CALLS_KEY =
  'ADMIN_FETCH_USER_ADVISORY_CALLS_KEY'
export const adminFetchUserAdvisoryCalls = (userId: number) =>
  fetchWrapper({
    fetchKey: ADMIN_FETCH_USER_ADVISORY_CALLS_KEY,
    defaultErrorMessage: 'Error fetching user advisory calls',
    fetchFunction: async () => {
      const json = await axios.get<UserFinancialAdvisoryCallEvent[]>(
        `/finances/api/v1/admin/user_financial_advisory_call_events/${userId}`
      )

      return json.data
    },
  })
