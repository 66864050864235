import { createSelector } from 'reselect'
import { ReduxState } from '../../../../../../utils/typeHelpers'
import { SubStepIdentifiers } from './stepProgress.helpers'

export const getAllEoyReviewSteps = (state: ReduxState) =>
  state.allEoyReviewSteps

export const selectEoyReviewStepForIdentifier = createSelector(
  [
    getAllEoyReviewSteps,
    (_: unknown, identifier: SubStepIdentifiers) => identifier,
  ],
  (allEoyReviewSteps, identifier) => {
    return allEoyReviewSteps[identifier] ? allEoyReviewSteps[identifier] : null
  }
)

export const selectEoyReviewStepsInList = createSelector(
  getAllEoyReviewSteps,
  (_: unknown, identifiers: SubStepIdentifiers[]) => identifiers,
  (allEoyReviewSteps, identifiers) =>
    identifiers.map((identifier) => allEoyReviewSteps[identifier])
)
