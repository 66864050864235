import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { sortBy } from 'lodash'

import {
  Alert,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import {
  getPaidTaxEstimatesByYear,
  getUnpaidTaxEstimatesByYear,
} from '../../../../QuarterlyTaxEstimates/userTaxEstimates.selector'
import {
  UserTaxEstimate,
  fetchUserTaxEstimatesIfNeeded,
} from '../../../../QuarterlyTaxEstimates/userTaxEstimates.slice'
import {
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  updateUserEoyReviewProgress,
  fetchUserEoyReviewProgress,
} from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import EstimateCard from './EstimateCard'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import {
  formatTaxQuarter,
  sortAndGroupEstimatesByTaxQuarter,
} from '../../../../QuarterlyTaxEstimates/helpers'
import { selectAnnualTaxFilingFormById } from '../../../annualTaxFilingForms.selector'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../../annualTaxFilingForms.slice'
import { selectUserEoyReviewProgressForSubstepIdentifier } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import PageHeader from '../../../../../../components/shared/PageHeader'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import useMissingAnswerValidation from '../../Shared/UseMissingAnswerValidation'

const MissingQuarterCard = ({
  taxQuarter,
  paidEstimatesDuringStep,
  unpaidEstimates,
  readOnly,
}: {
  taxQuarter: string
  unpaidEstimates: UserTaxEstimate[]
  paidEstimatesDuringStep: UserTaxEstimate[]
  readOnly?: boolean
}) => (
  <Card backgroundColor="stone40">
    <Text as="h2">{formatTaxQuarter(taxQuarter)}</Text>
    {unpaidEstimates.map((e) => (
      <EstimateCard key={e.id} estimate={e} readOnly={readOnly} />
    ))}

    {/* Paid estimates intentionally after unpaid */}
    {paidEstimatesDuringStep.map((e) => (
      <EstimateCard key={e.id} estimate={e} readOnly={readOnly} />
    ))}
  </Card>
)

const AddMissingQTEPayments = () => {
  const navigate = useNavigateWithLocation()
  const dispatch = useAppDispatch()

  const { formId } = useParams()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const year = filingForm?.year
  const { errorAlert, triggerError, scrollToRef } = useMissingAnswerValidation(
    `Please enter in quarterly tax information for every quarter in ${year}.`
  )

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  useProgressSteps({ steps: ENTER_MISSING_QUARTERLY_TAX_PAYMENTS })

  // All paid estimates
  const paidEstimates = useReselector(getPaidTaxEstimatesByYear, year)

  // All unpaid estimates
  const unpaidEstimates = useReselector(getUnpaidTaxEstimatesByYear, year)
  const unpaidEstimatesByTaxQuarter = useMemo(
    () => sortAndGroupEstimatesByTaxQuarter(unpaidEstimates),
    [unpaidEstimates]
  )

  // Unpaid estimates at start of this step
  const [unpaidEstimateIdsAtStart, setUnpaidEstimateIdsAtStart] = useState<
    number[]
  >([])

  // User just started this step, store all original ids
  useEffect(() => {
    if (unpaidEstimates.length && !unpaidEstimateIdsAtStart.length) {
      setUnpaidEstimateIdsAtStart(unpaidEstimates.map((e) => e.id))
    }
  }, [unpaidEstimates, unpaidEstimateIdsAtStart])

  const paidEstimatesDuringStepByTaxQuarter = useMemo(
    () => sortAndGroupEstimatesByTaxQuarter(paidEstimates),
    [paidEstimates]
  )

  const readOnly = useReselector(selectTqFormIsReadOnly, formId)

  // Looks at the taxQuarters within paid and unpaid estimates.
  // Returns a sorted, distinct array of string taxQuarters, e.g. ['2022-1', '2022-3']
  const distinctTaxQuarters = useMemo(
    () =>
      sortBy(
        [
          ...new Set([
            ...paidEstimates.map((e) => e.taxQuarter),
            ...unpaidEstimates.map((e) => e.taxQuarter),
          ]),
        ],
        (taxQuarter) => taxQuarter
      ),
    [paidEstimates, unpaidEstimates]
  )

  const progress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    SubStepIdentifiers.addMissingQTEPayments
  )

  const saveAndReturn = useAsyncCallback(async () => {
    if (progress?.id) {
      await dispatch(
        updateUserEoyReviewProgress(progress.id, {
          completedAt: new Date(),
        })
      )
    }
    navigate('/taxes/annual/tax_checklist/')
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchUserTaxEstimatesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (year) {
      dispatch(fetchUserEoyReviewProgress(year))
    }
  }, [dispatch, year])

  if (!filingForm) {
    return null
  }

  return (
    <>
      {errorAlert}
      {readOnly && (
        <PageHeader
          backControl={{
            link: '/taxes/annual/tax_checklist/',
            text: 'Back to Personal Tax Checklist',
          }}
          header={''}
        />
      )}
      <Grid>
        <Grid.Row />
        <Grid.Row />
        <GridRowColumn centerContent {...tqBigSpace}>
          <Image
            src={
              'https://heard-images.s3.us-west-1.amazonaws.com/assets/taxes.svg'
            }
            style={{ width: 230, height: 200, padding: 0 }}
          />
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="h1" textAlign="center">
            Update Quarterly Tax Payments
          </Text>
        </GridRowColumn>

        {!readOnly && (
          <GridRowColumn {...tqSmallSpace} centerContent>
            <Text>
              Please enter your tax payments and upload the corresponding
              receipts.
            </Text>
          </GridRowColumn>
        )}
        {readOnly && (
          <GridRowColumn {...tqSmallSpace} centerContent>
            <Text>
              You can no longer edit your quarterly tax payments, but you can
              view them anytime.
            </Text>
          </GridRowColumn>
        )}
        {!readOnly && (
          <>
            <GridRowColumn short {...tqSmallSpace} centerContent>
              <Text>
                <Link
                  newPage
                  href="https://support.joinheard.com/hc/en-us/articles/10122365411223"
                >
                  How do I find my quarterly tax payment receipt?
                </Link>
              </Text>
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <Alert>
                Payments that you have already entered are not shown. If you
                have questions about one of these, please reach out to the Heard
                Tax Team.
              </Alert>
            </GridRowColumn>
          </>
        )}
        {distinctTaxQuarters.length > 0 && (
          <GridRowColumn short {...tqSmallSpace}>
            {distinctTaxQuarters.map((taxQuarter) => (
              <MissingQuarterCard
                key={taxQuarter}
                taxQuarter={taxQuarter}
                paidEstimatesDuringStep={
                  paidEstimatesDuringStepByTaxQuarter[taxQuarter] || []
                }
                unpaidEstimates={unpaidEstimatesByTaxQuarter[taxQuarter] || []}
                readOnly={readOnly}
              />
            ))}
          </GridRowColumn>
        )}
        {!distinctTaxQuarters.length && (
          <GridRowColumn short {...tqSmallSpace}>
            <Card backgroundColor="lightGreen">
              <Text>
                All quarterly tax payments have been paid. You may continue to
                the next step.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <Grid.Row />
        <Grid.Row />
        {!readOnly && (
          <>
            <GridRowColumn short {...tqSmallSpace}>
              <Alert title="Why do we need this?">
                Updating your tax payments allows us to calculate a closer
                estimate for what you may owe this tax season. This also reduces
                your risk of underpayment interest and penalties, and resolves
                any inconsistencies with the IRS.
              </Alert>
            </GridRowColumn>
            <FormFlowFooter
              isSubmit
              continueDisabled={saveAndReturn.loading}
              onForward={
                unpaidEstimates.length > 0
                  ? triggerError
                  : saveAndReturn.callback
              }
              loading={saveAndReturn.loading}
              onBack={() => navigate('/taxes/annual/tax_checklist/')}
            />
          </>
        )}
      </Grid>
    </>
  )
}

export default AddMissingQTEPayments
