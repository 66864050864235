import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'
import { fetchWrapper } from '../../../reducers/fetch'

interface AboundPayer {
  id: number
  payerId: number
  name: string
  address: string
  city: string
  state: string
  zipcode: string
  country?: string
  phoneNumber: string
  taxIdNumber: string
  acceptedFilingDisclosure: boolean
  tinFingerprint?: string
  tinVerificationStatus?: string
  tinVerificationBlob?: {
    tin: string
    id: string
  }
}

export interface AboundContractor {
  id: number
  firstName: string
  lastName: string
  uuid: string
  abound_user_id?: string
  email?: string
  address?: string
  address2?: string
  city?: string
  state?: string
  zipcode?: string
  postalCode?: string
  country?: string
  taxIdNumber?: string
  tinFingerprint?: string
  tinVerificationStatus?: string
  businessName?: string
  createdAt?: string
  permissionToEmailForm?: boolean
  permissionToMailForm?: boolean
  tinVerificationBlob?: {
    tin: string
    id: string
  }
}

export interface AboundContractorDetailedResponse {
  aboundContractorProfile: AboundContractor
  email?: string
  foreignId: string
  profile: AboundContractor
  business?: {
    name?: string
    ein?: string
  } & Pick<
    AboundContractor,
    'address' | 'city' | 'state' | 'zipcode' | 'country'
  >
}

export interface AboundContractorState {
  [key: string]: AboundContractor
}
interface AboundTaxDocumentUser {
  id: number
  uuid: string
  firstName: string
  lastName: string
  email: string
  createdAt: string
}

export enum AboundTaxDocumentAboundStatus {
  not_started = 'not_started',
  created = 'created',
  verifying = 'verifying',
  pending = 'pending',
  error = 'error',
  done = 'done',
  filed = 'filed',
  accepted = 'accepted',
  rejected = 'rejected',
  voided = 'voided',
  corrected = 'corrected',
  deleted = 'deleted',
}

export enum AboundTaxDocumentInternalStatus {
  processing = 'processing',
  error = 'error',
  saved_and_notified = 'saved_and_notified',
}

export enum AboundMailingStatus {
  none = 'none',
  created = 'created',
  processingForDelivery = 'processingForDelivery',
  inTransit = 'inTransit',
  delivered = 'delivered',
  returnedToSender = 'returnedToSender',
}

export interface AboundTaxDocument {
  id: number
  aboundDocumentId: string
  aboundRequestId: string
  aboundStatus: AboundTaxDocumentAboundStatus
  internalStatus: AboundTaxDocumentInternalStatus
  aboundMailingStatus: AboundMailingStatus
  calendarYear: string
  contractorProfileId: number
  compensationInCents: number
  documentType: string
  lastUpdatedViaWebhookId: string
  filingState: string | null
  federalIncomeTaxWithheldInCents: number | null
  stateTaxIncomeInCents: number | null
  payerStateId?: string
  filedAt: string | null
  createdAt: string
  updatedAt: string
  userDocumentId?: number
  aboundErrorType?: string
  submittedAt?: string
  user_id: number
  user: AboundTaxDocumentUser
  approvedByUser: boolean
  AboundContractorProfile?: AboundContractor
  // Pick<
  //   AboundContractor,
  //   'firstName' | 'lastName' | 'tinVerificationStatus'
  // >
}

export type AboundTaxDocumentDataToSend = Pick<
  AboundTaxDocument,
  'compensationInCents' | 'contractorProfileId' | 'payerStateId'
>

export interface AboundTaxDocumentsState {
  [key: string]: AboundTaxDocument
}

const initialState: {
  payer?: AboundPayer
  contractors?: AboundContractorState
  taxDocs?: AboundTaxDocumentsState // keyed by contractor profile id
} = {}

const aboundSlice = createSlice({
  name: 'aboundSlice',
  initialState,
  reducers: {
    setAboundPayer: (state, action: PayloadAction<AboundPayer>) => {
      state.payer = action.payload
    },
    updateAboundPayer: (state, action: PayloadAction<AboundPayer>) => {
      state.payer = { ...state.payer, ...action.payload }
    },
    setAboundContractors: (
      state,
      action: PayloadAction<AboundContractorState>
    ) => {
      state.contractors = action.payload
    },
    addOrUpdateAboundContractor: (
      state,
      action: PayloadAction<AboundContractor>
    ) => {
      if (state.contractors) {
        state.contractors[action.payload.uuid] = action.payload
      } else {
        state.contractors = { [action.payload.uuid]: action.payload }
      }
    },
    setAboundTaxDocuments: (
      state,
      action: PayloadAction<AboundTaxDocumentsState>
    ) => {
      state.taxDocs = action.payload
    },
    // Only use for creating/updating the docs in Heard
    addOrUpdateAboundTaxDocuments: (
      state,
      action: PayloadAction<AboundTaxDocument>
    ) => {
      if (state.taxDocs) {
        state.taxDocs[action.payload.contractorProfileId] = action.payload
      } else {
        state.taxDocs = { [action.payload.contractorProfileId]: action.payload }
      }
    },
    removeContractorAndTheirTaxDoc: (
      state,
      action: PayloadAction<{ id: number; uuid: string }>
    ) => {
      if (state.contractors) {
        delete state.contractors[action.payload.uuid]
      }
      if (state.taxDocs) {
        delete state.taxDocs[action.payload.id]
      }
    },
  },
})

export default aboundSlice.reducer

// Actions
const {
  setAboundPayer,
  setAboundContractors,
  addOrUpdateAboundContractor,
  setAboundTaxDocuments,
  updateAboundPayer,
  addOrUpdateAboundTaxDocuments,
  removeContractorAndTheirTaxDoc,
} = aboundSlice.actions

export const FETCH_ABOUND_CONTRACTORS_KEY = 'FETCH_ABOUND_CONTRACTORS_KEY'
export const fetchAboundContractors = () =>
  fetchWrapper({
    fetchKey: FETCH_ABOUND_CONTRACTORS_KEY,
    defaultErrorMessage: 'Could not fetch abound contractors',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AboundContractor[]>(
        '/finances/api/v1/contractor_profiles?minimal=true'
      )
      const contractors = keyBy(json.data, 'uuid')
      dispatch(setAboundContractors(contractors))
      return json.data
    },
  })

export const ABOUND_CONTRACTOR_CREATE_KEY = 'ABOUND_CONTRACTOR_CREATE_KEY'
export const createAboundContractorRequest = (
  data: Omit<
    AboundContractor,
    'aboundUserId' | 'id' | 'uuid' | 'tinVerificationStatus'
  >
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error creating abound contractors profile',
    fetchKey: ABOUND_CONTRACTOR_CREATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<{
        aboundContractorProfile: AboundContractor
      }>('/finances/api/v1/contractor_profiles', data)
      dispatch(addOrUpdateAboundContractor(json.data.aboundContractorProfile))
      return json.data
    },
  })

export const ABOUND_CONTRACTOR_UPDATE_KEY = 'ABOUND_CONTRACTOR_UPDATE_KEY'
export const updateAboundContractorRequest = (
  id: number,
  data: Partial<AboundContractor>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating abound contractor profile',
    fetchKey: ABOUND_CONTRACTOR_UPDATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<AboundContractor>(
        `/finances/api/v1/contractor_profiles/${id}`,
        data
      )
      const contractor = {
        ...json.data,
      }
      dispatch(addOrUpdateAboundContractor(contractor))
      return json.data
    },
  })

export const ABOUND_CONTRACTOR_DELETE_KEY = 'ABOUND_CONTRACTOR_DELETE_KEY'
export const deleteAboundContractorRequest = (contractor: {
  id: number
  uuid: string
}) => {
  const { id, uuid } = contractor
  return fetchWrapper({
    defaultErrorMessage: 'Error updating abound contractor profile',
    fetchKey: ABOUND_CONTRACTOR_UPDATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<AboundContractorDetailedResponse>(
        `/finances/api/v1/contractor_profiles/${id}`
      )
      dispatch(removeContractorAndTheirTaxDoc({ id, uuid }))
      return json.data
    },
  })
}

export const FETCH_ABOUND_PAYER_KEY = 'FETCH_ABOUND_PAYER_KEY'
export const fetchAboundPayer = () =>
  fetchWrapper({
    fetchKey: FETCH_ABOUND_PAYER_KEY,
    defaultErrorMessage: 'Could not fetch abound payer profile',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AboundPayer>(
        '/finances/api/v1/payer_profiles'
      )
      dispatch(setAboundPayer(json.data))
      return json.data
    },
  })

export const ABOUND_PAYER_CREATE_KEY = 'ABOUND_PAYER_CREATE_KEY'
export const createAboundPayerRequest = (data: Partial<AboundPayer>) =>
  fetchWrapper({
    defaultErrorMessage: 'Error abound payer profile',
    fetchKey: ABOUND_PAYER_CREATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<
        Omit<AboundPayer, 'acceptedFilingDisclosure'> & {
          aboundPayerProfile: {
            acceptedFilingDisclosure: boolean
          }
        }
      >('/finances/api/v1/payer_profiles', data)
      const { aboundPayerProfile, ...rest } = json.data
      dispatch(
        setAboundPayer({
          ...rest,
          acceptedFilingDisclosure: aboundPayerProfile.acceptedFilingDisclosure,
        })
      )
      return json.data
    },
  })

export const ABOUND_PAYER_UPDATE_KEY = 'ABOUND_PAYER_UPDATE_KEY'
export const updateAboundPayerRequest = (
  id: number,
  data: Partial<AboundPayer>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating abound payer profile',
    fetchKey: ABOUND_PAYER_UPDATE_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<
        Omit<AboundPayer, 'acceptedFilingDisclosure'> & {
          aboundPayerProfile: {
            acceptedFilingDisclosure: boolean
          }
        }
      >(`/finances/api/v1/payer_profiles/${id}`, data)
      const { aboundPayerProfile, ...rest } = json.data
      dispatch(
        updateAboundPayer({
          ...rest,
          acceptedFilingDisclosure: aboundPayerProfile.acceptedFilingDisclosure,
        })
      )
      return json.data
    },
  })

export const FETCH_ABOUND_TAX_FILINGS_KEY = 'FETCH_ABOUND_TAX_FILINGS_KEY'
export const fetchAboundTaxDocumentsForPayer = () =>
  fetchWrapper({
    fetchKey: FETCH_ABOUND_TAX_FILINGS_KEY,
    defaultErrorMessage: 'Could not fetch abound tax documents',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<AboundTaxDocument[]>(
        '/finances/api/v1/1099s'
      )
      // 2) Sort ascending by `createdAt` so the newest item is last
      const sortedData = json.data
        .slice()
        .sort(
          (a, b) =>
            new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
        )

      // 3) Key by `contractorProfileId`.
      //    Lodash `keyBy` will use the last occurrence of each key,
      //    which is now the most recently created item.
      const filings = keyBy(sortedData, 'contractorProfileId')
      dispatch(setAboundTaxDocuments(filings))
      return json.data
    },
  })

export const ABOUND_TAX_FILINGS_CREATE_KEY = 'ABOUND_TAX_FILINGS_CREATE_KEY'
export const createAboundTaxDocumentRequest = (
  data: AboundTaxDocumentDataToSend
) =>
  fetchWrapper({
    fetchKey: ABOUND_TAX_FILINGS_CREATE_KEY,
    defaultErrorMessage: 'Error could not create abound tax document',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AboundTaxDocument>(
        '/finances/api/v1/1099s',
        data
      )
      dispatch(addOrUpdateAboundTaxDocuments(json.data))
      return json.data
    },
  })

export const ABOUND_TAX_FILINGS_UPDATE_KEY = 'ABOUND_TAX_FILINGS_UPDATE_KEY'
export const updateAboundTaxDocumentRequest = (
  id: number,
  data: AboundTaxDocumentDataToSend
) =>
  fetchWrapper({
    fetchKey: ABOUND_TAX_FILINGS_UPDATE_KEY,
    defaultErrorMessage: 'Error updating abound payer profile',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AboundTaxDocument>(
        `/finances/api/v1/1099s/${id}`,
        data
      )
      dispatch(addOrUpdateAboundTaxDocuments(json.data))
      return json.data
    },
  })

export const ABOUND_TAX_FILINGS_CORRECT_KEY = 'ABOUND_TAX_FILINGS_CORRECT_KEY'
export const correctAboundTaxDocumentRequest = (
  data: AboundTaxDocumentDataToSend,
  oldAboundTaxDocumentId?: number
) =>
  fetchWrapper({
    fetchKey: ABOUND_TAX_FILINGS_CORRECT_KEY,
    defaultErrorMessage: 'Error could not correct abound tax document',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AboundTaxDocument>(
        '/finances/api/v1/1099s/correct',
        {
          oldAboundTaxDocumentId,
          ...data,
        }
      )
      dispatch(addOrUpdateAboundTaxDocuments(json.data))
      return json.data
    },
  })

export const ABOUND_SUBMIT_ALL_1099S_KEY = 'ABOUND_SUBMIT_ALL_1099S_KEY'
export const submitAll1099sToAbound = () =>
  fetchWrapper({
    fetchKey: ABOUND_SUBMIT_ALL_1099S_KEY,
    defaultErrorMessage: 'Error could not submit abound tax document',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<AboundTaxDocument[]>(
        '/finances/api/v1/1099s/submit'
      )
      const filings = keyBy(json.data, 'contractorProfileId')
      dispatch(setAboundTaxDocuments(filings))
      return json.data
    },
  })

/*
  Admin Actions Below
*/

/* This fetches a list of users and their annual tax filings. We do not save this to the Redux store. */
export const fetchAdminAboundAnnualTaxFilings = (params: {
  page: number
  limit: number
  userId: number | undefined
  aboundStatus: AboundTaxDocumentAboundStatus | undefined
  internalStatus: AboundTaxDocumentInternalStatus | undefined
  // If this is not sent it will default to current tax year
  calendarYear: number | undefined
}) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching all user tax filings',
    fetchFunction: async () => {
      const json = await axios.get<{
        documents: AboundTaxDocument[]
        count: number
      }>('/finances/api/v1/admin/abound_tax_docs', { params })
      return json.data
    },
  })
