import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PayrollProfile {
  id: number
  userId: number
  isActive: boolean
  gustoCompanyUuid: string
  accessToken: string
  refreshToken: string | null
  gustoEmployeeUuid: string | null
  onboardingCompleted: boolean
  numberOfTeamMembers: number | null
  needsMigration?: boolean
  accessTokenExpirationTime: string
  companyName: string | null
  onboardedAt: string | null
  migratedFromGusto: boolean | null
  wcEnrolled: boolean | null
  wcEnrollmentStartedAt: string | null
  wcEnrollmentCompletedAt: string | null
  wcEnrollmentPolicyDetails: string | null
  wcProvider: string | null
  enabledAt: string | null
  disabledAt: string | null
  lastOnboardingStep: string | null
  lastOnboardingStepCompletedAt: string | null

  createdAt: string
  updatedAt: string
}

const initialState: PayrollProfile | null = null

const payrollProfileSlice = createSlice({
  name: 'payrollProfile',
  initialState: initialState as PayrollProfile | null,
  reducers: {
    setPayrollProfile: (_, action: PayloadAction<PayrollProfile | null>) =>
      action.payload,
  },
})

export const { setPayrollProfile } = payrollProfileSlice.actions

export default payrollProfileSlice.reducer
