import { createSelector } from 'reselect'
import { Dictionary, groupBy, isArray, partition, uniq } from 'lodash'

import { ReduxState } from '../../../../utils/typeHelpers'
import { getAllFetchState } from '../../../../reducers/fetch'
import { FETCH_USER_TAX_QUESTIONNAIRE_KEY } from './taxChecklistQuestion.actions'
import { TaxListOptionId, TaxListQuestionId } from './service'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistQuestionsState,
  TaxChecklistResponse,
} from './taxChecklistQuestion.slice'
import {
  getAnnualTaxFilingForms,
  selectAnnualTaxFilingFormById,
  selectFilingFormsForCurrentTaxYear,
  selectFilingFormsForYear,
} from '../annualTaxFilingForms.selector'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../Questionnaires/constants'
import {
  getUserTaxDocumentsForCategory,
  selectUserDocumentList,
} from '../../../UserDocuments/userDocuments.selector'
import {
  getUserDocCategoryByInternalNameFromCategoryList,
  selectUserDocumentCategoryList,
} from '../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { getUserEoyReviewProgress } from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { getAllEoyReviewSteps } from './Shared/ReviewStepsandProgresses/endOfYearReviewSteps.selector'
import { TAX_ENTITY_TYPES } from '../../taxConstants'
import { selectSubscriptionIncludesFreePersonalFiling } from '../../../../reducers/subscription.slice'
import {
  COMPLETE_PAYMENT,
  ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
  SubStepIdentifiers,
  TAX_QUESTIONNAIRE_STEPS_1040,
  TAX_QUESTIONNAIRE_STEPS_1120S,
} from './Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectAnnualTaxFilingForCurrentTaxYear } from '../annualTaxFilings.selector'
import { UserEoyReviewProgressState } from './Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { AllEoyReviewStepState } from './Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { AnnualTaxFilingForm } from '../annualTaxFilingForms.slice'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { UserDocumentCategory } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { selectTaxUserDocuments } from '../taxUserDocuments.selector'
import { TaxUserDocument } from '../taxUserDocuments.slice'
import { logSentryMessage } from '../../../../utils/sentryHelpers'

const PRIOR_YEAR_RETURN_CATEGORIES = [
  UserDocumentCategoryIdentifier.finalReturnForm1040,
  UserDocumentCategoryIdentifier.finalReturnForm1120s,
]

export const selectTaxListQuestionState = (state: ReduxState) =>
  state.taxChecklist

export const selectTaxListQuestion = createSelector(
  selectTaxListQuestionState,
  getAllFetchState,
  selectCurrentAnnualTaxYear,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, taxYear: string | undefined) => taxYear,
  (tqQuestions, fetchState, currentTaxYear, questionId, taxYear) => {
    const question = tqQuestions.questions[questionId]

    if (!question) {
      if (
        fetchState[FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear || currentTaxYear)]
          ?.receivedAt
      ) {
        logSentryMessage('Could not find tax list question', {
          extra: { questionId },
        })
      }

      return {
        question: null,
        options: [] as TaxChecklistQuestionOption[],
        responses: [] as TaxChecklistResponse[],
      }
    }

    return {
      question,
      options: question.options.map((id) => tqQuestions.options[id]),
      responses: question.responses.map((id) => tqQuestions.responses[id]),
    }
  }
)

export const selectTaxListQuestionsByIds = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionIds: TaxListQuestionId[]) => questionIds,
  (allQuestions, questionIds) =>
    questionIds.map((questionId) => {
      const question = allQuestions.questions[questionId]
      if (!question) {
        return {
          question: null,
          options: [] as TaxChecklistQuestionOption[],
          responses: [] as TaxChecklistResponse[],
        }
      }

      return {
        question,
        options: question?.options?.map((id) => allQuestions.options[id]),
        responses: question?.responses?.map((id) => allQuestions.responses[id]),
      }
    })
)

export const getTaxListQuestionResponseByFormId = (
  allQuestions: TaxChecklistQuestionsState,
  questionId: TaxListQuestionId,
  formId?: number | string
) => {
  const question = allQuestions.questions[questionId]

  if (!question) {
    return []
  }

  return Object.values(allQuestions.responses).filter(
    (response) =>
      question.responses.includes(response.id) &&
      response.annualTaxFilingFormId === Number(formId)
  )
}

export const selectTaxListQuestionResponsesForPriorYear = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, formId?: number | string) => formId,
  getAnnualTaxFilingForms,
  (allQuestions, questionId, formId, formMapping) => {
    const matchingForm =
      formId && formMapping[Number(formId)] ? formMapping[Number(formId)] : null

    if (!matchingForm) {
      return null
    }

    const prevYear = (Number(matchingForm.year) - 1).toString()

    const previousYearForm = Object.values(formMapping).find(
      (form) =>
        form.year === prevYear && form.formTypeId === matchingForm.formTypeId
    )

    if (!previousYearForm) {
      return null
    }

    return getTaxListQuestionResponseByFormId(
      allQuestions,
      questionId,
      previousYearForm.id
    )
  }
)

export const selectTaxListQuestionResponsesByFormId = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionId: TaxListQuestionId) => questionId,
  (_: unknown, __: unknown, formId?: number | string) => formId,
  getTaxListQuestionResponseByFormId
)

export const selectTaxListQuestionResponseByFormIdAndDependentId =
  createSelector(
    selectTaxListQuestionState,
    (_: unknown, questionId: TaxListQuestionId) => questionId,
    (_: unknown, __: unknown, formId: number) => formId,
    (_: unknown, __: unknown, ___: unknown, dependentId?: number) =>
      dependentId,
    (allQuestions, questionId, formId, dependentId) => {
      const question = allQuestions.questions[questionId]

      if (!question) {
        return null
      }

      return Object.values(allQuestions.responses).find(
        (response) =>
          question.responses.includes(response.id) &&
          response.annualTaxFilingFormId === formId &&
          response.userDependentId === dependentId
      )
    }
  )

export const selectTaxListQuestionResponseByFormIdAndChildcareProviderId =
  createSelector(
    selectTaxListQuestionState,
    (_: unknown, questionId: TaxListQuestionId) => questionId,
    (_: unknown, __: unknown, formId: number) => formId,
    (_: unknown, __: unknown, ___: unknown, childcareProviderId?: number) =>
      childcareProviderId,
    (allQuestions, questionId, formId, childcareProviderId) => {
      const question = allQuestions.questions[questionId]

      if (!question) {
        return null
      }

      return Object.values(allQuestions.responses).find(
        (response) =>
          question.responses.includes(response.id) &&
          response.annualTaxFilingFormId === formId &&
          response.childcareProviderId === childcareProviderId
      )
    }
  )

export const selectTaxListQuestionResponsesByQuestionIds = createSelector(
  selectTaxListQuestionState,
  (_: unknown, questionIds: TaxListQuestionId[]) => questionIds,
  (_: unknown, __: unknown, formId: number | undefined) => formId,
  (allQuestions, questionIds, formId) =>
    Object.values(allQuestions.responses).filter(
      (response) =>
        questionIds.includes(response.questionId) &&
        response.annualTaxFilingFormId === formId
    )
)

export type ResponseGroup =
  | 'userDependentId'
  | 'childcareProviderId'
  | 'groupId'

export const selectTaxListQuestionResponseGroup = createSelector(
  selectTaxListQuestionsByIds,
  (_: unknown, __: unknown, formIds?: number[]) => formIds,
  (_: unknown, __: unknown, ___: unknown, group?: ResponseGroup) => group,
  (
    questions,
    formIds,
    group
  ): Dictionary<{
    [key: string | number]: {
      question: TaxChecklistQuestion
      options: TaxChecklistQuestionOption[]
      responses: TaxChecklistResponse[]
    }
  }> => {
    const flattenedResponses = questions
      .map((q) =>
        q.responses.filter(({ annualTaxFilingFormId }) =>
          formIds?.includes(annualTaxFilingFormId)
        )
      )
      .flat()
    const groupedResponses = groupBy(flattenedResponses, group)

    const groups = Object.keys(groupedResponses).map((key, index) => ({
      [key.includes('object') ? index : key]: groupedResponses[key].map(
        (r) => ({
          question: questions.find((q) => q.question?.id === r.questionId)
            ?.question,
          options:
            questions.find((q) => q.question?.id === r.questionId)?.options ??
            [],
          responses: [r],
        })
      ),
    }))
    return Object.assign({}, ...groups)
  }
)

export const selectTaxListQuestionOptionByOptionId = createSelector(
  selectTaxListQuestion,
  (_: unknown, __: unknown, ___: unknown, optionId: string) => optionId,
  (question, optionId) =>
    question.options.find((option) => option.id.toString() === optionId)
)

export const selectAdminTaxList = createSelector(
  selectTaxListQuestionState,
  selectCurrentAnnualTaxYear,
  (tqQuestions): TaxChecklistQuestion[] => Object.values(tqQuestions.questions)
)

export const selectIsMarriedFilingJointlyOrQualifyingWidow = createSelector(
  selectTaxListQuestion,
  (question) =>
    question.responses?.[0]?.value === TaxListOptionId.married_filing_jointly ||
    question.responses?.[0]?.value === TaxListOptionId.qualifying_widow
)

export const selectSpouseResponses = createSelector(
  selectTaxListQuestionState,
  (questionData) =>
    Object.values(questionData.responses).filter((r) =>
      new RegExp(/spouse/i).test(r.questionId)
    )
)

const selectUserNeedsK1 = createSelector(
  selectAnnualTaxFilingFormById,
  selectTaxListQuestionState,
  getAnnualTaxFilingForms,
  (form, tlQState, filingForms) => {
    if (!form) {
      return false
    }

    const incomeFromK1Responses = getTaxListQuestionResponseByFormId(
      tlQState,
      TaxListQuestionId.k1_income,
      form.id
    )

    return (
      incomeFromK1Responses?.[0]?.value ||
      Object.values(filingForms).filter((f) => f.year === form?.year).length > 1
    )
  }
)

const selectRequiredDocCatsForYear = createSelector(
  selectFilingFormsForYear,
  selectTaxListQuestionState,
  selectUserNeedsK1,
  (forms, tlQState, userNeedsK1) => {
    let required: UserDocumentCategoryIdentifier[] = []
    let optional: UserDocumentCategoryIdentifier[] = []

    if (!forms.length) {
      return { required, optional }
    }

    const form1040 = forms.find(
      (f) => f.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1040
    )

    const form1120s = forms.find(
      (f) => f.formType.name === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
    )

    if (form1040) {
      const { id } = form1040

      const healthCareCoverageResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.healthcare_coverage_type,
        id
      )
      const healthCareCoverageSpouseResponse =
        getTaxListQuestionResponseByFormId(
          tlQState,
          TaxListQuestionId.healthcare_coverage_type_spouse,
          id
        )

      const heathCareAccountTypesResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.healthcare_account_types,
        id
      )

      const spouseFirstNameResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.spouse_first_name,
        id
      )

      const homeSaleResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.sold_home,
        id
      )

      const homePurchaseResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_purchase,
        id
      )

      const propertyTaxResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.paid_property_taxes,
        id
      )

      const refinanceHomeResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.refinanced_home,
        id
      )

      const homePurchaseResidenceResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_purchase_residence_type,
        id
      )

      const offerInCompromiseResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.offer_in_compromise,
        id
      )

      const numberPropertiesResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.number_of_rental_properties,
        id
      )

      const hadBusinessMileageResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.had_business_mileage,
        id
      )

      const hadCollegeStudentResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.had_college_students,
        id
      )

      const hadCharitableContributionResponse =
        getTaxListQuestionResponseByFormId(
          tlQState,
          TaxListQuestionId.charitable_contribution,
          id
        )

      const investmentsBoughtSoldResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.investments_bought_sold,
        id
      )

      const retirementPlanTypeResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.retirement_plan_type,
        id
      )

      const homeSaleResidenceResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.home_sale_residence_type,
        id
      )

      const paidMortgageInterestResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.paid_mortgage_interest,
        id
      )

      const cryptoStatementResponse = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.crypto_statement,
        id
      )

      const disabilityIncomeResponses = getTaxListQuestionResponseByFormId(
        tlQState,
        TaxListQuestionId.disability_income,
        id
      )

      required = [
        ...required,
        UserDocumentCategoryIdentifier.officialGovernmentId,
        ...(spouseFirstNameResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.spouseOfficialGovernmentId]
          : []),
      ]
      optional = [
        ...optional,
        ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
          healthCareCoverageResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_employer
          )) ||
        (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
          healthCareCoverageSpouseResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_employer
          ))
          ? [UserDocumentCategoryIdentifier.form1095c]
          : []),

        ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
          healthCareCoverageResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_other
          )) ||
        (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
          healthCareCoverageSpouseResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_other
          ))
          ? [UserDocumentCategoryIdentifier.form1095b]
          : []),

        ...((isArray(healthCareCoverageResponse?.[0]?.value) &&
          healthCareCoverageResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_marketplace
          )) ||
        (isArray(healthCareCoverageSpouseResponse?.[0]?.value) &&
          healthCareCoverageSpouseResponse?.[0]?.value.includes(
            TaxListOptionId.health_insurance_marketplace
          ))
          ? [
              UserDocumentCategoryIdentifier.form1095a,
              UserDocumentCategoryIdentifier.form1099hc,
              UserDocumentCategoryIdentifier.form3895,
              UserDocumentCategoryIdentifier.form8692,
            ]
          : []),

        ...(paidMortgageInterestResponse?.[0]?.value ||
        refinanceHomeResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.form1098]
          : []),

        ...(hadCollegeStudentResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.form1098t]
          : []),

        ...(investmentsBoughtSoldResponse?.[0]?.value ||
        cryptoStatementResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.form1099b]
          : []),

        ...(cryptoStatementResponse?.[0]?.value === false
          ? [UserDocumentCategoryIdentifier.cryptoTransactions]
          : []),

        ...(retirementPlanTypeResponse?.[0]?.value
          ? [
              UserDocumentCategoryIdentifier.form1099r,
              UserDocumentCategoryIdentifier.form5498,
            ]
          : []),

        ...(isArray(heathCareAccountTypesResponse?.[0]?.value) &&
        heathCareAccountTypesResponse?.[0]?.value.find(
          (val) => val === TaxListOptionId.hsa
        )
          ? [UserDocumentCategoryIdentifier.form1099sa]
          : []),

        ...(homeSaleResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.homeSaleDocuments]
          : []),

        ...(homePurchaseResidenceResponse?.[0]?.value ||
        homeSaleResidenceResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.homeDepreciationSchedule]
          : []),

        ...(offerInCompromiseResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.offerInCompromise]
          : []),

        ...(propertyTaxResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.propertyTaxDocuments]
          : []),

        ...(numberPropertiesResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.rentalDocuments]
          : []),

        ...(homePurchaseResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.homePurchaseDocuments]
          : []),

        ...(hadBusinessMileageResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.carRegistration]
          : []),

        ...(hadCharitableContributionResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.charity]
          : []),

        ...(disabilityIncomeResponses?.[0]?.value
          ? [UserDocumentCategoryIdentifier.form1099ssa]
          : []),

        UserDocumentCategoryIdentifier.formW2,
        ...(hadBusinessMileageResponse?.[0]?.value
          ? [UserDocumentCategoryIdentifier.mileageTracker]
          : []),
        UserDocumentCategoryIdentifier.form1099nec,
        UserDocumentCategoryIdentifier.form1099misc,
        UserDocumentCategoryIdentifier.form1099div,
        UserDocumentCategoryIdentifier.form1099k,
        UserDocumentCategoryIdentifier.form1099g,
        UserDocumentCategoryIdentifier.form1099int,
        UserDocumentCategoryIdentifier.form1098e,
        UserDocumentCategoryIdentifier.finalReturnForm1040,
        // this is for 2 form filers but the selector is handling it so ok to be here
        ...(userNeedsK1 ? [UserDocumentCategoryIdentifier.scheduleK1] : []),
      ]
    }

    if (form1120s) {
      required = [
        ...required,
        UserDocumentCategoryIdentifier.officialGovernmentId,
        UserDocumentCategoryIdentifier.formW3,
        UserDocumentCategoryIdentifier.form941,
        UserDocumentCategoryIdentifier.cp261Notice,
      ]

      optional = [
        ...optional,
        UserDocumentCategoryIdentifier.form1099div,
        UserDocumentCategoryIdentifier.form1099int,
        UserDocumentCategoryIdentifier.form1099k,
        UserDocumentCategoryIdentifier.form1099nec,
        UserDocumentCategoryIdentifier.finalReturnForm1120s,
        UserDocumentCategoryIdentifier.form2553,
        UserDocumentCategoryIdentifier.einDocuments,
        UserDocumentCategoryIdentifier.businessIncorporation,
      ]
    }

    return {
      required: uniq(required),
      optional: uniq(optional),
    }
  }
)

const isDocumentWithCategoryUploaded = (
  categoryId: string,
  documentCategories: UserDocumentCategory[],
  userDocuments: UserDocument[],
  taxUserDocuments: TaxUserDocument[],
  taxYear: string | undefined
) => {
  const category = getUserDocCategoryByInternalNameFromCategoryList(
    documentCategories,
    categoryId
  )
  const previousTaxYear = (Number(taxYear) - 1).toString()
  const usePreviousYear = PRIOR_YEAR_RETURN_CATEGORIES.some(
    (cat) => cat === category?.internalName
  )

  return Boolean(
    getUserTaxDocumentsForCategory(
      category,
      taxUserDocuments,
      userDocuments,
      usePreviousYear ? previousTaxYear : taxYear
    ).length
  )
}

export const selectUploadedNonUploadedDocs = createSelector(
  selectRequiredDocCatsForYear,
  selectTaxUserDocuments,
  selectUserDocumentList,
  selectUserDocumentCategoryList,
  (_: unknown, taxYear: string) => taxYear,
  (
    { required, optional },
    taxUserDocuments,
    userDocuments,
    documentCategories,
    taxYear
  ) => {
    const [uploadedRequired, nonUploadedRequired] = partition(required, (cat) =>
      isDocumentWithCategoryUploaded(
        cat,
        documentCategories,
        userDocuments,
        taxUserDocuments,
        taxYear
      )
    )

    const [uploadedOptional, nonUploadedOptional] = partition(optional, (cat) =>
      isDocumentWithCategoryUploaded(
        cat,
        documentCategories,
        userDocuments,
        taxUserDocuments,
        taxYear
      )
    )

    return {
      uploadedRequired,
      nonUploadedRequired,
      uploadedOptional,
      nonUploadedOptional,
    }
  }
)

export const selectDocumentProgress = createSelector(
  getAllEoyReviewSteps,
  getUserEoyReviewProgress,
  selectAnnualTaxFilingForCurrentTaxYear,
  (allEoyReviewSteps, progressesById, filing) => {
    const identifier = SubStepIdentifiers.uploadDocumentsCombined

    const step = allEoyReviewSteps[identifier]
      ? allEoyReviewSteps[identifier]
      : null

    return Object.values(progressesById).find(
      (progress) =>
        progress.endOfYearReviewStepId === step?.id &&
        progress.annualTaxFilingId === filing?.id
    )
  }
)

export const selectOtherTaxDocuments = createSelector(
  selectRequiredDocCatsForYear,
  selectUserDocumentList,
  selectTaxUserDocuments,
  selectUserDocumentCategoryList,
  (_: unknown, taxYear: string) => taxYear,
  ({ required, optional }, documents, taxUserDocuments, categories, taxYear) =>
    documents.filter((doc) => {
      const taxUserDoc = taxUserDocuments.find(
        (tud) => tud.userDocumentId === doc.id
      )
      const category = categories.find(
        (cat) => cat.id === doc.userDocumentCategoryId
      )
      return (
        taxUserDoc?.taxYear === taxYear &&
        (!category ||
          ![...required, ...optional].includes(
            category.internalName as UserDocumentCategoryIdentifier
          ))
      )
    })
)

export const selectDocsUploadedOrSkippedAndTotalCount = createSelector(
  selectRequiredDocCatsForYear,
  selectUserDocumentList,
  selectTaxUserDocuments,
  selectUserDocumentCategoryList,
  selectDocumentProgress,
  (_: unknown, year: string | undefined) => year,
  (
    { required, optional },
    documents,
    taxUserDocuments,
    documentCategories,
    documentProgress,
    year
  ) => {
    const skippedCategories =
      documentProgress?.responses?.[0]?.skippedDocumentCategories || []
    const needsHelpCategories =
      documentProgress?.responses?.[0]?.needsHelp || []

    const requiredCount = required.length
    const optionalCount = optional.length
    const requiredUploaded = required.filter((requiredCat) =>
      isDocumentWithCategoryUploaded(
        requiredCat,
        documentCategories,
        documents,
        taxUserDocuments,
        year
      )
    )

    const optionalUploaded = optional.filter((optionalCat) => {
      return (
        isDocumentWithCategoryUploaded(
          optionalCat,
          documentCategories,
          documents,
          taxUserDocuments,
          year
        ) ||
        skippedCategories.includes(optionalCat) ||
        needsHelpCategories.includes(optionalCat)
      )
    })

    const completeCount = requiredUploaded.length + optionalUploaded.length
    return {
      requiredCount,
      optionalCount,
      completeCount,
      totalCount: requiredCount + optionalCount,
    }
  }
)

export const selectAllRequiredDocsUploaded = createSelector(
  selectDocsUploadedOrSkippedAndTotalCount,
  (counts) => {
    return (
      Boolean(counts.requiredCount) &&
      counts.completeCount === counts.totalCount
    )
  }
)

export const selectK1IsUploadedOrNotNeeded = createSelector(
  selectFilingFormsForYear,
  selectUserDocumentList,
  selectTaxUserDocuments,
  selectUserDocumentCategoryList,
  selectUserNeedsK1,
  (_: unknown, _year: string, formId: number | string) => formId,
  (
    forms,
    documents,
    taxUserDocuments,
    documentCategories,
    userNeedsK1,
    formId
  ) => {
    const form = forms.find((form) => form.id === formId)
    if (!form || !userNeedsK1) {
      return true
    }

    const k1Category = getUserDocCategoryByInternalNameFromCategoryList(
      documentCategories,
      UserDocumentCategoryIdentifier.scheduleK1
    )
    return documents.some((doc) => {
      const taxUserDoc = taxUserDocuments.find(
        (tud) => tud.userDocumentId === doc.id
      )
      return (
        k1Category &&
        doc.userDocumentCategoryId === k1Category.id &&
        taxUserDoc?.taxYear === form.year
      )
    })
  }
)

export const getProgressFromIdentifier = (
  identifier: SubStepIdentifiers,
  steps: AllEoyReviewStepState,
  progresses: UserEoyReviewProgressState
) => {
  const step = Object.values(steps).find(
    (step) => step.identifier === identifier
  )
  const progress = step
    ? Object.values(progresses).find(
        (progress) => progress.endOfYearReviewStepId === step.id
      )
    : undefined

  return {
    step,
    progress,
  }
}

export const getTaxChecklistSections = (
  subscriptionIncludesFreePersonalFiling: boolean,
  filingForms: AnnualTaxFilingForm[],
  formId: string | number
) => {
  const isTwoFormFiler = filingForms.length > 1
  const formTypeName = filingForms.find((f) => f.id === formId)?.formType?.name

  const relevantSections: SubStepIdentifiers[][] = []
  if (formTypeName === TAX_ENTITY_TYPES.form_1120_s) {
    relevantSections.push(...TAX_QUESTIONNAIRE_STEPS_1120S)
  } else if (isTwoFormFiler && formTypeName === TAX_ENTITY_TYPES.form_1040) {
    relevantSections.push(
      ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
      ...TAX_QUESTIONNAIRE_STEPS_1040
    )
    // only users with an old subscription have to pay
    if (!subscriptionIncludesFreePersonalFiling) {
      relevantSections.push(COMPLETE_PAYMENT)
    }
  } else {
    relevantSections.push(
      ENTER_MISSING_QUARTERLY_TAX_PAYMENTS,
      ...TAX_QUESTIONNAIRE_STEPS_1040
    )
  }

  return relevantSections
}

export const selectRequiresPaymentForPersonalFiling = createSelector(
  selectSubscriptionIncludesFreePersonalFiling,
  selectFilingFormsForCurrentTaxYear,
  (freePersonalFiling, filingForms) =>
    Boolean(filingForms.length > 1 && !freePersonalFiling)
)
