import { ReduxState } from '../../../utils/typeHelpers'
import { createSelector } from 'reselect'
import { TaxfyleJobStatus } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'

export const getAllAnnualTaxFilings = (state: ReduxState) =>
  state.admin.allAnnualTaxFilings

export const selectAnnualTaxFilingsByYear = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, year: string) => year,
  (taxFilings, year) =>
    Object.values(taxFilings).filter((taxFiling) => taxFiling.year === year)
)

export const selectAnnualTaxFilingForId = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, id: number) => id,
  (filings, id) => (filings[id] ? filings[id] : null)
)

export const selectAnnualTaxFilingForIds = createSelector(
  getAllAnnualTaxFilings,
  (_: unknown, ids: number[]) => ids,
  (filings, ids) => ids.map((id) => filings[id])
)

export const selectAdminAnnualTaxFilingsByYearForUser = createSelector(
  selectAnnualTaxFilingsByYear,
  (_: unknown, __: unknown, userId: number) => userId,
  (taxFilings, userId) =>
    taxFilings.find(({ userId: filingUserId }) => filingUserId === userId)
)

export enum AdminOverallExtensionStatus {
  notApplicable = 'Not applicable',
  canceled = 'Canceled',
  accepted = 'Accepted',
  rejected = 'Rejected',
  inProgress = 'In Progress',
  notStarted = 'Not Started',
}
export const selectOverallExtensionStatusForFilingId = createSelector(
  selectAnnualTaxFilingForId,
  (filing) => {
    if (!filing?.extensionRequestedAt) {
      return AdminOverallExtensionStatus.notApplicable
    } else if (
      filing.extensionTaxfyleJob?.jobStatus === TaxfyleJobStatus.canceled
    ) {
      return AdminOverallExtensionStatus.canceled
    } else if (
      filing.extensionTaxfyleJob?.jobStatus === TaxfyleJobStatus.completed ||
      (filing.annualTaxFilingForms.length > 0 &&
        filing.annualTaxFilingForms.every((form) => form.extensionAccepted))
    ) {
      return AdminOverallExtensionStatus.accepted
    } else if (
      filing.annualTaxFilingForms?.some(
        (form) => form.extensionFiledAt && !form.extensionAccepted
      )
    ) {
      return AdminOverallExtensionStatus.rejected
    } else if (filing.extensionStartedAt) {
      return AdminOverallExtensionStatus.inProgress
    }
    return AdminOverallExtensionStatus.notStarted
  }
)
