import { useEffect, useMemo, useState } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { DateTime } from 'luxon'

import {
  Button,
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikRadioButton,
  FormikRadioToggleButton,
  FormikScrollOnError,
  FormikTextArea,
  getFieldNames,
  GridRowColumn,
  makeReqStringSchema,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { PayrollInterestScreenName } from './PayrollInterestFlow'
import { Colors } from '../../../../styles/theme'
import { markUserActionItemCompleteIfExists } from '../../../Dashboard/UserActionItems/service'
import { PostOnboardingTaskIdents } from '../../../Onboarding/config'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectIsCurrentUserScorp } from '../../../../selectors/user.selectors'
import {
  GEP_COST_PER_YEAR,
  GEP_PER_PERSON_PRICE,
  GEP_SUB_PRICE,
} from '../../helpers'
import { PayrollSetup } from '../../../../reducers/auth/userReducer'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import {
  fetchUserOnboardingStepsIfNeeded,
  updateUserOnboardingStep,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.actions'
import { getUserStepIdByOnboardingStepId } from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { GettingStartedOnboardingStep } from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { selectSubscriptionIncludesFreePayroll } from '../../../../reducers/subscription.slice'
import {
  GustoPlan,
  PayrollNotInterestedReason,
  submitPayrollInterestSurvey,
  UsedPayrollInPast,
} from '../payrollInterestSurvey.actions'
import { LabelDescription } from '../../../../components/BaseComponents/Input'
import LearnMorePayrollModal from '../LearnMorePayrollModal'

const PayrollInterestForm = ({
  setScreen,
}: {
  setScreen: (screen: string) => void
}) => {
  const dispatch = useAppDispatch()
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const [infoModalOpen, setInfoModalOpen] = useState(false)

  const gettingStartedStepId = useReselector(
    getUserStepIdByOnboardingStepId,
    GettingStartedOnboardingStep.payrollInterest
  )

  useEffect(() => {
    dispatch(fetchUserOnboardingStepsIfNeeded())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      payrollSetup: undefined as PayrollSetup | undefined,
      otherProvider: undefined as string | undefined,
      gustoPlan: undefined as string | undefined,
      interestedInGep: undefined as boolean | undefined,
      notInterestedReason: undefined as PayrollNotInterestedReason | undefined,
      usedGustoInPast: undefined as UsedPayrollInPast | undefined,
      otherNotInterestedReason: undefined as string | undefined,
      missingFeaturesNotInterestedReason: undefined as string | undefined,
      // not saved in DB
      needPayrollForOthers: undefined as boolean | undefined,
      needPayrollForSelf: undefined as boolean | undefined,
      needPayrollForEmployees: undefined as boolean | undefined,
      needPayrollForContractors: undefined as boolean | undefined,
      // not saved in DB
      needPayrollForOtherCheckbox: undefined as boolean | undefined,
      needPayrollForOther: undefined as string | undefined,
      multiplePayRates: undefined as boolean | undefined,
      gustoSponsoredBenefits: undefined as boolean | undefined,
      timeTracking: undefined as boolean | undefined,
      commissionOnlyEmployees: undefined as boolean | undefined,
    },
    onSubmit: async ({
      payrollSetup,
      otherProvider,
      gustoPlan,
      interestedInGep,
      notInterestedReason,
      usedGustoInPast,
      otherNotInterestedReason,
      missingFeaturesNotInterestedReason,
      needPayrollForSelf,
      needPayrollForEmployees,
      needPayrollForContractors,
      needPayrollForOther,
      multiplePayRates,
      gustoSponsoredBenefits,
      timeTracking,
      commissionOnlyEmployees,
    }) => {
      const submitSurveyRes = await dispatch(
        submitPayrollInterestSurvey({
          payrollSetup,
          payrollProvider: otherProvider,
          gustoPlan,
          interestedInGepAt: interestedInGep
            ? new Date().toISOString()
            : undefined,
          gepRequestFormSubmittedAt: new Date().toISOString(),
          notInterestedReason,
          otherNotInterestedReason,
          missingFeaturesNotInterestedReason,
          needPayrollForSelf,
          needPayrollForEmployees,
          needPayrollForContractors,
          needPayrollForOther,
          multiplePayRates,
          gustoSponsoredBenefits,
          timeTracking,
          commissionOnlyEmployees,
          usedGustoInPast,
        })
      )

      if (!submitSurveyRes) {
        return
      }

      markUserActionItemCompleteIfExists(
        PostOnboardingTaskIdents.SET_UP_PAYROLL_ACCESS
      )

      if (gettingStartedStepId) {
        dispatch(
          updateUserOnboardingStep({
            id: gettingStartedStepId,
            updatableFields: { completedAt: DateTime.now().toISO() },
          })
        )
      }

      setScreen(PayrollInterestScreenName.OUTRO)
    },
  })

  const { values, submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  const gridConfig = { ...makeGridConfig([10, 14], true) }

  const interestedInGEPLabel = useMemo(() => {
    if (values.payrollSetup === PayrollSetup.gusto) {
      if (hasFreePayrollWithSubscription) {
        return 'Are you interested in migrating your payroll to Heard?'
      } else {
        return 'Would you like to migrate your Gusto payroll to Heard?'
      }
    } else if (
      values.payrollSetup === PayrollSetup.other &&
      hasFreePayrollWithSubscription
    ) {
      return 'Are you interested in switching your payroll to Heard?'
    }
    return 'Are you interested in setting up payroll on Heard?'
  }, [values.payrollSetup, hasFreePayrollWithSubscription])

  const interestedInGEPDescription = useMemo(() => {
    if (values.payrollSetup === PayrollSetup.gusto) {
      if (hasFreePayrollWithSubscription) {
        return `Save up to $${GEP_COST_PER_YEAR} annually by switching to Heard. We offer a streamlined set of features necessary to ensure you are compliant. Depending upon your current plan, not all features may be supported in Heard Payroll.`
      } else {
        return `Migrating allows you to manage your payroll directly within Heard. This is a base cost of $${GEP_SUB_PRICE}/month + $${GEP_PER_PERSON_PRICE}/individual per month.`
      }
    } else if (hasFreePayrollWithSubscription) {
      return `Heard has an integrated payroll via the Gusto platform (value of $${GEP_COST_PER_YEAR} annually). We offer a streamlined set of features necessary to ensure you are compliant.`
    }
    return `Heard has an integrated payroll via the Gusto platform. This is a base cost of $${GEP_SUB_PRICE}/month + $${GEP_PER_PERSON_PRICE}/individual per month.`
  }, [values.payrollSetup, hasFreePayrollWithSubscription])

  return (
    <FormikProvider value={formik}>
      <Grid>
        <FormikScrollOnError />
        <GridRowColumn {...gridConfig}>
          <Text as="h2">Tell us about your payroll needs</Text>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <div
            style={{
              backgroundColor: Colors.lightYellow,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
              style={{ width: 180, height: 180 }}
            />
          </div>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <FormikLabelError
            name={fieldNames.payrollSetup}
            label="Do you currently have a payroll provider?"
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="Yes, through Gusto"
            value={PayrollSetup.gusto}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="Yes, through another provider"
            value={PayrollSetup.other}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn {...gridConfig} short>
          <FormikRadioButton
            name={fieldNames.payrollSetup}
            label="No, I don't have payroll set up yet"
            value={PayrollSetup.none}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>

        {values.payrollSetup === PayrollSetup.other && (
          <GridRowColumn {...gridConfig}>
            <FormikInput
              name={fieldNames.otherProvider}
              label="Who is your payroll provider?"
              description="e.g. QuickBooks, OnPay, Square, etc."
              placeholder="Enter payroll provider name"
              fullWidth
              shouldClearOnDismount
              schema={makeReqStringSchema()}
            />
          </GridRowColumn>
        )}

        {values.payrollSetup && (
          <>
            <GridRowColumn {...gridConfig}>
              <FormikLabelError
                name={fieldNames.interestedInGep}
                label={interestedInGEPLabel}
                description={
                  <>
                    <Text as="bodyXs">{interestedInGEPDescription}</Text>
                    <Button
                      variant="secondaryLink"
                      onClick={() => setInfoModalOpen(true)}
                      style={{ fontSize: 12, display: 'inline-block' }}
                    >
                      Learn more
                    </Button>
                  </>
                }
                shouldClearOnDismount
                schema={makeReqStringSchema()}
              />
              <LearnMorePayrollModal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
              />
            </GridRowColumn>
            <Grid.Row className="short">
              <Grid.Column computer={3} tablet={1} mobile={1} />
              <Grid.Column width={3}>
                <FormikRadioToggleButton
                  name={fieldNames.interestedInGep}
                  value
                  fullWidth
                >
                  Yes
                </FormikRadioToggleButton>
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikRadioToggleButton
                  name={fieldNames.interestedInGep}
                  value={false}
                  fullWidth
                >
                  No
                </FormikRadioToggleButton>
              </Grid.Column>
            </Grid.Row>
          </>
        )}

        {values.interestedInGep && (
          <>
            {values.payrollSetup === PayrollSetup.gusto && (
              <GridRowColumn {...gridConfig}>
                <FormikDropdown
                  name={fieldNames.gustoPlan}
                  options={[
                    { value: GustoPlan.simple, text: 'Simple' },
                    { value: GustoPlan.plus, text: 'Plus' },
                    { value: GustoPlan.premium, text: 'Premium' },
                    { value: GustoPlan.concierge, text: 'Concierge' },
                    { value: GustoPlan.contractor, text: 'Contractor Only' },
                    { value: GustoPlan.notSure, text: "I don't know" },
                  ]}
                  schema={makeReqStringSchema()}
                  fullWidth
                  label="Which plan do you currently have with Gusto?"
                  placeholder="Select Plan"
                  shouldClearOnDismount
                />
              </GridRowColumn>
            )}
            {isScorp && (
              <>
                <GridRowColumn {...gridConfig}>
                  <FormikLabelError
                    name={fieldNames.needPayrollForOthers}
                    label="Do you need payroll for anyone else besides you?"
                    description="All S corporations are required to pay themselves through payroll to be compliant."
                    shouldClearOnDismount
                    schema={makeReqStringSchema()}
                  />
                </GridRowColumn>
                <Grid.Row className="short">
                  <Grid.Column computer={3} tablet={1} mobile={1} />
                  <Grid.Column width={3}>
                    <FormikRadioToggleButton
                      name={fieldNames.needPayrollForOthers}
                      value
                      fullWidth
                    >
                      Yes
                    </FormikRadioToggleButton>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <FormikRadioToggleButton
                      name={fieldNames.needPayrollForOthers}
                      value={false}
                      fullWidth
                    >
                      No
                    </FormikRadioToggleButton>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            {!isScorp ||
              (values.needPayrollForOthers && (
                <>
                  <GridRowColumn {...gridConfig}>
                    <LabelDescription
                      label={
                        isScorp
                          ? 'Who else do you need payroll for?'
                          : 'Who do you need payroll for?'
                      }
                      description={`This will add a cost of $${GEP_PER_PERSON_PRICE}/individual per month. Select all that apply.`}
                    />
                  </GridRowColumn>
                  {!isScorp && (
                    <GridRowColumn {...gridConfig} short>
                      <FormikCheckbox
                        name={fieldNames.needPayrollForSelf}
                        label="Me"
                        shouldClearOnDismount
                      />
                    </GridRowColumn>
                  )}
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.needPayrollForEmployees}
                      label="W-2 employees"
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.needPayrollForContractors}
                      label="Contractors"
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.needPayrollForOtherCheckbox}
                      label="Other"
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                  {values.needPayrollForOtherCheckbox && (
                    <GridRowColumn {...gridConfig} short>
                      <FormikInput
                        name={fieldNames.needPayrollForOther}
                        placeholder="Enter who you need payroll for"
                        shouldClearOnDismount
                        fullWidth
                        schema={makeReqStringSchema()}
                      />
                    </GridRowColumn>
                  )}
                </>
              ))}

            {values.payrollSetup === PayrollSetup.other && (
              <>
                <GridRowColumn {...gridConfig}>
                  <FormikLabelError
                    name={fieldNames.usedGustoInPast}
                    label="Have you used Gusto for payroll in the past?"
                    shouldClearOnDismount
                    schema={makeReqStringSchema()}
                  />
                </GridRowColumn>
                <GridRowColumn short {...gridConfig}>
                  <FormikRadioButton
                    name={fieldNames.usedGustoInPast}
                    value={UsedPayrollInPast.yesAsEmployee}
                    label="Yes, as an employee of another business"
                  />
                </GridRowColumn>
                <GridRowColumn short {...gridConfig}>
                  <FormikRadioButton
                    name={fieldNames.usedGustoInPast}
                    value={UsedPayrollInPast.yesForCurrentBusiness}
                    label="Yes, for my current business"
                  />
                </GridRowColumn>
                <GridRowColumn short {...gridConfig}>
                  <FormikRadioButton
                    name={fieldNames.usedGustoInPast}
                    value={UsedPayrollInPast.yesForPreviousBusiness}
                    label="Yes, for a previous business"
                  />
                </GridRowColumn>
                <GridRowColumn short {...gridConfig}>
                  <FormikRadioButton
                    name={fieldNames.usedGustoInPast}
                    value={UsedPayrollInPast.no}
                    label="No"
                  />
                </GridRowColumn>
              </>
            )}

            {values.payrollSetup &&
              [PayrollSetup.gusto, PayrollSetup.other].includes(
                values.payrollSetup
              ) && (
                <>
                  <GridRowColumn {...gridConfig}>
                    <LabelDescription label="Select any features you are currently using." />
                  </GridRowColumn>
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.multiplePayRates}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Text as="bodySm">Multiple pay rates</Text>
                          <Popup content="You currently have an employee that you need to pay at different hourly rates." />
                        </div>
                      }
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                  {values.payrollSetup === PayrollSetup.gusto && (
                    <GridRowColumn {...gridConfig} short>
                      <FormikCheckbox
                        name={fieldNames.gustoSponsoredBenefits}
                        label={
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Text as="bodySm">Gusto-sponsored benefits</Text>
                            <Popup content="You currently have benefits administered through Gusto (e.g. health insurance administration, 401k contributions, HSA or FSA, etc.)." />
                          </div>
                        }
                        shouldClearOnDismount
                      />
                    </GridRowColumn>
                  )}
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.timeTracking}
                      label="Time tracking"
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                  <GridRowColumn {...gridConfig} short>
                    <FormikCheckbox
                      name={fieldNames.commissionOnlyEmployees}
                      label="100% commission employees"
                      shouldClearOnDismount
                    />
                  </GridRowColumn>
                </>
              )}
          </>
        )}

        {values.interestedInGep === false && (
          <>
            <Grid.Row />
            <GridRowColumn {...gridConfig}>
              <Text as="h2">Share your feedback</Text>
            </GridRowColumn>
            <GridRowColumn {...gridConfig}>
              <FormikDropdown
                name={fieldNames.notInterestedReason}
                options={[
                  ...(hasFreePayrollWithSubscription
                    ? []
                    : [
                        {
                          value: PayrollNotInterestedReason.price,
                          text: 'Price',
                        },
                      ]),
                  {
                    value: PayrollNotInterestedReason.missingFeatures,
                    text: 'Need more than streamlined basics',
                  },
                  {
                    value:
                      PayrollNotInterestedReason.stayingWithCurrentProvider,
                    text: 'Staying with current provider',
                  },
                  { value: PayrollNotInterestedReason.other, text: 'Other' },
                ]}
                label="What would make Heard Payroll a better fit for you?"
                description="We appreciate your feedback. This will be used to improve our services."
                fullWidth
                shouldClearOnDismount
              />
            </GridRowColumn>
            {values.notInterestedReason ===
              PayrollNotInterestedReason.missingFeatures && (
              <GridRowColumn {...gridConfig}>
                <FormikTextArea
                  name={fieldNames.missingFeaturesNotInterestedReason}
                  label="What features do you need?"
                  shouldClearOnDismount
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
            )}
            {values.notInterestedReason ===
              PayrollNotInterestedReason.other && (
              <GridRowColumn {...gridConfig}>
                <FormikTextArea
                  name={fieldNames.otherNotInterestedReason}
                  label="Please share why"
                  shouldClearOnDismount
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
            )}
          </>
        )}

        <FormFlowFooter
          onBack={() => setScreen(PayrollInterestScreenName.INTRO)}
          loading={isSubmitting}
          continueDisabled={isSubmitting}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default PayrollInterestForm
