import { useCallback, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useUsersnapCustomDisplayLogicWidget } from '../../../components/shared/UsersnapSurvey'
import { useReselector, useFetchResponse } from '../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectAllFilingFormsComplete } from './annualTaxFilingForms.selector'
import { getAnnualTaxFilingForYearSelector } from './annualTaxFilings.selector'
import {
  FETCH_TAX_FILINGS_KEY,
  fetchAnnualTaxFilingsIfNeeded,
  updateAnnualTaxFilings,
} from './annualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchUserCompleteOnboardingDate } from '../../Onboarding/UserOnboardingSteps/onboarding.actions'
import { selectMembershipStart } from '../../../selectors/user.selectors'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from './annualTaxFilingForms.slice'
import { selectIsFetchingForKeys } from '../../../reducers/fetch'

const UsersnapTaxSeasonNPSSurvey = () => {
  const dispatch = useAppDispatch()
  const completedDate = useFetchResponse(fetchUserCompleteOnboardingDate)
  const membershipStartDate = useReselector(selectMembershipStart)
  const shouldShowSurvey = useMemo(() => {
    let showSurvey = false
    if (completedDate?.status) {
      const currentDate = DateTime.now()
      const completedDateLuxon = completedDate?.mostRecentCompletedAt
        ? DateTime.fromISO(String(completedDate.mostRecentCompletedAt))
        : null
      if (completedDateLuxon) {
        const differenceInDays = currentDate.diff(
          completedDateLuxon,
          'days'
        ).days
        if (differenceInDays > 90) {
          showSurvey = true
        }
      }
    } else {
      const membershipStart = DateTime.fromISO(membershipStartDate || '')
      const currentDate = DateTime.now()
      const differenceInDays = currentDate.diff(membershipStart, 'days').days
      if (differenceInDays > 90) {
        showSurvey = true
      }
    }
    return showSurvey
  }, [completedDate, membershipStartDate])

  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const allFormsComplete = useReselector(
    selectAllFilingFormsComplete,
    taxFiling?.id
  )

  const isLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_TAX_FILINGS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  ])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
  }, [dispatch])

  const onSubmit = useCallback(() => {
    if (!taxFiling || taxFiling.taxNpsSubmittedAt) return
    dispatch(
      updateAnnualTaxFilings(taxFiling?.id, {
        taxNpsSubmittedAt: DateTime.now().toISO(),
      })
    )
  }, [dispatch, taxFiling])

  useUsersnapCustomDisplayLogicWidget({
    projectKey: process.env.VITE_USERSNAP_TAX_FYLE_PROJECT_KEY,
    shouldShow:
      // Only show the survey if the user has completed all forms and has not submitted their tax NPS
      !isLoading &&
      allFormsComplete &&
      taxFiling &&
      !taxFiling.taxNpsSubmittedAt &&
      shouldShowSurvey,
    // Only show the survey 3 times and then wait 3 days before showing it each time
    maxTimesShown: 3,
    showAgainAfterDays: 3,
    onSubmit,
  })

  return null
}

export default UsersnapTaxSeasonNPSSurvey
