import { datadogRum } from '@datadog/browser-rum'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  init,
  breadcrumbsIntegration,
  browserTracingIntegration,
} from '@sentry/react'
import { datadogLogs } from '@datadog/browser-logs'
import { ErrorBoundary } from 'react-error-boundary'
import 'fix-date'
import 'semantic-ui-css/semantic.min.css'
import 'normalize.css'

import { createReduxStore } from './store'
import './index.scss'
import App from './App'
import { checkLoggedIn, PreloadedState } from './utils/session'
import ErrorBoundaryScreen from './components/shared/ErrorBoundaryScreen'
import { initOpenFeature } from './features/OpenFeature'
import { logSentryError } from './utils/sentryHelpers'

init({
  environment: process.env.VITE_ENV,
  release: process.env.HEROKU_SLUG_COMMIT,
  integrations: (integrations) => {
    integrations = integrations.filter(
      (integration) => integration.name !== 'Breadcrumbs'
    )
    return [
      ...integrations,
      browserTracingIntegration(),
      breadcrumbsIntegration(),
    ]
  },
  enabled: Boolean(
    process.env.VITE_ENV &&
      ['staging', 'production'].includes(process.env.VITE_ENV)
  ),
  dsn: 'https://3045f283756f4358933c5e5faf1a9d3b@sentry.io/1877651',
  tracesSampleRate: process.env.VITE_ENV === 'production' ? 1.0 : 1.0,
})

if (
  process.env.VITE_ENV &&
  ['staging', 'production'].includes(process.env.VITE_ENV)
) {
  datadogRum.init({
    applicationId: '1258bcd3-1596-4b1f-bc97-b196156c8a6b',
    clientToken: 'pub3d876dba58507e63fe5533a95e7fa67c',
    site: 'datadoghq.com',
    service: 'heard-app',
    env: process.env.VITE_ENV,
    version: process.env.VITE_SHORT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogLogs.init({
    clientToken: 'pub1c5a93fb02e7bdaa6f097da69f8e59b4',
    site: 'datadoghq.com',
    service: 'app',
    env: process.env.VITE_ENV,
    version: process.env.VITE_SHORT_SHA,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}

datadogRum.startSessionReplayRecording()

initOpenFeature()

const renderApp = (preloadedState: PreloadedState) => {
  const store = createReduxStore(preloadedState)

  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <ErrorBoundary fallback={<ErrorBoundaryScreen />}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  )
}

;(async () => {
  let preloadedState

  try {
    // This call will always succeed as long as api is up
    preloadedState = await checkLoggedIn()
  } catch (e) {
    // Log error and display message if api is down
    logSentryError(e)
    const root = createRoot(document.getElementById('root') as HTMLElement)

    return root.render(<ErrorBoundaryScreen />)
  }

  return renderApp(preloadedState)
})()
