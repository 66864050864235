import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'

import {
  Button,
  FormikDropdown,
  FormikInput,
  getFieldNames,
  Modal,
} from '../../../../components/BaseComponents'
import {
  adminUpdatePayrollInterestSurveyResponse,
  PayrollIneligibleReason,
  PayrollInterestSurvey,
} from '../../../Payroll/Upsell/payrollInterestSurvey.actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const ReviewPayrollInterestModal = ({
  gepSurvey,
  onSave,
}: {
  gepSurvey: PayrollInterestSurvey
  onSave: () => void
}) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      gepEligible: gepSurvey.gepEligible,
      ineligibleReason: gepSurvey.ineligibleReason,
      ineligibleReasonOther: gepSurvey.ineligibleReasonOther,
    },
    onSubmit: async ({
      gepEligible,
      ineligibleReason,
      ineligibleReasonOther,
    }) => {
      const res = await dispatch(
        adminUpdatePayrollInterestSurveyResponse(gepSurvey.id, {
          gepEligible,
          ineligibleReason: gepEligible === false ? ineligibleReason : null,
          ineligibleReasonOther:
            gepEligible === false &&
            ineligibleReason === PayrollIneligibleReason.other
              ? ineligibleReasonOther
              : null,
          adminReviewedAt: new Date().toISOString(),
        })
      )

      if (res) {
        setModalOpen(false)
        onSave()
      }
    },
  })

  const { submitForm, values } = formik

  const fieldNames = getFieldNames(formik)

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>
        Update Payroll Eligibility
      </Button>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <FormikProvider value={formik}>
          <Modal.Header>Review GEP interest Survey</Modal.Header>
          <Modal.Content>
            <FormikDropdown
              name={fieldNames.gepEligible}
              optionValues={[true, false]}
              label="Is this user eligible for GEP?"
            />
            <br />
            {values.gepEligible === false && (
              <FormikDropdown
                name={fieldNames.ineligibleReason}
                label="Why is this user ineligible?"
                options={[
                  {
                    value: PayrollIneligibleReason.commissionBasedPayments,
                    text: 'Commission Based Payments',
                  },
                  {
                    value: PayrollIneligibleReason.multiplePayRates,
                    text: 'Multiple Pay Rates',
                  },
                  {
                    value: PayrollIneligibleReason.gustoPlanType,
                    text: 'Gusto Plan Type',
                  },
                  {
                    value: PayrollIneligibleReason.gustoSponsoredBenefits,
                    text: 'Gusto Sponsored Benefits',
                  },
                  { value: PayrollIneligibleReason.other, text: 'Other' },
                ]}
                shouldClearOnDismount
              />
            )}
            <br />
            {values.ineligibleReason === PayrollIneligibleReason.other && (
              <FormikInput
                name={fieldNames.ineligibleReasonOther}
                label="Other - Why is this user ineligible?"
                fullWidth
                shouldClearOnDismount
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button variant="secondary" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={submitForm}>Submit</Button>
          </Modal.Actions>
        </FormikProvider>
      </Modal>
    </>
  )
}

export default ReviewPayrollInterestModal
