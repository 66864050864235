import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../reducers/fetch'
import { StripeCoupon } from '../../actions/settings/billingActions'

export enum CatchUpBookkeepingStatusType {
  incomplete = 'incomplete',
  complete = 'complete',
}
export enum CatchUpBookkeepingStatusActor {
  admin = 'admin',
  system = 'system',
}
export interface CatchUpBookkeepingStatus {
  id: number
  userId: number
  updatedByUserId: number | null
  status: CatchUpBookkeepingStatusType
  actor: CatchUpBookkeepingStatusActor
  paymentRequired: boolean | null
  paidAt: string | null
  invoiceId: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export interface CatchUpBookkeepingStatusState {
  [key: number]: CatchUpBookkeepingStatus
}

export const FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS =
  'FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS'

const catchUpBookkeepingStatusSlice = createSlice({
  name: 'catchUpBookkeepingStatus',
  initialState: {} as CatchUpBookkeepingStatusState,
  reducers: {
    receiveCatchUpBookkeepingStatus: (
      state,
      action: PayloadAction<CatchUpBookkeepingStatus>
    ) => {
      state[action.payload.userId] = action.payload
    },
  },
})

export default catchUpBookkeepingStatusSlice.reducer

export const { receiveCatchUpBookkeepingStatus } =
  catchUpBookkeepingStatusSlice.actions

export const fetchUserCatchUpBookkeepingStatus = () => {
  return fetchWrapper({
    fetchKey: FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS,
    defaultErrorMessage: 'There was an error fetching user bookkeeping status.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<CatchUpBookkeepingStatus>(
        '/finances/api/v2/catch_up_bookkeeping_status'
      )

      dispatch(receiveCatchUpBookkeepingStatus(json.data))
      return json.data
    },
  })
}

export const FETCH_CATCH_UP_BK_PAYMENT_REQUIREMENTS =
  'FETCH_CATCH_UP_BK_PAYMENT_REQUIREMENTS'
export const setCatchUpBookkeepingPaymentRequirements = () => {
  return fetchWrapper({
    fetchKey: FETCH_CATCH_UP_BK_PAYMENT_REQUIREMENTS,
    defaultErrorMessage: 'There was an error fetching user bookkeeping status.',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<CatchUpBookkeepingStatus>(
        '/finances/api/v2/catch_up_bookkeeping_status/payment'
      )

      dispatch(receiveCatchUpBookkeepingStatus(json.data))
      return json.data
    },
  })
}

export const FETCH_NEEDS_TWO_CHECKOUTS = 'FETCH_NEEDS_TWO_CHECKOUTS'
export const fetchNeedsTwoCheckouts = () => {
  return fetchWrapper({
    fetchKey: FETCH_NEEDS_TWO_CHECKOUTS,
    fetchFunction: async (_dispatch) => {
      const json = await axios.get<boolean>(
        '/finances/api/v2/catch_up_bookkeeping_status/separate_checkout'
      )
      return json.data
    },
  })
}

export type CatchUpBKMonths = {
  monthsThisYear: number
  monthsLastYear: number
  totalMonths: number
}
export const FETCH_CATCH_UP_BK_MONTHS = 'FETCH_CATCH_UP_BK_MONTHS'
export const fetchCatchUpBKMonths = () => {
  return fetchWrapper({
    fetchKey: FETCH_CATCH_UP_BK_MONTHS,
    fetchFunction: async (_dispatch) => {
      const json = await axios.get<CatchUpBKMonths>(
        '/finances/api/v2/catch_up_bookkeeping_status/months'
      )
      return json.data
    },
    handleError: (_dispatch) => ({
      monthsSoFar: 0,
      monthsLastYear: 0,
      totalMonths: 0,
    }),
  })
}

export const FETCH_CATCH_UP_BK_COUPON = (
  isAnnual: boolean,
  isBasicPlanType: boolean
) =>
  `FETCH_CATCH_UP_BK_COUPON_IS_ANNUAL_${isAnnual}_IS_BASIC_PLAN_TYPE_${isBasicPlanType}`
export const fetchCatchUpBKCoupon = (
  isAnnual: boolean,
  isBasicPlanType: boolean
) => {
  return fetchWrapper({
    fetchKey: FETCH_CATCH_UP_BK_COUPON(isAnnual, isBasicPlanType),
    fetchFunction: async (_dispatch) => {
      const json = await axios.get<StripeCoupon>(
        '/finances/api/v2/catch_up_bookkeeping/coupon',
        { params: { isAnnual, isBasicPlanType } }
      )
      return json.data
    },
  })
}

export const adminUpdateUserCatchUpBookkeepingStatus = (
  userId: number,
  status: CatchUpBookkeepingStatusType
) => {
  return fetchWrapper({
    defaultErrorMessage: 'Error updating user bookkeeping status',
    fetchFunction: async (dispatch) => {
      const json = await axios.patch<CatchUpBookkeepingStatus>(
        `/finances/api/v2/admin/catch_up_bookkeeping_status/${userId}`,
        { status }
      )

      dispatch(receiveCatchUpBookkeepingStatus(json.data))
      return json.data
    },
  })
}
