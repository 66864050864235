import { useMemo } from 'react'
import { selectIsAfter1099NECSubmitToAboundDueDate } from '../aboundAnnualTaxFilings.selector'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import {
  AnnualTaxFiling,
  needContractorFilingOption,
} from '../annualTaxFilings.slice'
import CCorpDisqualified from './CCorpDisqualified'
import HelpSubmission from './HelpSubmission'
import ScorpConfirmation from './ScorpConfirmation'
import ScorpTaxEntityHelp from './ScorpTaxEntityHelp'
import SolePropTaxEntityHelp from './SolePropTaxEntityHelp'
import TaxEntity from './TaxEntity'
import TaxNeeds from './TSKNeeds/TaxNeeds'
import TSKReview from './TSKReview'
import ContractorPayroll from './ContractorPayroll'
import { useReselector } from '../../../../utils/sharedHooks'

type TSKFormFlow = {
  steps: string[]
  screens: {
    component: () => JSX.Element
    step: number
    screenName: TAX_SEASON_KICKOFF_SURVEY
  }[]
}

export const useTSKScreenFormFlow = ({
  setScreen,
  saveFormData,
}: {
  setScreen: (
    newScreen: TAX_SEASON_KICKOFF_SURVEY | null,
    replace?: boolean
  ) => void
  saveFormData: (
    data: Partial<AnnualTaxFiling> | null,
    newScreen: TAX_SEASON_KICKOFF_SURVEY | null
  ) => Promise<boolean>
}): TSKFormFlow => {
  const isAfter1099NECSubmitToAboundDueDate = useReselector(
    selectIsAfter1099NECSubmitToAboundDueDate
  )

  const config = useMemo(() => {
    // Skip contractor payroll screen if the 1099 NEC submit to Abound due date has passed
    const goToNextStep = (
      data: Partial<AnnualTaxFiling> | null,
      newScreen: TAX_SEASON_KICKOFF_SURVEY | null
    ) => {
      let screen = newScreen
      if (
        newScreen === TAX_SEASON_KICKOFF_SURVEY.contractorPayroll &&
        isAfter1099NECSubmitToAboundDueDate
      ) {
        // Skip screen and mark needs contractor as 'no'
        data = { ...data, needContractorFiling: needContractorFilingOption.no }
        screen = TAX_SEASON_KICKOFF_SURVEY.taxNeeds
      }
      return saveFormData(data, screen)
    }
    // Skip contractor payroll screen if the 1099 NEC submit to Abound due date has passed
    const goBack = (newScreen: TAX_SEASON_KICKOFF_SURVEY | null) => {
      if (
        newScreen === TAX_SEASON_KICKOFF_SURVEY.contractorPayroll &&
        isAfter1099NECSubmitToAboundDueDate
      ) {
        setScreen(TAX_SEASON_KICKOFF_SURVEY.taxEntity)
      } else {
        setScreen(newScreen)
      }
    }

    const props = {
      goToNextStep,
      goBack,
    }
    const screens = [
      {
        component: () => <TaxEntity {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.taxEntity,
      },
      {
        component: () => <ScorpConfirmation {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.scorpConfirmation,
      },
      {
        component: () => <SolePropTaxEntityHelp {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.solePropTaxEntityHelp,
      },
      {
        component: () => <ScorpTaxEntityHelp {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.scorpTaxEntityHelp,
      },
      {
        component: () => <CCorpDisqualified {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.cCorpDisqualified,
      },
      {
        component: () => <HelpSubmission {...props} />,
        step: 0,
        screenName: TAX_SEASON_KICKOFF_SURVEY.helpSubmission,
      },
      {
        component: () => <TaxNeeds {...props} />,
        step: 1,
        screenName: TAX_SEASON_KICKOFF_SURVEY.taxNeeds,
      },
      {
        component: () => <TSKReview />,
        step: 2,
        screenName: TAX_SEASON_KICKOFF_SURVEY.review,
      },
    ]
    const stepNames = {
      taxEntity: 'Tax entity',
      taxNeeds: 'Tax needs',
      review: 'Review',
    }
    const steps = Object.values(stepNames)
    if (!isAfter1099NECSubmitToAboundDueDate) {
      // Assume the step numbers are in order for the screens and get index of first screen after step 0
      const insertIndex = screens.findIndex((screen) => screen.step > 0)
      // Add contractor payroll screen after step 0
      screens.splice(insertIndex, 0, {
        component: () => <ContractorPayroll {...props} />,
        step: 1,
        screenName: TAX_SEASON_KICKOFF_SURVEY.contractorPayroll,
      })
      steps.splice(
        steps.findIndex((step) => step === stepNames.taxNeeds),
        0,
        'Contractor payroll'
      )
      // Update step numbers for screens after the inserted screen
      screens.slice(insertIndex + 1).forEach((screen) => screen.step++)
    }
    return { steps, screens }
  }, [isAfter1099NECSubmitToAboundDueDate, saveFormData, setScreen])

  return {
    steps: config.steps,
    screens: config.screens,
  }
}
