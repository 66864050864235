import { useNavigate } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Link,
  Text,
} from '../../components/BaseComponents'
import { useReselector } from '../../utils/sharedHooks'
import { selectHeardProductSubscription } from '../../reducers/subscription.slice'
import { DateTime } from 'luxon'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { useEffect, useState } from 'react'
import { makeGridConfig } from '../../components/BaseComponents/Grid'
import { ReactivatePlanModal } from '../../components/Finances/Accounts/ReactivatePlanModal'
import {
  CancellationStepIdentifier,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'
import { useAppDispatch } from '../../utils/typeHelpers'

const CancellationComplete = ({ recordId }: { recordId?: number }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [reactivateOpen, setReactivateOpen] = useState(false)

  const sub = useReselector(selectHeardProductSubscription)

  useEffect(() => {
    const updateRecord = async (recordId?: number) => {
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(recordId, {
          finalStepReached: CancellationStepIdentifier.CANCELLATION_COMPLETE,
        })(dispatch)
      }
    }
    updateRecord(recordId)
  }, [dispatch, recordId])

  return (
    <Grid>
      <GridRowColumn />
      <GridRowColumn />
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/check.svg"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Text as="h1">Goodbye for now!</Text>
      </GridRowColumn>
      <GridRowColumn centerContent {...makeGridConfig([8, 16], true)}>
        <Text as="bodyLg">
          The last day of your subscription will be{' '}
          {
            //nextBillingDate is set to the current period end in the slice
            sub?.nextBillingDate &&
              DateTime.fromSeconds(sub?.nextBillingDate).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SIMPLE
              )
          }
          . We hope you’ll join us again when the timing is right!
        </Text>
      </GridRowColumn>
      <GridRowColumn />
      <GridRowColumn centerContent>
        <Button onClick={() => setReactivateOpen(true)}>
          Reactivate Subscription
        </Button>
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Button variant="actionLink" onClick={() => navigate('/accounts')}>
          Back to Settings
        </Button>
      </GridRowColumn>
      <GridRowColumn />
      <GridRowColumn {...makeGridConfig([8, 16], true)}>
        <Card backgroundColor="stone40">
          <Text as="h2">Need to download your data?</Text>
          <br />
          <Text as="bodyMd">
            Visit your <Link to="/accounts">account settings</Link> page, scroll
            down to the &quot;Plan & Billing&quot; section, and click
            &quot;Download Account Information.&quot; You&apos;ll then receive
            an email when your information is ready to download with a link to
            do so
          </Text>
        </Card>
      </GridRowColumn>
      {reactivateOpen && (
        <ReactivatePlanModal
          open={reactivateOpen}
          close={() => setReactivateOpen(false)}
          redirectUrl="/accounts"
        />
      )}
    </Grid>
  )
}

export default CancellationComplete
