import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy, uniq } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../reducers/fetch'

// Client-side models
export interface QuarterlyTaxEstimateDetail {
  taxYear: string
  taxQuarter: string
  irsQuarterStartsAt: string
  irsQuarterEndsAt: string
  irsPaymentDueAt: string
  newUserCutOffAt: string
  checklistLaunchDate: string | null
  calculationStartsAt: string
  clientNotifiedAt: string
  bookkeepingPeriodStartsAt: string
  bookkeepingPeriodEndsAt: string
  bookkeepingPeriodTotalMonths: number
  edgeCaseUserIds?: number[]
  status: QuarterlyTaxEstimateDetailStatuses
  createdAt: string
  updatedAt: string
  updatedBy?: number
}

export enum QuarterlyTaxEstimateDetailStatuses {
  active = 'active',
  future = 'future',
  past = 'past',
}

export interface QuarterlyTaxEstimateDetailsState {
  byId: { [key: string]: QuarterlyTaxEstimateDetail }
  allIds: string[]
}

const initialState: QuarterlyTaxEstimateDetailsState = {
  byId: {},
  allIds: [],
}

// Our slice object contains the reducer, which modifies state for this entity
const quarterlyTaxEstimateDetailsSlice = createSlice({
  name: 'quarterlyTaxEstimateDetails',
  initialState,
  reducers: {
    receiveSingleQuarterlyTaxEstimateDetail: (
      state,
      action: PayloadAction<QuarterlyTaxEstimateDetail>
    ) => {
      const key = `${action.payload.taxQuarter}${action.payload.taxYear}`
      state.byId[key] = action.payload
      state.allIds = uniq([key, ...state.allIds])
    },
    receiveAllQuarterlyTaxEstimateDetails: (
      state,
      action: PayloadAction<{ [key: string]: QuarterlyTaxEstimateDetail }>
    ) => {
      // Updating state
      state.byId = {
        ...state.byId,
        ...action.payload,
      }

      // Updating state
      state.allIds = [...state.allIds, ...Object.keys(action.payload)]
    },
  },
})

// Exported so we can access within appReducer.ts
export default quarterlyTaxEstimateDetailsSlice.reducer

// These functions are used to manipulate our Redux state
const {
  receiveSingleQuarterlyTaxEstimateDetail,
  receiveAllQuarterlyTaxEstimateDetails,
} = quarterlyTaxEstimateDetailsSlice.actions

/*
  Remaining logic below provides REST functionality to our UI components
*/

// Keys used within our fetchWrapper. Sometimes used by UI components for ascertaining the request's state
export const FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY =
  'FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY'
export const UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY =
  'UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY'
export const CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY =
  'CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY'

// These functions are called by our UI components

// For entities whose data changes infrequently, we use the "IfNeeded()" pattern to prevent unnecessary requests
export const fetchAllQuarterlyTaxEstimateDetailsIfNeeded = () =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_QUARTERLY_TAX_ESTIMATION_DETAILS_KEY,
    defaultErrorMessage: 'Error fetching all quarterly tax estimate details',
    fetchFunction: async (dispatch) => {
      // Make the GET request
      const json = await axios.get<QuarterlyTaxEstimateDetail[]>(
        '/finances/api/v1/tax_estimates/details/list'
      )
      // Update Redux state with data
      dispatch(
        receiveAllQuarterlyTaxEstimateDetails(
          keyBy(json.data, (val) => `${val.taxQuarter}${val.taxYear}`)
        )
      )
      return json.data
    },
  })

export const updateSingleQuarterlyTaxEstimateDetail = (
  taxQuarter: string,
  taxYear: string,
  data: Partial<QuarterlyTaxEstimateDetail>
) =>
  fetchWrapper({
    fetchKey:
      UPDATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY + taxQuarter + taxYear,
    defaultErrorMessage: 'Error updating the quarterly tax estimate detail',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<QuarterlyTaxEstimateDetail>(
        `/finances/api/v1/admin/quarterly_tax_estimate_details/${taxQuarter}/${taxYear}`,
        data
      )
      dispatch(receiveSingleQuarterlyTaxEstimateDetail(json.data))
      return json.data
    },
  })

export const createSingleQuarterlyTaxEstimateDetail = (
  data: Partial<QuarterlyTaxEstimateDetail>
) =>
  fetchWrapper({
    fetchKey: CREATE_SINGLE_QUARTERLY_TAX_ESTIMATION_DETAIL_KEY,
    defaultErrorMessage: 'Error creating the quarterly tax estimate detail',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<QuarterlyTaxEstimateDetail>(
        '/finances/api/v1/admin/quarterly_tax_estimate_details',
        data
      )

      dispatch(receiveSingleQuarterlyTaxEstimateDetail(json.data))
      return json.data
    },
  })
