import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Container, Grid, Divider } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import PayrollSetupList from './PayrollSetupList'
import {
  FETCH_LOCATIONS_KEY,
  fetchAllCompanyLocations,
  POST_CREATE_COMPANY_LOCATION_KEY,
  postCreateCompanyLocation,
  PUT_UPDATE_COMPANY_LOCATION_KEY,
  putUpdateCompanyLocation,
} from '../payrollActions'
import GustoAddress from '../GustoAddress'
import {
  selectFilingLocationOrParsedAddress,
  selectMailingLocation,
  selectPayrollCompanyLocations,
} from '../payroll.selectors'
import {
  getIsFetchingOrNotStarted,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import {
  GEP_ENROLL_PATHS,
  ONBOARDING_STEPS,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  GridRowColumn,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../Amplitude'

const LocationInput = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()

  const filingRef = useRef<GustoAddress>(null)
  const mailingRef = useRef<GustoAddress>(null)

  const locations = useReselector(selectPayrollCompanyLocations)
  const filingAddress = useReselector(selectFilingLocationOrParsedAddress)
  const mailingAddress = useReselector(selectMailingLocation)

  const isPosting = useReselector(selectIsFetchingForKeys, [
    POST_CREATE_COMPANY_LOCATION_KEY,
    PUT_UPDATE_COMPANY_LOCATION_KEY,
  ])

  const [isMailing, setIsMailing] = useState(
    !filingAddress?.uuid || filingAddress?.uuid === mailingAddress?.uuid
  )

  const [filingLocation, setFilingLocation] = useState(filingAddress?.uuid)
  const [mailingLocation, setMailingLocation] = useState(mailingAddress?.uuid)

  const locationOptions = useMemo(
    () => [
      ...Object.values(locations).map((location) => ({
        text: location.street_1,
        value: location.uuid,
      })),
      { text: 'Add new', value: '' },
    ],
    [locations]
  )

  const save = useCallback(async () => {
    // Filing updates
    if (filingLocation) {
      const address = locations[filingLocation]
      const res = await putUpdateCompanyLocation(filingLocation, {
        ...address,
        mailing_address: isMailing,
        filing_address: true,
      })(dispatch)

      if (!res) {
        return
      }
    } else if (filingRef.current) {
      const address = filingRef.current.getAddressWithPhone()
      if (!address) {
        return
      }

      const res = await postCreateCompanyLocation({
        ...address,
        filing_address: true,
        mailing_address: isMailing,
      })(dispatch)

      if (!res) {
        return
      }
    }

    // Mailing updates
    if (!isMailing) {
      if (mailingLocation) {
        const address = locations[mailingLocation]
        await putUpdateCompanyLocation(mailingLocation, {
          ...address,
          mailing_address: true,
          filing_address: false,
        })(dispatch)
      } else if (mailingRef.current) {
        const address = mailingRef.current.getAddressWithPhone()
        if (!address) {
          return
        }

        const res = await postCreateCompanyLocation({
          ...address,
          filing_address: false,
          mailing_address: true,
        })(dispatch)

        if (!res) {
          return
        }
      }
    }

    track('completed payroll step - create or edit company addresses')
    navigate(GEP_ENROLL_PATHS.employee)
  }, [
    dispatch,
    filingLocation,
    isMailing,
    locations,
    mailingLocation,
    navigate,
    track,
  ])

  return (
    <Grid>
      <GridRowColumn>
        <Text as="h2">Company Addresses</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text as="bodyLg">
          To automate your payroll filings, we need your company&apos;s
          addresses. Please confirm the following.
        </Text>
      </GridRowColumn>
      <Divider />
      <PayrollError fetchKey={PUT_UPDATE_COMPANY_LOCATION_KEY} />
      <PayrollError fetchKey={POST_CREATE_COMPANY_LOCATION_KEY} />
      <GridRowColumn>
        <Dropdown
          fullWidth
          required
          placeholder="Select a location"
          value={filingLocation}
          options={locationOptions}
          onChange={(value) => {
            setFilingLocation(value)
            if (value !== undefined) {
              mailingRef.current?.clear()
            }
          }}
        />
      </GridRowColumn>
      {!filingLocation && (
        <GridRowColumn>
          <GustoAddress {...filingAddress} ref={filingRef} />
        </GridRowColumn>
      )}
      <GridRowColumn columnStyle={{ display: 'flex' }}>
        <Checkbox checked disabled />
        <Text style={{ marginTop: -4, marginLeft: 10 }}>
          This is my <b>filing address</b>
          <br />
          This is your official business address
        </Text>
      </GridRowColumn>
      <GridRowColumn columnStyle={{ display: 'flex' }}>
        <Checkbox checked={isMailing} onChange={setIsMailing} />
        <Text style={{ marginTop: -4, marginLeft: 10 }}>
          This is my <b>mailing address</b>
          <br />
          This is where you can receive mail (usually the same as filing)
        </Text>
      </GridRowColumn>

      {!isMailing && (
        <>
          <GridRowColumn>
            <Text color="green">
              <b>Mailing Address</b>
            </Text>
          </GridRowColumn>

          <GridRowColumn>
            <Dropdown
              fullWidth
              required
              placeholder="Select a location"
              value={mailingLocation}
              options={locationOptions}
              onChange={(value) => {
                setMailingLocation(value)
                if (value !== undefined) {
                  mailingRef.current?.clear()
                }
              }}
            />
          </GridRowColumn>
          {!mailingLocation && (
            <GridRowColumn>
              <GustoAddress ref={mailingRef} />
            </GridRowColumn>
          )}
        </>
      )}
      <Grid.Row />

      <Grid.Row>
        <Grid.Column width={6}>
          <Button
            fullWidth
            variant="secondary"
            onClick={() => navigate(GEP_ENROLL_PATHS.federal)}
          >
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={4} />
        <Grid.Column width={6}>
          <Button onClick={save} fullWidth loading={isPosting}>
            Save & Continue
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const PayrollEnrollLocations = () => {
  const dispatch = useAppDispatch()
  const fetchingLocations = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_LOCATIONS_KEY
  )
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.locations)

  useEffect(() => {
    dispatch(fetchAllCompanyLocations())
  }, [dispatch])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              {!fetchingLocations && <LocationInput />}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEnrollLocations
