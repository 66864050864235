import { StripeInterval } from '../../../reducers/subscription.slice'
import { StripeProduct } from '../../../actions/settings/billingActions'
import { fetchStripePlans } from '../../../constants/pricingConstants'
import { dollarsToCurrency } from '../../../utils/currencyHelpers'
import { ADDITIONAL_1040_COST } from '../../Taxes/AnnualTaxes/constants'
import { DateTime } from 'luxon'

export interface PlanDetail {
  text: string
  tooltip?: string | null
}

const currentYear = DateTime.now().year
const filingYear = currentYear + 1

// Stores the entire collection of "selling points" in a single object
const PlanDetails: Record<string, PlanDetail> = {
  annualBusinessTaxPrepSoloScorp: {
    text: `${currentYear} Annual business income tax prep and filing (filed in ${filingYear})`,
    tooltip:
      "Our team files your annual state and federal business income tax return. Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.",
  },
  annualPersonalTaxPrepSCorp: {
    text: `${currentYear} Annual personal income tax prep and filing (filed in ${filingYear})`,
    tooltip: `Our team files your annual state and federal personal income tax return ($${ADDITIONAL_1040_COST} value). Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.`,
  },
  annualIncomeTaxPrepSoleProp: {
    text: `${currentYear} Annual personal income tax prep and filing (filed in ${filingYear})`,
    tooltip:
      "Our team files your annual state and federal personal income tax return. Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.",
  },
  quarterlyTaxEstimates: {
    text: 'Quarterly tax estimates',
    tooltip:
      'Every quarter, we calculate your estimated payments and remind you when to pay so you never miss a deadline.',
  },
  dedicatedTaxProfessional: {
    text: `Dedicated tax expert who supports you through your ${currentYear} tax filing`,
    tooltip:
      'Work directly with your dedicated tax expert during your tax filing to maximize your deductions.',
  },
  paidTaxAdvisory: {
    text: 'Personalized Tax Consultations (Add on)',
    tooltip:
      'You’ll be able to book 30 and 60 minute conversations with your tax preparer during your tax season for an additional fee.',
  },
  monthlyBookkeepingServices: {
    text: 'Monthly bookkeeping services',
    tooltip: null,
  },
  bookkeepingProfessionalsSpecialized: {
    text: 'Bookkeeping professionals specialized in therapy practice',
    tooltip:
      'Our only focus is therapists. That means your dedicated bookkeeping team and tax professionals are experts in therapy practices and all of the nuances that come with running one.',
  },
  unlimitedAppMessaging: {
    text: 'Unlimited in-app messaging support',
    tooltip:
      "You can message our support team through the Heard app 24/7 and we'll get back to you in 1-2 business days.",
  },
  financialReportsAndProfitAllocation: {
    text: 'Financial reports and profit allocation guide',
    tooltip:
      'We share monthly financial reports and allocation guide to help you confidently allocate your profit. Financial reports are automatically generated once bookkeeping is complete, with a summary of your cash flow and profit and loss. This helps you better understand your practice finances.',
  },
  expenseBreakdown: {
    text: 'Expense breakdown',
    tooltip: null,
  },
  singlePlaceToKeepDocuments: {
    text: 'A single place to keep important documents and receipts',
    tooltip: null,
  },
  payrollIncluded: {
    text: 'Integrated payroll management via Gusto',
    tooltip:
      'We’ve teamed up with Gusto to provide stress-free payroll, embedded right in your Heard dashboard. Payroll for yourself is included ($45/mo value). Additional employees will cost $6/mo per employee.',
  },
  payrollAddOn: {
    text: 'Integrated payroll management via Gusto (Add on)',
    tooltip:
      'We’ve teamed up with Gusto to provide stress-free payroll, embedded right in your Heard dashboard. You’ll be billed $39/mo + $6/mo per employee.',
  },
  sCorpCompliance: {
    text: 'S corporation compliance',
    tooltip:
      "We make sure you stay compliant and avoid fees or penalties from the IRS. We audit your books and advise you if you aren't compliant with S corporation tax regulations and provide resources for you to keep on hand so you understand how to remain in compliance.",
  },
  reasonableSalaryDetermination: {
    text: 'Reasonable salary determination',
    tooltip:
      "Throughout the year, we'll review your reasonable salary to make sure you're paying yourself the right amount. Setting a reasonable salary that is not too high or too low can affect your practice's profits and overall business success.",
  },
  sCorpElection: {
    text: 'S corporation election',
    tooltip:
      "We'll file Form 2553 for you to elect your therapy practice to be taxed as an S corporation by the IRS. We help you meet S corporation requirements: Incorporation and EIN changes. You can deduct certain business expenses, such as salaries, wages, bonuses, rent, utilities, and supplies. Deducting these expenses can reduce your taxable income and lower your taxes.",
  },
  sCorpEligibilityAssessment: {
    text: 'S corporation readiness report',
    tooltip:
      "We'll create a personalized readiness report to determine if it is a good time to elect S corporation, including an overview of the tax savings and associated costs.",
  },
  cubkReconciliation: {
    text: 'Complete reconciliation of past transactions',
    tooltip: 'Complete reconciliation of past transactions',
  },
  cubkProfessionalReview: {
    text: 'Professional review of deductible expenses',
    tooltip: 'Professional review of deductible expenses',
  },
  cubkFinancialInsights: {
    text: 'Year-to-date financial insights and reporting',
    tooltip: 'Year-to-date financial insights and reporting',
  },
  basicPlanTaxesAccountingDocs: {
    text: 'Accounting documents for easy tax filing with an outside preparer',
  },
  basicPlanIdentifyTaxDeductions: {
    text: 'Identification of tax deductible expenses via dedicated bookkeeping team',
  },
}

// Construct arrays of plan details for each of the 4 sections - Tax, Bookkeeping, Budgeting & Tracking, S-Corporations

// Tax
export const getTaxSectionDetails = ({
  isScorp,
  isBasicPlan,
  basicPlanSearchParamValue,
}: {
  isScorp: boolean
  isBasicPlan: boolean
  basicPlanSearchParamValue: string | null
}) => {
  const details: PlanDetail[] = []
  if (isBasicPlan) {
    if (basicPlanSearchParamValue !== 'basic') {
      details.push(PlanDetails.quarterlyTaxEstimates)
    }
    return details
  }

  if (isScorp) {
    details.push(PlanDetails.annualBusinessTaxPrepSoloScorp)
    details.push(PlanDetails.annualPersonalTaxPrepSCorp)
  } else {
    details.push(PlanDetails.annualIncomeTaxPrepSoleProp)
  }
  details.push(
    PlanDetails.quarterlyTaxEstimates,
    PlanDetails.dedicatedTaxProfessional,
    PlanDetails.paidTaxAdvisory
  )
  return details
}

// Bookkeeping
export const getBookkeepingSectionDetails = (isBasicPlan: boolean) => {
  const details: PlanDetail[] = []
  if (isBasicPlan) {
    details.push(
      PlanDetails.basicPlanTaxesAccountingDocs,
      PlanDetails.basicPlanIdentifyTaxDeductions
    )
  }
  details.push(
    PlanDetails.monthlyBookkeepingServices,
    PlanDetails.bookkeepingProfessionalsSpecialized,
    PlanDetails.unlimitedAppMessaging
  )
  return details
}

// Catch up bookkeeping
export const generalCUBKDetails = [
  PlanDetails.cubkReconciliation,
  PlanDetails.cubkProfessionalReview,
  PlanDetails.cubkFinancialInsights,
]

// Budgeting & Tracking
export const getBudgetingSectionDetails = ({
  isGroup,
  isSCorp,
}: {
  isGroup: boolean
  isSCorp: boolean
}) => {
  const details: PlanDetail[] = [
    PlanDetails.financialReportsAndProfitAllocation,
    PlanDetails.expenseBreakdown,
    PlanDetails.singlePlaceToKeepDocuments,
  ]
  if (!isSCorp && isGroup) {
    details.push(PlanDetails.payrollAddOn)
  }
  return details
}

// S Corporation
export const getSCorpSectionDetails = ({
  isSCorp,
  isBasicPlan,
}: {
  isSCorp: boolean
  isBasicPlan: boolean
}) => {
  if (isBasicPlan) {
    return []
  }
  if (isSCorp) {
    return [
      PlanDetails.payrollIncluded,
      PlanDetails.sCorpCompliance,
      PlanDetails.reasonableSalaryDetermination,
    ]
  }
  return [PlanDetails.sCorpEligibilityAssessment, PlanDetails.sCorpElection]
}

export const getProductByInterval = (
  products: StripeProduct[],
  interval: StripeInterval
) =>
  products.find(
    (product) => product.default_price.recurring.interval === interval
  )

// This shape is compatible with the new StripeProductCard component
// We cannot fetch Basic Plans from stripe b/c their shape on Stripe is inconsistent
// with other primary products. Sahil says we're planning to sunset Basic Plans come 2024,
// so we should keep pricing constants related to this product until then
export const fetchBasicProductsV2 = () => {
  const stripePlans = fetchStripePlans()

  const baseProduct: StripeProduct = {
    id: '',
    name: '',
    object: 'product',
    active: false,
    attributes: [],
    created: 0,
    description: '',
    features: [],
    images: [],
    livemode: false,
    metadata: {},
    statement_descriptor: '',
    tax_code: '',
    unit_label: '',
    updated: 0,
    url: '',
    default_price: {
      id: '',
      object: 'price',
      active: true,
      billing_scheme: '',
      created: 0,
      currency: 'USD',
      livemode: false,
      metadata: null,
      nickname: '',
      product: '',
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 0,
      unit_amount_decimal: '',
      recurring: {
        interval: StripeInterval.month,
        aggregate_usage: null,
        interval_count: 0,
        trial_period_days: null,
        usage_type: 'licensed',
      },
    },
  }

  const result = {
    BASIC_SOLO_PRODUCTS: [
      // solo monthly
      {
        ...baseProduct,
        name: 'Basic Plus Plan (Monthly)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_solo.monthly.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_solo.monthly.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.month,
          },
        },
      },
      // solo annual product
      {
        ...baseProduct,
        name: 'Basic Plus Plan (Annual)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_solo.annual.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_solo.annual.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.year,
          },
        },
      },
    ],

    BASIC_GROUP_PRODUCTS: [
      // group monthly
      {
        ...baseProduct,
        name: 'Basic Plus Plan (Monthly)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_group.monthly.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_group.monthly.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.month,
          },
        },
      },
      // group annual
      {
        ...baseProduct,
        name: 'Basic Plus Plan (Annual)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_group.annual.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_group.annual.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.year,
          },
        },
      },
    ],
    BASIC_NO_QTE_PRODUCTS: [
      // solo monthly
      {
        ...baseProduct,
        name: 'Basic Plan (Monthly)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_no_qte.monthly.priceId,
          unit_amount: dollarsToCurrency(
            stripePlans.basic_no_qte.monthly.amount
          ).intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.month,
          },
        },
      },
      // solo annual
      {
        ...baseProduct,
        name: 'Basic Plan (Annual)',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_no_qte.annual.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_no_qte.annual.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.year,
          },
        },
      },
    ],
  }

  return result
}
