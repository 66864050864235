import { Grid, Loader } from 'semantic-ui-react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import { useFetchResponse, useReselector } from '../../../utils/sharedHooks'
import {
  FETCH_INSIGHTS_CASHFLOW_BALANCE_FOR_DEPOSITORY_ACCTS,
  fetchInsightsCashflowBalanceForDepositoryAccts,
} from '../../../components/Finances/shared/financeActions'
import { getIsFetching } from '../../../reducers/fetch'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { formatCurrencyFromCents } from '../../../utils/currencyHelpers'
import {
  formatISOFromUTC,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'
import { useAnalyticsTrack, useAnalyticsView } from '../../Amplitude'
import { useEffect } from 'react'

export const CashflowInsightsCard = () => {
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  const pageView = useAnalyticsView()

  useEffect(() => {
    pageView('cashflow monitoring card')
  }, [pageView])

  const cashflowSummary = useFetchResponse(
    fetchInsightsCashflowBalanceForDepositoryAccts
  )

  const queryLoading = useReselector(
    getIsFetching,
    FETCH_INSIGHTS_CASHFLOW_BALANCE_FOR_DEPOSITORY_ACCTS
  )

  return (
    <Card type="subsection" backgroundColor="natural">
      {queryLoading && <Loader />}
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Text as="h2">Cashflow</Text>
          </Grid.Column>
        </Grid.Row>
        {cashflowSummary && (
          <>
            <GridRowColumn style={{ paddingBottom: 5 }}>
              <Text as="display2">
                {formatCurrencyFromCents(cashflowSummary.totalBalanceInCents)}
              </Text>
            </GridRowColumn>
            <GridRowColumn style={{ paddingTop: 0 }}>
              Cash in{' '}
              {formatISOFromUTC(
                cashflowSummary.asOfDate,
                DATE_FORMATS_LUXON.MONTH
              )}
            </GridRowColumn>
            {Math.round(Math.abs(cashflowSummary.percentageChange)) !== 0 && (
              <Grid.Row>
                <Grid.Column>
                  <Icon
                    icon={
                      cashflowSummary.percentageChange > 0
                        ? light('arrow-up')
                        : light('arrow-down')
                    }
                    style={{ marginLeft: 8 }}
                  />
                </Grid.Column>
                <Grid.Column width={13}>
                  <Text as="h3">
                    Your cashflow is trending{' '}
                    {Math.round(Math.abs(cashflowSummary.percentageChange))}%{' '}
                    {cashflowSummary.percentageChange > 0 ? 'higher' : 'lower'}{' '}
                    than usual.
                  </Text>
                </Grid.Column>
              </Grid.Row>
            )}
          </>
        )}
        <Grid.Row width={14}>
          <Grid.Column>
            <Button
              variant="secondary"
              onClick={() => {
                navigate('/insights/profit')
                track('clicked view profit button', {
                  button: 'view profit',
                })
              }}
            >
              View Profit{' '}
              <Icon icon={light('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}

export default CashflowInsightsCard
