import { useRef, useState, Fragment, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Divider, Grid, Icon, Menu, Popup, Table } from 'semantic-ui-react'
import { CSVLink } from 'react-csv'

import TaxFilingFormUploadModal from './TaxFilingFormUploadModal'
import {
  AnnualTaxFilingExtensionPayment,
  AnnualTaxFilingForm,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { useReselector } from '../../../utils/sharedHooks'
import {
  Button,
  GridRowColumn,
  Label,
  Text,
} from '../../../components/BaseComponents'
import type { LabelColorMap } from '../../../components/BaseComponents/Label'
import TaxSeasonKickoffModal from '../AnnualTaxDetails/components/TaxSeasonKickoffModal'
import { fetchTSKResponses } from './TaxPackageInformation/TaxSeasonKickoffSurveyCSV'
import { fetchProfitLossCSV } from './TaxPackageInformation/ProfitLossCSV'
import {
  DATE_FORMATS,
  getTimeDifferenceInDaysAndHours,
} from '../../../utils/dateHelpers'
import FormProgressModal from './FormProgressModal'
import { selectAnnualTaxFilingForId } from './adminAnnualTaxFilings.selector'
import NeedsManualBalanceSheetLabel from '../../Reports/Admin/AdminBalanceSheet/ManualBalanceSheetLabel'
import ExtensionCell from './ExtensionCell'
import UserStateLabel from '../../../components/Admin/UserRecord/UserAccountStates/UserStateLabel'
import TaxfyleJobHistoryModal from './TaxfyleJobHistoryModal'
import YearEndModuleStatusModal from './YearEndModuleStatusModal'
import NotesModal from './NotesModal'

interface TableDataProps {
  filingId: number
  refreshData: () => void
  page: number
  taxYear: string
}

const FormsQuestionnaireStatusLabelColor: Record<
  string,
  keyof typeof LabelColorMap
> = {
  not_started: 'red',
  general_questions: 'orange',
  document_upload: 'yellow',
  deductions: 'blue',
  summary: 'gray',
  submitted: 'green',
  review_pending: 'neutral',
  review_complete: 'darkYellow',
}

export const annualTaxFilingFormTaxfyleJobStatusLabelColor: Record<
  TaxfyleJobStatus,
  keyof typeof LabelColorMap
> = {
  UNDER_CONSTRUCTION: 'orange',
  INFO_GATHERING: 'orange',
  UNCLAIMED: 'orange',
  CLAIMED: 'yellow',
  IDLE: 'red',
  ON_HOLD: 'red',
  COMPLETED: 'darkGreen',
  CANCELLED: 'red',
}

export const annualTaxFilingFormTaxfyleJobStepLabelColor: Record<
  TaxfyleJobStep,
  keyof typeof LabelColorMap
> = {
  JOB_STARTED: 'orange',
  OPEN_ITEMS: 'orange',
  DRAFT_IN_REVIEW: 'yellow',
  DRAFT_REJECTED: 'red',
  DRAFT_APPROVED: 'green',
  AUTHORIZATION_REQUESTED: 'yellow',
  AUTHORIZATION_SIGNED: 'darkYellow',
  RETURN_FILED: 'darkGreen',
}

const TaxfyleStatusCell = ({
  taxfyleJob,
}: {
  taxfyleJob: AnnualTaxFilingForm['taxfyleJob']
}) => {
  const [activityModalOpen, setActivityModalOpen] = useState(false)
  if (!taxfyleJob) {
    return null
  }
  return (
    <Grid>
      <GridRowColumn>
        <Button variant="link" onClick={() => setActivityModalOpen(true)}>
          View Activity
        </Button>
      </GridRowColumn>
      <TaxfyleJobHistoryModal
        createdAt={taxfyleJob.createdAt}
        taxfyleJobId={taxfyleJob.jobId}
        open={activityModalOpen}
        onClose={() => setActivityModalOpen(false)}
      />
      <GridRowColumn short>
        <Label
          color={
            annualTaxFilingFormTaxfyleJobStatusLabelColor[taxfyleJob.jobStatus]
          }
        >
          {taxfyleJob.jobStatus}
        </Label>
      </GridRowColumn>
      <GridRowColumn short>
        <Label
          color={
            taxfyleJob.jobStep
              ? annualTaxFilingFormTaxfyleJobStepLabelColor[taxfyleJob.jobStep]
              : undefined
          }
        >
          {taxfyleJob.jobStep ?? 'No Job Step'}
        </Label>
      </GridRowColumn>
    </Grid>
  )
}

const AnnualTaxFilingTableData = ({
  filingId,
  refreshData,
  taxYear,
}: TableDataProps) => {
  const filing = useReselector(selectAnnualTaxFilingForId, filingId)
  const user = filing?.user || filing?.annualTaxFilings
  const filingTaxForms = filing?.annualTaxFilingForms
  const [taxSeasonKickoffCsvLoading, setTSKCsvLoading] = useState(false)
  const [pnlCsvLoading, setPnlCsvLoading] = useState(false)
  const [pnlCsv, setPnlCsv] = useState<string[][]>([])
  const [tskCsv, setTSKCsv] = useState<string[][]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [modalUserContext, setModalUserContext] = useState('')
  const [taxSeasonKickoffSurveyModalOpen, setTaxSeasonKickoffSurveyModalOpen] =
    useState(false)
  const [yearEndModuleStatusModalOpen, setYearEndModuleStatusModalOpen] =
    useState(false)
  const [modalFilingFormIdContext, setModalFilingFormIdContext] =
    useState<number>()
  const [modalFilingFormTypeNameContext, setModalFilingFormTypeNameContext] =
    useState('')
  const [
    modalFilingFormExtensionPaymentsContext,
    setModalFilingFormExtensionPaymentsContext,
  ] = useState<AnnualTaxFilingExtensionPayment[]>()
  const [filingFormIsExtendedContext, setFilingFormIsExtendedContext] =
    useState<boolean>()
  const [formProgressModalForm, setFormProgressModalForm] =
    useState<AnnualTaxFilingForm>()
  const [notesModalOpen, setNotesModalOpen] = useState(false)

  const filingForms = useMemo(
    () => filing?.annualTaxFormNeeds?.join(', ') || '',
    [filing?.annualTaxFormNeeds]
  )

  const fetchTaxSeasonKickoffSurveyResponses = useCallback(() => {
    setTSKCsvLoading(true)
    setTSKCsv([[]])
    if (!filing || !user) return
    const responses = fetchTSKResponses(filing, taxYear, user)
    setTSKCsv(responses)
    setTSKCsvLoading(false)
  }, [filing, taxYear, user])

  const handleModalOpen = useCallback(
    (
      modalView: string,
      modalUserContext: string,
      filingFormId?: number,
      filingFormTypeName?: string,
      filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[] | null,
      filingFormIsExtended?: boolean | null
    ) => {
      setModalView(modalView)
      setModalUserContext(modalUserContext)
      if (filingFormId) {
        setModalFilingFormIdContext(filingFormId)
      }
      if (filingFormTypeName) {
        setModalFilingFormTypeNameContext(filingFormTypeName)
      }
      if (filingFormExtensionPayments) {
        setModalFilingFormExtensionPaymentsContext(filingFormExtensionPayments)
      }
      setFilingFormIsExtendedContext(filingFormIsExtended || false)
      setModalOpen(true)
    },
    []
  )

  const pnlCsvRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const preparePnlCSV = useCallback(async () => {
    if (!user) return
    setPnlCsvLoading(true)
    setPnlCsv([])
    const responses = await fetchProfitLossCSV(taxYear, user)
    setPnlCsv(responses)
    setPnlCsvLoading(false)
    pnlCsvRef.current?.link.click()
  }, [taxYear, user])

  if (!user || !filing) {
    return null
  }

  return (
    <Table.Row key={user.id}>
      <Table.Cell>
        <Text>{user.id}</Text>
        <Link to={`/admin/finances/records/${user.id}`}>
          {user.firstName} {user.lastName}
        </Link>
        <UserStateLabel user={user} />
        <br />
        <Text>{user.email}</Text>
        <br />
        <Text>
          <b>BK:</b>{' '}
          {`${user.bookkeeper?.firstName} ${user.bookkeeper?.lastName} `}
        </Text>
        <Text>
          <b>User Created:</b>{' '}
          {moment(user.createdAt).format(DATE_FORMATS.DISPLAY_SHORT)}
        </Text>
        <br />
        <NeedsManualBalanceSheetLabel userId={user.id} year={taxYear} />
        <br />
        <Link to={`/admin/finances/annual-tax-filings/${filing.id}`}>
          View Annual Tax Filing
        </Link>
      </Table.Cell>
      <Table.Cell>
        <div>
          {filing.pretaxSurveySubmittedAt && (
            <>
              <Label color="green">Completed</Label>
              <Text>
                On:{' '}
                {moment(filing.pretaxSurveySubmittedAt).format(
                  DATE_FORMATS.DISPLAY_SHORT
                )}
              </Text>
            </>
          )}
          {!filing.pretaxSurveySubmittedAt && (
            <Label color="red">Incomplete</Label>
          )}
          <Text>{filingForms}</Text>
        </div>
        <Button
          variant="link"
          onClick={() => setTaxSeasonKickoffSurveyModalOpen(true)}
        >
          View Answers
        </Button>
        <TaxSeasonKickoffModal
          filing={filing}
          open={taxSeasonKickoffSurveyModalOpen}
          close={() => setTaxSeasonKickoffSurveyModalOpen(false)}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          variant="link"
          onClick={() => setYearEndModuleStatusModalOpen(true)}
        >
          Check Year End Progress
        </Button>
        <br />
        <br />
        <Button variant="link" onClick={() => setNotesModalOpen(true)}>
          Update Notes
        </Button>
        {notesModalOpen && (
          <NotesModal
            filingId={filingId}
            onClose={() => setNotesModalOpen(false)}
          />
        )}
        <YearEndModuleStatusModal
          open={yearEndModuleStatusModalOpen}
          close={() => setYearEndModuleStatusModalOpen(false)}
          userId={user.id}
          userFirstName={user.firstName}
          userLastName={user.lastName}
        />
      </Table.Cell>
      <Table.Cell>
        {filingTaxForms?.map((form) => (
          <Fragment key={form.id}>
            {form.formType.displayName}
            <Divider />
          </Fragment>
        ))}
      </Table.Cell>
      <Table.Cell>
        {filingTaxForms?.map((form) => (
          <Fragment key={form.id}>
            <TaxfyleStatusCell taxfyleJob={form.taxfyleJob} />
            <Divider />
          </Fragment>
        ))}
      </Table.Cell>
      <Table.Cell>
        {filingTaxForms?.map((form) => (
          <Fragment key={form.id}>
            {form.taxfyleJob?.statusLastUpdatedAt && (
              <>
                <Label>
                  {getTimeDifferenceInDaysAndHours(
                    form.taxfyleJob.statusLastUpdatedAt
                  )}
                </Label>
                <Label>
                  {form.taxfyleJob.stepLastUpdatedAt
                    ? getTimeDifferenceInDaysAndHours(
                        form.taxfyleJob.stepLastUpdatedAt
                      )
                    : 'No Job Step'}
                </Label>
              </>
            )}
            <Divider />
          </Fragment>
        ))}
      </Table.Cell>
      <Table.Cell>
        {filingTaxForms?.map((form) => (
          <Fragment key={form.id}>
            {form.taxfyleJob?.jobId && (
              <Link
                to={`${process.env.TAXFYLE_BASE_URL}/jobs/${form.taxfyleJob.jobId}`}
                target="_blank"
              >
                {form.formType.displayName} Link
              </Link>
            )}
            <Divider />
          </Fragment>
        ))}
      </Table.Cell>
      <ExtensionCell filingId={filingId} refreshData={refreshData} />
      <Table.Cell>
        {filingTaxForms?.map((form) => (
          <>
            <Label
              color={
                FormsQuestionnaireStatusLabelColor[
                  form.questionnaireResponseStatus
                ]
              }
              onClick={() => setFormProgressModalForm(form)}
            >
              {form.questionnaireResponseStatus}
            </Label>
            <Divider />
          </>
        ))}
      </Table.Cell>
      <Table.Cell>
        <Link
          to={`/admin/finances/financial-reports/${user.id}/profit-loss`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Profit and Loss
        </Link>
        <br />
        <div style={{ cursor: 'pointer' }}>
          <Popup on="click" trigger={<Icon name="ellipsis vertical" />}>
            <Menu secondary vertical>
              <Menu.Item
                name="Download PNL"
                disabled={pnlCsvLoading}
                onClick={preparePnlCSV}
              >
                Download PNL
                <Icon name="download" />
              </Menu.Item>
              <CSVLink
                className="hidden"
                target="_blank"
                data={pnlCsv}
                filename={`${taxYear}-profit-and-loss-${user.firstName}-${user.lastName}.csv`}
                ref={pnlCsvRef}
              />
              <Menu.Item
                name="Download Tax Season Kickoff Responses"
                onClick={fetchTaxSeasonKickoffSurveyResponses}
                disabled={taxSeasonKickoffCsvLoading}
              >
                <CSVLink
                  className="hidden"
                  target="_blank"
                  data={tskCsv}
                  filename={`${taxYear}-tax-season-kickoff-survey-${user.firstName}-${user.lastName}.csv`}
                >
                  Download Tax Season Kickoff Responses
                </CSVLink>
                <Icon name="download" />
              </Menu.Item>
              <Menu.Item
                name="Update Miscellaneous"
                onClick={() =>
                  handleModalOpen(
                    'Update Miscellaneous',
                    `${user.firstName} ${user.lastName}`
                  )
                }
              >
                Update Miscellaneous
                <Icon name="upload" />
              </Menu.Item>
            </Menu>
          </Popup>
        </div>
      </Table.Cell>
      <TaxFilingFormUploadModal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        modalView={modalView}
        setModalView={setModalView}
        modalUserContext={modalUserContext}
        filingId={filing.id}
        refreshData={refreshData}
        filingFormId={modalFilingFormIdContext}
        filingFormTypeName={modalFilingFormTypeNameContext}
        taxFilingUserId={user.id}
        filingFormExtensionPayments={modalFilingFormExtensionPaymentsContext}
        filingFormIsExtended={filingFormIsExtendedContext}
      />
      {formProgressModalForm && filingTaxForms && (
        <FormProgressModal
          form={formProgressModalForm}
          forms={filingTaxForms}
          onClose={() => setFormProgressModalForm(undefined)}
        />
      )}
    </Table.Row>
  )
}

export default AnnualTaxFilingTableData
