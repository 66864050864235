import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, GridRowColumn, Icon, Modal, Text } from '../../BaseComponents'
import { Colors } from '../../../styles/theme'
import { Container, Grid, Image, List } from 'semantic-ui-react'
import { ReactNode, useCallback } from 'react'
import { fetchBillingPortalSession } from '../../../actions/settings/billingActions'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { ANNUAL_TAX_RETURNS_FED_AND_STATE_VALUE_IN_DOLLARS } from '../../../constants/businessConstants'

const contentStyle = {
  background: Colors.natural,
  padding: 48,
}

const Perk = ({ children }: { children: ReactNode }) => (
  <List.Item style={{ padding: '8px 0' }}>
    <List.Icon style={{ verticalAlign: 'middle' }}>
      <Icon color="green" icon={regular('check')} />
    </List.Icon>
    <List.Content>{children}</List.Content>
  </List.Item>
)

const AnnualTaxesContent = () => {
  const dispatch = useAppDispatch()

  const redirectToStripePortal = useCallback(async () => {
    const res = await fetchBillingPortalSession()(dispatch)
    if (res) {
      window.location.href = res.url
    }
  }, [dispatch])

  return (
    <>
      <Modal.Content style={contentStyle}>
        <Grid>
          <GridRowColumn>
            <Text as="display">
              Annual Taxes
              <Icon
                size="xs"
                style={{ marginLeft: 16 }}
                icon={regular('lock')}
              />
            </Text>
          </GridRowColumn>
          <Grid.Row columns={2} style={{ paddingBottom: 80 }}>
            <Grid.Column>
              <Text>
                Heard&apos;s annual tax service guides you through the tax
                filing process by seamlessly integrating with bookkeeping to
                ensure you&apos;re maximizing your deductible expenses on your
                tax returns. Our team of third-party tax preparers files your
                annual tax returns for both federal and state (a $
                {ANNUAL_TAX_RETURNS_FED_AND_STATE_VALUE_IN_DOLLARS} value).
              </Text>
              <br />
              <List>
                <Perk>
                  Personalized preparation of your annual tax returns for you
                </Perk>
                <Perk>Annual federal and state taxes filed for you</Perk>
                <Perk>Upload your receipts directly into Heard</Perk>
                <Perk>Unlimited messaging with tax preparer </Perk>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="https://heard-images.s3.amazonaws.com/assets/annual-tax-teaser-cards.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Container
        style={{
          backgroundColor: Colors.moss,
          margin: 0,
          padding: '28px 48px',
        }}
      >
        <Grid>
          <Grid.Row verticalAlign="middle" columns={2}>
            <Grid.Column width={11}>
              <Text>You&apos;re currently on a Basic Subscription Plan</Text>
            </Grid.Column>
            <Grid.Column width={5}>
              <Button onClick={redirectToStripePortal}>
                Upgrade to Pro Plan
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

export default AnnualTaxesContent
