import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Header,
  Button,
  Loader,
  Divider,
  Menu,
  Dropdown,
  Dimmer,
} from 'semantic-ui-react'

import { fetchTransactionCategoriesIfNeeded } from '../../features/Reports/reports.slice'
import { getUserById } from '../../selectors/user.selectors'
import TransactionTable from './TransactionTable'
import {
  clearTransactionFilters,
  clearAndUpdateTransactionFilters,
} from '../../reducers/finances/transactionFiltersReducer'
import UserBreadcrumbs from './UserRecord/UserBreadcrumbs'
import LockedBooksMessage from '../../features/Transactions/LockedBooksMessage'
import { adminFetchSingleUser } from '../../actions/admin/adminAllUsersActions'
import { useReselector, useToggle } from '../../utils/sharedHooks'
import UserStateLabel from './UserRecord/UserAccountStates/UserStateLabel'
import UserAccountCanceledAlert from './UserRecord/UserAccountStates/UserAccountCanceledAlert'
import PaginatedTransactionTable from './PaginatedTransactionTable'
import { useAppDispatch } from '../../utils/typeHelpers'
import { fetchTransactionsUpdatesDisabled } from '../../actions/admin/transactionActions'
import { UpdatesDisabledPage } from '../../features/Transactions/UpdatesDisabledPage'

const UserTransactionPanel = () => {
  const dispatch = useAppDispatch()
  const { userId } = useParams()
  const [loading, setLoading] = useState(true)
  const [updatesDisabled, setUpdatesDisabled] = useState(false)
  const [showV2, toggleShowV2] = useToggle(false)
  const navigate = useNavigate()

  const user = useReselector(getUserById, userId)

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      if (userId) {
        await Promise.all([
          dispatch(adminFetchSingleUser(userId)),
          dispatch(
            clearAndUpdateTransactionFilters({ userId: Number(userId) })
          ),
        ])
        const transactionsDisabled = await dispatch(
          fetchTransactionsUpdatesDisabled(userId)
        )
        if (transactionsDisabled !== false && transactionsDisabled?.message) {
          setUpdatesDisabled(true)
        }
      }
      setLoading(false)
    }

    fetch()
  }, [dispatch, userId])

  // Clear transaction filters when navigating away
  useEffect(() => {
    return () => {
      dispatch(clearTransactionFilters())
    }
  }, [dispatch])

  const lockedYear =
    user?.booksLockedForAdminYear || user?.booksLockedForUserYear

  if (updatesDisabled) {
    return <UpdatesDisabledPage />
  }

  return (
    <div id="adminPanel">
      {user && (
        <>
          <UserBreadcrumbs userId={user.id} pageName={'Transactions'} />
          <Divider />
          {lockedYear && (
            <LockedBooksMessage
              className="noBorder"
              year={lockedYear}
              admin
              userOnlyLocked={
                Boolean(user.booksLockedForUserYear) &&
                !user.booksLockedForAdminYear
              }
            />
          )}
          <div className="flexbox">
            <div>
              <Header as="h3">
                <Link to={`/admin/finances/records/${user.id}`}>
                  <Button circular icon="arrow left" />
                </Link>
                All Transactions for User: {user.firstName} {user.lastName}
              </Header>
            </div>

            <Menu.Menu position="right">
              <Dropdown button fluid text="Other Actions">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="View Imported"
                    onClick={() =>
                      navigate(
                        `/admin/finances/transactions/${user.id}/imported`
                      )
                    }
                  />
                  <Dropdown.Item onClick={() => toggleShowV2()}>
                    {showV2 ? 'Show Old View' : 'Show New View'}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </div>
          <br />
          <div>
            <UserStateLabel user={user} />
            <br />
            <br />
            <UserAccountCanceledAlert user={user} />
          </div>
        </>
      )}
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      {!loading && user && !showV2 && <TransactionTable userId={user.id} />}
      {!loading && user && showV2 && <PaginatedTransactionTable user={user} />}
    </div>
  )
}

export default UserTransactionPanel
