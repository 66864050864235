import { useMemo, useState, useEffect } from 'react'
import { Grid, Label } from 'semantic-ui-react'
import { DateTime } from 'luxon'
import {
  getCategoryData,
  filterChartAPIResponse,
  processDataForChart,
  ChartAPIResponse,
  getInsightsDateRange,
  INSIGHTS_TYPE,
  filterChartCategories,
  insightsCopyConstants,
  insightsTrackDefaultFilters,
  getExampleExpenseChartData,
} from './utils'
import { InsightsChart } from './InsightsChart'
import {
  fetchInsightsBarchartData,
  FETCH_INSIGHTS_BARCHART_DATA_KEY,
} from './financeActions'
import { GridRowColumn, Text, Dropdown, Loader } from '../../BaseComponents'
import {
  useGetEndDateForFinancialInsights,
  useReselector,
} from '../../../utils/sharedHooks'
import { getFetchError, selectIsFetchingForKeys } from '../../../reducers/fetch'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../utils/typeHelpers'
import FreeTrialUnreviewedAlert from '../../shared/FreeTrialUnreviewedAlert'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'

export const TrackInsightsSection = ({
  inputDate,
  type,
  shouldDisplaySampleData = false,
}: {
  inputDate?: string
  type: INSIGHTS_TYPE
  shouldDisplaySampleData?: boolean
}) => {
  const dispatch = useAppDispatch()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const [responseData, setResponseData] = useState<ChartAPIResponse[]>()
  const [filter, setFilter] = useState('all')

  const currentDate = useMemo(
    () =>
      inputDate
        ? DateTime.fromFormat(inputDate, DATE_FORMATS_LUXON.INPUT)
        : DateTime.now(),
    [inputDate]
  )

  const getEndDate = useGetEndDateForFinancialInsights(currentDate)

  const dateRange = useMemo(() => {
    return getInsightsDateRange(getEndDate())
  }, [getEndDate])

  useEffect(() => {
    const fetchData = async (range: [string, string]) => {
      const [startDate, endDate] = range
      const data = shouldDisplaySampleData
        ? getExampleExpenseChartData()
        : await fetchInsightsBarchartData(type, startDate, endDate)(dispatch)
      setResponseData(data)
    }
    if (dateRange) {
      fetchData(dateRange)
    }
  }, [dispatch, dateRange, type, shouldDisplaySampleData])

  const { chartCategories, topCategories } = useMemo(() => {
    if (!responseData)
      return {
        chartCategories: null,
        topCategories: [],
      }
    return getCategoryData(responseData)
  }, [responseData])

  const filteredData = useMemo(() => {
    if (!responseData) return []

    return filterChartAPIResponse(responseData, filter, topCategories)
  }, [responseData, filter, topCategories])

  const filteredChartCategories = useMemo(() => {
    if (!chartCategories) return {}
    return filterChartCategories(chartCategories, filter, topCategories)
  }, [chartCategories, filter, topCategories])

  const chartData = useMemo(() => {
    return processDataForChart(filteredData, dateRange, type)
  }, [filteredData, dateRange, type])

  const dropdownOptions = useMemo(() => {
    const options = [...insightsTrackDefaultFilters[type]]
    if (!chartCategories) return options
    Object.entries(chartCategories).forEach(([identifier, category]) => {
      options.push({
        text: category.category_name,
        value: identifier,
      })
    })
    return options
  }, [chartCategories, type])

  const dateRangeString = useMemo(() => {
    if (!dateRange) return ''
    const [startDate, endDate] = dateRange
    const start = DateTime.fromFormat(
      startDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    const end = DateTime.fromFormat(
      endDate,
      DATE_FORMATS_LUXON.TIMESTAMP
    ).toFormat(DATE_FORMATS_LUXON.MONTH_YEAR)
    return `Last 12 months (${start} - ${end})`
  }, [dateRange])

  const queryLoading = useReselector(selectIsFetchingForKeys, [
    FETCH_INSIGHTS_BARCHART_DATA_KEY,
  ])

  const insightsBarchartDataError = useReselector(
    getFetchError,
    FETCH_INSIGHTS_BARCHART_DATA_KEY
  )

  const isChartDataEmpty = isEmpty(filteredData)

  return (
    <>
      <Grid.Row verticalAlign="middle">
        <Grid.Column
          computer={8}
          tablet={7}
          mobile={16}
          style={isMobile ? { marginBottom: 12 } : undefined}
        >
          <Text as="h2">
            {insightsCopyConstants[type].trackPageTitle}{' '}
            {shouldDisplaySampleData && (
              <Label color="orange" style={{ borderRadius: 5 }}>
                Sample data
              </Label>
            )}
          </Text>
        </Grid.Column>
        <Grid.Column
          computer={4}
          tablet={5}
          mobile={16}
          style={isMobile ? { marginBottom: 12 } : undefined}
        >
          <Text as="bodySm"> {dateRangeString} </Text>
        </Grid.Column>
        <Grid.Column
          computer={4}
          tablet={4}
          mobile={16}
          style={{ minWidth: 180, marginBottom: isMobile ? 12 : undefined }}
        >
          {type !== INSIGHTS_TYPE.PROFIT && (
            <Dropdown
              variant="checked"
              value={filter}
              options={dropdownOptions}
              onChange={setFilter}
              dividerAfter={type === INSIGHTS_TYPE.EXPENSES ? [1] : [0]}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn className="short">
        <FreeTrialUnreviewedAlert />
      </GridRowColumn>
      {queryLoading ? (
        <GridRowColumn>
          <Loader loading />
        </GridRowColumn>
      ) : (
        <GridRowColumn className="short">
          {insightsBarchartDataError ? (
            <GridRowColumn>
              <Grid>
                <GridRowColumn style={{ textAlign: 'center' }}>
                  <Text as="h3">Something Went Wrong</Text>
                </GridRowColumn>
                <GridRowColumn style={{ textAlign: 'center' }}>
                  <Text color="darkGray">Please try again in a bit.</Text>
                </GridRowColumn>
              </Grid>
            </GridRowColumn>
          ) : (
            <InsightsChart
              data={chartData}
              categories={chartCategories || {}}
              filteredCategories={filteredChartCategories || {}}
              filter={filter}
              isChartDataEmpty={isChartDataEmpty}
              type={type}
            />
          )}
        </GridRowColumn>
      )}
    </>
  )
}
