import { useCallback, useMemo, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { Button, Link, Modal, Popup, Table } from '../BaseComponents'
import moment from 'moment-timezone'
import { sortBy } from 'lodash'

import {
  editAllocationGuide,
  fetchAllocationGuidesSummary,
} from '../../actions/allocationGuideActions'
import { AllocationGuide } from '../../reducers/finances/allocationGuidesReducer'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import { useAnalyticsTrack } from '../../features/Amplitude'
import { useAppDispatch } from '../../utils/typeHelpers'

const AllocationGuidesTable = ({
  allocationGuides,
}: {
  allocationGuides: { [key: number]: AllocationGuide }
}) => {
  const dispatch = useAppDispatch()
  const [selectedId, setSelectedId] = useState<number>()

  const track = useAnalyticsTrack()

  const handleUpdateAllocationGuide = useCallback(async () => {
    if (!selectedId) {
      return
    }
    track('submitted allocation guide update')
    await editAllocationGuide(selectedId)(dispatch)
    await fetchAllocationGuidesSummary()(dispatch)
    setSelectedId(undefined)
  }, [dispatch, selectedId, track])

  const handleUpdate = useCallback(
    (id: number, date: string) => {
      const dateMoment = moment(date)
      setSelectedId(id)
      track('clicked allocation guide update', {
        report_month: dateMoment.month(),
        report_year: dateMoment.year(),
      })
    },
    [track]
  )

  const tableBody = useMemo(() => {
    if (Object.keys(allocationGuides).length === 0) {
      return (
        <Table.Row textAlign="center">
          <Table.Cell colSpan={4}>None yet</Table.Cell>
        </Table.Row>
      )
    } else {
      return sortBy(Object.values(allocationGuides), 'date')
        .reverse()
        .map(({ id, date, compiledOn }) => (
          <Table.Row key={id}>
            <Table.Cell>
              {moment(date).format(DATE_FORMATS.MONTH_YEAR)}
            </Table.Cell>
            <Table.Cell>
              {moment(compiledOn).format(DATE_FORMATS.DISPLAY_LONG)}
            </Table.Cell>
            <Table.Cell>
              <Link to={`/allocation-guides/view/${id}`} size="small">
                View Guide
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Button onClick={() => handleUpdate(id, date)}>Update</Button>
            </Table.Cell>
          </Table.Row>
        ))
    }
  }, [allocationGuides, handleUpdate])

  return (
    <div className="allocationList">
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Month</Table.HeaderCell>
            <Table.HeaderCell width={6}>Date Compiled</Table.HeaderCell>
            <Table.HeaderCell width={4}>Action</Table.HeaderCell>
            <Table.HeaderCell width={4}>
              Update
              <Popup
                content="Clicking Update will change the allocated amounts of this guide based on the allocation percentages you currently have set"
                hoverable
                style={{ margin: '0 0 0 -4px' }}
                trigger={
                  <Icon
                    name="info circle"
                    style={{
                      cursor: 'pointer',
                      margin: '0 0 0 12px',
                    }}
                  />
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{tableBody}</Table.Body>

        <Modal size="small" dimmer="inverted" open={Boolean(selectedId)}>
          <Modal.Header>Confirm Allocation Guide Update</Modal.Header>
          <Modal.Content>
            Please confirm that you would like to make changes to the selected
            allocation guide. Changes will be based on the currently set
            percentages. Would you like to continue?
          </Modal.Content>
          <Modal.Actions>
            <Button
              variant="secondary"
              onClick={() => setSelectedId(undefined)}
            >
              Cancel
            </Button>
            <Button onClick={handleUpdateAllocationGuide}>
              Confirm Changes
            </Button>
          </Modal.Actions>
        </Modal>
      </Table>
    </div>
  )
}

export default AllocationGuidesTable
