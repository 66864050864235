export enum FontWeight {
  REGULAR = 400,
  MEDIUM = 500,
  SEMIBOLD = 600,
}

export const Colors = {
  // primary colors
  green: '#226752',
  white: '#ffffff',

  // secondary colors
  black: '#1d1d1d',
  forest: '#374941',
  stone: '#f2f2ef',
  stone40: '#f2f2ef66',
  stone40OnWhiteNoAlpha: '#fafaf9',
  natural: '#fcf8f2',

  // tertiary colors
  moss: '#cbd5ca',
  sunrise: '#fae3c3',
  blush: '#f0e7d5',
  oak: '#656a47',
  greenBlue: '#8AD2BC',
  yellowOrange: '#F2D891',

  // neutral colors
  charcoal: '#40403d',
  darkGray: '#6F6F6B',
  mediumGray: '#90908d',
  gray: '#c9c9c5',
  lightGray: '#e1e1dd',

  // accent
  blue: '#4d6ca4',
  navy: '#5c5f9d',
  purple: '#764c89',
  red: '#b95042',
  magenta: '#b54775',
  orange: '#ac590c',
  yellow: '#936d0d',
  teal: '#2f7d7d',
  champagne: '#faeecd',
  accentGreen: '#457634',
  accentRed: '#FFE0E0',
  lightBlue: '#f3f6ff',
  lightNavy: '#f0eeff',
  lightPurple: '#f8f2ff',
  lightRed: '#fff3f3',
  lightMagenta: '#fff3f6',
  lightOrange: '#fbf3ea',
  lightYellow: '#fffaea',
  lightGreen: '#f1fef5',
  lightTeal: '#f0feff',

  // Other
  transparent: 'transparent',
}

export const Fonts = {
  display: {
    fontSize: 48,
    lineHeight: '120%',
    fontWeight: FontWeight.REGULAR,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  display2: {
    fontSize: 32,
    lineHeight: '124%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  display3: {
    fontSize: 20,
    lineHeight: '124%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  h1: {
    fontSize: 26,
    lineHeight: '124%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  h2: {
    fontSize: 20,
    lineHeight: '124%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  h3: {
    fontSize: 16,
    lineHeight: '124%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Werksatz!important',
    color: Colors.black,
  },
  // todo all of the body sizes are off of design but this will be a large lift to fix
  bodyLg: {
    // todo should be 16
    fontSize: 18,
    lineHeight: '155%',
    fontWeight: FontWeight.REGULAR,
    fontFamily: 'Inter!important',
  },
  bodyMd: {
    // todo should be 14
    fontSize: 16,
    lineHeight: '148%',
    fontWeight: FontWeight.REGULAR,
    fontFamily: 'Inter!important',
  },
  bodySm: {
    // todo should be 12
    fontSize: 14,
    lineHeight: '140%',
    fontWeight: FontWeight.REGULAR,
    fontFamily: 'Inter!important',
  },
  // todo this style does not exist in the design. Remove it once bodySm font is fixed
  bodyXs: {
    fontSize: 12,
    lineHeight: '140%',
    fontWeight: FontWeight.REGULAR,
    fontFamily: 'Inter!important',
  },
  buttonLg: {
    fontSize: '16px',
    lineHeight: '100%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
  buttonMd: {
    fontSize: '14px',
    lineHeight: '100%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
  buttonSm: {
    fontSize: '12px',
    lineHeight: '100%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
  eyebrow: {
    fontSize: 12,
    lineHeight: '140%',
    fontWeight: FontWeight.SEMIBOLD,
    textTransform: 'uppercase' as const,
    fontFamily: 'Werksatz!important',
    letterSpacing: '.03em',
    color: Colors.black,
  },
  link: {
    fontSize: '16px',
    lineHeight: '155%',
    fontWeight: FontWeight.MEDIUM,
    color: Colors.green,
    fontFamily: 'Inter!important',
  },
  secondaryLink: {
    fontSize: 16,
    lineHeight: '155%',
    fontWeight: FontWeight.REGULAR,
    textDecorationLine: 'underline',
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
  actionLink: {
    fontSize: 16,
    lineHeight: '120%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
  actionLinkMd: {
    fontSize: 14,
    lineHeight: '120%',
    fontWeight: FontWeight.MEDIUM,
    fontFamily: 'Inter!important',
    color: Colors.black,
  },
}
