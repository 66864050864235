import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import {
  Container,
  Header,
  Dimmer,
  Loader,
  Menu,
  Button,
  Divider,
  Tab,
  Label,
} from 'semantic-ui-react'

import PracticeInformationCard from './PracticeInformationCard'
import UserTransactionsCard from './UserTransactionsCard'
import BookkeepingReportsCard from './BookkeepingReportsCard'
import FinancialAccountsCard from './FinancialAccountsCard'
import TaxTabPanel from '../../../features/Admin/UserRecord/TaxTabPanel'
import PayrollTabPanel from '../../../features/Admin/UserRecord/payroll/PayrollTabPanel'
import AllocationGuidesCard from './AllocationGuidesCard'
import UserActionItemsPanel from './UserActionItemsPanel'
import {
  adminIsUserNoServiceFreeTrial,
  getUserById,
  selectAdminUserState,
} from '../../../selectors/user.selectors'
import { adminFetchSingleUser } from '../../../actions/admin/adminAllUsersActions'
import { fetchTransactionCategoriesIfNeeded } from '../../../features/Reports/reports.slice'
import { fetchAllTransactionRulesIfNeeded } from '../../../actions/admin/transactionRuleActions'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../features/Admin/UserDocumentCategories/userDocumentCategories.slice'

import SmartRuleCreation from '../../../features/Admin/SmartRuleCreation'
import './UserRecord.scss'
import Documents from '../../../features/Admin/Documents'
import UserBreadcrumbs from './UserBreadcrumbs'
import AdminUserCallsCard from '../../../features/Checkins/Admin/AdminUserCallsCard'
import FinancialReportsCard from './FinancialReportsCard'
import FinancialAccountReconciliationsCard from './FinancialAccountReconciliationsCard'
import AccountDetailsTabPanel from '../../../features/Admin/UserRecord/AccountDetailsTabPanel'
import Pill from '../../BaseComponents/Pill'
import { fetchAdminUsersDocuments } from '../../../actions/admin/userDocumentsActions'
import { useReselector } from '../../../utils/sharedHooks'
import UserRecordNotes from './UserRecordNotes'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { selectNumberUserDocumentsNeedsReview } from '../../../features/UserDocuments/userDocuments.selector'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectAdminAnnualTaxFilingsByYearForUser } from '../../../features/Admin/Taxes/adminAnnualTaxFilings.selector'
import { fetchAdminAnnualTaxFilings } from '../../../features/Admin/Taxes/adminAnnualTaxFilings.slice'
import SCorpPanel from '../../../features/Admin/UserRecord/SCorpPanel'
import UserAccountCanceledAlert from './UserAccountStates/UserAccountCanceledAlert'
import UserStateLabel from './UserAccountStates/UserStateLabel'
import { useAppDispatch } from '../../../utils/typeHelpers'
import FinancialAdvisoryPanel from '../../../features/Admin/UserRecord/FinancialAdvisoryPanel'
import SubmissionsPanel from '../../../features/Bookkeeping/end-of-year-review/admin/submissions-panel'
import { UpdatesDisabledPage } from '../../../features/Transactions/UpdatesDisabledPage'
import { fetchTransactionsUpdatesDisabled } from '../../../actions/admin/transactionActions'
import AdminUserCouponAssignment from '../../../features/UserCoupons/AdminUserCouponAssignment'

const UserRecordHeaderTaxJoinerStatus = ({
  user,
}: {
  user: UserWithAdminInfo
}) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const pageLimitAndLengthForSingleUser = 1

  useEffect(() => {
    fetchAdminAnnualTaxFilings({
      page: pageLimitAndLengthForSingleUser,
      limit: pageLimitAndLengthForSingleUser,
      year: taxYear,
      userIds: user.id,
    })(dispatch)
  }, [dispatch, taxYear, user.id])

  const annualTaxFiling = useReselector(
    selectAdminAnnualTaxFilingsByYearForUser,
    taxYear,
    user.id
  )

  if (annualTaxFiling?.isLateJoiner) {
    return <Label color="red">{Number(taxYear) + 1} Late Joiner</Label>
  } else {
    return null
  }
}
const UserRecordHeader = ({ user }: { user: UserWithAdminInfo }) => {
  const userState = useReselector(selectAdminUserState, user.id)

  if (!userState) {
    return null
  }

  return (
    <Menu secondary className="userRecordHeader">
      <Menu.Item>
        <Link to="/admin/finances/list-users">
          <Button circular icon="arrow left" />
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Header as="h3" {...(userState === 'unpaid' && { color: 'red' })}>
          {user?.firstName} {user?.lastName} <UserStateLabel user={user} />
          <UserRecordHeaderTaxJoinerStatus user={user} />
        </Header>
      </Menu.Item>

      <Menu.Menu position="right" />
    </Menu>
  )
}

const UserInfoPanel = ({ user }: { user: UserWithAdminInfo }) => (
  <div className="clientRecordPanel left">
    <PracticeInformationCard user={user} />
    <UserRecordNotes userId={user.id} />
    <BookkeepingReportsCard user={user} />
    <AllocationGuidesCard user={user} />
    <FinancialAccountsCard user={user} />
    <UserTransactionsCard user={user} />
    <AdminUserCallsCard userId={user.id} />
    <FinancialAccountReconciliationsCard userId={user.id} />
  </div>
)

const UserRecord = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [updatesDisabled, setUpdatesDisabled] = useState(false)
  const { userId: userIdString } = useParams<{ userId: string }>()
  const [searchParams] = useSearchParams()
  const userId = Number(userIdString)
  const user = useReselector(getUserById, userId)
  const isFreeTrial = useReselector(adminIsUserNoServiceFreeTrial, user?.id)
  const numNeedsReview = useReselector(
    selectNumberUserDocumentsNeedsReview,
    userId
  )

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(adminFetchSingleUser(userId)),
        dispatch(fetchTransactionCategoriesIfNeeded()),
        dispatch(fetchAllTransactionRulesIfNeeded()),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        dispatch(fetchAdminUsersDocuments(userId)),
      ])
      const transactionsDisabled = await dispatch(
        fetchTransactionsUpdatesDisabled(userId)
      )
      if (transactionsDisabled !== false && transactionsDisabled?.message) {
        setUpdatesDisabled(true)
      }

      setLoading(false)
    }

    fetch()
  }, [dispatch, userId])

  if (updatesDisabled) {
    return <UpdatesDisabledPage />
  }

  return (
    <Container id="userRecord">
      <UserBreadcrumbs userId={userId} />
      <Divider />

      {user && <UserRecordHeader user={user} />}
      {(loading || !user) && (
        <Dimmer active inverted>
          <Loader>Fetching</Loader>
        </Dimmer>
      )}
      {!loading && user && <UserAccountCanceledAlert user={user} />}
      {!loading && user && (
        <Tab
          menu={{ secondary: true, pointing: true }}
          defaultActiveIndex={searchParams.get('tab') === 'documents' ? 5 : 0}
          panes={[
            {
              menuItem: 'User Record',
              render: () => <UserInfoPanel user={user} />,
            },
            {
              menuItem: 'Quarterly Tax Estimates',
              render: () => (
                <div className="splitPanelWrapper">
                  <TaxTabPanel userId={userId} />
                </div>
              ),
            },
            {
              menuItem: 'Action Items',
              render: () => (
                <div className="splitPanelWrapper">
                  <UserActionItemsPanel user={user} />
                </div>
              ),
            },
            {
              menuItem: 'Transaction Rules',
              render: () => (
                <div className="splitPanelWrapper">
                  <SmartRuleCreation user={user} />
                </div>
              ),
            },
            {
              menuItem: 'Financial Statements',
              render: () => (
                <div className="splitPanelWrapper">
                  <FinancialReportsCard
                    userId={user.id}
                    isFreeTrial={isFreeTrial}
                  />
                </div>
              ),
            },
            {
              menuItem: (
                <Menu.Item key="documents">
                  Documents <Pill>{numNeedsReview}</Pill>
                </Menu.Item>
              ),
              render: () => (
                <div className="splitPanelWrapper">
                  <Documents user={user} />
                </div>
              ),
            },
            {
              menuItem: 'Payroll',
              render: () => (
                <div className="splitPanelWrapper">
                  <PayrollTabPanel userId={userId} />
                </div>
              ),
            },
            {
              menuItem: 'Account Details',
              render: () => (
                <div className="splitPanelWrapper">
                  <AccountDetailsTabPanel
                    userId={userId}
                    isFreeTrial={isFreeTrial}
                  />
                </div>
              ),
            },
            {
              menuItem: 'Submissions',
              render: () => (
                <div className="splitPanelWrapper">
                  <SubmissionsPanel userId={userId} />
                </div>
              ),
            },
            {
              menuItem: 'Miscellaneous',
              render: () => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  <div className="splitPanelWrapper">
                    <SCorpPanel userId={userId} />
                  </div>
                  <div className="splitPanelWrapper">
                    <FinancialAdvisoryPanel userId={userId} />
                  </div>
                  <div className="splitPanelWrapper">
                    <AdminUserCouponAssignment userId={userId} />
                  </div>
                </div>
              ),
            },
          ]}
        />
      )}
    </Container>
  )
}

export default UserRecord
