import { useCallback, useEffect } from 'react'
import { Document } from './types'
import { UserDocument } from '../../UserDocuments/userDocuments.slice'
import { IsMarkedNotRelevantFunction } from './not-relevant-documents'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
  fetchUserYearEndModuleStatuses,
} from '../../YearEndModuleStatus/yearEndModuleStatus.slice'

export const useDocuments = ({
  stepContextDocuments = [],
  userDocuments = [],
}: {
  stepContextDocuments?: Document[]
  userDocuments: UserDocument[]
}) => {
  const filterDocuments = useCallback(
    (categoryInternalName: string, provider: string, documentName?: string) => {
      const uploadedIds =
        stepContextDocuments
          ?.filter(
            (doc) =>
              doc.categoryCode === categoryInternalName &&
              doc.provider === provider &&
              doc.name === documentName &&
              !doc.notRelevant
          )
          .map((doc) => doc.documentId) ?? []
      return userDocuments.filter((doc) => uploadedIds.includes(doc.id))
    },
    [stepContextDocuments, userDocuments]
  )

  const getDocument = useCallback(
    (categoryInternalName: string, provider: string, documentName?: string) => {
      const uploadedDoc = stepContextDocuments?.find(
        (doc) =>
          doc.categoryCode === categoryInternalName &&
          doc.provider === provider &&
          doc.name === documentName
      )
      return (
        uploadedDoc ?? {
          categoryCode: categoryInternalName,
          provider,
          name: documentName,
        }
      )
    },
    [stepContextDocuments]
  )

  const getStatus = useCallback(
    (
      categoryInternalName: string,
      provider: string,
      isMarkedNotRelevant?: IsMarkedNotRelevantFunction,
      documentName?: string
    ) => {
      const hasDocs =
        filterDocuments(categoryInternalName, provider, documentName).length > 0
      const notRelevant = isMarkedNotRelevant?.(
        categoryInternalName,
        provider,
        documentName
      )
      return hasDocs || notRelevant ? 'success' : 'none'
    },
    [filterDocuments]
  )

  return {
    filterDocuments,
    getStatus,
    getDocument,
  }
}

export const useYearEndBookkeepingValidation = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const validateAccess = async () => {
      try {
        const moduleStatusesResponse =
          await fetchUserYearEndModuleStatuses()(dispatch)

        const bookkeepingModule =
          moduleStatusesResponse?.yearEndModuleStatuses?.find(
            (module) => module.moduleType === YearEndModuleType.eoyBookkeeping
          )
        const bookkeepingFollowupModule =
          moduleStatusesResponse?.yearEndModuleStatuses?.find(
            (module) =>
              module.moduleType === YearEndModuleType.eoyBookkeepingFollowup
          )
        const validStatuses = [
          YearEndModuleStatusOptions.inProgress,
          YearEndModuleStatusOptions.upNext,
          YearEndModuleStatusOptions.actionRequired,
          YearEndModuleStatusOptions.overdue,
        ]

        if (
          !bookkeepingModule?.status ||
          !bookkeepingFollowupModule?.status ||
          (bookkeepingModule.status !== YearEndModuleStatusOptions.complete &&
            !validStatuses.includes(bookkeepingModule.status)) ||
          (bookkeepingModule.status === YearEndModuleStatusOptions.complete &&
            !validStatuses.includes(bookkeepingFollowupModule.status))
        ) {
          navigate('/taxes/annual')
          return
        }
      } catch (error) {
        console.error('Error validating year-end bookkeeping access:', error)
        navigate('/taxes/annual')
      }
    }

    validateAccess()
  }, [dispatch, navigate])
}
