import currency from 'currency.js'
import { useFormik, FormikProvider } from 'formik'
import { isEmpty, isEqual } from 'lodash'
import { useMemo, useCallback, useState, useEffect } from 'react'
import { Container, Divider, Grid } from 'semantic-ui-react'

import { UPDATE_FINANCIAL_PROFILE_KEY } from '../../../../actions/financialProfileActions'
import { UPDATE_USER_KEY } from '../../../../actions/userActions'
import {
  GridRowColumn,
  FormikInput,
  getFieldName,
  getFieldNames,
  makeNumberSchema,
  FormikLabelError,
  makeReqStringSchema,
  FormikRadioToggleButton,
  makeReqBoolSchema,
  FormikDropdown,
  makeStringArraySchema,
  Card,
  Popup,
  Text,
  Button,
} from '../../../../components/BaseComponents'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import {
  invalidateFetch,
  selectIsFetchingForKeys,
} from '../../../../reducers/fetch'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import {
  centsToDollars,
  dollarsToCents,
  formatCurrency,
} from '../../../../utils/currencyHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { FETCH_ALL_ANNUAL_TAX_DETAILS_KEY } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { FETCH_DOCUMENT_CATEGORIES_KEY } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import {
  selectSortedTaxReturnDocumentsForYear,
  selectUserTaxDocumentsForCategory,
} from '../../../UserDocuments/userDocuments.selector'
import { FETCH_USER_DOCUMENTS_KEY } from '../../../UserDocuments/userDocuments.slice'
import {
  FORM_1040_DETAILS_SCREENS,
  Form1040DetailsProps,
} from '../TaxChecklist/Form1040/Details'
import ReadOnlyForm from '../TaxChecklist/Shared/ReadOnlyForm'
import { TaxListQuestionId, TaxListOptionId } from '../TaxChecklist/service'
import {
  selectIsMarriedFilingJointlyOrQualifyingWidow,
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../TaxChecklist/taxChecklist.selectors'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  postUpdateTaxQuestionnaireResponses,
} from '../TaxChecklist/taxChecklistQuestion.actions'
import {
  TaxChecklistResponse,
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistResponseValue,
} from '../TaxChecklist/taxChecklistQuestion.slice'
import {
  AnnualTaxFilingForm,
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
} from '../annualTaxFilingForms.slice'
import { EXTENSION_REQUEST_SCREENS } from './Combined'
import UploadCard from '../TaxChecklist/Documents/UploadCard'
import { LabelDescription } from '../../../../components/BaseComponents/Input'
import {
  fetchAnnualTaxFilingsIfNeeded,
  updateAnnualTaxFilings,
} from '../annualTaxFilings.slice'
import { getAnnualTaxFilingById } from '../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { select1040FormForYear } from '../annualTaxFilingForms.selector'
import { convertUtcToLocalDate } from '../../../../utils/dateHelpers'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'

export const useBusinessIncome = ({
  isUpdating,
  filingForm1120s,
  forCombinedFlow,
}: {
  isUpdating: boolean
  filingForm1120s?: AnnualTaxFilingForm
  forCombinedFlow?: boolean
}) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const user = useReselector(getCurrentUser)
  const allFetchKeys = useMemo(
    () => [
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      UPDATE_USER_KEY,
      UPDATE_FINANCIAL_PROFILE_KEY(user?.financialProfile?.id),
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      FETCH_USER_DOCUMENTS_KEY,
      FETCH_DOCUMENT_CATEGORIES_KEY,
    ],
    [taxYear, user?.financialProfile?.id]
  )
  const questionEstimatedBusinessIncome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_income_in_cents,
    taxYear
  )
  const responseEstimatedBusinessIncomeInCents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_income_in_cents,
    filingForm1120s?.id
  )
  const questionBusinessCalendarType = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.business_calendar_type,
    taxYear
  )
  const responseBusinessCalendarType = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.business_calendar_type,
    filingForm1120s?.id
  )
  const questionBusinessPrevYearReturn = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.previous_year_business_tax_return,
    taxYear
  )
  const responseBusinessPrevYearReturn = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.previous_year_business_tax_return,
    filingForm1120s?.id
  )

  const prevYearBizReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).business
  const questionMultiStateBusinessStates = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.multi_state_business_states,
    taxYear
  )
  const responseMultiStateBusinessStates = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.multi_state_business_states,
    filingForm1120s?.id
  )
  // Derived TF responses
  const responseNumberStatesConductedBiz = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_states_conducted_business,
    filingForm1120s?.id
  )
  const govIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.officialGovernmentId,
    taxYear
  )

  const dropdownOptions = questionMultiStateBusinessStates?.options?.map(
    (o) => ({
      text: o.text,
      value: o.id,
      key: o.id,
    })
  )

  const calendarYearOption = questionBusinessCalendarType.options.find(
    (option) => option.id === TaxListOptionId.calendar_year
  )
  const fiscalYearOption = questionBusinessCalendarType.options?.find(
    (option) => option.id === TaxListOptionId.fiscal_year
  )

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse>[] | null,
      _newScreen: FORM_1040_DETAILS_SCREENS | EXTENSION_REQUEST_SCREENS | null
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))
      if (data?.length) {
        const res = await dispatch(postUpdateTaxQuestionnaireResponses(data))
        if (res) {
          return true
        }
      } else {
        return true
      }
      return false
    },
    [dispatch, allFetchKeys]
  )

  const isInitialValidBusinessIncome = Boolean(
    responseEstimatedBusinessIncomeInCents?.[0]?.value &&
      responseBusinessCalendarType?.[0]?.value &&
      (forCombinedFlow ? true : responseMultiStateBusinessStates?.[0]?.value) &&
      responseBusinessPrevYearReturn?.[0]?.value !== null &&
      (responseBusinessPrevYearReturn?.[0]?.value
        ? !isEmpty(prevYearBizReturns)
        : true)
  )

  const businessIncomeFormik = useFormik({
    initialValues: {
      estimatedBusinessIncome:
        typeof responseEstimatedBusinessIncomeInCents?.[0]?.value === 'number'
          ? centsToDollars(responseEstimatedBusinessIncomeInCents[0].value)
          : undefined,
      businessCalendarType: responseBusinessCalendarType?.[0]?.value,
      hadPreviousYearReturnBiz: responseBusinessPrevYearReturn?.[0]?.value,
      multiStateBusinessStates: responseMultiStateBusinessStates?.[0]?.value,
    },
    isInitialValid: isInitialValidBusinessIncome,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.estimatedBusinessIncome &&
        dollarsToCents(currency(values.estimatedBusinessIncome)) !==
          responseEstimatedBusinessIncomeInCents?.[0]?.value
      ) {
        responseData.push({
          id: responseEstimatedBusinessIncomeInCents?.[0]?.id,
          annualTaxFilingFormId: filingForm1120s?.id,
          questionId: TaxListQuestionId.business_income_in_cents,
          value: dollarsToCents(currency(values.estimatedBusinessIncome)),
        })
      }
      if (
        !isEqual(
          values.businessCalendarType,
          responseBusinessCalendarType?.[0]?.value
        )
      ) {
        responseData.push({
          id: responseBusinessCalendarType?.[0]?.id,
          annualTaxFilingFormId: filingForm1120s?.id,
          questionId: TaxListQuestionId.business_calendar_type,
          value: values.businessCalendarType,
        })
      }
      if (
        values.hadPreviousYearReturnBiz !==
        responseBusinessPrevYearReturn?.[0]?.value
      ) {
        responseData.push({
          id: responseBusinessPrevYearReturn?.[0]?.id,
          annualTaxFilingFormId: filingForm1120s?.id,
          questionId: TaxListQuestionId.previous_year_business_tax_return,
          value: values.hadPreviousYearReturnBiz,
        })
      }
      if (
        !forCombinedFlow &&
        values.multiStateBusinessStates !==
          responseMultiStateBusinessStates?.[0]?.value
      ) {
        responseData.push({
          id: responseMultiStateBusinessStates?.[0]?.id,
          annualTaxFilingFormId: filingForm1120s?.id,
          questionId: TaxListQuestionId.multi_state_business_states,
          value: values.multiStateBusinessStates,
        })
      }
      if (
        !forCombinedFlow &&
        Array.isArray(values.multiStateBusinessStates) &&
        values.multiStateBusinessStates.length !==
          responseNumberStatesConductedBiz?.[0]?.value
      ) {
        responseData.push({
          id: responseNumberStatesConductedBiz?.[0]?.id,
          annualTaxFilingFormId: filingForm1120s?.id,
          questionId: TaxListQuestionId.number_states_conducted_business,
          value: values.multiStateBusinessStates.length,
        })
      }
      return await saveFormData(responseData, null)
    },
  })

  const { values, validateForm, initialValues } = businessIncomeFormik
  const isPrevYear1120sUploadedOrNotRequired =
    !isEmpty(prevYearBizReturns) ||
    responseBusinessPrevYearReturn?.[0]?.value === false
  const [editBusinessIncome, setEditBusinessIncome] = useState(
    !businessIncomeFormik.isValid
  )

  const readOnlyBusinessIncome = useMemo(() => {
    const defaultItems: {
      question: TaxChecklistQuestion | null
      options: TaxChecklistQuestionOption[]
      responses: TaxChecklistResponse[]
      responseText?: TaxChecklistResponseValue
      questionOverride?: string
    }[] = [
      {
        ...questionEstimatedBusinessIncome,
        responseText: formatCurrency(
          currency(initialValues.estimatedBusinessIncome ?? 0)
        ),
      },
      {
        ...questionBusinessCalendarType,
        responseText:
          questionBusinessCalendarType.options.find(
            (option) => option.id === initialValues.businessCalendarType
          )?.text ?? initialValues.businessCalendarType,
      },
      {
        ...questionBusinessPrevYearReturn,
        responseText: initialValues.hadPreviousYearReturnBiz ? 'Yes' : 'No',
      },
      {
        questionOverride: `Your ${Number(taxYear) - 1} Business Tax Return`,
        responseText: !isEmpty(prevYearBizReturns)
          ? prevYearBizReturns.map((doc) => doc.fileDescription).join(', ')
          : 'Not Uploaded',
        question: null,
        options: [],
        responses: [],
      },
    ]
    if (!forCombinedFlow) {
      defaultItems.push({
        ...questionMultiStateBusinessStates,
        responseText: Array.isArray(initialValues.multiStateBusinessStates)
          ? initialValues.multiStateBusinessStates.join(', ')
          : initialValues.multiStateBusinessStates,
      })
      defaultItems.push({
        questionOverride: `Your ${taxYear} Official Government ID`,
        responseText: !isEmpty(govIds)
          ? govIds.map((doc) => doc.fileDescription).join(', ')
          : 'This is required. Please upload.',
        question: null,
        options: [],
        responses: [],
      })
    }
    return defaultItems
  }, [
    questionEstimatedBusinessIncome,
    initialValues.estimatedBusinessIncome,
    initialValues.businessCalendarType,
    initialValues.hadPreviousYearReturnBiz,
    initialValues.multiStateBusinessStates,
    questionBusinessCalendarType,
    questionBusinessPrevYearReturn,
    taxYear,
    prevYearBizReturns,
    forCombinedFlow,
    questionMultiStateBusinessStates,
    govIds,
  ])

  const businessIncomeContent = (
    <FormikProvider value={businessIncomeFormik}>
      <Container text textAlign="left">
        <Grid>
          {/* READY ONLY BUSINESS INCOME FORM */}
          {!editBusinessIncome && (
            <GridRowColumn>
              <ReadOnlyForm
                title="Estimate Business Taxes"
                setEditing={() => setEditBusinessIncome(true)}
                readOnlyData={readOnlyBusinessIncome}
              />
            </GridRowColumn>
          )}
          {/* EDITABLE BUSINESS INCOME FORM */}
          {editBusinessIncome && (
            <GridRowColumn>
              <Card type="subsection" backgroundColor="stone40">
                <Text as="h2">Estimate Business Taxes</Text>
                <Divider />
                {!forCombinedFlow && (
                  <GridRowColumn
                    {...makeGridConfig([8, 14], true)}
                    style={{ marginBottom: 24 }}
                  >
                    <Text>
                      Your tax preparer will need the following in order to
                      provide an estimated tax payment amount for {taxYear}.
                    </Text>
                  </GridRowColumn>
                )}
                <GridRowColumn {...makeGridConfig([8, 14], true)}>
                  <Grid>
                    <GridRowColumn style={{ justifyContent: 'center' }}>
                      <FormikInput
                        componentType="currency"
                        name={getFieldName<typeof values>(
                          'estimatedBusinessIncome'
                        )}
                        label={questionEstimatedBusinessIncome.question?.text}
                        schema={makeNumberSchema({
                          allowedDecimals: 2,
                        })}
                        fullWidth
                        placeholder="$0.00"
                      />
                    </GridRowColumn>
                    <GridRowColumn style={{ justifyContent: 'center' }}>
                      <FormikLabelError
                        name={getFieldName<typeof values>(
                          'businessCalendarType'
                        )}
                        label={questionBusinessCalendarType.question?.text}
                        schema={makeReqStringSchema({
                          field: 'Business Calendar',
                        })}
                      />
                    </GridRowColumn>
                    <Grid.Row
                      className="short"
                      style={{ justifyContent: 'center' }}
                    >
                      <Grid.Column width={8}>
                        <FormikRadioToggleButton
                          fullWidth
                          name={getFieldName<typeof values>(
                            'businessCalendarType'
                          )}
                          value={String(calendarYearOption?.id)}
                          onClick={validateForm}
                        >
                          {calendarYearOption?.text}
                        </FormikRadioToggleButton>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormikRadioToggleButton
                          fullWidth
                          name={getFieldName<typeof values>(
                            'businessCalendarType'
                          )}
                          value={String(fiscalYearOption?.id)}
                          onClick={validateForm}
                        >
                          {fiscalYearOption?.text}
                        </FormikRadioToggleButton>
                      </Grid.Column>
                    </Grid.Row>
                    <GridRowColumn style={{ justifyContent: 'center' }}>
                      <FormikLabelError
                        name={getFieldName<typeof values>(
                          'hadPreviousYearReturnBiz'
                        )}
                        label={questionBusinessPrevYearReturn.question?.text}
                        schema={makeReqBoolSchema()}
                      />
                    </GridRowColumn>
                    <Grid.Row
                      className="short"
                      style={{ justifyContent: 'center' }}
                    >
                      <Grid.Column width={8} style={{ paddingRight: 0 }}>
                        <FormikRadioToggleButton
                          fullWidth
                          name={getFieldName<typeof values>(
                            'hadPreviousYearReturnBiz'
                          )}
                          value
                          onClick={validateForm}
                        >
                          Yes
                        </FormikRadioToggleButton>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormikRadioToggleButton
                          fullWidth
                          name={getFieldName<typeof values>(
                            'hadPreviousYearReturnBiz'
                          )}
                          value={false}
                          onClick={validateForm}
                        >
                          No
                        </FormikRadioToggleButton>
                      </Grid.Column>
                    </Grid.Row>
                    {values.hadPreviousYearReturnBiz && filingForm1120s?.id && (
                      <UploadCard
                        isRequired
                        docCategoryIdentifier={
                          UserDocumentCategoryIdentifier.finalReturnForm1120s
                        }
                        styleOverrides={{
                          skipDescription: true,
                          cardBackgroundColor: 'stone40',
                        }}
                      />
                    )}
                  </Grid>
                </GridRowColumn>
                <GridRowColumn {...makeGridConfig([8, 14], true)}>
                  <Divider section />
                </GridRowColumn>
                {!forCombinedFlow && (
                  <>
                    <GridRowColumn {...makeGridConfig([8, 14], true)}>
                      <FormikDropdown
                        name={getFieldName<typeof values>(
                          'multiStateBusinessStates'
                        )}
                        label={questionMultiStateBusinessStates?.question?.text}
                        options={dropdownOptions}
                        schema={makeStringArraySchema()}
                        multiple
                        clearable
                        fullWidth
                        afterLabel={
                          <Popup
                            content={
                              <Grid>
                                <GridRowColumn>
                                  <Text as="h3">
                                    Registering business in another state
                                  </Text>
                                </GridRowColumn>
                                <GridRowColumn short>
                                  <Text>
                                    Select <b>Yes</b> if you see clients in more
                                    than one state.
                                  </Text>
                                </GridRowColumn>
                              </Grid>
                            }
                          />
                        }
                      />
                    </GridRowColumn>
                    <GridRowColumn
                      {...makeGridConfig([8, 14], true)}
                      style={{ marginTop: 16 }}
                    >
                      <LabelDescription label="Upload documents" />
                      <UploadCard
                        isRequired
                        docCategoryIdentifier={
                          UserDocumentCategoryIdentifier.officialGovernmentId
                        }
                        styleOverrides={{
                          skipDescription: true,
                          cardBackgroundColor: 'stone40',
                        }}
                      />
                    </GridRowColumn>
                    <GridRowColumn {...makeGridConfig([8, 14], true)}>
                      <Divider section />
                    </GridRowColumn>
                  </>
                )}
                <GridRowColumn
                  columnStyle={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 20,
                  }}
                >
                  <Button
                    disabled={!isInitialValidBusinessIncome}
                    onClick={() => {
                      businessIncomeFormik.resetForm()
                      setEditBusinessIncome(false)
                    }}
                    style={{ border: 0 }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={isUpdating || businessIncomeFormik.isSubmitting}
                    variant="secondary"
                    onClick={async () => {
                      const success = await businessIncomeFormik.submitForm()
                      if (success) {
                        setEditBusinessIncome(false)
                      }
                    }}
                    disabled={
                      !businessIncomeFormik.isValid ||
                      isUpdating ||
                      businessIncomeFormik.isSubmitting ||
                      (values.hadPreviousYearReturnBiz &&
                        isEmpty(prevYearBizReturns)) ||
                      (!forCombinedFlow && isEmpty(govIds))
                    }
                  >
                    Save
                  </Button>
                </GridRowColumn>
              </Card>
            </GridRowColumn>
          )}
        </Grid>
      </Container>
    </FormikProvider>
  )

  return {
    businessIncomeContent,
    businessIncomeFormik,
    isPrevYear1120sUploadedOrNotRequired,
    govIds,
    editBusinessIncome,
  }
}

export const usePersonalTaxEstimateQuestions = ({
  goToNextStep,
  taxYear,
}: Form1040DetailsProps) => {
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const needsSpouseInfo = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    taxYear
  )
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  ])

  const questionEstimatedHouseholdIncomeInCents = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.household_income_in_cents,
    taxYear
  )
  const responseEstimatedHouseholdIncomeInCents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.household_income_in_cents,
    form1040?.id
  )
  const responsePersonalPrevYearReturn = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.previous_year_personal_tax_return,
    form1040?.id
  )

  const questionEstimatedHouseholdWithholdingsInCents = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.household_income_withholdings_in_cents,
    taxYear
  )
  const responseEstimatedHouseholdWithholdingsInCents = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.household_income_withholdings_in_cents,
    form1040?.id
  )

  const govIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.officialGovernmentId,
    taxYear
  )
  const spouseGovIds = useReselector(
    selectUserTaxDocumentsForCategory,
    UserDocumentCategoryIdentifier.spouseOfficialGovernmentId,
    taxYear
  )
  const prevYearPersonalReturns = useReselector(
    selectSortedTaxReturnDocumentsForYear,
    (Number(taxYear) - 1).toString()
  ).personal

  const isInitialValid = Boolean(
    typeof responseEstimatedHouseholdIncomeInCents?.[0]?.value === 'number' &&
      typeof responseEstimatedHouseholdWithholdingsInCents?.[0]?.value ===
        'number' &&
      !isEmpty(govIds) &&
      (needsSpouseInfo ? !isEmpty(spouseGovIds) : true) &&
      (responsePersonalPrevYearReturn?.[0]?.value
        ? !isEmpty(prevYearPersonalReturns)
        : true)
  )

  const personalFormik = useFormik({
    initialValues: {
      estimatedHouseholdIncome:
        typeof responseEstimatedHouseholdIncomeInCents?.[0]?.value === 'number'
          ? centsToDollars(responseEstimatedHouseholdIncomeInCents[0].value)
          : undefined,
      estimatedHouseholdWithholdings:
        typeof responseEstimatedHouseholdWithholdingsInCents?.[0]?.value ===
        'number'
          ? centsToDollars(
              responseEstimatedHouseholdWithholdingsInCents[0].value
            )
          : undefined,
      hadPreviousYearReturn: responsePersonalPrevYearReturn?.[0]?.value,
    },
    enableReinitialize: true,
    isInitialValid,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.estimatedHouseholdIncome &&
        dollarsToCents(currency(values.estimatedHouseholdIncome)) !==
          responseEstimatedHouseholdIncomeInCents?.[0]?.value
      ) {
        responseData.push({
          id: responseEstimatedHouseholdIncomeInCents?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.household_income_in_cents,
          value: dollarsToCents(currency(values.estimatedHouseholdIncome)),
        })
      }
      if (
        values.estimatedHouseholdWithholdings &&
        dollarsToCents(currency(values.estimatedHouseholdWithholdings)) !==
          responseEstimatedHouseholdWithholdingsInCents?.[0]?.value
      ) {
        responseData.push({
          id: responseEstimatedHouseholdWithholdingsInCents?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.household_income_withholdings_in_cents,
          value: dollarsToCents(
            currency(values.estimatedHouseholdWithholdings)
          ),
        })
      }
      if (
        values.hadPreviousYearReturn !==
        responsePersonalPrevYearReturn?.[0]?.value
      ) {
        responseData.push({
          id: responsePersonalPrevYearReturn?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.previous_year_personal_tax_return,
          value: values.hadPreviousYearReturn,
        })
      }
      return await goToNextStep(
        responseData,
        EXTENSION_REQUEST_SCREENS.skipNavigation
      )
    },
  })

  const { initialValues } = personalFormik
  const fieldNames = getFieldNames(personalFormik)

  const isPrevYear1040Uploaded = !isEmpty(prevYearPersonalReturns)

  const [editPersonalIncome, seEditPersonalIncome] = useState(
    !personalFormik.isValid
  )

  const readOnlyPersonalIncome = useMemo(() => {
    const defaultItems: {
      question: TaxChecklistQuestion | null
      options: TaxChecklistQuestionOption[]
      responses: TaxChecklistResponse[]
      responseText?: TaxChecklistResponseValue
      questionOverride?: string
    }[] = [
      {
        ...questionEstimatedHouseholdIncomeInCents,
        responseText: formatCurrency(
          currency(initialValues.estimatedHouseholdIncome ?? 0)
        ),
      },
      {
        ...questionEstimatedHouseholdWithholdingsInCents,
        responseText: formatCurrency(
          currency(initialValues.estimatedHouseholdWithholdings ?? 0)
        ),
      },
      {
        questionOverride: `Your ${Number(taxYear) - 1} Personal Tax Return`,
        responseText: isEmpty(prevYearPersonalReturns)
          ? "I don't have this"
          : prevYearPersonalReturns
              .map((doc) => doc.fileDescription)
              .join(', '),
        question: null,
        options: [],
        responses: [],
      },
      {
        questionOverride: 'Official Government ID',
        responseText: govIds.map((doc) => doc.fileDescription).join(', '),
        question: null,
        options: [],
        responses: [],
      },
    ]
    if (needsSpouseInfo) {
      defaultItems.push({
        questionOverride: 'Spouse Official Government ID',
        responseText: spouseGovIds.map((doc) => doc.fileDescription).join(', '),
        question: null,
        options: [],
        responses: [],
      })
    }
    return defaultItems
  }, [
    govIds,
    initialValues.estimatedHouseholdIncome,
    initialValues.estimatedHouseholdWithholdings,
    needsSpouseInfo,
    prevYearPersonalReturns,
    questionEstimatedHouseholdIncomeInCents,
    questionEstimatedHouseholdWithholdingsInCents,
    spouseGovIds,
    taxYear,
  ])

  const personalTaxEstimateContent = (
    <FormikProvider value={personalFormik}>
      <Container text textAlign="left">
        <Grid>
          {/* READY ONLY PERSONAL INCOME FORM */}
          {!editPersonalIncome && (
            <GridRowColumn>
              <ReadOnlyForm
                title="Personal Income"
                setEditing={() => seEditPersonalIncome(true)}
                readOnlyData={readOnlyPersonalIncome}
              />
            </GridRowColumn>
          )}
          {/* EDITABLE PERSONAL INCOME FORM */}
          {editPersonalIncome && (
            <GridRowColumn>
              <Card type="subsection" backgroundColor="stone40">
                <Text as="h2">Personal Income</Text>
                <Divider />
                <GridRowColumn>
                  <Grid>
                    <GridRowColumn style={{ justifyContent: 'center' }}>
                      <FormikInput
                        componentType="currency"
                        name={fieldNames.estimatedHouseholdIncome}
                        label={
                          questionEstimatedHouseholdIncomeInCents.question?.text
                        }
                        description={`This includes your private practice income, and any W-2 or 1099 income earned by you ${needsSpouseInfo ? 'or your spouse ' : ''}outside of your practice`}
                        schema={makeNumberSchema({
                          allowedDecimals: 2,
                        })}
                        fullWidth
                        placeholder="$0.00"
                      />
                    </GridRowColumn>
                    <GridRowColumn>
                      <FormikInput
                        componentType="currency"
                        name={fieldNames.estimatedHouseholdWithholdings}
                        label={
                          questionEstimatedHouseholdWithholdingsInCents.question
                            ?.text
                        }
                        description={`This includes withholdings from W-2 income earned outside of your practice${
                          needsSpouseInfo ? ', and spouse’s W-2 income' : ''
                        }. If you have none, you can enter “0.00”. Please do not include Social Security or Medicare tax.`}
                        schema={makeNumberSchema({
                          allowedDecimals: 2,
                        })}
                        fullWidth
                        placeholder="$0.00"
                      />
                    </GridRowColumn>
                    <GridRowColumn>
                      <LabelDescription label="Upload documents" />
                      <UploadCard
                        isRequired
                        docCategoryIdentifier={
                          UserDocumentCategoryIdentifier.finalReturnForm1040
                        }
                        styleOverrides={{
                          skipDescription: true,
                          cardBackgroundColor: 'stone40',
                        }}
                      />
                      <UploadCard
                        isRequired
                        docCategoryIdentifier={
                          UserDocumentCategoryIdentifier.officialGovernmentId
                        }
                        styleOverrides={{
                          skipDescription: true,
                          cardBackgroundColor: 'stone40',
                        }}
                      />
                      {needsSpouseInfo && (
                        <UploadCard
                          isRequired
                          docCategoryIdentifier={
                            UserDocumentCategoryIdentifier.spouseOfficialGovernmentId
                          }
                          styleOverrides={{
                            skipDescription: true,
                            cardBackgroundColor: 'stone40',
                          }}
                        />
                      )}
                    </GridRowColumn>
                  </Grid>
                </GridRowColumn>
                <GridRowColumn {...makeGridConfig([8, 14], true)}>
                  <Divider section />
                </GridRowColumn>
                <GridRowColumn
                  columnStyle={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 20,
                  }}
                >
                  <Button
                    disabled={!isInitialValid}
                    onClick={() => {
                      personalFormik.resetForm()
                      seEditPersonalIncome(false)
                    }}
                    style={{ border: 0 }}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={isUpdating || personalFormik.isSubmitting}
                    variant="secondary"
                    onClick={async () => {
                      const success = await personalFormik.submitForm()
                      if (success) {
                        seEditPersonalIncome(false)
                      }
                    }}
                    disabled={
                      !personalFormik.isValid ||
                      isUpdating ||
                      personalFormik.isSubmitting ||
                      !isPrevYear1040Uploaded ||
                      isEmpty(govIds) ||
                      (needsSpouseInfo && isEmpty(spouseGovIds))
                    }
                  >
                    Save
                  </Button>
                </GridRowColumn>
              </Card>
            </GridRowColumn>
          )}
        </Grid>
      </Container>
    </FormikProvider>
  )

  return {
    personalTaxEstimateContent,
    personalFormik,
    isPrevYear1040Uploaded,
    govIds,
    spouseGovIds,
    needsSpouseInfo,
    editPersonalIncome,
  }
}

export const useStartExtension = (taxFilingId?: number) => {
  const dispatch = useAppDispatch()
  const filing = useReselector(getAnnualTaxFilingById, taxFilingId)
  const taxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const extensionSurveyStartDate = convertUtcToLocalDate(
    taxDetails?.extension_survey_start_date
  )

  const extensionSurveyIsLaunched =
    extensionSurveyStartDate && DateTime.now() >= extensionSurveyStartDate
  const navigate = useNavigate()

  useEffect(() => {
    // if it's before the launch date, redirect to the annual taxes page
    if (extensionSurveyIsLaunched === false) {
      navigate('/taxes/annual')
    }
  }, [extensionSurveyIsLaunched, navigate])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    // Make sure filing exists and that extensionStartedAt hasn't started
    if (
      filing?.id &&
      !filing?.extensionStartedAt &&
      extensionSurveyIsLaunched
    ) {
      dispatch(
        updateAnnualTaxFilings(filing.id, {
          extensionStartedAt: new Date().toISOString(),
        })
      )
    }
  }, [
    dispatch,
    filing?.id,
    filing?.extensionStartedAt,
    extensionSurveyIsLaunched,
  ])
}
