import { CSSProperties, Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Pagination, PaginationProps, Table } from 'semantic-ui-react'
import moment from 'moment'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { DateColumn, SortAction } from './index'
import type { getTableData } from './actions'
import {
  EOYBookkeepingFollowupSubstep,
  YearEndModuleStatusOptions,
} from '../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { Icon, Link, Pill, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'

interface CRMTableProps {
  data: ReturnType<typeof getTableData>
  column: DateColumn | null
  direction: 'ascending' | 'descending' | null
  dispatch: Dispatch<SortAction>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  count: number
  year: string
}

const CRMTable: FC<CRMTableProps> = ({
  data,
  column,
  direction,
  dispatch,
  page,
  setPage,
  count,
  year,
}) => {
  const handlePaginationChange = (_: unknown, data: PaginationProps) => {
    setPage(data.activePage as number)
  }

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>YE Bookkeeping</Table.HeaderCell>
            <Table.HeaderCell>Onboarding Date</Table.HeaderCell>
            {moment.monthsShort().map((month) => (
              <Table.HeaderCell
                key={month}
                sorted={
                  (column === month.toLowerCase() && direction) || undefined
                }
                onClick={() =>
                  dispatch({
                    type: 'CHANGE_SORT',
                    column: month.toLowerCase() as DateColumn,
                  })
                }
              >
                {month}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((row, i) => (
            <TableData key={i} row={row} year={year} />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        defaultActivePage={page}
        firstItem={null}
        lastItem={null}
        totalPages={Math.ceil(count / 10)}
        onPageChange={handlePaginationChange}
      />
    </>
  )
}

export default CRMTable

interface TableDataProps {
  row: ReturnType<typeof getTableData>[number]
  year: string
}

const { actionRequired, waitingOnHeard, complete, upcoming } =
  YearEndModuleStatusOptions
const { balanceSheetGeneration } = EOYBookkeepingFollowupSubstep

const YEBookkeepingCell = ({ row, year }: TableDataProps) => {
  const status = useMemo(
    () => row.eoyBookkeepingFollowupStatus,
    [row.eoyBookkeepingFollowupStatus]
  )

  const Status = () => {
    const Wrapper = ({ children }: { children: React.ReactNode }) => (
      <div style={{ paddingBottom: 8 }}>{children}</div>
    )

    if (status === actionRequired || status === upcoming) {
      return (
        <Wrapper>
          <Text as="eyebrow">Waiting on user</Text>
        </Wrapper>
      )
    } else if (status === waitingOnHeard) {
      if (
        row.eoyBookkeepingFollowupSubstepIdentifier === balanceSheetGeneration
      ) {
        return (
          <Wrapper>
            <Pill variant="solid" color="orange">
              Manual balance sheet needed
            </Pill>
          </Wrapper>
        )
      }
      return (
        <Wrapper>
          <Pill variant="solid" color="purple">
            Ready for review
          </Pill>
        </Wrapper>
      )
    } else if (status === complete) {
      return (
        <Wrapper>
          <Text as="eyebrow" color="green">
            Books complete <Icon icon={solid('check')} color="green" />
          </Text>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Text as="bodyXs" color="darkGray">
          Not yet submitted
        </Text>
      </Wrapper>
    )
  }

  const AnnualTaxExtension = () => {
    if (status === complete) {
      return null
    }

    return (
      <div style={{ paddingBottom: 8 }}>
        {row.hasAnnualTaxExtension ? (
          <Text as="bodyXs">Extended</Text>
        ) : (
          <Text as="bodyXs" color="orange" style={{ fontWeight: 700 }}>
            Not extended
          </Text>
        )}
      </div>
    )
  }

  const ViewSummaryLink = () => {
    if (status && [waitingOnHeard, actionRequired, complete].includes(status)) {
      return (
        <Link
          size="small"
          to={`/admin/finances/records/${row.user.id}/submissions/${year}`}
        >
          View summary
        </Link>
      )
    }

    return null
  }

  const style: CSSProperties = {
    minWidth: 170,
  }

  if (
    status === waitingOnHeard &&
    row.eoyBookkeepingFollowupSubstepIdentifier === balanceSheetGeneration
  ) {
    style.backgroundColor = Colors.lightOrange
  } else if (status === waitingOnHeard) {
    style.backgroundColor = Colors.lightPurple
  } else if (status === complete) {
    style.backgroundColor = Colors.lightGreen
  }

  return (
    <Table.Cell style={style}>
      <Status />
      <AnnualTaxExtension />
      <ViewSummaryLink />
    </Table.Cell>
  )
}

const TableData: FC<TableDataProps> = ({ row, year }) => {
  return (
    <Table.Row key={row.user.id}>
      <Table.Cell>
        <a
          href={`/admin/finances/records/${row.user.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.user.firstName} {row.user.lastName}
        </a>
      </Table.Cell>
      <YEBookkeepingCell row={row} year={year} />
      <Table.Cell>{row.onboardingDate}</Table.Cell>
      {Object.values(row.reports).map((report, i) => {
        if (report) {
          return (
            <Table.Cell key={i} style={setStyle(report.status)}>
              {bookkeepingStatusMapping(report.status)}
              <a
                href={`/admin/finances/records/${row.user.id}/monthly-books/${report.id}`}
                target="_blank"
                rel="noreferrer"
              >
                view
              </a>
            </Table.Cell>
          )
        }
        return <Table.Cell key={i}>N/A</Table.Cell>
      })}
    </Table.Row>
  )
}

const setStyle = (status: string) => {
  let color

  switch (status) {
    case 'done':
      color = 'green'
      break
    case 'reconciled':
      color = 'yellow'
      break
    case 'in_progress':
    case 'categorized':
      color = 'orange'
      break
    case 'not_started':
    case 'missing_info':
    case 'plaid_error':
      color = 'red'
      break
    default:
      break
  }
  return { background: color, textColor: 'white' }
}

const bookkeepingStatusMapping = (status: string) => {
  switch (status) {
    case 'not_started':
      return <p>Not Started</p>
    case 'in_progress':
      return <p>In Progress</p>
    case 'missing_info':
      return <p>Missing Information</p>
    case 'plaid_error':
      return <p>Plaid Error</p>
    case 'categorized':
      return <p>Categorized</p>
    case 'reconciled':
      return <p>Reconciled</p>
    case 'done':
      return <p>Done</p>
    default:
      return null
  }
}
