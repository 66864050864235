import { captureMessage, captureException } from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'

import { getStore } from '../store'

// This should be coming from Sentry but it's not being exported properly
interface ScopeContext {
  user: {
    id?: string | number
  }
  level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
  extra: Record<string, unknown>
  tags: {
    [key: string]: string
  }
}

// todo delete this file and replace with logHeardError
export const logSentryError = (
  error: unknown,
  captureContext?: Partial<ScopeContext>
) => {
  const state = getStore()?.getState()
  const userId = state?.auth.user?.id

  captureException(error, {
    ...captureContext,
    user: { id: userId?.toString() },
  })

  datadogRum.addError(error, {
    ...captureContext,
    user: { id: userId?.toString() },
  })
}

// todo delete this file and replace with logHeardError
export const logSentryMessage = (
  message: string,
  captureContext?: Partial<ScopeContext>
) => {
  const state = getStore()?.getState()
  const userId = state?.auth.user?.id

  captureMessage(message, {
    ...captureContext,
    user: { id: userId?.toString() },
  })

  datadogRum.addError(message, {
    ...captureContext,
    user: { id: userId?.toString() },
  })
}
