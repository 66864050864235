import { useState } from 'react'
import { Embed, Progress } from 'semantic-ui-react'
import { random } from 'lodash'
import { DateTime } from 'luxon'

import { Button, Modal, Text } from '../../components/BaseComponents'
import { useAsyncCallback, useReselector } from '../../utils/sharedHooks'
import { getCurrentUser } from '../../selectors/user.selectors'
import {
  fetchAllCompanyForms,
  fetchAllEmployeeForms,
  fetchCompanyFederalTaxes,
  fetchCompanyOnboardingStatus,
  fetchEmployeeFederalTaxes,
  fetchEmployeePaymentMethod,
  postAcceptCompanyTOS,
  postCreateBankAccount,
  postCreateCompanyLocation,
  postCreateEmployee,
  postCreateEmployeeBankAccount,
  postCreateEmployeeHomeAddress,
  postCreateEmployeeJob,
  postCreateEmployeeWorkAddress,
  postCreateGustoFlow,
  postCreateSignatory,
  postCreatePayrollCompany,
  postPaySchedule,
  postSendTestDeposits,
  putApproveDemoCompany,
  putFinishOnboarding,
  putSignCompanyForm,
  putSignEmployeeForm,
  putUpdateCompanyFederalTaxes,
  putUpdateCompanyIndustry,
  putUpdateEmployeeFederalTaxes,
  putUpdateEmployeePaymentMethod,
  putUpdateEmployeeStateTaxes,
  putUpdateJobCompensation,
  putVerifyBankAccount,
} from './payrollActions'
import { useAppDispatch } from '../../utils/typeHelpers'
import { selectPayrollProfile } from './payroll.selectors'
import { INDUSTRY_OPTIONS } from './helpers'
import { DATE_FORMATS_LUXON } from '../../utils/dateHelpers'
import { isProduction } from '../../utils/envHelpers'

// We use this address to set up user and company.  The employee state questions are dependent on WA state
const HeardAddress = {
  street_1: '113 Cherry St',
  street_2: null,
  city: 'Seattle',
  state: 'WA',
  zip: '98104',
  phone_number: '6615555555',
}

const CreatePayrollDevAccount = () => {
  const dispatch = useAppDispatch()

  const user = useReselector(getCurrentUser)
  const payrollProfile = useReselector(selectPayrollProfile)

  const [open, setOpen] = useState(false)
  const [gustoFlowModalOpen, setGustoFlowModalOpen] = useState(false)
  const [statusText, setStatusText] = useState('Not started')
  const [error, setError] = useState('')
  const [percentValue, setPercentageValue] = useState(0)
  const [link, setLink] = useState<string>()

  const startPayrollDevAccountSetup = useAsyncCallback(async () => {
    // Edge case, user will be logged in
    if (!user) {
      return undefined
    }

    setStatusText('creating payroll profile')
    const payrollProfileRes = await postCreatePayrollCompany({
      user: {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone: null,
      },
      company: { name: `${user.lastName} Inc` },
    })(dispatch)

    if (!payrollProfileRes) {
      return setError('Failed to create payroll profile')
    }
    setStatusText('setting up industry')
    setPercentageValue((val) => val + 1)

    const industryRes = await putUpdateCompanyIndustry(
      INDUSTRY_OPTIONS['Offices of Physicians, Mental Health Specialists']
    )(dispatch)

    if (!industryRes) {
      return setError('Failed to set industry')
    }

    setStatusText('setting up company federal taxes')
    setPercentageValue((val) => val + 1)

    const currentFederalTaxes = await dispatch(fetchCompanyFederalTaxes())

    if (!currentFederalTaxes) {
      return setError('Failed to fetch federal taxes')
    }

    // The federal taxes call will fail for duplicated eins so this may require a few attempts
    let federalTaxesRes
    for (let i = 0; i < 4 && !federalTaxesRes; i++) {
      setStatusText(`setting up company federal taxes attempt: ${i + 1}`)

      const randomEin = random(100000000, 999999999)
      federalTaxesRes = await putUpdateCompanyFederalTaxes({
        version: currentFederalTaxes.version,
        taxable_as_scorp: true,
        tax_payer_type: 'S-Corporation',
        filing_form: '941',
        ein: randomEin.toString(),
      })(dispatch)

      if (federalTaxesRes) {
        break
      }
    }

    if (!federalTaxesRes) {
      return setError('Failed to set federal taxes')
    }

    setStatusText('setting up company locations')
    setPercentageValue((val) => val + 1)

    const companyLocationRes = await postCreateCompanyLocation({
      ...HeardAddress,
      filing_address: true,
      mailing_address: true,
    })(dispatch)

    if (!companyLocationRes) {
      return setError('Failed to set company location')
    }

    setStatusText('creating employee')
    setPercentageValue((val) => val + 1)

    const employeeRes = await postCreateEmployee({
      first_name: user.firstName,
      last_name: user.lastName,
      date_of_birth: '1980-01-01',
      email: user.email,
      ssn: '123-45-6789',
    })(dispatch)

    if (!employeeRes) {
      return setError('Failed to set employee location')
    }

    const homeAddressRes = await postCreateEmployeeHomeAddress(
      employeeRes.uuid,
      HeardAddress
    )(dispatch)

    if (!homeAddressRes) {
      return setError('Failed to set employee home address')
    }

    setStatusText('set employee job')
    setPercentageValue((val) => val + 1)

    const workAddressResponse = await dispatch(
      postCreateEmployeeWorkAddress(employeeRes.uuid, {
        location_uuid: companyLocationRes.uuid,
      })
    )

    if (!workAddressResponse) {
      return setError('Failed to set employee work address')
    }

    const jobRes = await postCreateEmployeeJob(employeeRes.uuid, {
      title: 'Some Cool Job',
      hire_date: '2020-01-01',
    })(dispatch)

    const compensationUuid = jobRes?.compensations[0]?.uuid
    const compensationVersion = jobRes?.compensations[0]?.version

    if (!jobRes || !compensationUuid || !compensationVersion) {
      return setError('Failed to set employee job')
    }

    const compensationRes = await putUpdateJobCompensation(compensationUuid, {
      rate: '20.00',
      payment_unit: 'Hour',
      version: compensationVersion,
      flsa_status: 'Salaried Nonexempt',
    })(dispatch)

    if (!compensationRes) {
      return setError('Failed to set compensation')
    }

    setStatusText('set employee federal taxes')
    setPercentageValue((val) => val + 1)

    const getFederalTaxesRes = await fetchEmployeeFederalTaxes(
      employeeRes.uuid
    )(dispatch)

    if (!getFederalTaxesRes) {
      return setError('Failed to get employee federal taxes')
    }

    const setEmployeeFederalRes = await putUpdateEmployeeFederalTaxes(
      employeeRes.uuid,
      {
        version: getFederalTaxesRes.version,
        filing_status: 'Single',
        extra_withholding: '0.00',
        two_jobs: false,
        dependents_amount: '0',
        other_income: '0.00',
        deductions: '0.00',
        w4_data_type: 'rev_2020_w4',
      }
    )(dispatch)

    if (!setEmployeeFederalRes) {
      return setError('Failed to set employee federal taxes')
    }

    setStatusText('set employee state taxes')
    setPercentageValue((val) => val + 1)

    const updateStateTaxRes = await putUpdateEmployeeStateTaxes(
      employeeRes.uuid,
      {
        states: [
          {
            state: 'WA',
            questions: [
              {
                key: 'occupational_code',
                answers: [
                  {
                    value: '291129',
                    valid_from: '2010-01-01',
                    valid_up_to: null,
                  },
                ],
              },
              {
                key: 'file_new_hire_report',
                answers: [
                  { value: true, valid_from: '2010-01-01', valid_up_to: null },
                ],
              },
            ],
          },
        ],
      }
    )(dispatch)

    if (!updateStateTaxRes) {
      return setError('Failed to update employee state taxes')
    }

    setStatusText('set up employee bank account')
    setPercentageValue((val) => val + 1)

    const createEmployeeBankAccountRes = await postCreateEmployeeBankAccount(
      employeeRes.uuid,
      {
        name: 'Bank Account',
        routing_number: '102001017',
        account_type: 'Checking',
        account_number: '01234567',
      }
    )(dispatch)

    if (!createEmployeeBankAccountRes) {
      return setError('Failed to set up employee bank account')
    }

    const paymentMethodRes = await fetchEmployeePaymentMethod(employeeRes.uuid)(
      dispatch
    )

    if (!paymentMethodRes) {
      return setError('Failed to get employee payment method')
    }

    const updatePaymentMethodRes = await putUpdateEmployeePaymentMethod(
      employeeRes.uuid,
      {
        type: 'Direct Deposit',
        version: paymentMethodRes.version,
        split_by: 'Percentage',
        splits: [
          {
            uuid: createEmployeeBankAccountRes.uuid,
            name: createEmployeeBankAccountRes.name,
            priority: 1,
            split_amount: 100,
          },
        ],
      }
    )(dispatch)

    if (!updatePaymentMethodRes) {
      return setError('Failed to update employee payment method')
    }

    setStatusText('sign employee forms')
    setPercentageValue((val) => val + 1)

    const getFormsRes = await fetchAllEmployeeForms(employeeRes.uuid)(dispatch)

    if (!getFormsRes) {
      return setError('Failed to get employee forms')
    }

    for (const form of getFormsRes) {
      if (form.requires_signing) {
        const signFormRes = await putSignEmployeeForm(
          employeeRes.uuid,
          form.uuid,
          {
            signature_text: `${user.firstName} ${user.lastName}`,
            agree: true,
          }
        )(dispatch)

        if (!signFormRes) {
          return setError('Failed to sign employee form')
        }
      }
    }

    setStatusText('set up company pay schedule')
    setPercentageValue((val) => val + 1)

    const payScheduleRes = await postPaySchedule({
      frequency: 'Every week',
      anchor_pay_date: DateTime.now().toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
      anchor_end_of_pay_period: DateTime.now().toFormat(
        DATE_FORMATS_LUXON.GUSTO_SUBMIT
      ),
      day_1: 15,
      day_2: 31,
    })(dispatch)

    if (!payScheduleRes) {
      return setError('Failed to set up company pay schedule')
    }

    setStatusText('setting up company bank account')
    setPercentageValue((val) => val + 1)

    const createCompanyBankAccountRes = await postCreateBankAccount({
      routing_number: '102001017',
      account_type: 'Checking',
      account_number: '01234567',
    })(dispatch)

    if (!createCompanyBankAccountRes) {
      return setError('Failed to set up company bank account')
    }

    const testDepositRes = await postSendTestDeposits(
      createCompanyBankAccountRes.uuid
    )(dispatch)

    if (!testDepositRes) {
      return setError('Failed to send test deposits')
    }

    const verifyBankAccountRes = await putVerifyBankAccount(
      createCompanyBankAccountRes.uuid,
      {
        deposit_1: Number(testDepositRes.deposit_1),
        deposit_2: Number(testDepositRes.deposit_2),
      }
    )(dispatch)

    if (!verifyBankAccountRes) {
      return setError('Failed to verify bank account')
    }

    setStatusText('setting up state setup flow')
    setPercentageValue((val) => val + 1)

    const res = await postCreateGustoFlow('state_setup')(dispatch)

    if (res) {
      setStatusText(
        'Company state tax setup is manual - open link and fill out details to continue ' +
          'Valid WA data: ' +
          'Unified Business ID - 111 111 199 ' +
          'Participation Activation Code - 252555 ' +
          'ESD Number - 001-234567-89-0 ' +
          'Workers’ Comp Account ID - 363,636-36'
      )
      setLink(res.url)
    } else {
      return setError('Failed to get state setup link')
    }

    return undefined
  })

  // This is triggered when the iframe state setup modal is closed
  const finishPayrollDevAccountSetup = useAsyncCallback(async () => {
    // Edge case, user will be logged in
    if (!user) {
      return undefined
    }

    setGustoFlowModalOpen(false)
    const res = await fetchCompanyOnboardingStatus()(dispatch)

    const isStateSetupCompleted =
      Boolean(res) &&
      !res?.onboarding_steps.find(
        (step) => step.id === 'state_setup' && step.completed
      )

    // This step wasn't completed in the iframe, don't continue yet
    if (isStateSetupCompleted) {
      return undefined
    }

    setLink(undefined)
    setStatusText('setting signatory')
    setPercentageValue((val) => val + 1)

    const signatoryRes = await dispatch(
      postCreateSignatory({
        first_name: user.firstName,
        last_name: user.lastName,
        birthday: '1980-01-01',
        title: 'Owner',
        phone: '6615555555',
        email: user.email,
        ssn: '123-45-6789',
        home_address: {
          ...HeardAddress,
          country: 'United States',
        },
      })
    )

    if (!signatoryRes) {
      return setError('Could not create signatory')
    }

    setStatusText('signing forms')
    setPercentageValue((val) => val + 1)

    const companyFormsRes = await dispatch(fetchAllCompanyForms())

    if (!companyFormsRes) {
      return setError('Could not get company forms')
    }

    for (const form of companyFormsRes) {
      if (form.requires_signing) {
        const signFormRes = await putSignCompanyForm(form.uuid, {
          signature_text: `${user.firstName} ${user.lastName}`,
          agree: true,
        })(dispatch)

        if (!signFormRes) {
          return setError('Failed to sign company form')
        }
      }
    }

    setStatusText('Completing final steps')
    setPercentageValue((val) => val + 1)

    const finishOnboardingRes = await putFinishOnboarding()(dispatch)

    if (!finishOnboardingRes) {
      return setError('finish onboarding failed')
    }

    const acceptTermsRes = await postAcceptCompanyTOS()(dispatch)

    if (!acceptTermsRes) {
      return setError('accept terms failed')
    }

    // As soon as this step is complete the user is re-naved due to change of company approval so this modal is auto closed
    const approveDemoCompanyRes = await putApproveDemoCompany()(dispatch)

    if (!approveDemoCompanyRes) {
      return setError('approve demo company failed')
    }

    setPercentageValue((val) => val + 1)
    return undefined
  })

  if (isProduction()) {
    return null
  }

  const disableActions = Boolean(
    startPayrollDevAccountSetup.loading ||
      finishPayrollDevAccountSetup.loading ||
      link
  )

  const flowDisabled = Boolean(payrollProfile?.gustoCompanyUuid)

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="warning"
        style={{ margin: 'auto' }}
        disabled={flowDisabled}
      >
        Open Payroll Dev Account Creator (Dev/staging only)
        {flowDisabled &&
          ' - Signup process has already been started, creator cannot recover.  Continue with standard flow'}
      </Button>
      <Modal open={open} size="tiny">
        <Modal.Header>Create Payroll Dev Account</Modal.Header>
        <Modal.Content>
          <Text as="h3">Automatically setup payroll for your user</Text>
          <br />
          <Text color="red">
            Please don&apos;t close this modal or refresh page during this
            process
          </Text>
          <br />
          <Progress
            indicating={
              startPayrollDevAccountSetup.loading ||
              finishPayrollDevAccountSetup.loading
            }
            error={Boolean(error)}
            value={percentValue}
            // this is count of number of setPercentageValues
            total={17}
          />
          <Text color="yellow">{statusText}</Text>
          {error && <Text color="red">{error}</Text>}
          <br />
          {link && (
            <Button onClick={() => setGustoFlowModalOpen(true)} fullWidth>
              Set up company state taxes
            </Button>
          )}
          <Modal
            open={gustoFlowModalOpen}
            size="fullscreen"
            closeIcon
            onClose={finishPayrollDevAccountSetup.callback}
          >
            <Modal.Content>
              <Embed active url={link} iframe={{ scrolling: true }} />
            </Modal.Content>
          </Modal>
        </Modal.Content>

        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => setOpen(false)}
            disabled={disableActions}
          >
            Cancel
          </Button>
          <Button
            onClick={startPayrollDevAccountSetup.callback}
            disabled={Boolean(disableActions || error)}
          >
            Start
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default CreatePayrollDevAccount
