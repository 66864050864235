import { ReactNode, useMemo } from 'react'
import { Divider, Grid } from 'semantic-ui-react'

import { Button, GridRowColumn } from '../BaseComponents'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

const FormFlowFooter = ({
  onBack,
  onForward,
  continueDisabled,
  isSubmit,
  hideContinue = false,
  extraButton,
  loading,
  continueButtonText,
}: {
  onBack: () => void
  onForward?: () => void
  continueDisabled?: boolean
  isSubmit?: boolean
  hideContinue?: boolean
  extraButton?: ReactNode
  loading?: boolean
  continueButtonText?: string
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const backButton = useMemo(
    () => (
      <Button fullWidth variant="secondary" onClick={onBack}>
        Back
      </Button>
    ),
    [onBack]
  )

  const continueButton = useMemo(
    () =>
      !hideContinue && (
        <Button
          fullWidth
          onClick={onForward}
          disabled={continueDisabled}
          loading={loading}
        >
          {continueButtonText || (isSubmit ? 'Submit' : 'Continue')}
        </Button>
      ),
    [
      continueDisabled,
      hideContinue,
      isSubmit,
      onForward,
      loading,
      continueButtonText,
    ]
  )

  return (
    <>
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      {isMobile ? (
        <>
          <GridRowColumn spacer={1} width={14}>
            {continueButton}
          </GridRowColumn>
          {extraButton && (
            <GridRowColumn spacer={1} width={14} short>
              {extraButton}
            </GridRowColumn>
          )}
          <GridRowColumn spacer={1} width={14} short>
            {backButton}
          </GridRowColumn>
        </>
      ) : (
        <Grid.Row>
          <Grid.Column computer={3} tablet={5} mobile={16}>
            {backButton}
          </Grid.Column>
          <Grid.Column
            computer={extraButton ? 7 : 10}
            tablet={extraButton ? 1 : 6}
            only="computer tablet"
          />
          {extraButton && (
            <Grid.Column computer={3} tablet={5} mobile={16}>
              {extraButton}
            </Grid.Column>
          )}
          <Grid.Column computer={3} tablet={5} mobile={16}>
            {continueButton}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  )
}

export default FormFlowFooter
