import { Grid, Image } from 'semantic-ui-react'

import {
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import { PayrollInterestScreenName } from './PayrollInterestFlow'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectIsCurrentUserScorp } from '../../../../selectors/user.selectors'
import { selectSubscriptionIncludesFreePayroll } from '../../../../reducers/subscription.slice'
import { useState } from 'react'
import LearnMorePayrollModal from '../LearnMorePayrollModal'

const PayrollInterestIntro = ({
  setScreen,
}: {
  setScreen: (screen: string | null) => void
}) => {
  const gridConfig = { ...makeGridConfig([10, 14], true) }
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )
  const [showPayrollModal, setShowPayrollModal] = useState(false)

  return (
    <Grid>
      <GridRowColumn {...gridConfig}>
        <Text as="h1">
          {isScorp
            ? 'Payroll for S corporations'
            : 'Payroll for group practice owners'}
        </Text>
      </GridRowColumn>
      <Grid.Row />
      <GridRowColumn {...gridConfig}>
        <Text>
          {isScorp
            ? 'As an S corporation, you’re required by the IRS to pay yourself and any employees through payroll.'
            : 'As a group practice, you’re required by the IRS to pay employees through payroll.'}
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={3} />
        <Grid.Column
          width={2}
          verticalAlign="middle"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/Bank.svg"
            style={{ width: 80, height: 80 }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          {isScorp ? (
            <>
              <Text as="h3">Why am I required to have payroll?</Text>
              <br />
              <Text>
                This guarantees that payroll taxes (Medicare and Social
                Security) are being collected on the{' '}
                <Link
                  as="secondaryLink"
                  href="https://support.joinheard.com/hc/en-us/articles/7475592736919-How-do-I-set-a-reasonable-salary-and-why-is-it-important"
                  newPage
                >
                  reasonable salary
                </Link>{' '}
                that you must pay yourself.
              </Text>
            </>
          ) : (
            <>
              <Text as="h3">Do I need to have payroll?</Text>
              <Text>
                Group practice owners generally need to manage payroll if they
                have any employees on staff as W-2 workers, which means they are
                required to withhold taxes and pay their employees a salary or
                hourly wage.
                <br />
                <br />
                If you only utilize independent contractors (1099s), you may not
                need to handle payroll for those individuals as they are
                responsible for their own taxes.
              </Text>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
      `
      <Grid.Row>
        <Grid.Column width={3} />
        <Grid.Column
          width={2}
          verticalAlign="middle"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/question_coin.svg"
            style={{ width: 80, height: 80 }}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Text as="h3">What if I don’t have a payroll provider?</Text>
          <br />
          {hasFreePayrollWithSubscription ? (
            <Text>
              You can sign up for{' '}
              <Button
                style={{ display: 'inline' }}
                variant="secondaryLink"
                onClick={() => setShowPayrollModal(true)}
              >
                Heard Payroll
              </Button>{' '}
              in a later step. This service is included in your Heard
              membership.
            </Text>
          ) : (
            <Text>
              You can sign up for{' '}
              <Button
                style={{ display: 'inline' }}
                variant="secondaryLink"
                onClick={() => setShowPayrollModal(true)}
              >
                Heard Payroll
              </Button>{' '}
              as an add-on service. You’ll be able to do this in a later step.
            </Text>
          )}
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn {...gridConfig}>
        <Text>Next, we’ll ask for some details about your payroll needs.</Text>
      </GridRowColumn>
      <FormFlowFooter
        onBack={() => setScreen(null)}
        onForward={() => setScreen(PayrollInterestScreenName.FORM)}
      />
      <LearnMorePayrollModal
        open={showPayrollModal}
        onClose={() => setShowPayrollModal(false)}
      />
    </Grid>
  )
}

export default PayrollInterestIntro
