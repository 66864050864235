import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid, Image } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import './enroll.scss'

import {
  selectPayrollCompany,
  selectPayrollProfile,
} from '../payroll.selectors'
import {
  GEP_ENROLL_PATHS,
  getResumeLink,
  ONBOARDING_STEPS,
  useOnboardingSteps,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import PayrollSetupList from './PayrollSetupList'
import { putApproveDemoCompany } from '../payrollActions'
import {
  Card,
  Text,
  Button,
  GridRowColumn,
  CircularWrapper,
  Link,
  Icon,
} from '../../../components/BaseComponents'
import { useAnalyticsView } from '../../Amplitude'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  useFetchResponseWithUtils,
  useReselector,
} from '../../../utils/sharedHooks'
import CreatePayrollDevAccount from '../CreatePayrollDevAccount'
import { getCurrentUser } from '../../../selectors/user.selectors'
import { PayrollSetup } from '../../../reducers/auth/userReducer'
import { selectSubscriptionIncludesFreePayroll } from '../../../reducers/subscription.slice'
import { Colors } from '../../../styles/theme'
import { makeGridConfig } from '../../../components/BaseComponents/Grid'
import { PayrollIntroCard } from '../Upsell/WelcomeToPayroll'
import { getMostRecentPayrollInterestSurvey } from '../Upsell/payrollInterestSurvey.actions'
import { isProduction } from '../../../utils/envHelpers'

const NextStep1 = () => {
  const user = useReselector(getCurrentUser)
  const latestPayrollSurvey = useFetchResponseWithUtils(
    getMostRecentPayrollInterestSurvey
  )

  const payrollSetup =
    latestPayrollSurvey.result?.payrollSetup || user?.payrollSetup

  if (payrollSetup === PayrollSetup.gusto) {
    return (
      <>
        <Text>Connect to Gusto</Text>
        <Text as="bodySm">
          After clicking Enroll in Payroll, you’ll be prompted to login to
          Gusto. Once you accept the terms, your Gusto information will
          automatically sync with Heard.
        </Text>
      </>
    )
  }

  return (
    <>
      <Text>Complete payroll onboarding</Text>
      <Text as="bodySm">This takes 30-45 minutes.</Text>
    </>
  )
}

const Step2 = () => {
  const user = useReselector(getCurrentUser)
  const latestPayrollSurvey = useFetchResponseWithUtils(
    getMostRecentPayrollInterestSurvey
  )

  const payrollSetup =
    latestPayrollSurvey.result?.payrollSetup || user?.payrollSetup

  return (
    <>
      <Text>Start managing your payroll in Heard</Text>
      <Text as="bodySm">
        Your payroll should now be active in Heard.
        {payrollSetup === PayrollSetup.other
          ? ' Export your statements from your previous provider and upload them into Gusto.'
          : ''}
      </Text>

      {payrollSetup === PayrollSetup.gusto && (
        <>
          <br />
          <Text as="bodyXs" color="darkGray">
            Important: Migration fully transfers your payroll to Heard. Your
            Gusto payroll will no longer be available, nor will they be able to
            provide support. Please reach out to us via Messages instead.
          </Text>
        </>
      )}
      <br />
      <Link
        href="https://support.joinheard.com/hc/en-us/sections/7709867454359-Payroll"
        newPage
        style={{ fontSize: 14 }}
      >
        Heard Payroll help articles
        <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
      </Link>
    </>
  )
}

const FreeToEnroll = ({ enrollButton }: { enrollButton: ReactNode }) => {
  const gridConfig = { ...makeGridConfig([10, 14], true) }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
      <Grid>
        <GridRowColumn {...gridConfig}>
          <Text as="h1">Enroll in Heard Payroll</Text>
        </GridRowColumn>
        <Grid.Row />
        <GridRowColumn {...gridConfig}>
          <Card
            backgroundColor="lightOrange"
            type="subsection"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/pencil.svg"
              style={{
                width: 180,
                height: 180,
                backgroundColor: Colors.transparent,
              }}
            />
          </Card>
        </GridRowColumn>
        <GridRowColumn {...gridConfig}>
          <Text as="h2">Next steps</Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={3}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <CircularWrapper height={28} wrapperColor="stone">
              <Text as="h3">1</Text>
            </CircularWrapper>
          </Grid.Column>
          <Grid.Column computer={9} tablet={9} mobile={11}>
            <NextStep1 />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            computer={4}
            tablet={4}
            mobile={3}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <CircularWrapper height={28} wrapperColor="stone">
              <Text as="h3">2</Text>
            </CircularWrapper>
          </Grid.Column>
          <Grid.Column computer={9} tablet={9} mobile={11}>
            <Step2 />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...gridConfig}>{enrollButton}</GridRowColumn>
        <CreatePayrollDevAccount />
      </Grid>
    </div>
  )
}

export const EnrollmentIntro = ({
  enrollButton,
}: {
  enrollButton: ReactNode
}) => {
  const hasFreePayrollWithSubscription = useReselector(
    selectSubscriptionIncludesFreePayroll
  )

  if (hasFreePayrollWithSubscription) {
    return <FreeToEnroll enrollButton={enrollButton} />
  }

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row />
      <GridRowColumn spacer={2} width={12}>
        <Text as="display" color="forest" textAlign="center">
          Enroll in Payroll with Heard
        </Text>
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Text textAlign="center">
          Run payroll for your employees, contractors and even yourself, all
          within Heard’s platform.
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={2} />
        <Grid.Column width={4}>
          <PayrollIntroCard />
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid>
            <GridRowColumn>
              <Text as="h2">Next steps</Text>
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column
                width={3}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <CircularWrapper height={28} wrapperColor="stone">
                  <Text as="h3">1</Text>
                </CircularWrapper>
              </Grid.Column>
              <Grid.Column width={13}>
                <NextStep1 />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                width={3}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <CircularWrapper height={28} wrapperColor="stone">
                  <Text as="h3">2</Text>
                </CircularWrapper>
              </Grid.Column>
              <Grid.Column width={13}>
                <Step2 />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn>{enrollButton}</GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
      <CreatePayrollDevAccount />
    </Grid>
  )
}

const PayrollEnroll = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payrollProfile = useReselector(selectPayrollProfile)
  const payrollCompany = useReselector(selectPayrollCompany)
  const steps = useOnboardingSteps()
  const pageView = useAnalyticsView()
  const nextPageLink = getResumeLink(steps)
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.enrollment)

  const approveCompany = useCallback(
    () => putApproveDemoCompany()(dispatch),
    [dispatch]
  )

  useEffect(() => {
    // If company is approved auto navigate to run payroll page
    if (payrollCompany?.company_status === 'Approved') {
      navigate('/payroll/run_payroll')
    }
    pageView('payroll enroll')
  }, [navigate, payrollCompany?.company_status, pageView])

  const enrollButton = useMemo(
    () => (
      <Button onClick={() => navigate(nextPageLink)}>
        {nextPageLink === GEP_ENROLL_PATHS.overview
          ? 'Enroll in Payroll'
          : 'Resume Enrollment'}
      </Button>
    ),
    [navigate, nextPageLink]
  )

  if (payrollProfile?.onboardingCompleted) {
    return (
      <Grid>
        <GridRowColumn>
          <Text as="h1">Welcome to Heard Payroll!</Text>
        </GridRowColumn>
        <Divider />
        <Grid.Row>
          <Grid.Column width={6}>
            <PayrollSetupList />
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={8}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Text as="h2" textAlign="center">
                    You&apos;re all set up! We&apos;ll notify you when payroll
                    is ready to be run.
                  </Text>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4} />
                <Grid.Column width={8}>
                  <Image src="https://heard-images.s3.us-west-1.amazonaws.com/assets/payroll_rocket.png" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        {!isProduction() && (
          <GridRowColumn width={6}>
            <Button onClick={approveCompany} fullWidth>
              Approve demo company
            </Button>
          </GridRowColumn>
        )}
        <Grid.Row />
        <Grid.Row />
        <Grid.Row />
      </Grid>
    )
  }

  return <EnrollmentIntro enrollButton={enrollButton} />
}

export default PayrollEnroll
