import { useEffect, useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Label, Sticky } from 'semantic-ui-react'
import {
  Alert,
  GridRowColumn,
  Icon,
  Link,
  Text,
  Toggle,
} from '../../BaseComponents'
import { ArticleCard } from '../shared/ArticleCard'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import {
  useReselector,
  useToggle,
  useTrackFinancialInsightsButtonClick,
} from '../../../utils/sharedHooks'
import {
  ARTICLE_TYPE,
  FinancialInsightsButtonType,
  INSIGHTS_TYPE,
} from '../shared/utils'
import InsightsBreakdownTable from '../shared/InsightsBreakdownTable'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { UncategorizedTransactionBanner } from '../shared/UncategorizedTransactionBanner'
import { isEmpty } from 'lodash'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { CurrentPanel } from '../shared/CurrentPanel'
import { TrackInsightsSection } from '../shared/TrackInsightsSection'
import { BannerCard } from '../../BaseComponents/BannerCard'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { DisplaySampleDataBanner } from './DisplaySampleDataBanner'
import { fetchInsightsTableDropdownOptions } from '../shared/financeActions'
import { Colors } from '../../../styles/theme'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'
import {
  useInsightsDatePicker,
  InsightsDatePicker,
} from '../shared/InsightsDatePicker'
import { InsightBreadcrumb } from '../shared/InsightBreadcrumb'

const InsightsExpensesPanel = () => {
  const dispatch = useAppDispatch()
  const [displayBanner, toggleBanner] = useToggle(true)
  const [shouldDisplaySampleData, setShouldDisplaySampleData] = useState(false)
  const [displaySampleDataBanner, setDisplaySampleDataBanner] = useState(false)
  const [years, setYears] = useState<number[]>([])
  const [searchParams] = useSearchParams()

  const { monthParam, yearParam } = useMemo(() => {
    return {
      monthParam: searchParams.get('month'),
      yearParam: searchParams.get('year'),
    }
  }, [searchParams])

  const { yearFilter, monthFilter, inputDate, setYearFilter, setMonthFilter } =
    useInsightsDatePicker(monthParam, yearParam)

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const linkedPlaidAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const hasLinkedPlaidAccounts = !isEmpty(linkedPlaidAccounts)
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  const trackInsights = useTrackFinancialInsightsButtonClick()

  useEffect(() => {
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const fetchYears = async () => {
      const years = await fetchInsightsTableDropdownOptions(
        INSIGHTS_TYPE.EXPENSES
      )(dispatch)

      if (years?.length) {
        setYears(years)
        setDisplaySampleDataBanner(false)
      } else {
        setYears([2024, 2023, 2022])
        setDisplaySampleDataBanner(true)
      }
    }
    fetchYears()
  }, [dispatch])

  const closeBanner = () => {
    toggleBanner()
    trackInsights({
      button: FinancialInsightsButtonType.CLOSE_BANNER,
      insightsType: INSIGHTS_TYPE.EXPENSES,
    })
  }

  const closeSampleDataBanner = () => {
    setDisplaySampleDataBanner(false)
  }

  return (
    <div
      style={{
        width: 'calc(100% + 60px)',
        height: '100%',
        marginTop: shouldDisplaySampleData ? -24 : 0,
        marginLeft: -40,
        overflow: 'hidden',
      }}
    >
      {shouldDisplaySampleData && (
        <Sticky>
          <GridRowColumn
            style={{
              backgroundColor: Colors.orange,
              padding: 20,
            }}
          >
            <Text color="white" textAlign="center">
              <Icon
                icon={solid('circle-exclamation')}
                color="white"
                style={{ marginRight: 8 }}
              />
              This is a <b>demo</b> page using <b>sample data.</b>
            </Text>
          </GridRowColumn>
        </Sticky>
      )}
      <br />

      <Grid
        style={{
          marginLeft: '40px',
          marginRight: '20px',
        }}
      >
        {!shouldDisplaySampleData && (
          <GridRowColumn>
            <InsightBreadcrumb month={monthFilter} year={yearFilter} />
          </GridRowColumn>
        )}
        <GridRowColumn
          short
          columnStyle={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: 24,
          }}
        >
          <Text as="h1"> Expenses </Text>
          <InsightsDatePicker
            monthFilter={monthFilter}
            yearFilter={yearFilter}
            handleMonthFilterChange={setMonthFilter}
            handleYearFilterChange={setYearFilter}
            insightsType={INSIGHTS_TYPE.INCOME}
          />
        </GridRowColumn>
        {displaySampleDataBanner && !hasFreeTrialPromoCode && (
          <GridRowColumn>
            <DisplaySampleDataBanner
              headerText="Your expense insights are coming soon"
              imageUrl="https://heard-images.s3.amazonaws.com/assets/notepad-pencil.png"
              onClose={closeSampleDataBanner}
              bodyContent={
                <>
                  Once we&apos;ve completed your books, you&apos;ll be able to
                  see personalized insights based on your transaction data.
                  <br />
                  <br />
                  In the meantime, you can view this page with sample data.
                  <br />
                  <br />
                  <Toggle
                    checked={shouldDisplaySampleData}
                    onChange={() =>
                      setShouldDisplaySampleData(!shouldDisplaySampleData)
                    }
                    label="View with sample data"
                  />
                </>
              }
            />
          </GridRowColumn>
        )}
        {displayBanner && !displaySampleDataBanner && (
          <GridRowColumn short>
            <BannerCard
              headerText="What are Business Expenses?"
              imageUrl="https://heard-images.s3.amazonaws.com/assets/chart-tablet-banner.svg"
              onClose={closeBanner}
              bodyContent={
                <>
                  Business expenses are ordinary and necessary costs incurred to
                  operate your business, like rent, professional fees, and
                  software services. These are your consistent expenses that
                  occur every month.
                  <br />
                  <br />
                  Note: What about my expenses that aren&apos;t every month? For
                  expenses that are less often, like paying for licenses or
                  continuing education, decide on a percentage to set aside for
                  your “Operating Expenses” in your{' '}
                  <Link to="/allocation-guides" size="small">
                    Allocation guide
                  </Link>
                  .
                </>
              }
            />
          </GridRowColumn>
        )}
        {!hasLinkedPlaidAccounts && (
          <GridRowColumn>
            <Alert>
              <Text>
                You do not have a connected bank account.{' '}
                <Link to={'/accounts#connected-institutions'}>
                  Link Business Account
                </Link>
              </Text>
            </Alert>
          </GridRowColumn>
        )}
        <UncategorizedTransactionBanner inputDate={inputDate} />
        <CurrentPanel
          inputDate={inputDate}
          insightsType={INSIGHTS_TYPE.EXPENSES}
          shouldDisplaySampleData={shouldDisplaySampleData}
        />
        <TrackInsightsSection
          inputDate={inputDate}
          type={INSIGHTS_TYPE.EXPENSES}
          shouldDisplaySampleData={shouldDisplaySampleData}
        />
        <GridRowColumn>
          <Text as="h2">
            Expenses Breakdown{' '}
            {shouldDisplaySampleData && (
              <Label color="orange" style={{ borderRadius: 5 }}>
                Sample data
              </Label>
            )}
          </Text>
        </GridRowColumn>
        <InsightsBreakdownTable
          inputDate={inputDate}
          insightsType={INSIGHTS_TYPE.EXPENSES}
          shouldDisplaySampleData={shouldDisplaySampleData}
          years={years}
        />
        <GridRowColumn>
          <Text as="h2">Learn More</Text>
        </GridRowColumn>
        <Grid.Row className="short" columns={isMobile ? 1 : 3}>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="How to Build a Budget for Your Therapy Practice"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/how-to-build-a-budget-for-your-therapy-practice"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="How to Determine if Something is a Tax Deduction for Therapists (with Examples)"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/how-to-determine-if-something-is-a-tax-deduction-for-therapists-with-examples"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
          <Grid.Column style={isMobile ? { marginBottom: 12 } : undefined}>
            <ArticleCard
              title="What Therapists Need to Know About Deducting Education Expenses"
              type={ARTICLE_TYPE.ARTICLE}
              url="https://www.joinheard.com/articles/what-therapists-need-to-know-about-deducting-education-expenses"
              insightsType={INSIGHTS_TYPE.EXPENSES}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default InsightsExpensesPanel
