import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Table,
  Dimmer,
  Loader,
  Container,
  Breadcrumb,
  Divider,
  Icon,
  Label,
  Accordion,
} from 'semantic-ui-react'
import moment from 'moment'

import UserTransactionsHeader from './UserTransactionsHeader'
import CurrencyFormatLabel from '../../shared/CurrencyFormatLabel'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import { fetchUserAllTransactionsIfNeeded } from '../../../actions/admin/transactionActions'
import '../TransactionTable.scss'
import { centsToDollars } from '../../../utils/currencyHelpers'
import UserBreadcrumbs from '../UserRecord/UserBreadcrumbs'
import { useReselector } from '../../../utils/sharedHooks'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { getGroupedImportedTransactionsForUser } from '../../../features/Transactions/transactions.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const TransactionTableRow = ({ transaction }: { transaction: Transaction }) => (
  <Table.Row>
    <Table.Cell>{transaction.id}</Table.Cell>
    <Table.Cell>
      {moment(transaction.date).format(DATE_FORMATS.DISPLAY_SHORT)}
    </Table.Cell>
    <Table.Cell>
      {transaction.accountTransactions && (
        <span>
          {transaction.accountTransactions?.plaidInstitutionName}:{' '}
          {transaction.accountTransactions?.name}{' '}
          {transaction.accountTransactions?.mask}
        </span>
      )}
      {!transaction.accountTransactions && <span>N/A</span>}
    </Table.Cell>
    <Table.Cell>{transaction.description}</Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyFormatLabel value={centsToDollars(transaction.amountInCents)} />
    </Table.Cell>
  </Table.Row>
)

const ImportAccordionList = ({
  accountName,
  title,
  transactions,
}: {
  accountName: string
  title: string
  transactions: Transaction[]
}) => {
  const [active, setActive] = useState(false)
  return (
    <Accordion fluid styled>
      <Accordion.Title
        active={active}
        index={0}
        onClick={() => setActive(!active)}
      >
        <Icon name="dropdown" />
        <Label content={accountName} fluid color="green" size="large" />
        <Label content={`Imported on: ${title}`} fluid size="large" />
      </Accordion.Title>

      <Accordion.Content active={active}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>tId</Table.HeaderCell>
              <Table.HeaderCell width={2}>Date</Table.HeaderCell>
              <Table.HeaderCell width={3}>Account</Table.HeaderCell>
              <Table.HeaderCell width={8}>Description</Table.HeaderCell>

              <Table.HeaderCell width={2}>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {transactions.map((transaction) => (
              <TransactionTableRow
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  )
}

const getAccountName = (transaction?: Transaction) => {
  if (transaction?.accountTransactions) {
    return `${transaction.accountTransactions?.plaidInstitutionName}: ${transaction.accountTransactions?.name} ${transaction.accountTransactions?.mask}`
  } else {
    return 'N/A'
  }
}

const ImportedTransactionsPanel = () => {
  const { userId } = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const transactions = useReselector(
    getGroupedImportedTransactionsForUser,
    userId
  )

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchAllUsersIfNeeded()),
        userId && dispatch(fetchUserAllTransactionsIfNeeded(userId)),
      ])

      setLoading(false)
    }

    fetch()
  }, [dispatch, userId])

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading all Transactions</Loader>
      </Dimmer>
    )
  }
  return (
    <Container id="needsClarificationTransactionsPanel">
      <UserBreadcrumbs userId={userId}>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section>
          <Link to={`/admin/finances/transactions/${userId}`}>
            Transactions
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Imported</Breadcrumb.Section>
      </UserBreadcrumbs>
      <Divider />

      <UserTransactionsHeader
        backlink={`/admin/finances/transactions/${userId}`}
        headerDescription={'Manually Imported Transactions'}
      />
      {Object.keys(transactions).map((transKey) => {
        const subTransactions = transactions[transKey]
        return (
          <ImportAccordionList
            title={transKey}
            transactions={subTransactions}
            key={transKey}
            accountName={getAccountName(subTransactions[0])}
          />
        )
      })}
      {Object.keys(transactions).length === 0 && (
        <div>
          <p>No manually imported transactions.</p>
        </div>
      )}
    </Container>
  )
}

export default ImportedTransactionsPanel
