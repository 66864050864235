import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import PayrollSetupList from './PayrollSetupList'
import {
  fetchEmployees,
  fetchEmployeeFederalTaxes,
  putUpdateEmployeeFederalTaxes,
  PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY,
  FILLING_STATUSES,
} from '../payrollActions'
import PrefilledNoticeContainer from './PrefilledNoticeContainer'
import {
  GEP_ENROLL_PATHS,
  ONBOARDING_STEPS,
  useUpdatePayrollOnboardingStep,
} from '../helpers'
import PayrollError from '../PayrollError'
import {
  Button,
  Card,
  FormikDropdown,
  FormikInput,
  GridRowColumn,
  Link,
  makeNumberSchema,
  makeReqStringSchema,
  Text,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import { getIsFetching } from '../../../reducers/fetch'
import {
  selectEnrollFederalFormDefaults,
  selectFirstEmployee,
} from '../payroll.selectors'
import { Gusto_EmployeeFederalTaxes } from '../generated_gusto_types'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useAnalyticsTrack } from '../../Amplitude'

const validationSchema = yup.object({
  filing_status: makeReqStringSchema({ field: 'filing status' }),
  extra_withholding: makeNumberSchema({
    field: 'extra withholding',
    allowedDecimals: 2,
  }),
  two_jobs: yup.string(),
  dependents_amount: makeNumberSchema({ field: 'dependents amount' }),
  other_income: makeNumberSchema({ field: 'other income', allowedDecimals: 2 }),
  deductions: makeNumberSchema({ field: 'deductions', allowedDecimals: 2 }),
})

const FederalTaxForm = ({
  taxes,
  employeeUuid,
}: {
  taxes: Gusto_EmployeeFederalTaxes
  employeeUuid?: string
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const track = useAnalyticsTrack()
  useUpdatePayrollOnboardingStep(ONBOARDING_STEPS.employeeFederalTaxes)

  const isPosting = useReselector(
    getIsFetching,
    PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY
  )
  const initialValues = useReselector(selectEnrollFederalFormDefaults, taxes)

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({
      filing_status,
      extra_withholding,
      deductions,
      two_jobs,
      other_income,
      dependents_amount,
    }) => {
      if (!employeeUuid) {
        return
      }

      const res = await putUpdateEmployeeFederalTaxes(employeeUuid, {
        version: taxes.version,
        filing_status,
        extra_withholding,
        two_jobs: two_jobs === 'Yes',
        dependents_amount,
        other_income,
        deductions,
        w4_data_type: 'rev_2020_w4',
      })(dispatch)

      if (res) {
        track('completed payroll step - employee federal taxes')
        navigate(GEP_ENROLL_PATHS.employeeState)
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text as="h2">Add yourself as an employee</Text>
        </GridRowColumn>
        <Divider />
        <GridRowColumn>
          <Text as="h2">Employee Federal Taxes</Text>
        </GridRowColumn>
        <PayrollError fetchKey={PUT_UPDATE_EMPLOYEE_FEDERAL_TAXES_KEY} />
        <GridRowColumn>
          <Text>
            The following information will be used to calculate your federal tax
            withholdings. To answer the below questions, please use{' '}
            <Link href="https://www.irs.gov/pub/irs-pdf/fw4.pdf" newPage>
              form W-4
            </Link>{' '}
            or the{' '}
            <Link
              href="https://apps.irs.gov/app/tax-withholding-estimator"
              newPage
            >
              IRS calculator
            </Link>
            .
          </Text>
        </GridRowColumn>
        <GridRowColumn width={10}>
          <FormikDropdown
            placeholder="Filing Status"
            optionValues={FILLING_STATUSES}
            name="filing_status"
            label={
              <>
                Federal Filing Status{' '}
                <Link
                  href="https://support.joinheard.com/hc/en-us/articles/4763580296727"
                  newPage
                >
                  Help me with this
                </Link>
              </>
            }
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikDropdown
            optionValues={['Yes', 'No']}
            name="two_jobs"
            label="Multiple Jobs (2c) (optional)"
            description="Includes spouse (if applicable). Answering 2c results in higher withholding, but to preserve privacy, this can be left unchecked. To learn more, read the IRS's instructions."
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikInput
            name="dependents_amount"
            label="Dependents Total (3) (if applicable)"
            description="Enter the results for line 3 from the IRS calculator or form W-4."
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikInput
            name="other_income"
            componentType="currency"
            label="Other income (4a) (optional)"
            description="Enter the results for line 4a from the IRS calculator or form W-4."
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikInput
            name="deductions"
            componentType="currency"
            label="Deductions (4b) (optional)"
            description="Enter the results for line 4b from the IRS calculator or form W-4."
            fullWidth
            required
          />
        </GridRowColumn>
        <GridRowColumn>
          <FormikInput
            name="extra_withholding"
            componentType="currency"
            label="Extra withholding (4c) (optional)"
            description="Enter the results for line 4c from the IRS calculator or form W-4."
            fullWidth
            required
          />
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={6}>
            <Button
              fullWidth
              variant="secondary"
              onClick={() => navigate(GEP_ENROLL_PATHS.employeeJob)}
            >
              Back
            </Button>
          </Grid.Column>
          <Grid.Column width={4} />
          <Grid.Column width={6}>
            <Button onClick={formik.submitForm} fullWidth loading={isPosting}>
              Save & Continue
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormikProvider>
  )
}

const PayrollEmployeeFederal = () => {
  const dispatch = useAppDispatch()
  const [federalTaxes, setFederalTaxes] = useState<Gusto_EmployeeFederalTaxes>()
  const firstEmployee = useReselector(selectFirstEmployee)

  useEffect(() => {
    dispatch(fetchEmployees())
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      if (!firstEmployee?.uuid) {
        return
      }
      const federalTaxesRes = await fetchEmployeeFederalTaxes(
        firstEmployee.uuid
      )(dispatch)

      if (federalTaxesRes) {
        setFederalTaxes(federalTaxesRes)
      }
    }

    fetch()
  }, [dispatch, firstEmployee?.uuid])

  return (
    <Container>
      <Card>
        <Grid>
          <GridRowColumn>
            <Text as="h1">Setting Up Payroll</Text>
          </GridRowColumn>
          <Divider />
          <PrefilledNoticeContainer />
          <Grid.Row>
            <Grid.Column width={9}>
              {/* form reinitialize isn't working without this check for some reason*/}
              {federalTaxes && (
                <FederalTaxForm
                  taxes={federalTaxes}
                  employeeUuid={firstEmployee?.uuid}
                />
              )}
            </Grid.Column>
            <Grid.Column width={7}>
              <PayrollSetupList />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}

export default PayrollEmployeeFederal
