import { Grid, Divider } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Truncate } from '@re-dev/react-truncate'

import {
  GridRowColumn,
  Button,
  Icon,
  Text,
  Card,
} from '../../../../../components/BaseComponents'
import {
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistResponse,
  TaxChecklistResponseValue,
} from '../taxChecklistQuestion.slice'

interface props {
  title: string
  setEditing: () => void
  readOnlyData: {
    responseText?: TaxChecklistResponseValue
    questionOverride?: string
    question: TaxChecklistQuestion | null
    options: TaxChecklistQuestionOption[]
    responses: TaxChecklistResponse[]
  }[]
}

const ReadOnlyForm = ({ title, setEditing, readOnlyData }: props) => (
  <Card type="body" backgroundColor="white" variant="raised">
    <Grid>
      <GridRowColumn
        columnStyle={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text as="h2">{title}</Text>
        <Button variant="secondary" onClick={setEditing}>
          <Icon icon={regular('pen')} />
        </Button>
      </GridRowColumn>
      <GridRowColumn short>
        <Divider />
      </GridRowColumn>
      {readOnlyData.map((qr) => (
        <Grid.Row key={qr.question?.id}>
          <Grid.Column
            width={7}
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'center',
            }}
          >
            <Text>
              {/* 
                  questionOverride makes it possible to use custom questions/descriptions
                  and match it with custom responseText. It exists so we don't have to mock 
                  the TaxChecklistQuestion format to create custom questions.
                */}
              {qr.question?.text ?? qr.questionOverride}
            </Text>
          </Grid.Column>
          <Grid.Column width={9}>
            <Text as="bodyLg">
              <Truncate>{qr.responseText}</Truncate>
            </Text>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  </Card>
)

export default ReadOnlyForm
