import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import { PayrollProfile } from '../../../Payroll/payrollProfile.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import {
  FETCH_PAYROLLS_FOR_USER_KEY,
  fetchPayrollsForUser,
} from '../../../Payroll/payrollActions'
import { Gusto_Payroll } from '../../../Payroll/generated_gusto_types'
import { DATE_FORMATS_LUXON } from '../../../../utils/dateHelpers'
import {
  GridRowColumn,
  Loader,
  Table,
  Text,
} from '../../../../components/BaseComponents'

const AdminPayrollOnboardedDashboard = ({
  payrollProfile,
}: {
  payrollProfile: PayrollProfile
}) => {
  const dispatch = useAppDispatch()
  const loading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_PAYROLLS_FOR_USER_KEY(payrollProfile.userId)
  )
  const [payrolls, setPayrolls] = useState<Gusto_Payroll[]>([])

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchPayrollsForUser(payrollProfile.userId, {
        start_date: DateTime.now().toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
        end_date: DateTime.now()
          .plus({ months: 3, day: -1 })
          .toFormat(DATE_FORMATS_LUXON.GUSTO_SUBMIT),
        payroll_types: ['regular', 'off_cycle'],
      })(dispatch)

      if (res) {
        setPayrolls(res.slice(0, 3))
      }
    }

    fetch()
  }, [dispatch, payrollProfile.userId])

  return (
    <>
      <GridRowColumn>
        <Text as="h3">Payroll Details</Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <b>Gusto Company UUID</b>: {payrollProfile.gustoCompanyUuid}
        </Text>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <b>Number of Team Members:</b>{' '}
          {String(payrollProfile.numberOfTeamMembers)}
        </Text>
      </GridRowColumn>

      <GridRowColumn short>
        <Text>Upcoming {payrolls.length} Payrolls</Text>
      </GridRowColumn>

      <GridRowColumn short>
        <Loader loading={loading} />
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pay Period</Table.HeaderCell>
              <Table.HeaderCell>Deadline to Process Payroll</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {payrolls.map((payroll) => (
            <Table.Row key={payroll.payroll_uuid}>
              <Table.Cell>
                {payroll.pay_period.start_date}
                {' - '}
                {payroll.pay_period.end_date}
              </Table.Cell>
              <Table.Cell>{payroll.payroll_deadline}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </GridRowColumn>
      <GridRowColumn short>
        <Text>
          <i>To view all Payrolls for this user, please visit Gusto</i>
        </Text>
      </GridRowColumn>
    </>
  )
}

export default AdminPayrollOnboardedDashboard
