import moment from 'moment'
import { Button, Table, Text } from '../../../../components/BaseComponents'
import { getUser } from '../../../../reducers/auth/user.selectors'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectPathwayByIdentifier } from '../qaFeatures.selectors'
import {
  deleteQAUser,
  DELETE_QA_USER_KEY,
  QAUser,
  resetQAUser,
  RESET_QA_USER_KEY,
} from '../qaUsers.slice'
import { Truncate } from '@re-dev/react-truncate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getIsFetching } from '../../../../reducers/fetch'
import { selectCSVRolesForUser } from '../qaUsers.selectors'
import AutoCompleteTqModal from './AutoCompleteTqModal'
import { selectCurrentAnnualTaxYear } from '../../AnnualTaxDetails/annualTaxDetails.selector'
import { selectAdminAnnualTaxFilingsByYearForUser } from '../../Taxes/adminAnnualTaxFilings.selector'
import { fetchAdminAnnualTaxFilings } from '../../Taxes/adminAnnualTaxFilings.slice'
import { TaxfyleJobStatus } from '../../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { TaxEntityTypeOptions } from '../../../Taxes/taxConstants'
import { useGodViewLogin } from '../../../../utils/adminHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'

const UNSET_VALUE = '--'

const UserTableRow = ({ user }: { user: QAUser }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const currentUser = useReselector(getUser)
  const pathway = useReselector(
    selectPathwayByIdentifier,
    user.qaDetails.pathway.identifier
  )
  const resetLoading = useReselector(getIsFetching, RESET_QA_USER_KEY(user.id))
  const deleteLoading = useReselector(
    getIsFetching,
    DELETE_QA_USER_KEY(user.id)
  )
  const dispatch = useAppDispatch()
  const actionsDisabled = !currentUser || currentUser?.id === user.id

  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxFiling = useReselector(
    selectAdminAnnualTaxFilingsByYearForUser,
    taxYear,
    user.id
  )
  const hasUnsubmittedForms = useMemo(() => {
    const filingForms = annualTaxFiling?.annualTaxFilingForms ?? []
    return (
      filingForms.length > 0 &&
      filingForms.some(
        (form) =>
          !form.taxfyleJob ||
          form.taxfyleJob.jobStatus === TaxfyleJobStatus.underConstruction
      )
    )
  }, [annualTaxFiling?.annualTaxFilingForms])

  const onGodViewClicked = useGodViewLogin()

  const onResetClicked = useCallback(async () => {
    await resetQAUser(user.id)(dispatch)
  }, [dispatch, user.id])

  const onDeleteClicked = useCallback(() => {
    deleteQAUser(user.id)(dispatch)
  }, [dispatch, user.id])

  const roles = useReselector(selectCSVRolesForUser, user.id)
  const taxEntityType = user.financialProfile?.taxEntityType
    ? TaxEntityTypeOptions.find(
        (o) => o.key === user.financialProfile?.taxEntityType
      )?.text || 'cannot find'
    : UNSET_VALUE

  const lastReset = user.qaDetails.lastResetAt
    ? moment(user.qaDetails.lastResetAt).fromNow()
    : UNSET_VALUE

  const lastActive = user.qaDetails.lastActiveAt
    ? moment(user.qaDetails.lastActiveAt).fromNow()
    : UNSET_VALUE

  return (
    <Table.Row key={`qa-user-${user.id}`}>
      <Table.Cell>{user.id}</Table.Cell>
      <Table.Cell>{`${user.firstName} ${user.lastName}`}</Table.Cell>
      <Table.Cell title={user.email} width={2}>
        <Truncate width={100}>{user.email}</Truncate>
      </Table.Cell>
      <Table.Cell>{roles}</Table.Cell>
      <Table.Cell>{taxEntityType}</Table.Cell>
      <Table.Cell>{lastReset}</Table.Cell>
      <Table.Cell>{lastActive}</Table.Cell>
      <Table.Cell>{pathway?.name}</Table.Cell>
      <Table.Cell disabled={actionsDisabled} style={{ fontSize: '95%' }}>
        {!actionsDisabled && (
          <>
            <Button
              style={{ padding: 8 }}
              onClick={() => onGodViewClicked(user)}
              fullWidth
              variant="actionLink"
            >
              Godview
            </Button>
            <Button
              style={{ padding: 8 }}
              disabled={resetLoading}
              loading={resetLoading}
              onClick={onResetClicked}
              fullWidth
              variant="actionLink"
            >
              Reset State
            </Button>
            {hasUnsubmittedForms && (
              <Button
                style={{ padding: 8 }}
                onClick={() => setModalOpen(true)}
                fullWidth
                variant="actionLink"
              >
                Autocomplete TQ
              </Button>
            )}
            <Button
              style={{ padding: 8 }}
              disabled={deleteLoading}
              loading={deleteLoading}
              onClick={onDeleteClicked}
              fullWidth
              variant="actionLink"
            >
              Delete
            </Button>
          </>
        )}
        {actionsDisabled && (
          <Text as="bodySm">Currently logged-in as this user</Text>
        )}
      </Table.Cell>
      <AutoCompleteTqModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        userId={user.id}
      />
    </Table.Row>
  )
}

const UserTable = ({ users }: { users: QAUser[] }) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  useEffect(() => {
    fetchAdminAnnualTaxFilings({
      page: 1,
      limit: users.length,
      year: taxYear,
      userIds: users.map((u) => u.id),
    })(dispatch)
  }, [dispatch, taxYear, users])

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>id</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Role</Table.HeaderCell>
        <Table.HeaderCell>Tax Entity Type</Table.HeaderCell>
        <Table.HeaderCell>Last Reset</Table.HeaderCell>
        <Table.HeaderCell>Last Active</Table.HeaderCell>
        <Table.HeaderCell>Pathway</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Actions
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {users?.map((user) => (
          <UserTableRow key={`qa-user-${user.id}`} user={user} />
        ))}
      </Table.Body>
    </Table>
  )
}

export default UserTable
