import { useCallback, useMemo, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'

import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import { UPDATE_USER_KEY } from '../../../../../../actions/userActions'
import {
  GridRowColumn,
  Alert,
  Loader,
} from '../../../../../../components/BaseComponents'
import PageHeader from '../../../../../../components/shared/PageHeader'
import {
  selectErrorsForKeys,
  selectIsFetchingForKeys,
  invalidateFetch,
} from '../../../../../../reducers/fetch'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import {
  useReselector,
  useScrollRef,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../../annualTaxFilingForms.slice'
import CheckYourDetailsPanel from '../../Shared/PersonalDetails/CheckYourDetailsPanel'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  postUpdateTaxQuestionnaireResponses,
  fetchUserTaxQuestionnaire,
  UPSERT_DEPENDENTS_KEY,
  DELETED_DEPENDENTS_KEY,
  FETCH_DEPENDENTS_KEY,
} from '../../taxChecklistQuestion.actions'
import BusinessInfoPanel from './BusinessInfoPanel'
import { fetchAboundTaxDocumentsForPayer } from '../../../aboundAnnualTaxFilings.slice'
import LocationInfoPanel from './LocationInfo'
import HealthcareInfoPanel from './HealthcareInfoPanel'
import FilingStatusPanel from './FilingStatusPanel'
import SpousePanel from './SpousePanel'
import DependentsPanel from './Dependents'
import { UPDATE_FINANCIAL_PROFILE_KEY } from '../../../../../../actions/financialProfileActions'
import Form1040ReviewPanel from './ReviewPanel'
import {
  createIsSomeEnum,
  filterNulls,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import { CHECK_YOUR_DETAILS_1040 } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { fetchAllEoyReviewStepsIfNeeded } from '../../Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { fetchUserEoyReviewProgress } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import useProgressSteps, {
  StepUpdates,
} from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { selectIsMarriedFilingJointlyOrQualifyingWidow } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { EXTENSION_REQUEST_SCREENS } from '../../../ExtensionSurvey/Combined'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import { fetchUserDocuments } from '../../../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../../../taxUserDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

export enum FORM_1040_DETAILS_SCREENS {
  confirmDetails = 'confirm-details',
  businessInfo = 'business-info',
  locationInfo = 'location-info',
  healthcareInfo = 'healthcare-info',
  filingStatus = 'filing-status',
  spouse = 'spouse',
  dependents = 'dependents',
  review = 'review',
  skipNavigation = 'skip-navigation',
}

export const isForm1040DetailsScreen = createIsSomeEnum(
  FORM_1040_DETAILS_SCREENS
)

type Form1040ScreenOptions =
  | FORM_1040_DETAILS_SCREENS
  | EXTENSION_REQUEST_SCREENS
  | null

export interface Form1040DetailsProps {
  goToNextStep: (
    data: Partial<TaxChecklistResponse | undefined>[] | null,
    newScreen: Form1040ScreenOptions | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => Form1040ScreenOptions
  ) => Promise<boolean>
  goBack: (newScreen: Form1040ScreenOptions | null) => void
  nextScreen?: Form1040ScreenOptions | null
  previousScreen?: Form1040ScreenOptions | null
  taxYear: string
  isReadOnly?: boolean
}

const Form1040Details = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const backLink = '/taxes/annual/tax_checklist'
  const { setScreen, currentScreen } = useSetScreen({
    backLink,
    reviewScreen: FORM_1040_DETAILS_SCREENS.review,
  })
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { scrollRef, scrollToRef } = useScrollRef()

  const user = useReselector(getCurrentUser)
  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: CHECK_YOUR_DETAILS_1040 }
  )
  const needsSpouseInfo = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    taxYear
  )

  const allFetchKeys = useMemo(
    () => [
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      UPDATE_USER_KEY,
      UPDATE_FINANCIAL_PROFILE_KEY(user?.financialProfile?.id),
      UPSERT_DEPENDENTS_KEY,
      DELETED_DEPENDENTS_KEY,
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      FETCH_DEPENDENTS_KEY,
      ...progressFetchKeys,
    ],
    [user?.financialProfile?.id, taxYear, progressFetchKeys]
  )

  const updateError = useReselector(selectErrorsForKeys, allFetchKeys)
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse | undefined>[] | null,
      newScreen: Form1040ScreenOptions,
      stepUpdates: StepUpdates = {}
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))
      const parsedData = data && filterNulls(data)

      if (parsedData?.length) {
        const res = await dispatch(
          postUpdateTaxQuestionnaireResponses(parsedData)
        )

        if (!res) {
          return false
        }
      }

      const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
      if (!progressUpdatesSuccessful) {
        return false
      }
      if (FORM_1040_DETAILS_SCREENS.skipNavigation !== newScreen) {
        setScreen(newScreen)
      }
      return true
    },
    [dispatch, setScreen, allFetchKeys, updateProgressData]
  )

  const screenConfig = useMemo(() => {
    const props = { goToNextStep: saveFormData, goBack: setScreen, taxYear }
    const screens = [
      {
        component: () => <CheckYourDetailsPanel {...props} formId={formId} />,
        screenName: FORM_1040_DETAILS_SCREENS.confirmDetails,
        step: 0,
      },
      {
        component: () => <FilingStatusPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.filingStatus,
        step: 1,
      },
      {
        component: () => <BusinessInfoPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.businessInfo,
        step: 2,
      },
      {
        component: () => <LocationInfoPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.locationInfo,
        step: 2,
        order: 3,
      },
      {
        component: () => <HealthcareInfoPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.healthcareInfo,
        step: 2,
        order: 4,
      },
      {
        component: () => <DependentsPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.dependents,
        step: 3,
        order: needsSpouseInfo ? 6 : 5,
      },
      {
        component: () => <Form1040ReviewPanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.review,
        step: 4,
        order: needsSpouseInfo ? 7 : 6,
      },
    ]
    if (needsSpouseInfo) {
      screens.splice(5, 0, {
        component: () => <SpousePanel {...props} />,
        screenName: FORM_1040_DETAILS_SCREENS.spouse,
        step: 3,
        order: 5,
      })
    }
    return screens
  }, [saveFormData, setScreen, taxYear, needsSpouseInfo, formId])

  const { progressBar, content } = useFormFlow({
    steps: [
      'Confirm Details',
      'Filing Status',
      `Update Details for ${taxYear}`,
      'Spouse and Dependents',
      'Review',
    ],
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchUserEoyReviewProgress(taxYear))
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch, taxYear])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(FORM_1040_DETAILS_SCREENS.confirmDetails, true)
    }
  }, [setScreen, currentScreen])

  useEffect(() => {
    scrollToRef()
  }, [currentScreen, scrollToRef])

  return (
    <>
      <span ref={scrollRef} />
      <PageHeader
        backControl={
          readOnly
            ? { link: backLink, text: 'Back to Personal Tax Checklist' }
            : undefined
        }
        header={`${taxYear} Personal Tax Questionnaire: Check Your Details`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!readOnly && <GridRowColumn>{progressBar}</GridRowColumn>}
        {updateError.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{updateError[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default Form1040Details
