import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import moment from 'moment'
import { DateTime } from 'luxon'

import { ReduxState } from '../../../utils/typeHelpers'
import { AnnualTaxDetailStatuses } from './annualTaxDetails.slice'
import { convertUtcToLocalDate } from '../../../utils/dateHelpers'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { selectTaxEntityType } from '../../../selectors/user.selectors'

export const selectAnnualTaxDetails = (state: ReduxState) =>
  state.annualTaxDetails

/*
  Select the single "current" record if exists
*/
export const selectCurrentAnnualTaxDetails = createSelector(
  [selectAnnualTaxDetails],
  (annualTaxDetails) =>
    Object.values(annualTaxDetails).find(
      (d) => d.status === AnnualTaxDetailStatuses.current
    )
)

/*
  Select the single "current" year if exists, if not default
*/
export const selectCurrentAnnualTaxYear = createSelector(
  [selectCurrentAnnualTaxDetails],
  (annualTaxDetails): string => {
    const now = moment()
    const taxYearDefault =
      now.month() < 10
        ? now.subtract(1, 'years').year().toString()
        : now.year().toString()
    return annualTaxDetails?.taxYear || taxYearDefault
  }
)

/*
  Selects all records sorted by taxYear desc
*/
export const selectAllAnnualTaxDetailsSorted = createSelector(
  [selectAnnualTaxDetails],
  (annualTaxDetails) => {
    return orderBy(
      Object.values(annualTaxDetails),
      [(d) => Number(d.taxYear)],
      ['desc']
    )
  }
)

/*
   Select by year
*/
export const selectTaxDetailsByYear = createSelector(
  selectAnnualTaxDetails,
  (_: unknown, year: string) => year,
  (annualTaxDetails, year) => {
    return Object.values(annualTaxDetails).find((d) => d.taxYear === year)
  }
)

/**
 * Late joiners can opt into previous year taxes during sign-up
 * in this timeframe
 */
export const selectCanOptIntoPreviousTaxYear = createSelector(
  selectCurrentAnnualTaxDetails,
  selectTaxEntityType,
  (taxDetails, taxEntity) => {
    if (!taxDetails) {
      return false
    }
    const optInCutOff =
      taxEntity === TAX_ENTITY_TYPES.form_1040
        ? convertUtcToLocalDate(
            taxDetails?.form_1040_new_user_tax_cutoff_date
          )?.endOf('day')
        : convertUtcToLocalDate(
            taxDetails?.form_1120_s_new_user_tax_cutoff_date
          )?.endOf('day')

    const lateJoinerAfterDate = convertUtcToLocalDate(
      taxDetails?.new_user_cutoff_date
    )?.endOf('day')
    if (!optInCutOff || !lateJoinerAfterDate) {
      return false
    }
    const NOW = DateTime.now()
    return NOW > lateJoinerAfterDate && NOW <= optInCutOff
  }
)
